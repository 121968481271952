<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addUtilities") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="utility.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="utility.name"
          @input="validator$.utility.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="utility.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="utility.description"
          @input="validator$.utility.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateUtility">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useUtilitiesNew } from "../use/master/utilities/useUtilitiesNew"

export default {
  name: "ModalAddUtilities",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { utility, validator$, createUtility } = useUtilitiesNew()
    return {
      utility,
      validator$,
      createUtility,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateUtility() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createUtility(this.utility)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewUtilityOpen() {
      this.utility.name = null
      this.utility.description = null
      this.validator$.utility.$reset()
    },
  },
}
</script>
