<template>
  <EcFlex class="w-full h-screen items-center justify-center bg-c4-200">
    <EcBox class="text-cWhite">
      <EcText class="text-6xl text-cWhite font-bold"> 404 </EcText>
      <EcText class="text-xl text-cWhite">Sorry, this page could not be found</EcText>
      <EcText class="text-xl text-cWhite"
        >Please contact <a class="font-bold font-2xl" href="mailto:info@readybc.com.au">info@readybc.com.au</a> if you think this
        is in error</EcText
      >
    </EcBox>
  </EcFlex>
</template>

<script></script>
