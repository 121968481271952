<template>
  <EcModalSimple :isVisible="isModalAddNewRoleOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("activity.role.new") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="role.label"
          :label="$t('activity.role.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="role.label"
          @input="validator$.role.label.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="role.description"
          :label="$t('activity.role.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="role.description"
          @input="validator$.role.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewRoleModal">
          {{ $t("activity.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateRole">
          {{ $t("activity.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useRoleNew } from "../use/role/useRoleNew"

export default {
  name: "ModalAddRole",

  emits: ["handleCloseAddNewRoleModal", "handleCallbackAddNewRole"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewRoleOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { role, validator$, createRole } = useRoleNew()
    return {
      role,
      validator$,
      createRole,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateRole() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createRole(this.role)

      if (response) {
        this.handleCloseAddNewRoleModal()
        this.handleCallbackAddNewRole()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewRoleModal() {
      this.$emit("handleCloseAddNewRoleModal")
    },

    handleCallbackAddNewRole() {
      this.$emit("handleCallbackAddNewRole")
    },
  },

  watch: {
    isModalAddNewRoleOpen() {
      this.role.label = null
      this.role.description = null
      this.validator$.role.$reset()
    },
  },
}
</script>
