import isEmpty from "lodash.isempty"

export function useActivityWithExcelRecord() {
  // const { createActivityMasterData } = useCreateBulkActivities()

  /**
   * Read data from clipboard
   *
   * @returns
   */
  const readActivityMultipleRowsFromClipboard = async () => {
    try {
      let excelTextRows = []

      const data = await window.navigator?.clipboard?.readText()

      if (data?.length > 0) {
        excelTextRows = data?.trim()?.split("$$")
      }

      return excelTextRows
    } catch (error) {
      console.warn("Brower does not support copy from Clipboard", error)
    }
  }

  /**
   * Parse data
   * @param {*} excelTextRows
   * @returns
   */
  const parseExcelRows = async () => {
    const parsedActivities = []
    const excelTextRows = await readActivityMultipleRowsFromClipboard()

    excelTextRows?.forEach((excelTextRow) => {
      const parsedRow = parseActivityRow(excelTextRow)

      if (parsedRow) {
        parsedActivities.push(parsedRow)
      }
    })

    return parsedActivities
  }

  /**
   * Parse data
   * @returns
   */
  const parseActivityRow = (excelTextRow) => {
    if (excelTextRow?.length < 0) {
      return
    }

    try {
      const columnMapping = {
        0: "process_no", // A
        1: "business_unit", // B
        2: "division", // C
        3: "name", // D
        4: "location", // E
        5: "roles", // F
        6: "alternative_roles", // G
        7: "min_people", // H
        8: "is_remote", // I
        9: "remote_access_factors", // J
        10: "on_site_requires", // K
        11: "applications", // L
        12: "is_reliant_server", // M
        13: "equipment", // N
        14: "utilities", // O
        15: "internal_dependencies", // P
        16: "suppliers", // Q
        17: "bcm_assessed", // R
        18: "tolerable_period_disruption", // S
        19: "reason_choose_dependent_time", // T
        20: "recoveryTime", // U
        21: "is_rto_tested", // V
        22: "threat_ranking", // W
        23: "3_workaround", // X
        24: "3_feasible_duration", // Y
        25: "3_feasible_activation", // Z
        26: "1_workaround", // AA
        27: "1_feasible_duration", // AB
        28: "1_feasible_activation", // AC
        29: "5_workaround", // AD
        30: "5_feasible_duration", // AE
        31: "5_feasible_activation", // AF
        32: "4_workaround", // AG
        33: "4_feasible_duration", // AH
        34: "4_feasible_activation", // AI
        35: "2_workaround", // AJ
        36: "2_feasible_duration", // AK
        37: "2_feasible_activation", // AL
      }

      const activity = {}

      if (excelTextRow?.length > 0) {
        const parsedValues = excelTextRow?.replace(/^[\t\r\n]+/, "")?.split("\t")

        parsedValues.forEach((value, index) => {
          const property = columnMapping[index]

          activity[property] = value?.replace(/(\r\n|\n)/g, ",")?.replace(/"/g, "")
        })

        return activity
      }
    } catch (e) {
      console.warn("Brower does not support copy from Clipboard", e)
    }
  }

  /**
   * Fill out data
   */
  const fillOutActivityDataFromParsedRow = (activityRow, parsedActivity) => {
    activityRow.name = parsedActivity?.name
    activityRow.is_remote = parsedActivity?.is_remote === "Yes"
    activityRow.on_site_requires = parsedActivity?.on_site_requires
    activityRow.reason_choose_dependent_time = parsedActivity?.reason_choose_dependent_time
    activityRow.is_reliant_server = parsedActivity?.is_reliant_server === "Yes" || parsedActivity?.is_reliant_server?.length > 0

    // MTPD

    // RTO
    activityRow.is_rto_tested = parsedActivity?.is_rto_tested === "Yes"

    // Min people
    fillOutActivityMinPeople(activityRow, parsedActivity)
  }

  /**
   *
   * @param {*} activityRow
   * @param {*} parsedActivity
   */
  const fillOutActivityMinPeople = (activityRow, parsedActivity) => {
    const minPeopleStrs = parsedActivity?.min_people?.replace(/[^0-9,]/g, "")?.split(",")

    let minPeopleNum = 0

    minPeopleStrs?.forEach((minPeopleStr) => {
      if (minPeopleStr?.length > 0) {
        minPeopleNum += Number.parseInt(minPeopleStr?.trim()) || 0
      }
    })

    activityRow.min_people = minPeopleNum
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingDivisions
   */
  const excelFillInDivision = (activity, parsedActivity, existingDivisions) => {
    if (parsedActivity && parsedActivity?.roles?.length > 0) {
      // Handle

      existingDivisions?.forEach((existingDivision) => {
        if (existingDivision?.name?.trim()?.toLowerCase() === parsedActivity?.division?.trim()?.toLowerCase()) {
          activity.division = existingDivision
        }
      })
    }
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingBU
   */
  const excelFillInBU = (activity, parsedActivity, existingBUs) => {
    existingBUs?.forEach((existingBU) => {
      if (existingBU?.name?.trim()?.toLowerCase() === parsedActivity?.business_unit?.trim()?.toLowerCase()) {
        activity.business_unit = existingBU
      }
    })
  }

  /**
   *
   * @param {*} activity
   * @param {*} parsedActivity
   * @param {*} existingLocations
   */
  const excelFillInLocation = (activity, parsedActivity, existingLocations) => {
    const locations = parsedActivity?.location?.split(",")
    if (isEmpty(locations)) {
      return
    }

    activity.primary_locations = []
    existingLocations?.forEach((existingLocation) => {
      locations?.forEach((parsedLocation) => {
        if (existingLocation?.name?.trim()?.toLowerCase() === parsedLocation?.trim()?.toLowerCase()) {
          activity.primary_locations.push(existingLocation)
        }
      })
    })
  }

  /**
   * Fill in roles
   */
  const excelFillInRoles = (activity, parsedActivity, existingRoles, newRoleCallback) => {
    if (parsedActivity && parsedActivity?.roles?.length > 0) {
      // Handle
      let createNew = true
      const rolesStrs = parsedActivity?.roles?.split(",")
      activity.roles = []

      rolesStrs.forEach((roleName) => {
        existingRoles?.forEach((existingRole) => {
          if (existingRole?.name?.trim()?.toLowerCase() === roleName?.trim()?.toLowerCase()) {
            activity.roles?.push(existingRole)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newRoleCallback(option)
        }
      })
    }
  }

  /**
   * Fill in alternative roles
   */
  const excelFillInAlternativeRoles = (activity, parsedActivity, existingAlternativeRoles, newAlternativeRoleCallback) => {
    if (parsedActivity && parsedActivity?.alternative_roles?.length > 0) {
      // Handle
      let createNew = true
      const alternativeRolesStrs = parsedActivity?.alternative_roles.split(",")
      activity.alternative_roles = []

      alternativeRolesStrs.forEach((roleName) => {
        existingAlternativeRoles?.forEach((existingRole) => {
          if (existingRole?.name?.trim()?.toLowerCase() === roleName?.trim()?.toLowerCase()) {
            activity.alternative_roles?.push(existingRole)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newAlternativeRoleCallback(option)
        }
      })
    }
  }

  /**
   * Fill in RAFs
   */
  const excelFillInRemoteAccessFactors = (activity, parsedActivity, existingRAFs, newRAFCallback) => {
    if (parsedActivity && parsedActivity?.remote_access_factors?.length > 0) {
      // Handle
      let createNew = true
      const rafStrs = parsedActivity?.remote_access_factors.split(",")
      activity.remote_access_factors = []

      rafStrs.forEach((rafName) => {
        existingRAFs?.forEach((existingRAF) => {
          if (existingRAF?.name?.trim()?.toLowerCase() === rafName?.trim()?.toLowerCase()) {
            activity.remote_access_factors?.push(existingRAF)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newRAFCallback(option)
        }
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillInApps = (activity, parsedActivity, existingApps, newAppCallback) => {
    if (parsedActivity && parsedActivity?.applications?.length > 0) {
      // Handle
      let createNew = true
      const appStrs = parsedActivity?.applications.split(",")
      activity.applications = []

      appStrs.forEach((appName) => {
        existingApps?.forEach((existingApp) => {
          if (existingApp?.name?.trim()?.toLowerCase() === appName?.trim()?.toLowerCase()) {
            activity.applications?.push(existingApp)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newAppCallback(option)
        }
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillUtilities = (activity, parsedActivity, existingUtilities, newUtilityCallback) => {
    if (parsedActivity && parsedActivity?.utilities?.length > 0) {
      // Handle
      let createNew = true
      const utilityStrs = parsedActivity?.utilities.split(",")
      activity.utilities = []

      utilityStrs.forEach((utilityName) => {
        existingUtilities?.forEach((existingUtility) => {
          if (existingUtility?.name?.trim().toLowerCase() === utilityName?.trim().toLowerCase()) {
            activity.utilities?.push(existingUtility)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newUtilityCallback(option)
        }
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillEquipment = (activity, parsedActivity, existingEquipment, newEquipmentCallback) => {
    if (parsedActivity && parsedActivity?.equipment?.length > 0) {
      // Handle
      let createNew = true
      const equipmentStrs = parsedActivity?.equipment.split(",")
      activity.equipments = []

      equipmentStrs.forEach((equipmentName) => {
        existingEquipment?.forEach((existingEqm) => {
          if (existingEqm?.name?.trim()?.toLowerCase() === equipmentName?.trim()?.toLowerCase()) {
            activity.equipments?.push(existingEqm)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newEquipmentCallback(option)
        }
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillInternalDependencies = (activity, parsedActivity, internalDependencies) => {
    if (parsedActivity && parsedActivity?.internal_dependencies?.length > 0) {
      // Handle

      const dependencyStrs = parsedActivity?.internal_dependencies.split(",")

      activity.internal_dependencies = []

      dependencyStrs.forEach((dependencyName) => {
        if (dependencyName?.length > 0) {
          internalDependencies?.forEach((dependency) => {
            if (
              dependency?.name?.toLowerCase()?.includes(dependencyName?.toLowerCase()) ||
              dependency?.transformName?.toLowerCase()?.includes(dependencyName?.toLowerCase())
            ) {
              activity.internal_dependencies.push(dependency)
            }
          })
        }
      })
    }
  }

  /**
   * Fill in Apps
   */
  const excelFillSuppliers = (activity, parsedActivity, existingSuppliers, newSupplierCallback) => {
    if (parsedActivity && parsedActivity?.suppliers?.length > 0) {
      // Handle
      let createNew = true
      const supplierStrs = parsedActivity?.suppliers.split(",")
      activity.suppliers = []

      supplierStrs.forEach((supplierName) => {
        existingSuppliers?.forEach((existingSupplier) => {
          if (existingSupplier?.name?.trim()?.toLowerCase() === supplierName?.trim()?.toLowerCase()) {
            const supplier = JSON.parse(JSON.stringify(existingSupplier))
            // Clone for detach reference
            activity.suppliers?.push(supplier)
            createNew = false
          }
        })

        /**
         * Temporary disabled
         */
        if (createNew) {
          // const option = createActivityMasterData()
          // newSupplierCallback(option)
        }
      })
    }
  }

  /**
   * Fill in BCM Assessed
   */
  const excelFillBcmAssessed = (activity, parsedActivity) => {
    if (!parsedActivity || parsedActivity?.suppliers?.length === 0 || parsedActivity?.bcm_assessed.length === 0) {
      return
    }

    const bcmLine = parsedActivity?.bcm_assessed.split(",")

    bcmLine.forEach((bcmAssessed) => {
      const bcmAssessedData = bcmAssessed?.split("-") ?? null
      const supplierName = bcmAssessedData[0] ?? null
      const assessedMethod = bcmAssessedData[2] ?? null

      if (!bcmAssessedData || !supplierName || !assessedMethod) {
        return
      }
      const suppliers = activity?.suppliers
      const supplier = suppliers?.find((item) => item.name?.trim()?.toLowerCase() === supplierName?.trim()?.toLowerCase()) ?? null

      if (supplier) {
        supplier.assessed_methods = assessedMethod?.trim()
      }
    })
  }

  /**
   * Fill in MTPD
   */
  const excelFillMTPD = (activity, parsedActivity, mtpdTimeOptions) => {
    if (parsedActivity && parsedActivity?.tolerable_period_disruption?.length > 0) {
      // Handle

      mtpdTimeOptions?.forEach((existingMtpdTimeOption) => {
        if (
          existingMtpdTimeOption?.name?.trim()?.toLowerCase() ===
          parsedActivity?.tolerable_period_disruption?.trim()?.toLowerCase()
        ) {
          activity.tolerable_period_disruption = existingMtpdTimeOption?.uid
        }
      })
    }
  }

  /**
   * Fill in MTPD
   */
  const excelFillRTO = (activity, parsedActivity, rtoTimeOptions) => {
    if (parsedActivity && parsedActivity?.tolerable_period_disruption?.length > 0) {
      // Handle

      rtoTimeOptions?.forEach((rtoTimeOption) => {
        if (rtoTimeOption?.name?.trim()?.toLowerCase() === parsedActivity?.recoveryTime?.trim()?.toLowerCase()) {
          activity.recoveryTime = rtoTimeOption?.uid
        }
      })
    }
  }

  /**
   * Fill in Scenarios
   */
  const excelFillInScenarios = (activity, parsedActivity, disruptionScenarios) => {
    if (parsedActivity && parsedActivity?.threat_ranking?.length > 0) {
      // Handle

      const mapScenarios = {
        1: "Loss of IT Systems",
        2: "Critical Supplier Disruption",
        3: "Loss of Personnel",
        4: "Loss of Utilities or Equipment",
        5: "Denial of Access to Site",
      }

      const threatRankingStrs = parsedActivity?.threat_ranking?.split(",")
      activity.disruption_scenarios = []

      // With give ranking strings
      threatRankingStrs?.forEach((threatRankingStr) => {
        const threatRanking = threatRankingStr?.replace(/\D/g, "")?.trim()

        // Find the mapped scenarios

        if (mapScenarios[threatRanking]) {
          // Loop through scenarios

          disruptionScenarios?.forEach((disruptionScenario) => {
            // If the mapped scenario and the value from DB is matched
            if (mapScenarios[threatRanking]?.trim()?.toLowerCase() === disruptionScenario?.name?.trim()?.toLowerCase()) {
              const scenario = { ...disruptionScenario }

              // Workaround
              const workaround = parsedActivity[threatRanking + "_workaround"]
              const workaroundFeasibleDuration = parsedActivity[threatRanking + "_feasible_duration"]
              const workaroundFeasibleActivation = parsedActivity[threatRanking + "_feasible_activation"]

              if (workaround?.length > 0) {
                // scenario.workaround_option = 1

                // Solution
                scenario.workaround_solution = workaround

                // Duration
                scenario.workaround_feasibly = workaroundFeasibleDuration

                // Activation
                scenario.feasible_activation = workaroundFeasibleActivation
              }

              // Push the disruption scenario
              activity.disruption_scenarios?.push(scenario)
            }
          })
        }
      })
    }
  }

  return {
    parseExcelRows,
    fillOutActivityDataFromParsedRow,
    excelFillInDivision,
    excelFillInBU,
    excelFillInLocation,
    excelFillInRoles,
    excelFillInAlternativeRoles,
    excelFillInRemoteAccessFactors,
    excelFillInApps,
    excelFillUtilities,
    excelFillEquipment,
    excelFillInternalDependencies,
    excelFillSuppliers,
    excelFillMTPD,
    excelFillRTO,
    excelFillInScenarios,
    excelFillBcmAssessed,
  }
}
