import * as api from "../api/userFetcher"
import { useGlobalStore } from "@/stores/global"
import { handleErrorForUser } from "../api"
import { useI18n } from "vue-i18n"

export function useUserAssign() {
  const globalStore = useGlobalStore()

  const { t } = useI18n()

  /**
   * Get user detail
   * @param {*} userId
   * @returns
   */
  async function getUserDetail(userId) {
    try {
      const { data } = await api.getUserDetail(userId)

      if (!data || data.error) {
        handleErrorForUser({ error: data?.error, $t: t })
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage({ type: "error", content: error?.message })
      return {}
    }
  }

  /**
   *
   * @param {*} uid
   * @param {*} payload
   * @returns
   */
  async function assignUser(uid, payload) {
    try {
      const { data } = await api.assignUser(uid, payload)

      if (!data || data.error) {
        handleErrorForUser({ error: data?.error, $t: t })
      } else {
        globalStore.addSuccessToastMessage("Updated")

        return data
      }
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return error
    }
  }

  return {
    getUserDetail,
    assignUser,
  }
}
