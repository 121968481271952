import * as api from "@/readybc/composables/api/apiRoles"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import { required, maxLength } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export const useRoleDetail = () => {
  const globalStore = useGlobalStore()

  const form = ref({
    name: "",
    label: "",
    description: "",
    permissions: [],
  })

  const rules = {
    form: {
      name: { required, maxLength: maxLength(40) },
      label: { required, maxLength: maxLength(40) },
      description: {},
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} uid
   * @returns
   */
  const getRoleDetail = async (uid) => {
    try {
      const { data } = await api.fetchRoleDetail(uid)

      return data
    } catch (error) {
      return error
    }
  }

  /**
   *
   * @param {*} roleUid
   * @param {*} permissionUid
   */
  const updateRolePermissions = async (payload, uid) => {
    try {
      const { data } = await api.updateRole(uid, payload)
      if (!data || data.error) {
        throw new Error(data.error)
      }

      globalStore.addSuccessToastMessage("Update role and permissions success")
      return true
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    getRoleDetail,
    updateRolePermissions,
    form,
    v$,
  }
}
