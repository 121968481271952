import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchDivision = async (uid) => {
  return fetcher.get(`identity/api/v1/divisions/${uid}`)
}

export const fetchDivisionList = async (params) => {
  return fetcher.get("identity/api/v1/divisions", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const createDivision = async (payload) => {
  return fetcher.post("identity/api/v1/divisions", payload)
}

export const updateDivision = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/divisions/${uid}`, payload)
}

export const deleteDivision = async (uid) => {
  return fetcher.delete(`identity/api/v1/divisions/${uid}`)
}
