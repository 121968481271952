import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import * as api from "../../api/equipmentFetcher"
import { useGlobalStore } from "@/stores/global"

export function useEquipmentNew() {
  const globalStore = useGlobalStore()

  const equipment = ref({
    category: {},
    owners: [{ uid: "" }],
  })

  const rules = {
    equipment: {
      name: { required },
      description: {},
      status: {},
      category: {},
      owners: {},
    },
  }

  const vldator$ = useVuelidate(rules, { equipment })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createNewEquipment = async (payload) => {
    try {
      const { data } = await api.createNewEquipment(payload)
      globalStore.addSuccessToastMessage("Created new equipment, redirect to equipment list...")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    equipment,
    vldator$,
    createNewEquipment,
  }
}
