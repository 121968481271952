<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("setting.general.masterDataModules.mtdp") }}
        </EcHeadline>

        <!-- Add  -->
        <EcButton class="mb-3 lg:mb-0" iconPrefix="plus-circle" variant="primary-sm" @click="handleClickAdd">
          {{ $t("setting.general.buttons.addMTDP") }}
        </EcButton>
      </EcFlex>
    </EcFlex>

    <EcFlex class="flex justify-end items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="w-1/4">
        <RFormInput
          class="w-full"
          v-model="selectedSort"
          componentName="EcSelect"
          :options="sortOptions"
          :allowSelectNothing="false"
          :placeholder="$t('organization.sortBy.sortBy')"
          nameKey="label"
          valueKey="name"
          @change="handleSort"
        />
      </EcFlex>
    </EcFlex>
    <!-- Table -->
    <RTable :isLoading="isLoading" :list="listData" class="mt-4 lg:mt-6">
      <template #header>
        <RTableHeaderRow>
          <RTableHeaderCell v-for="(h, idx) in headerData" :key="idx" class="text-cBlack">
            {{ h.label }}
          </RTableHeaderCell>
          <RTableHeaderCell variant="gradient" />
        </RTableHeaderRow>
      </template>
      <template v-slot="{ item, last, first }">
        <RTableRow class="hover:bg-c0-100">
          <RTableCell>
            <EcText class="w-24">
              {{ item.name }}
            </EcText>
          </RTableCell>

          <!-- Desc -->
          <RTableCell>
            <EcText class="w-24">
              {{ item.description }}
            </EcText>
          </RTableCell>
          <!-- Value -->
          <RTableCell>
            <EcText class="w-24">
              {{ item.value }}
            </EcText>
          </RTableCell>

          <!-- Action -->
          <RTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }" :isTruncate="false" variant="gradient">
            <EcFlex class="items-center justify-center h-full">
              <RTableAction class="w-30">
                <!-- Edit action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-c1-500 hover:bg-c0-100"
                  @click="handleClickEdit(item.uid)"
                >
                  <EcIcon class="mr-3" icon="Pencil" />
                  <EcText class="font-medium">{{ $t("setting.general.buttons.edit") }}</EcText>
                </EcFlex>

                <!-- Delete action -->
                <!--                <EcFlex-->
                <!--                  class="items-center px-4 py-2 cursor-pointer text-cError-500 hover:bg-c0-100"-->
                <!--                  @click="handleOpenDeleteModal(item.uid, this.getResourceOwnerFullName(item))"-->
                <!--                >-->
                <!--                  <EcIcon class="mr-3" icon="X" />-->
                <!--                  <EcText class="font-medium">{{ $t("activity.buttons.delete") }}</EcText>-->
                <!--                </EcFlex>-->
                <!-- End delete -->
              </RTableAction>
            </EcFlex>
          </RTableCell>
        </RTableRow>
      </template>
    </RTable>

    <!-- Pagination -->
    <EcFlex class="flex-col my-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus :currentPage="currentPage" :limit="pageSize" :totalCount="pageTotal" class="mb-4 sm:mb-0" />
      <RPagination v-model="currentPage" :itemPerPage="pageSize" :totalItems="pageTotal" @input="setPage($event)" />
    </EcFlex>

    <!-- Actions -->
    <EcFlex class="">
      <EcButton variant="tertiary" @click="handleBackToMasterList">
        {{ $t("setting.general.buttons.back") }}
      </EcButton>
    </EcFlex>
    <!-- Modal  delete owner -->
    <teleport to="#layer1">
      <ModalAddMTDP
        :isModalAddNewOpen="isModalAddNewOpen"
        @handleCloseAddNewModal="handleCloseAddNewModal"
        @handleCallbackAddNew="handleCallbackAddNew"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useMTDPList } from "@/modules/setting/use/master/mtdp/useMTDPList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import ModalAddMTDP from "../../../../components/ModalAddMTDP.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import { APIQueryBuilder } from "@/readybc/composables/helpers/apiQuery/apiQuery"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export default {
  name: "ViewMTDPList",
  setup() {
    const globalStore = useGlobalStore()
    const { getMTDPList, t, listData } = useMTDPList()

    return {
      apiQueryBuilder: new APIQueryBuilder(),
      globalStore,
      getMTDPList,
      t,
      listData,
    }
  },
  data() {
    return {
      selectedSort: "sort_by_value",
      sortOptions: {
        byValue: {
          name: "sort_by_value",
          label: this.$t("setting.sortBy.sortByValue"),
        },
        byName: {
          name: "sort_by_name",
          label: this.$t("setting.sortBy.sortByName"),
        },
        byDateCreated: {
          name: "sort_by_date_created",
          label: this.$t("setting.sortBy.sortByDateCreated"),
        },
      },
      pageSize: 10,
      pageTotal: 0,
      headerData: [
        { label: this.$t("setting.general.labels.name") },
        { label: this.$t("setting.general.labels.description") },
        { label: this.$t("setting.general.labels.valueInHour") },
      ],
      currentPage: 1,
      searchQuery: "",
      isLoading: false,
      isModalAddNewOpen: false,
    }
  },
  mounted() {
    this.fetchListData()
  },
  computed: {
    /**
     * Format time
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  watch: {
    currentPage() {
      this.apiQueryBuilder.setPage(this.currentPage)
      this.fetchListData()
    },
  },
  methods: {
    formatData,

    /**
     * fetch
     * @returns {Promise<void>}
     */
    async fetchListData() {
      this.isLoading = true

      if (this.selectedSort === "sort_by_value") {
        this.apiQueryBuilder.setSorts("value", SortDirectionEnum.ASC)
      } else if (this.selectedSort === "sort_by_name") {
        this.apiQueryBuilder.setSorts("name", SortDirectionEnum.ASC)
      } else {
        this.apiQueryBuilder.setSorts("created_at", SortDirectionEnum.DESC)
      }

      const mtdpList = await this.getMTDPList(this.apiQueryBuilder.toQuery())

      if (mtdpList && mtdpList.data) {
        this.listData = mtdpList.data

        // Pagination
        this.currentPage = mtdpList.current_page
        this.pageSize = mtdpList.per_page
        this.pageTotal = mtdpList.total
      }
      this.isLoading = false
    },

    // Handle events

    /**
     * Add new mtdp
     */
    handleClickAdd() {
      this.isModalAddNewOpen = true
    },

    /**
     * Cancel add mtdp
     */
    handleCloseAddNewModal() {
      this.isModalAddNewOpen = false
    },

    /**
     * Create mtdp callback
     */
    handleCallbackAddNew() {
      this.fetchListData()
    },

    /**
     * Back to master list
     */
    handleBackToMasterList() {
      goto("ViewMasterSetting")
    },

    /**
     *
     * @param {*} mtdp Uid
     */
    handleClickEdit(Uid) {
      goto("ViewMTDPDetail", {
        params: {
          uid: Uid,
        },
      })
    },

    setPage(event) {
      this.currentPage = event.target.value
    },
    handleSort() {
      this.apiQueryBuilder.clearSorts()

      // Back to first page
      this.apiQueryBuilder.setPage(1)
      this.fetchListData()
    },
    // ==== PRE-LOAD ==========
  },
  components: { EcFlex, ModalAddMTDP },
}
</script>
