import { SearchJoinTypeEnum, TypeCompareEnum, SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

/**
 * Pagination
 */
export class Pagination {
  page = 1
  total = 0
  size = 20

  constructor(page = 1, size = 20, total = 0) {
    this.page = page
    this.size = size
    this.total = total
  }
}

/**
 * Search conditions
 */
export class SearchCondition {
  field = null
  value = null
  operator = TypeCompareEnum.LIKE

  constructor(field = null, value = null, operator = TypeCompareEnum.LIKE) {
    this.field = field
    this.value = value
    this.operator = operator
  }
}

/**
 * Sortable
 */
export class Sortable {
  field = null
  direction = SortDirectionEnum.ASC

  constructor(field, direction = SortDirectionEnum.ASC) {
    this.field = field
    this.direction = direction
  }
}

/**
 * Criteria
 */
export class ApiCriteria {
  relations = []
  search = []
  sort = []
  customSort = []
  pagination = new Pagination()
  searchJoin = SearchJoinTypeEnum.AND
  queries = {}

  constructor() {
    this.setSort("created_at", "DESC")
  }

  /**
   *
   * @param {*} searchJoin
   */
  setSearchJoin(searchJoin) {
    this.searchJoin = searchJoin
  }

  /** ===== PAGINATION === */
  setPage(page) {
    this.pagination.page = page
  }

  setTotalPage(total) {
    this.pagination.total = total
  }

  /** ============================ RELATIONS ============================ */
  withRelation(relation) {
    if (!this.relations?.includes(relation)) {
      this.relations.push(relation)
    }
  }

  /**
   *
   * @param {*} relations
   */
  withRelations(relations) {
    this.relations = relations
  }

  clearRelations() {
    this.relations = []
  }

  /**
   *
   * @param {*} relation
   */
  removeRelation(relation) {
    const idx = this.relations?.findIndex((r) => {
      return (relation = r)
    })
    if (idx >= 0) {
      this.relations?.splice(idx, 1)
    }
  }

  /**
   *
   */
  removeRelations() {
    this.relations = []
  }

  /** ============================ QUERY ============================= */
  setQueries(queries = {}) {
    this.queries = queries
  }

  setQuery(key, value) {
    this.queries[key] = value
  }

  deleteQuery(key) {
    delete this.queries[key]
  }

  /** ============================ SORT ============================== */
  clearSorts() {
    this.sort = []
  }

  /**
   *
   * @param {*} field
   * @param {*} direction
   */
  setSort(field, direction = SortDirectionEnum.ASC, isCustomSort = false, clearOthers = true) {
    if (clearOthers) {
      this.clearSorts()
    }

    if (isCustomSort) {
      this.setCustomSortObj(new Sortable(field, direction))
      return
    }

    this.setSortObj(new Sortable(field, direction))
  }

  /**
   *
   * @param {*} sortObj
   */
  setSortObj(sortObj) {
    const idx = this.sort?.findIndex((sort) => {
      return sort?.field === sortObj?.field
    })

    if (idx >= 0) {
      this.sort[idx] = sortObj
    } else {
      this.sort.push(sortObj)
    }
  }

  /**
   *
   * @param {*} sortObj
   */
  setCustomSortObj(sortObj) {
    const idx = this.customSort?.findIndex((customSort) => {
      return customSort?.field === sortObj?.field
    })

    if (idx >= 0) {
      this.customSort[idx] = sortObj
    } else {
      this.customSort.push(sortObj)
    }
  }

  /** ============================ SEARH ============================== */

  /**
   *
   * @param {*} search
   */
  setSearch(field, value, operator) {
    this.setSearchObj(new SearchCondition(field, value, operator))
  }

  /**
   *
   * @param {*} field
   * @param {*} startValue
   * @param {*} toValue
   */
  setSearchBetween(field, startValue, toValue) {
    this.setSearch(field, `${startValue},${toValue}`, TypeCompareEnum.BETWEEN)
  }

  /**
   *
   * @param {*} relation
   * @param {*} value
   * @param {*} operator
   */
  setRelationSearch(relation, field, value, operator) {
    const searchField = `${relation}.${field}`
    this.setSearch(searchField, value, operator)
  }

  /**
   *
   * @param {*} search
   */
  setSearchObj(searchObj) {
    const idx = this.search?.findIndex((s) => {
      return s.field === searchObj?.field && s.operator === searchObj?.operator
    })

    if (idx >= 0) {
      this.search[idx] = searchObj
    } else {
      this.search.push(searchObj)
    }
  }

  /**
   *
   * @param {*} field
   * @param {*} operator
   */
  removeSearch(field, operator = TypeCompareEnum.LIKE) {
    const idx = this.search?.findIndex((s) => {
      return s.field === field && s.operator === operator
    })

    if (idx >= 0) {
      this.search?.splice(idx, 1)
    }
  }

  clearSearch() {
    this.search = []
  }

  /**
   * ====================== PAGINATION ========
   */

  setPageSize(size) {
    this.pagination.size = parseInt(size) || 10
  }

  resetPage() {
    this.pagination.page = 1
  }

  /**
   * ------------
   */

  reset() {
    this.relations = []
    this.search = []
    this.sort = []

    // Always keep the setup size
    const pagination = new Pagination()
    pagination.size = this.pagination.size
    this.pagination = pagination
    this.searchJoin = SearchJoinTypeEnum.AND
  }
  /**
   * ======================= BUILD QUERY =======================
   */

  getRelations() {
    if (this.relations?.length <= 0) {
      return {}
    }
    return {
      with: this.relations?.join(";"),
    }
  }

  /**
   *
   * @returns
   */
  getSearches() {
    const searchValues = []
    const searchConditions = []

    this.search?.forEach((s) => {
      searchValues.push(`${s.field}:${s.value}`)
      searchConditions.push(`${s.field}:${s.operator}`)
    })

    if (searchValues?.length > 0) {
      return {
        search: searchValues?.join(";"),
        searchFields: searchConditions?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getSorts() {
    const orderBy = []
    const direction = []
    this.sort?.forEach((sort) => {
      orderBy.push(sort.field)
      direction.push(sort.direction)
    })

    if (orderBy?.length > 0) {
      return {
        orderBy: orderBy.join(";"),
        sortedBy: direction?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getCustomSorts() {
    const orderBy = []
    const direction = []
    this.customSort?.forEach((sort) => {
      orderBy.push(sort.field)
      direction.push(sort.direction)
    })

    if (orderBy?.length > 0) {
      return {
        customSortBy: orderBy.join(";"),
        customSortedBy: direction?.join(";"),
      }
    }

    return {}
  }

  /**
   *
   * @returns
   */
  getPagination() {
    return {
      page: this.pagination.page,
      per_page: this.pagination.size,
    }
  }

  getQueries() {
    return this.queries
  }

  toQuery() {
    return {
      ...this.getRelations(),
      ...this.getSearches(),
      ...this.getSorts(),
      ...this.getCustomSorts(),
      ...this.getPagination(),
      ...this.getQueries(),
      searchJoin: this.searchJoin,
    }
  }
}

export { TypeCompareEnum }
