import fetcher from "@/modules/core/api/fetcher"

const fetchSupplierStatuses = async function () {
  return fetcher.get("/identity/api/v1/supplier-statuses")
}

const fetchAllSupplierStatuses = async function () {
  return fetcher.get("/identity/api/v1/supplier-statuses/all")
}

export { fetchSupplierStatuses, fetchAllSupplierStatuses }
