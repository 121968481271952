import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchAllDivisionList = async (params = {}) => {
  return fetcher.get("identity/api/v1/divisions/all", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchDivision = async (uid, params) => {
  return fetcher.get(`identity/api/v1/divisions/${uid}`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}
