import * as api from "@/modules/resilience_plan/api/category/apiResiliencePlanCategory"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useResiliencePlanCategoryNew() {
  const isLoading = ref(false)
  const isUpdateLoading = ref(false)

  const category = ref({})

  const rules = {
    category: {
      name: { required },
      order: {},
      description: {},
    },
  }

  const v$ = useVuelidate(rules, { category })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createResiliencePlanCategory = async (payload) => {
    v$.value.$touch()

    if (v$.value.$invalid) {
      return
    }

    try {
      isLoading.value = true
      const { data } = await api.createResiliencePlanCategory(payload)

      return data
    } catch (error) {
      return false
    }
  }

  return {
    isLoading,
    category,
    v$,
    createResiliencePlanCategory,
    isUpdateLoading,
  }
}
