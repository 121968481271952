<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("equipment.title.editEquipment") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("equipment.title.equipmentDetail") }}</EcText>
          <!-- Name -->
          <EcFlex class="flex-wrap max-w-full mb-8">
            <EcBox class="w-full lg:w-10/12 sm:pr-6">
              <RFormInput
                v-model="equipment.name"
                componentName="EcInputText"
                :label="$t('equipment.labels.name')"
                :validator="v$"
                field="equipment.name"
                @input="v$.equipment.name.$touch()"
              />
            </EcBox>
          </EcFlex>

          <!-- Desc -->
          <EcFlex class="flex-wrap max-w-full">
            <EcBox class="w-full lg:w-10/12 mb-6 sm:pr-6">
              <RFormInput
                v-model="equipment.description"
                componentName="EcInputLongText"
                :rows="2"
                :label="$t('equipment.labels.description')"
                :validator="v$"
                field="equipment.description"
                @input="v$.equipment.description.$touch()"
              />
            </EcBox>
          </EcFlex>

          <!-- Categories -->
          <EcBox class="flex-wrap max-w-md items-center mb-8">
            <EcFlex class="mb-3">
              <EcLabel class="text-base font-medium"> {{ $t("equipment.category.label") }}</EcLabel>
              <EcButton
                v-permissions:equipment_category-create
                variant="primary-rounded"
                class="h-6 ml-2"
                id="btn-new-category"
                v-tooltip="{ text: 'New category' }"
                @click="handleOpenAddNewCategoryModal"
              >
                <EcIcon icon="Plus" class="h-6 w-4" />
              </EcButton>
            </EcFlex>
            <!-- select box -->
            <EcFlex class="item-centers">
              <RFormInput
                class="w-full sm:w-6/12"
                v-model="equipment.category"
                componentName="EcMultiSelect"
                :allowSelectNothing="false"
                :isSingleSelect="true"
                :options="categories"
                :valueKey="'uid'"
                :validator="v$"
                field="equipment.category.uid"
                @change="v$.equipment.category.$touch()"
              />
              <EcSpinner v-if="isLoadingCategories" class="ml-2"></EcSpinner>
            </EcFlex>
          </EcBox>

          <!-- Owners select -->
          <EcBox class="w-full mb-8">
            <EcFlex>
              <EcLabel class="text-base font-medium"> {{ $t("equipment.labels.owners") }}</EcLabel>
              <EcButton
                v-permissions:equipment_owner-create
                variant="primary-rounded"
                class="h-6 ml-2"
                id="btn-new-owner"
                @click="handleOpenAddNewOwnerModal"
                v-tooltip="{ text: 'Did not see owner? Add a new one' }"
              >
                <EcIcon icon="Plus" width="16" height="16" />
              </EcButton>
            </EcFlex>
            <!-- Owner row -->
            <EcBox class="items-center mb-2 w-full" v-for="(role, index) in equipment.owners" :key="index">
              <EcFlex class="items-center">
                <RFormInput
                  class="w-full lg:w-10/12 sm:pr-6"
                  v-model="equipment.owners[index]"
                  componentName="EcMultiSelect"
                  :isSingleSelect="true"
                  :options="filteredOwners"
                  :valueKey="'uid'"
                  :nameKey="'full_name'"
                  :validator="v$"
                  field="equipment.owners[index].uid"
                />

                <!-- Loading owners -->
                <EcSpinner v-if="isLoadingOwners" class="ml-2"></EcSpinner>

                <!-- Remove button -->
                <EcButton
                  v-if="index !== equipment.owners.length - 1"
                  class="ml-2"
                  variant="tertiary-rounded"
                  @click="handleRemoveOwner(index)"
                >
                  <EcIcon class="text-c1-400" icon="X" width="16" height="16" />
                </EcButton>

                <!-- Add button -->
                <EcButton
                  v-if="index == equipment.owners.length - 1 && equipment.owners.length < owners.length"
                  class="ml-2 btn-more-owner"
                  variant="primary-rounded"
                  v-tooltip="{ text: 'Add more owner who owns this equipment' }"
                  @click="handleAddMoreOwner"
                >
                  <EcIcon icon="Plus" width="16" height="16" />
                </EcButton>
                <!-- End role select -->
              </EcFlex>

              <!-- Error message -->
              <EcBox v-if="v$.equipment.owners.$errors?.length > 0">
                <EcText
                  class="text-sm text-cError-500 text-left"
                  v-for="error in v$.equipment.owners.$each.$response.$errors[index].uid"
                  :key="error"
                >
                  {{ error.$message }}
                </EcText>
              </EcBox>
              <!-- Add new owner slot -->
            </EcBox>
          </EcBox>
          <!-- End Owners Select -->

          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleClickCancel">
              {{ $t("equipment.buttons.back") }}
            </EcButton>

            <EcButton v-permissions:equipment-update variant="primary" class="ml-4" @click="handleClickConfirm">
              {{ $t("equipment.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete Equipment -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("equipment.labels.deleteEquipment") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 text-base leading-normal">
            {{ $t("equipment.labels.noteDeleteEquipment") }}
          </EcText>
          <EcButton
            class="mx-5"
            variant="warning"
            iconPrefix="Trash"
            id="btn-modal-delete-equipment"
            @click="handleOpenDeleteModal"
          >
            {{ $t("equipment.buttons.deleteEquipment") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete equipment -->
    <teleport to="#layer1">
      <ModalDeleteEquipment
        :isModalDeleteEquipmentOpen="isModalDeleteOpen"
        @handleCloseDeleteModal="handleCloseDeleteModal"
        @handleDeleteCallback="handleDeleteCallback"
      />
    </teleport>

    <!-- Modal add new equipment owner -->
    <teleport to="#layer2">
      <ModalAddNewOwner
        :isModalAddNewOwnerOpen="isModalAddNewOwnerOpen"
        @handleCloseAddNewOwnerModal="handleCloseAddNewOwnerModal"
        @handleCallbackAddNewOwner="handleCallbackAddNewOwner"
      />
    </teleport>

    <!-- Modal add new equipment category -->
    <teleport to="#layer3">
      <ModalAddNewCategory
        :isModalAddNewCategoryOpen="isModalAddNewCategoryOpen"
        @handleCloseAddNewCategoryModal="handleCloseAddNewCategoryModal"
        @handleCallbackAddNewCategory="handleCallbackAddNewCategory"
      />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useEquipmentDetail } from "../../use/equipment/useEquipmentDetail"
import { useEquipmentStatusEnum } from "@/modules/equipment/use/equipment/useEquipmentStatusEnum"
import { useCategoryList } from "@/modules/equipment/use/category/useCategoryList"
import { useOwnerList } from "@/modules/equipment/use/owner/useOwnerList"
import { useGlobalStore } from "@/stores/global"
import ModalAddNewOwner from "@/modules/equipment/components/ModalAddNewOwner.vue"
import ModalDeleteEquipment from "../../components/ModalDeleteEquipment.vue"
import ModalAddNewCategory from "../../components/ModalAddNewCategory.vue"
import EcFlex from "@/components/EcFlex/index.vue"

export default {
  name: "ViewEquipmentDetail",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalAddNewOwnerOpen: false,
      isModalAddNewCategoryOpen: false,
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isLoadingOwners: false,
      isLoadingCategories: false,
      categories: [],
      owners: [],
    }
  },
  beforeMount() {
    this.fetchEquipment()
    this.fetchCategories()
    this.fetchOwners()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { equipment, v$, getEquipment, updateEquipment } = useEquipmentDetail()
    const { statuses } = useEquipmentStatusEnum()
    const { getOwnerListAll } = useOwnerList()
    const { getEquipmentCategoryList } = useCategoryList()

    return {
      getEquipment,
      updateEquipment,
      getOwnerListAll,
      getEquipmentCategoryList,
      equipment,
      v$,
      globalStore,
      statuses,
    }
  },
  computed: {
    /**
     * Filtered owners
     */
    filteredOwners() {
      const selectedOwnerUids = this.equipment.owners.map((r) => {
        return r.uid
      })
      return this.owners.map((owner) => {
        owner.disabled = selectedOwnerUids.includes(owner.uid)
        return owner
      })
    },
  },
  methods: {
    // =========== Owners ================ //
    /**
     * Add more owners
     */
    handleAddMoreOwner() {
      this.equipment.owners.push({ uid: "" })
    },

    /**
     * Remove item in array
     * @param {*} index
     */
    handleRemoveOwner(index) {
      this.equipment.owners.splice(index, 1)
    },

    /**
     * Create next to create activity
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()
      if (this.v$.equipment.$invalid) {
        return
      }

      const { uid } = this.$route.params
      this.isUpdateLoading = true

      const res = await this.updateEquipment(this.equipment, uid)
      if (res) {
        this.goToViewEquipmentList()
      }
      this.isUpdateLoading = false
    },

    /**
     * Cancel update equipment
     */
    handleClickCancel() {
      this.goToViewEquipmentList()
    },

    /**
     * redirect to main equipment screen
     */
    goToViewEquipmentList() {
      goto("ViewEquipmentList")
    },

    /**
     * Open Add New Owner modal
     */
    handleOpenAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = true
    },

    /**
     * Open close add new owner modal
     */
    handleCloseAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = false
    },

    /**
     * Open Add New Category modal
     */
    handleOpenAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = false
    },

    /**
     * Callback after add new owner
     */
    handleCallbackAddNewOwner() {
      this.fetchOwners()
    },

    /**
     * Callback after add new category
     */
    handleCallbackAddNewCategory() {
      this.fetchCategories()
    },

    /**
     * Open delete equipment modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete equipment modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /***
     * After delete callback from modal
     */
    handleDeleteCallback() {
      goto("ViewEquipmentList")
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch Equipment
     */
    async fetchEquipment() {
      this.isLoading = true

      const response = await this.getEquipment(this.uid)
      if (response) {
        this.equipment = response
        this.equipment.category = response.category ?? {}
        this.equipment.owners = response.owners?.length > 0 ? response.owners : [{ uid: "" }]
      }
      this.isLoading = false
    },

    /**
     * Fetch owners
     */
    async fetchOwners() {
      this.isLoadingOwners = true
      const response = await this.getOwnerListAll()
      if (response) {
        this.owners = response
      }
      this.isLoadingOwners = false
    },

    /**
     * Fetch Categories
     */
    async fetchCategories() {
      this.isLoadingCategories = true
      const response = await this.getEquipmentCategoryList()
      if (response) {
        this.categories = response
      }
      this.isLoadingCategories = false
    },
  },
  components: { ModalAddNewOwner, ModalDeleteEquipment, ModalAddNewCategory, EcFlex },
}
</script>
