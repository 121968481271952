import isEmpty from "lodash.isempty"
export const rtoMustLessThenMTPDValidator = (value, siblings, vm) => {
  return value <= vm?.form?.tolerable_period_disruptions?.value
}

export const mtpdMustGreaterThenRTOValidator = (value, siblings, vm) => {
  if (isEmpty(vm?.form?.recoveryTime)) {
    return true
  }
  return value >= vm?.form?.recoveryTime?.value
}

export default {
  rtoMustLessThenMTPDValidator,
  mtpdMustGreaterThenRTOValidator,
}
