<template>
  <RLayout>
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ division?.name }}
        </EcHeadline>
        <EcButton variant="primary-sm" class="mb-3 lg:mb-0" iconPrefix="PlusCircle" @click="handleClickAddBusinessUnit">
          {{ $t("organization.bu.add") }}
        </EcButton>
      </EcFlex>
      <!-- Search box -->
      <EcFlex class="flex-grow justify-end items-center w-full md:w-auto">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.search')"
          class="w-full md:max-w-xs"
          id="search-bu"
          @update:modelValue="onFilter"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <EcBox v-if="!isLoading" variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <!-- Business Unit items -->
      <EcFlex v-if="!this.isLoading" class="w-6/12 lg:flex-wrap grid grid-cols-1">
        <BusinessUnitCardItem
          v-for="item in businessUnits"
          :organizationUid="organizationUid"
          :businessUnit="item"
          :key="item.uid"
          @handleDeletedBuItem="handleDeletedBuItem"
        />

        <!-- No data found -->
        <EcBox v-show="businessUnits?.length <= 0">
          <EcText>{{ $t("core.noDataHere") }}</EcText>
        </EcBox>
      </EcFlex>

      <!-- Pagination -->
      <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
        <RPaginationStatus
          :currentPage="currentPage"
          :limit="filters.page.size"
          :totalCount="filters.page.total"
          class="mb-4 sm:mb-0"
        />
        <RPagination
          v-model="currentPage"
          :itemPerPage="filters.page.size"
          :totalItems="filters.page.total"
          @input="setPage($event)"
        />
      </EcFlex>
    </EcBox>
    <RLoading v-else />

    <!-- Actions -->
    <EcBox class="width-full mt-8">
      <!-- Button create -->
      <EcFlex class="mt-6">
        <EcButton class="" variant="primary" id="btn-back" @click="handleClickBack">
          {{ $t("organization.bu.back") }}
        </EcButton>
      </EcFlex>
    </EcBox>
  </RLayout>
</template>

<script>
import { useBusinessUnitList } from "../../use/business_unit/useBusinessUnitList"
import { useDivisionDetail } from "../../use/division/useDivisionDetail"
import { ref } from "vue"
import BusinessUnitCardItem from "../../components/business_unit/BusinessUnitCardItem.vue"
import { goto } from "@/modules/core/composables"

export default {
  name: "ViewBusinessUnitList",
  props: {
    divisionUorganizationUidid: {
      type: String,
      default: "",
    },
    divisionUid: {
      type: String,
      default: "",
    },
  },
  data() {
    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 9,
        total: 0,
      },
    }

    return {
      searchQuery: "",
      onFilter: "",
      isLoading: false,
      isLoadingDivisions: false,
      isUpdateLoading: false,
      filters,
    }
  },
  mounted() {
    // Fetch Divisions
    this.fetchDivision()
    this.fetchBusinessUnits()
  },
  setup() {
    const { getDivision } = useDivisionDetail()
    const { getBusinessUnitsByDivision, currentPage } = useBusinessUnitList()
    const division = ref({})
    const businessUnits = ref([])
    return {
      division,
      businessUnits,
      currentPage,
      getDivision,
      getBusinessUnitsByDivision,
    }
  },
  computed: {},
  methods: {
    /**
     * Fetch division
     */
    async fetchDivision() {
      this.isLoading = true
      const response = await this.getDivision(this.divisionUid)
      if (response && response.uid) {
        this.division = response
      }
      this.isLoading = false
    },

    /**
     * Fetch business units
     */
    async fetchBusinessUnits() {
      this.isLoading = true
      const response = await this.getBusinessUnitsByDivision(this.divisionUid, this.filters)
      if (response && response.data) {
        this.businessUnits = response.data
      }

      // Pagination
      this.currentPage = response.current_page
      this.filters.page.size = response.per_page
      this.filters.page.total = response.total

      this.isLoading = false
    },

    /**
     * Handle deleted BU item
     */
    async handleDeletedBuItem() {
      await this.fetchBusinessUnits()
    },

    /**
     *
     */
    handleClickAddBusinessUnit() {
      goto("ViewDivisionBusinessUnitNew", {
        params: {
          divisionUid: this.divisionUid,
        },
      })
    },

    /**
     * Clear search
     */
    handleClearSearch() {},

    /**
     * Back to division business unit list
     */
    handleClickBack() {
      goto("ViewDepartmentManagement", {
        params: {
          divisionUid: this.divisionUid,
        },
      })
    },
  },
  watch: {
    currentPage() {
      this.filters.page.number = this.currentPage
      this.fetchBusinessUnits()
    },
  },
  components: { BusinessUnitCardItem },
}
</script>
