import { useGlobalStore } from "@/stores/global"
import * as api from "../api/supplierFetcher"
import { ref } from "vue"

export function useSupplier() {
  const globalStore = useGlobalStore()
  const supplier = ref([])

  /**
   *
   * @returns
   */
  async function getSupplierList() {
    try {
      const data = await api.fetchSupplier()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.list"))
    }
  }

  return {
    getSupplierList,
    supplier,
  }
}
