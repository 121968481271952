import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 *
 * @param {*} payload
 * @returns
 */
export const createNewEquipment = async (payload) => {
  return fetcher.post(`identity/api/v1/equipments`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const fetchEquipment = async (uid) => {
  return fetcher.get(`identity/api/v1/equipments/${uid}`)
}

/**
 *
 * @param {*} payload
 * @param {*} uid
 * @returns
 */
export const updateEquipment = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/equipments/${uid}`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const deleteEquipment = async (uid) => {
  return fetcher.delete(`identity/api/v1/equipments/${uid}`)
}

/**
 *
 * Get list resources
 * @returns
 */
export const fetchEquipmentList = async (params) => {
  return fetcher.get(`/identity/api/v1/equipments`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchAllEquipments = async () => {
  return fetcher.get(`/identity/api/v1/equipments/all`)
}

/**
 * Download activities
 * @returns
 */
export const downloadEquipments = async (categoryUid) => {
  const query = new URLSearchParams()
  query.append("categoryUid", categoryUid)

  return fetcher.get(`/identity/api/v1/equipments/download/all?` + query.toString(), {
    responseType: "blob",
  })
}
