import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchCategoryList = async (params) => {
  return fetcher.get("identity/api/v1/equipment-categories", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchCategory = async (uid) => {
  return fetcher.get(`identity/api/v1/equipment-categories/${uid}`)
}

export const updateCategory = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/equipment-categories/${uid}`, payload)
}

export const deleteCategory = async (uid) => {
  return fetcher.delete(`identity/api/v1/equipment-categories/${uid}`)
}

export const createCategory = async (payload) => {
  return fetcher.post("identity/api/v1/equipment-categories", payload)
}
