<template>
  <th :class="headerCellClass" :style="headerCellStyle">
    <slot />
    <EcIcon
      width="18"
      v-if="sortable"
      :icon="sortIcon"
      class="cursor-pointer absolute top-[5px]"
      :class="[orderIconRight]"
      @click="handleSortAndFilter(false)"
    />
    <!-- Filter box -->
    <EcFlex v-if="filters?.length > 0">
      <!-- Icon filter -->
      <EcIcon
        width="18"
        icon="Filter"
        class="cursor-pointer absolute right-1 top-[5px]"
        :class="selectedOptions?.length > 0 ? 'text-cError-600' : ''"
        @click="toggleFilterPopup"
      />
      <!-- Filter popup -->
      <EcBox v-if="isFilterOpen" class="absolute bg-c0-50 right-0 shadow-lg p-3 rounded border border-c0-200">
        <!-- Select box filter-->
        <EcMultiSelect variant="primary-sm" v-model="selectedOptions" :options="filters" class="font-base"></EcMultiSelect>

        <!-- Filter action -->
        <EcFlex class="mt-4">
          <EcButton variant="secondary-outline-xs" class="mr-2" @click="handleClearFilters">Clear</EcButton>
          <EcButton variant="primary-xs" @click="handleSortAndFilter(true)">Apply</EcButton>
        </EcFlex>
      </EcBox>
      <!-- End filter popup -->
    </EcFlex>
  </th>
</template>

<script>
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export default {
  name: "RTableHeaderCell",
  props: {
    variant: {
      type: String,
      default: "default",
    },
    hkey: {
      type: String,
      default: "",
    },
    resizeable: {
      type: Boolean,
      default: false,
    },
    sortable: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["handleSortAndFilter"],
  data() {
    const isAsc = true
    const isFilterOpen = false
    const selectedOptions = []

    return {
      isAsc,
      isFilterOpen,
      selectedOptions,
    }
  },
  computed: {
    orderIconRight() {
      return this.filters?.length > 0 ? "right-6" : "right-1"
    },
    headerCellClass() {
      return {
        "text-base text-c1-800 h-10 px-6 py-2 text-left whitespace-no-wrap font-semibold relative": true,
        "top-0 right-0 px-8": this.variant === "gradient",
        "disable-resize-columns": !this.resizeable,
        "lg:border-r-1 lg:border-c1-100": this.resizeable,
      }
    },
    headerCellStyle() {
      return this.variant === "gradient" ? "background: linear-gradient(270deg, #F6F7FB 85%, rgba(255, 255, 255, 0) 100%)" : ""
    },
    headerSortable() {
      return this.sortable ? "" : ""
    },
    sortIcon() {
      return this.isAsc ? "SortAZ" : "SortZA"
    },
  },
  methods: {
    handleClearFilters() {
      this.selectedOptions = []
    },
    /**
     * Handle sort and filter
     */
    handleSortAndFilter(isApplyFilter) {
      if (!isApplyFilter) {
        this.isAsc = !this.isAsc
      }

      const sortData = {
        sortKey: this.hkey?.trim(),
        sortOrder: this.isAsc ? SortDirectionEnum.ASC : SortDirectionEnum.DESC,
      }

      const filterData = {
        filterKey: this.hkey?.trim(),
        filterData: this.selectedOptions,
      }

      this.$emit("handleSortAndFilter", {
        sort: sortData,
        filter: filterData,
      })

      this.isFilterOpen = false
    },

    /**
     * Toggle filter popup
     */
    toggleFilterPopup() {
      this.isFilterOpen = !this.isFilterOpen
    },
  },
}
</script>
<style>
.disable-resize-columns {
  border-right: none !important;
}
</style>
