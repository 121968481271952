<template>
  <RLayout :title="organization?.name">
    <RLayoutTwoCol :isLoading="isLoading">
      <template #left>
        <EcBox variant="card-1" class="width-full px-4 sm:px-10">
          <!-- Organization detail -->
          <EcText class="font-bold text-lg">Duplicate Organisation Detail</EcText>
          <EcBox>
            <!-- Logo -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full mb-6 sm:pr-6">
                <RUploadFiles
                  :documentTitle="logoTitle"
                  :maxFileNum="1"
                  :isImage="true"
                  dir="logo"
                  docType="logo"
                  :isUploadOnSelect="true"
                  :uploadedFileUrls="uploadedFileUrls"
                  dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
                  @handleSingleUploadResult="handleLogoUploaded"
                />
              </EcBox>
            </EcFlex>

            <!-- Name -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model="organization.name"
                  componentName="EcInputText"
                  :label="$t('organization.name')"
                  :validator="v$"
                  field="organization.name"
                  @input="v$.organization.name.$touch()"
                  @keyup="handleNameInput"
                />
              </EcBox>
            </EcFlex>

            <!-- Status -->
            <EcFlex v-if="!isLandlord" class="flex-wrap max-w-md">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  componentName="EcToggle"
                  v-model="organization.is_active"
                  :label="$t('organization.active')"
                  showLabel
                  :labelTrue="$t('organization.yes')"
                  :labelFalse="$t('organization.no')"
                />
              </EcBox>
            </EcFlex>

            <!-- Desc -->
            <EcFlex class="flex-wrap max-w-full mt-2">
              <EcBox class="w-full sm:pr-6">
                <RFormInput
                  v-model="organization.description"
                  componentName="EcInputLongText"
                  :rows="2"
                  :label="$t('organization.desc')"
                  :validator="v$"
                  field="form.description"
                  @input="v$.organization.description.$touch()"
                />
              </EcBox>
            </EcFlex>

            <!-- Friendly URL -->
            <EcFlex v-if="!isLandlord" class="flex-wrap items-center max-w-full mt-2">
              <EcBox class="w-4/12 mb-6">
                <RFormInput
                  v-model="organization.friendly_url"
                  componentName="EcInputText"
                  :label="$t('organization.friendlyUrl')"
                  :validator="v$"
                  field="organization.address"
                  placeholder="will be generated if empty"
                  @input="v$.organization.friendly_url.$touch()"
                />
              </EcBox>
              <EcFlex class="items-center">
                <EcText>.{{ hostName }}</EcText>
                <EcSpinner v-if="isCheckingFriendlyUrl" class="ml-4" variant="basic" />
              </EcFlex>

              <!-- Open link -->
              <EcButton variant="transparent" class="h-3" :href="'https://' + organization?.domain" target="_blank">
                <EcIcon icon="OpenUp" width="20" height="20" />
              </EcButton>
            </EcFlex>

            <!-- Industries -->
            <EcFlex v-if="!isLandlord" class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <EcText class="mb-2">Industries</EcText>
                <EcMultiSelect :modelValue="organization.industries" :options="industries" :valueKey="'uid'" />
              </EcBox>
            </EcFlex>

            <!-- Address -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full sm:pr-6">
                <RFormInput
                  v-model="organization.address"
                  componentName="EcInputText"
                  :label="$t('organization.address')"
                  :validator="v$"
                  field="form.address"
                  @input="v$.form.address.$touch()"
                />
              </EcBox>
            </EcFlex>
          </EcBox>
        </EcBox>

        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="mb-4 font-bold text-lg">Organisation Owner</EcText>

          <!-- Owner first name -->
          <EcBox>
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model="organization.owner.first_name"
                  componentName="EcInputText"
                  :rows="2"
                  :label="$t('organization.owner.firstName')"
                  :validator="v$"
                  field="organization.owner.first_name"
                  @input="v$.organization.owner.first_name.$touch()"
                />
              </EcBox>
            </EcFlex>
          </EcBox>

          <!-- Owner last name -->
          <EcBox>
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model="organization.owner.last_name"
                  componentName="EcInputText"
                  :rows="2"
                  :label="$t('organization.owner.lastName')"
                  :validator="v$"
                  field="organization.owner.last_name"
                  @input="v$.organization.owner.last_name.$touch()"
                />
              </EcBox>
            </EcFlex>
          </EcBox>

          <!-- Owner email -->
          <EcBox>
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model="organization.owner.email"
                  componentName="EcInputText"
                  :rows="2"
                  :label="$t('organization.owner.email')"
                  :validator="v$"
                  field="organization.owner.email"
                  @input="v$.organization.owner.email.$touch()"
                />
              </EcBox>
            </EcFlex>
          </EcBox>

          <!-- Owner phone -->
          <EcBox>
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full 2xl:w-6/12 mb-6 sm:pr-6">
                <RFormInput
                  v-model="organization.owner.phone"
                  componentName="EcInputText"
                  :rows="2"
                  :label="$t('organization.owner.phone')"
                  :validator="v$"
                  field="organization.owner.phone"
                  @input="v$.organization.owner.phone.$touch()"
                />
              </EcBox>
            </EcFlex>
          </EcBox>
          <!-- end -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isDuplicating" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleCancelDuplicateOrganization">
              {{ $t("organization.back") }}
            </EcButton>
            <EcButton class="ml-4" variant="primary" @click="handleClickDuplicateOrganization">
              {{ $t("organization.duplicate") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10">
            <EcSpinner variant="secondary" type="dots" />
          </EcBox>
        </EcBox>
      </template>

      <template #right>
        <EcBox>
          <!-- Delete organization -->
          <EcBox variant="card-1" class="mb-8">
            <EcHeadline as="h3" variant="h3" class="text-c1-800 px-5">
              {{ $t("organization.relativeData") }}
            </EcHeadline>
            <EcText class="px-5 my-6 text-c0-500 leading-normal text-base">
              {{ $t("organization.noteRelativeData") }}
            </EcText>

            <!-- Checkbox -->
            <EcBox class="mx-5">
              <EcLabel class="text-base"> {{ checkedRelativeData?.length }} item(s) selected</EcLabel>
              <!-- Row -->
              <EcBox v-for="(object, idx) in relativeData" class="mt-2" :key="idx">
                <!-- Parrent -->
                <EcFlex>
                  <EcCheckbox v-model="object.checked" class="mr-2" />
                  <EcLabel class="text-base">{{ (seq = idx + 1) }}. {{ object.label }}</EcLabel>
                </EcFlex>
                <!-- Children -->
                <EcFlex v-for="(children, cidx) in object.children" :key="cidx" class="mt-2 ml-8">
                  <EcCheckbox v-model="children.checked" class="mr-2" />
                  <EcLabel class="text-base"> {{ children.label }}</EcLabel>
                </EcFlex>
              </EcBox>

              <!-- End -->
            </EcBox>
          </EcBox>
        </EcBox>
      </template>
    </RLayoutTwoCol>
  </RLayout>
</template>

<script>
import { useOrganizationDetail } from "../../use/organization/useOrganizationDetail"
import { useOrganizationDuplication } from "../../use/organization/useOrganizationDuplication"
import { goto } from "@/modules/core/composables"
import { useIndustry } from "../../use/industry/useIndustry"
import { ref } from "vue"
import { subdomain } from "@/readybc/composables/helpers/helpers"

export default {
  name: "ViewOrganizationDuplication",
  props: {
    organizationUid: {
      type: String,
      default: "",
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isDuplicating: false,
      isCheckingFriendlyUrl: false,
      logoTitle: "Logo",
      confirmedOrganizationName: "",
      isModalArchiveOpen: false,
      fileOf: "organization",
      uploadedFileUrls: [],
    }
  },
  setup() {
    const { getOrganization } = useOrganizationDetail()
    const { organization, v$, relativeData, duplicateOrganization } = useOrganizationDuplication()
    const { getIndustries, getTransformedIndustries } = useIndustry()
    const industries = ref([])

    return {
      industries,
      getIndustries,
      organization,
      relativeData,
      v$,
      getOrganization,
      duplicateOrganization,
      getTransformedIndustries,
    }
  },
  computed: {
    isLandlord() {
      return this.organization?.landlord === true
    },
    hostName() {
      return process.env.VUE_APP_HOST_NAME
    },

    checkedRelativeData() {
      return this.relativeData?.filter((item) => {
        return item.checked
      })
    },

    /**
     * Checked relative data keys
     */
    checkedRelativeDataKeys() {
      const cloneObjKeys = []

      this.relativeData?.forEach((item) => {
        if (item.checked) {
          cloneObjKeys.push(item.key)
        }

        item?.children.forEach((item) => {
          if (item.checked) {
            cloneObjKeys.push(item.key)
          }
        })
      })

      return cloneObjKeys
    },
  },
  mounted() {
    this.fetchIndustries()
    this.fetchOrganization()
  },
  methods: {
    /**
     * Fetch Org
     */
    async fetchOrganization() {
      this.isLoading = true
      const orgRes = await this.getOrganization(this.organizationUid)
      if (orgRes) {
        this.transformData(orgRes)
      }

      this.isLoading = false
    },

    /**
     *
     * @param {*} orgRes
     */
    transformData(orgRes) {
      this.organization.name = "Duplicated " + orgRes.name
      this.organization.is_active = orgRes.is_active
      this.organization.description = orgRes.description
      this.organization.logo_path = orgRes.logo_path
      this.organization.logo_url = orgRes.logo_url
      this.organization.address = orgRes.address
      this.organization.friendly_url = "duplicated-" + orgRes.friendly_url

      // Owner
      if (orgRes?.owner) {
        this.organization.owner.first_name = orgRes?.owner?.first_name
        this.organization.owner.last_name = orgRes?.owner?.last_name
        this.organization.owner.email = orgRes?.owner?.email
        this.organization.owner.phone = orgRes?.owner?.phone
      }

      // Industries
      this.organization.industries = orgRes?.industries

      // Logo
      this.uploadedFileUrls = [this.organization.logo_url]
    },

    /**
     * Fetch industries
     */
    async fetchIndustries() {
      this.industries = await this.getIndustries()
    },

    /**
     * Handle click duplicate organization
     */
    async handleClickDuplicateOrganization() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.isDuplicating = true

      // Get checked relative data
      this.organization.cloneObjs = this.checkedRelativeData.map((item) => {
        item.children = item.children?.filter((child) => child.checked)

        return item
      })

      this.organization.from = this.organizationUid

      const org = await this.duplicateOrganization(this.organization)
      if (org && org.uid) {
        this.organization = org
        if (!this.organization.owner) {
          this.organization.owner = {}
        }
        goto("ViewOrganizationList")
      }
      this.isDuplicating = false
    },

    /** Cancel update */
    handleCancelDuplicateOrganization() {
      goto("ViewOrganizationList")
    },

    /**
     * Handle logo uploaded
     */
    handleLogoUploaded(result) {
      this.organization.logo = { uid: result.uid }
    },

    /**
     * Handle name input
     */
    handleNameInput() {
      this.organization.friendly_url = subdomain(this.organization.name)
      this.v$.organization.name.$touch()
    },
  },
  watch: {
    organization(org) {
      if (org && !org.owner) {
        org.owner = {}
      }
    },
  },
}
</script>
