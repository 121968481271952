<template>
  <EcFlex class="w-full">
    <!-- Loading -->
    <EcFlex v-if="isFrameLoading">
      <EcSpinner class="m-4" />
      <span class="mt-4 text-sm min-w-48 italic">Document loading...</span>
    </EcFlex>

    <EcFlex v-if="document?.uid" class="w-full h-[100vh] mt-4 mb-4">
    <!--      Embedded mode -->
      <iframe
        v-if="embedded"
        :src="documentEmbeddedSrc"
        style="width: 99%; color: #fff"
        frameborder="0"
        :ref="iframeRef"
        @load="handleFrameLoad"
      ></iframe>

      <iframe
        v-else
        :ref="iframeRef"
        style="width: 99%; color: #fff"
        :src="documentIframeSrc"
        frameBorder="0"
        @load="handleFrameLoad"
      ></iframe>
    </EcFlex>

    <EcFlex v-if="document?.uid && !document.url" class="w-full justify-center">
      {{ $t("report.labels.somethingWrong") }}
    </EcFlex>
  </EcFlex>
</template>

<script>
import { ref } from "vue"
import { useI18n } from "vue-i18n"
import EcFlex from "@/components/EcFlex/index.vue"

export default {
  name: "EcDocumentPreview",
  components: { EcFlex },
  props: {
    document: {
      type: Object,
      default: null,
    },
    embedded: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
      isFrameLoading: true,
      iframeRef: ref(),
      isShowWarning: false,
    }
  },
  setup() {
    const { t } = useI18n()

    return {
      t,
    }
  },

  computed: {
    /**
     * View SCR
     */
    documentIframeSrc() {
      const encodedUrl = encodeURIComponent(this.document?.url)

      return `https://view.officeapps.live.com/op/view.aspx?src=${encodedUrl}&wdOrigin=BROWSELINK&wdEmbedCode=0`
    },

    /**
     * Embbeded SRC
     */
    documentEmbeddedSrc() {
      const encodedUrl = encodeURIComponent(this.document?.url)

      return `https://view.officeapps.live.com/op/embed.aspx?src=${encodedUrl}&wdOrigin=BROWSELINK&wdEmbedCode=0`
    },
  },
  methods: {
    handleFrameLoad() {
      this.isFrameLoading = false
    },
  },
}
</script>
