<template>
  <div class="flex w-full rounded items-center">
    <div
      v-for="(progress, idx) in data"
      :key="idx"
      class="group relative flex items-center text-xs justify-center h-5"
      :class="[getRouded(idx)]"
      :style="getStyle(progress)"
    >
      <span class="truncate">{{ progress[labelKey] }} - {{ progress[valueKey] }}</span>
      <!-- Tooltip -->
      <span
        class="group-hover:opacity-100 transition-opacity bg-c0-800 px-2 py-1 min-w-24 text-center text-sm text-cWhite rounded absolute top-1.5 opacity-0 m-4 mx-auto z-10"
      >
        {{ progress[labelKey] }} <br />
        {{ progress[valueKey] }}/{{ this.total }} or {{ parseFloat(getPercentage(progress)).toFixed(2) }} %
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "EcColorProgressBar",
  props: {
    data: {
      type: Array,
      required: true,
      default: () => [],
    },

    valueKey: {
      type: String,
      default: "value",
    },

    labelKey: {
      type: String,
      default: "label",
    },
    bgColorKey: {
      type: String,
      default: "bgColor",
    },
    textColorKey: {
      type: String,
      default: "textColor",
    },
  },

  computed: {
    /**
     * Get total
     */
    total() {
      let total = 0
      this.data.forEach((item) => {
        total += parseInt(item[this.valueKey])
      })

      return total
    },
  },
  methods: {
    getPercentage(progress) {
      return (progress[this.valueKey] / this.total) * 100
    },

    /**
     *
     * @param {*} progress
     */
    getStyle(progress) {
      return `width: ${this.getPercentage(progress)}%; color: ${progress[this.textColorKey] || "#00000"}; background-color: ${
        progress[this.bgColorKey]
      }`
    },

    /**
     *
     * @param {*} idx
     */
    getRouded(idx) {
      if (idx === 0) {
        return "rounded-l"
      } else if (idx === this.data?.length - 1) {
        return "rounded-r"
      }

      return ""
    },
  },
}
</script>
