import { defineStore } from "pinia"

export const useMFA = defineStore("useMFA", {
  state: () => ({
    mfaChallenge: {},
  }),

  getters: {
    getMFAChallenge() {
      return this.mfaChallenge
    },
  },
  actions: {
    setMFAChallenge(challengeData) {
      this.mfaChallenge = challengeData
    },
  },
})
