import { useGlobalStore } from "@/stores/global"
import * as api from "@/modules/notification/api/eventNofiticationFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"
import { notContainHtmlTagValidator } from "@/readybc/composables/helpers/validator";

export function useEventNotificationDetail() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const eventNotification = ref({
    pinned: false,
    all_user: false,
    methodObjs: [],
    receivers: {
      users: [],
      roles: [],
      industries: [],
    },
  })

  const rules = {
    eventNotification: {
      name: { required, notContainHtmlTagValidator },
      title: { required, notContainHtmlTagValidator },
      description: { required, notContainHtmlTagValidator },
      ruleModels: {
        required: function (model) {
          if (this.eventNotification?.typeObj?.value === "auto") {
            return model?.value?.length > 0
          }

          return true
        },
      },
      ruleActions: {
        required: function (model) {
          if (this.eventNotification?.typeObj?.value === "auto") {
            return model?.value?.length > 0
          }
          return true
        },
      },
      typeObj: {},
      methodObjs: { required },
      data: { required },
      receivers: {
        users: {},
        roles: {},
        industries: {},
      },
    },
  }

  const v$ = useVuelidate(rules, { eventNotification })

  /**
   *
   * @param {*} filters
   * @returns
   */
  const getEventNotification = async (uid) => {
    try {
      const { data } = await api.fetchEventNotificationDetail(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.listTemplates"))
    }
  }

  /**
   *
   * @param {*} filters
   * @returns
   */
  const updateEventNotification = async (payload, uid) => {
    try {
      const { data } = await api.updateEventNotification(payload, uid)

      globalStore.addSuccessToastMessage("Updated")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.listTemplates"))
    }
  }

  /**
   *
   * @param {*} filters
   * @returns
   */
  const deleteEventNotification = async (uid) => {
    try {
      const { data } = await api.deleteEventNotification(uid)

      globalStore.addSuccessToastMessage("Deleted")
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.deleteEventNoti"))
    }
  }

  return {
    eventNotification,
    v$,
    getEventNotification,
    updateEventNotification,
    deleteEventNotification,
  }
}
