<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <!-- Title -->
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("setting.labels.general") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <GeneralSubMenu />
    <EcBox variant="card-1" class="wrapper">
      <EcBox class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.login_time_out"
          field="generalSetting.login_time_out"
          :validator="validator$"
          componentName="EcSelect"
          :options="timeoutList"
          :valueKey="'value'"
          :allowSelectNothing="false"
          :label="$t('setting.general.timeout')"
        />
      </EcBox>

      <EcBox class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.timezone"
          field="generalSetting.timezone"
          :validator="validator$"
          componentName="EcSelect"
          :options="timezoneList"
          :valueKey="'zone'"
          :allowSelectNothing="false"
          :label="$t('setting.general.timezone')"
        />
      </EcBox>

      <EcBox v-if="isLandlord" class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.live_notification"
          :label="$t('setting.general.labels.live_notification')"
          componentName="EcToggle"
          showLabel
          :labelTrue="$t('setting.general.labels.yes')"
          :labelFalse="$t('setting.general.labels.no')"
          :validator="validator$"
          field="generalSetting.live_notification"
        />
      </EcBox>

      <!-- IDP centralized -->
      <EcBox v-if="isLandlord" class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.idp"
          :label="$t('setting.general.labels.idp')"
          componentName="EcToggle"
          showLabel
          :labelTrue="$t('setting.general.labels.yes')"
          :labelFalse="$t('setting.general.labels.no')"
          :validator="validator$"
          field="generalSetting.idp"
        />
      </EcBox>

      <!-- Allow duplicated SBU name -->
      <EcBox v-if="isEncodaUser" class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.allow_duplicated_sbu_name"
          :label="$t('setting.general.labels.allow_duplicated_sbu_name')"
          componentName="EcToggle"
          showLabel
          :labelTrue="$t('setting.general.labels.yes')"
          :labelFalse="$t('setting.general.labels.no')"
          :validator="validator$"
          field="generalSetting.allow_duplicated_sbu_name"
        />
      </EcBox>

      <!-- Is theme -->
      <EcBox v-if="isEncodaUser" class="w-3/12 mb-6 mt-10">
        <RFormInput
          v-model="generalSetting.tenant_theme"
          field="generalSetting.tenant_theme"
          :validator="validator$"
          componentName="EcSelect"
          :options="themeList"
          :valueKey="'value'"
          :allowSelectNothing="false"
          :label="$t('setting.general.theme')"
        />
      </EcBox>
      <!-- Actions -->
      <EcFlex v-if="!isUpdating" class="mt-8">
        <EcButton class="mr-4" variant="tertiary-outline" @click="handleClickCancel()">
          {{ $t("setting.cancel") }}
        </EcButton>

        <EcButton variant="primary" @click="handleUpdateGeneralSettings()">
          {{ $t("setting.update") }}
        </EcButton>
      </EcFlex>
      <EcSpinner v-else />
    </EcBox>
    <RLoading v-if="isLoading" />
  </RLayout>
</template>
<script>
import GeneralSubMenu from "./GeneralSubMenu.vue"
import { useGeneralSetting } from "@/modules/setting/use/general_setting/useGeneralSetting"
import { goto } from "@/modules/core/composables"
import { useTimezoneList } from "@/modules/setting/use/general_setting/useTimezoneList"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewGeneralSetting",
  components: { GeneralSubMenu },
  data() {
    return {
      isLoading: false,
      isUpdating: false,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { generalSetting, validator$, timeoutList, themeList, getDetailGeneralSetting, updateGeneralSetting } =
      useGeneralSetting()
    const { getTzList, timezoneList } = useTimezoneList()

    return {
      globalStore,
      generalSetting,
      validator$,
      timeoutList,
      themeList,
      getTzList,
      timezoneList,
      getDetailGeneralSetting,
      updateGeneralSetting,
    }
  },
  mounted() {
    this.fetchGeneralSetting()
    this.getTzList()
  },
  computed: {
    isLandlord() {
      return this.globalStore?.isLandlord
    },
    isEncodaUser() {
      return this.globalStore?.isEncodaUser
    },
  },
  methods: {
    /**
     * Update setting
     */
    async handleUpdateGeneralSettings() {
      this.validator$.$touch()
      if (this.validator$.generalSetting.$invalid) {
        return
      }
      this.isUpdating = true
      await this.updateGeneralSetting(this.generalSetting)
      //
      this.isUpdating = false
    },

    /**
     * Cancel update
     */
    handleClickCancel() {
      goto("ViewSettingList")
    },
    /**
     * fetch general setting
     * @returns {Promise<void>}
     */
    async fetchGeneralSetting() {
      this.isLoading = true
      const response = await this.getDetailGeneralSetting()

      if (response && Object.keys(response).length > 0) {
        this.generalSetting = response
      }

      this.isLoading = false
    },
  },
}
</script>
