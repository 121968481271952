import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchBCPStatusList = async (params) => {
  return fetcher.get("identity/api/v1/bcps-statuses", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchBCPStatus = async (id) => {
  return fetcher.get(`identity/api/v1/bcps-statuses/${id}`)
}

export const fetchAllBCPStatus = async () => {
  return fetcher.get(`identity/api/v1/bcps-statuses/all`)
}

export const updateBCPStatus = async (payload, id) => {
  return fetcher.put(`identity/api/v1/bcps-statuses/${id}`, payload)
}

export const deleteBCPStatus = async (id) => {
  return fetcher.delete(`identity/api/v1/bcps-statuses/${id}`)
}

export const createBCPStatus = async (payload) => {
  return fetcher.post("identity/api/v1/bcps-statuses", payload)
}
