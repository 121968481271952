import { ref } from "vue"
import * as api from "../../api/generalSettingFetcher"
import { useGlobalStore } from "@/stores/global"

/**
 *
 * @returns
 */
export function useTimezoneList() {
  const globalStore = useGlobalStore()
  const timezoneList = ref([])
  /**
   *
   * @param uid
   * @returns {Promise<undefined>}
   */
  async function getTzList() {
    try {
      const { data } = await api.fetchTz()

      timezoneList.value = data
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("Get Timezone error"))
    }
  }
  return {
    getTzList,
    timezoneList,
  }
}
