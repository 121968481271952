<template>
  <a v-if="isExternalLink" v-bind="$attrs" :href="to" :target="externalLinkTarget">
    <slot />
  </a>

  <div v-else>
    <!-- If valid to -->
    <router-link v-if="isValidLink" v-bind="$props" custom v-slot="{ isActive, href, navigate }">
      <a v-bind="$attrs" :href="href" @click="navigate" :class="isActive ? activeClass : inactiveClass">
        <slot />
      </a>
    </router-link>
    <!-- Invalid link then display empty route -->
    <a v-else href="#">
      <slot />
    </a>
  </div>
</template>

<script>
import { RouterLink } from "vue-router"
import isEmpty from "lodash.isempty"

export default {
  name: "EcNavLink",
  inheritAttrs: false,

  props: {
    // add @ts-ignore if using TypeScript
    ...RouterLink.props,
    inactiveClass: String,

    externalLinkTarget: {
      type: String,
      default: "_blank",
    },
  },

  computed: {
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http")
    },

    isValidLink() {
      return !isEmpty(this.to)
    },
  },
}
</script>
