import ViewApplicationList from "@/modules/application/views/ViewApplicationList.vue"
import ViewApplicationDetail from "@/modules/application/views/ViewApplicationDetail.vue"

export default [
  {
    path: "/applications",
    component: ViewApplicationList,
    name: "ViewApplicationList",
    props: true,
    meta: {
      module: "application",
      title: "Application List",
    },
  },
  {
    path: "/applications/:uid",
    component: ViewApplicationDetail,
    name: "ViewApplicationDetail",
    props: true,
    meta: {
      module: "settings",
      title: "Application Detail",
    },
  },
]
