import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/rtoFetcher"
import { ref } from "vue"
import { numeric, required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useRTONew() {
  const globalStore = useGlobalStore()
  const rto = ref({})

  const rules = {
    rto: {
      name: { required },
      description: {},
      value: {
        numeric,
      },
    },
  }

  const validator$ = useVuelidate(rules, { rto })

  /**
   *
   * @param {*} payload
   * @returns
   */
  async function createRTO(payload) {
    try {
      const { data } = await api.createRTO(payload)

      globalStore.addSuccessToastMessage(this.$t("activity.messages.createRoleSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.updateActivityRole"))
    }
  }

  return {
    createRTO,
    rto,
    validator$,
  }
}
