import { ref } from "vue"
import { required, numeric, minLength, maxLength } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useGlobalStore } from "@/stores/global"
import * as api from "@/modules/setting/api/mfaFetcher"

export function useUserMFA() {
  const globalStore = useGlobalStore()

  const selectedMfa = ref("")
  const ENABLED = true
  const DISABLED = false
  const SUCCESS = false

  const preferenceMfa = () => {}

  const qr = ref({})

  const rules = {
    qr: {
      otp: {
        required,
        numeric,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  }

  const v$ = useVuelidate(rules, { qr })

  /**
   *
   * @returns
   */
  const getQRUri = async () => {
    try {
      const { data } = await api.fetchQRUri()

      if (!data || data.error) {
        globalStore.addErrorToastMessage(data?.error || "Unable to get QR")
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const verifyMfa = async (payload) => {
    try {
      const { data } = await api.verifyMfa(payload)

      if (!data || data.error) {
        globalStore.addErrorToastMessage(data?.error || "Unable to verify OTP Code")
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    qr,
    selectedMfa,
    ENABLED,
    DISABLED,
    SUCCESS,
    v$,
    getQRUri,
    preferenceMfa,
    verifyMfa,
  }
}
