<template>
  <EcPopup :title="$t('report.modal.title.reportTokens')" v-model="isOpen" size="xl" :showFooter="false">
    <template v-slot:body>
      <EcSpinner v-if="isLoading"></EcSpinner>
      <EcBox class="p-6">
        <EcFlex class="items-center">
          <EcIcon icon="QuestionMark" width="14" class="mr-2"></EcIcon>
          <EcLabel class="text-sm"> Hover on token to see description (Click on item to copy) </EcLabel>
        </EcFlex>
        <EcBox v-for="(replacementToken, key) in replacementTokens" :key="key" class="mt-4 ml-4">
          <!-- Object name -->
          <EcLabel class="text-base font-bold">
            {{ replacementToken.label }}
          </EcLabel>

          <!-- Replacements -->
          <EcFlex class="ml-4 mt-3 grid grid-cols-5 gap-2">
            <EcLabel
              v-tooltip="{ text: token.value, position: 'bottom' }"
              class="text-base italic hover:cursor-pointer"
              v-for="(token, idx) in replacementToken.tokens"
              :key="idx"
              @click="handleClickReplacementItem(token)"
            >
              {{ token.label }}
              {{ buildReplacement(token.key) }}
            </EcLabel>
          </EcFlex>
          <!-- End replacements -->
        </EcBox>
      </EcBox>
    </template>
  </EcPopup>
</template>
<script>
import { useReplacementTokens } from "../composable/use/useReplacementTokens"
import { copyValue } from "@/readybc/composables/helpers/helpers"

export default {
  name: "ModalReplacementTokens",
  emits: ["close"],
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "",
    },
  },

  data() {
    return {
      isOpen: false,
    }
  },

  setup() {
    const { replacementTokens, isLoading, getReplacementTokens } = useReplacementTokens()

    return { replacementTokens, isLoading, getReplacementTokens, copyValue }
  },

  methods: {
    buildReplacement(token) {
      return "${" + token + "}"
    },

    /**
     *
     * @param {*} token
     */
    handleClickReplacementItem(token) {
      return this.copyValue(this.buildReplacement(token.key))
    },
  },

  watch: {
    isModalOpen(val) {
      this.isOpen = val

      if (val === true) {
        this.getReplacementTokens(this.type)
      }
    },

    isOpen(val) {
      if (val === false) {
        this.$emit("close")
      }
    },
  },
}
</script>
