import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"
import * as api from "../../api/generalSettingFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useGeneralSetting() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()
  const generalSetting = ref({
    login_time_out: "900",
    timezone: "Australia/Melbourne",
  })
  const timeoutList = [
    {
      value: "900",
      name: "15 Minutes",
    },
    {
      value: "1800",
      name: "30 Minutes",
    },
    {
      value: "2700",
      name: "45 Minutes",
    },
    {
      value: "3600",
      name: "1 Hour",
    },
    {
      value: "-1",
      name: "Never",
    },
  ]
  const themeList = [
    {
      value: "blue",
      name: "Blue",
    },
    {
      value: "green",
      name: "Green",
    },
  ]

  // validation
  const rules = {
    generalSetting: {
      login_time_out: { required },
      timezone: {},
    },
  }
  const validator$ = useVuelidate(rules, { generalSetting })

  /**
   * @param payload
   * @returns array config
   */
  const updateGeneralSetting = async (payload) => {
    try {
      const { data } = await api.updateGeneralSetting(payload)
      if (!data) {
        globalStore.addErrorToastMessage(t("setting.general.messages.createError"))
      }
      globalStore.addSuccessToastMessage(t("setting.general.messages.saveSuccess"))
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param uid
   * @returns {Promise<undefined>}
   */
  async function getDetailGeneralSetting() {
    try {
      const { data } = await api.fetchGeneralSetting()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    generalSetting,
    validator$,
    timeoutList,
    themeList,
    getDetailGeneralSetting,
    updateGeneralSetting,
  }
}
