<template>
  <RLayout title="New Role">
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-6/12 lg:pr-4 mb-8" rightCls="lg:w-6/12 lg:pr-4 mb-8">
      <template #left>
        <EcBox class="max-w-2xl px-4 mt-8 sm:px-10">
          <EcFlex class="flex-wrap max-w-md">
            <!-- Role key -->
            <EcBox class="w-full mb-6 hidden">
              <RFormInput
                v-model="form.name"
                componentName="EcInputText"
                :label="$t('role.label.key')"
                :validator="v$"
                field="form.name"
                :disabled="true"
                placeholder="Auto generate from Name"
              />
            </EcBox>

            <!-- Role label -->
            <EcBox class="w-full mb-6">
              <RFormInput
                v-model="form.label"
                componentName="EcInputText"
                :label="$t('role.label.name')"
                :validator="v$"
                field="form.label"
                @input="handleRoleNameInput"
              />
            </EcBox>

            <!-- Role desc -->
            <EcBox class="w-full mb-6">
              <RFormInput
                v-model="form.description"
                componentName="EcInputText"
                :label="$t('user.label.description')"
                :validator="v$"
                field="form.description"
                @input="v$.form.description.$touch()"
              />
            </EcBox>
          </EcFlex>

          <EcFlex v-if="!isCreating" class="mt-6">
            <EcButton v-permissions:role-create variant="primary" id="btn-create" @click="handleSubmit">
              {{ $t("user.button.create") }}
            </EcButton>
            <EcButton class="ml-4" variant="tertiary-outline" btn="btn-cancel" @click="handleCancel">
              {{ $t("user.button.cancel") }}
            </EcButton>
          </EcFlex>
          <EcBox v-else class="flex items-center h-10 mt-6">
            <EcSpinner variant="secondary" type="dots" />
          </EcBox>
        </EcBox>
      </template>

      <template #right>
        <!-- Permissions -->
        <PermissionPanel class="max-w-2xl px-4 mt-8 sm:px-10" @update:modelValue="handlePermissionChange" />
      </template>
    </RLayoutTwoCol>
  </RLayout>

  <teleport to="#layer1">
    <ModalRequirePermission
      :modalRequirePermissionOpen="modalRequirePermissionOpen"
      @handleCloseRequirePermissionModal="handleCloseRequirePermissionModal"
    />
  </teleport>
</template>

<script>
import { ref } from "vue"
import { useRouter } from "vue-router"
import { useRoleNew } from "../use/useRoleNew"
import PermissionPanel from "../components/PermissionPanel.vue"
import ModalRequirePermission from "../components/ModalRequirePermission.vue"

export default {
  name: "ViewRoleNew",

  data() {
    return {
      modalRequirePermissionOpen: false,
    }
  },
  setup() {
    const { form, v$, createNewRole } = useRoleNew()
    const isLoading = ref(false)
    const isCreating = ref(false)
    const router = useRouter()

    return {
      form,
      v$,
      createNewRole,
      isLoading,
      isCreating,
      router,
    }
  },
  methods: {
    /**
     *
     * @param {*} e
     */
    handleRoleNameInput(e) {
      this.form.name = e.target.value.replace(/\s+/g, "-").toLowerCase()
      this.v$.form.label.$touch()
    },

    /**
     *
     */
    async handleSubmit() {
      // Validate first
      this.v$.form.$touch()
      if (this.v$.form.$invalid) {
        return
      }

      if (!this.form?.permissionsUids || this.form?.permissionsUids?.length <= 0) {
        this.modalRequirePermissionOpen = true
        return
      }

      this.isCreating = true

      const payload = {
        name: this.form.name,
        label: this.form.label,
        description: this.form.description,
        permissionsUids: this.form?.permissionsUids || [],
      }

      const resposne = await this.createNewRole(payload)

      if (resposne?.uid) {
        this.router.push({ name: "ViewRoles" })
      }
      this.isCreating = false
    },

    /**
     *
     * @param {*} value
     */
    handlePermissionChange(value) {
      this.form.permissionsUids = value
    },

    // Redirect to role list if cancel
    handleCancel() {
      this.router.push({ name: "ViewRoles" })
    },

    handleCloseRequirePermissionModal() {
      this.modalRequirePermissionOpen = false
    },
  },
  components: { PermissionPanel, ModalRequirePermission },
}
</script>
