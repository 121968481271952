<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("setting.general.labels.edit") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("setting.general.labels.detail") }}</EcText>

          <!--  name -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="status.name"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.name')"
              componentName="EcInputText"
              :validator="v$"
              field="status.name"
              @input="v$.status.name.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Desc -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="status.description"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.description')"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="status.description"
              @input="v$.status.description.$touch()"
            ></RFormInput>
          </EcBox>

          <!--  Review date control -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="status.review_date_editable"
              class="w-full sm:w-8/12 sm:pr-6"
              label="Review Date Editable?"
              componentName="EcCheckbox"
              :validator="v$"
              field="form.review_date_editable"
              @input="v$.status.review_date_editable.$touch()"
            ></RFormInput>
          </EcBox>

          <!--  Due date control -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="status.due_date_editable"
              class="w-full sm:w-8/12 sm:pr-6"
              label="Due Date Editable?"
              componentName="EcCheckbox"
              :validator="v$"
              field="form.due_date_editable"
              @input="v$.status.due_date_editable.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Color picker -->
          <EcBox class="mt-4">
            <RFormInput
              class="w-full sm:w-4/12 sm:pr-6"
              :label="$t('setting.general.labels.bgColor')"
              v-model="status.bg"
              componentName="EcMultiSelect"
              :options="colorList"
              :isSingleSelect="true"
              :valueKey="'tag_color'"
              :allowSelectNothing="false"
            />
          </EcBox>

          <EcBox class="mt-4">
            <RFormInput
              class="w-full sm:w-4/12 sm:pr-6"
              :label="$t('setting.general.labels.textColor')"
              v-model="status.text"
              componentName="EcMultiSelect"
              :options="textList"
              :isSingleSelect="true"
              :valueKey="'tag_text_color'"
              :allowSelectNothing="false"
            />
          </EcBox>

          <!-- Preview -->
          <EcBox class="mt-4">
            <EcLabel class="text-base font-medium"> {{ $t("setting.general.labels.preview") }}</EcLabel>
            <EcText
              variant="square-pill-wrapper"
              class="grid items-center h-8 w-32"
              :class="[status?.bg?.tag_color, status?.text?.tag_text_color]"
            >
              {{ status?.name || $t("setting.general.labels.lorem") }}</EcText
            >
          </EcBox>
          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleClickCancel">
              {{ $t("activity.buttons.back") }}
            </EcButton>

            <EcButton variant="primary" class="ml-4" @click="handleClickConfirm">
              {{ $t("activity.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete  -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("setting.general.buttons.delete") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal text-base">
            {{ $t("setting.general.labels.noteDelete") }}
          </EcText>
          <EcButton class="mx-5" variant="warning-sm" iconPrefix="Trash" @click="handleOpenDeleteModal">
            {{ $t("setting.general.buttons.delete") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete  -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-2xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("setting.confirmToDelete") }}
            </EcHeadline>
            <!--  Name -->
            <EcText class="text-lg font-bold">
              {{ status.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("setting.general.labels.confirmDelete") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
            <EcButton class="ml-3" variant="warning" @click="handleDeleteResilienceStatus">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useResiliencePlanStatusDetail } from "@/modules/resilience_plan/use/status/useResiliencePlanStatusDetail"
import { useColorMappingList } from "@/readybc/composables/use/useColorMappingList"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewResilienceStatusDetail",
  props: {
    uid: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
    }
  },
  mounted() {},
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { status, v$, getResilienceStatus, updateResiliencePlanStatus, deleteResilienceStatus } =
      useResiliencePlanStatusDetail()
    const { colorList, textList, getBgColorName, getTextColorName } = useColorMappingList()
    return {
      getResilienceStatus,
      updateResiliencePlanStatus,
      deleteResilienceStatus,
      getBgColorName,
      getTextColorName,
      status,
      v$,
      globalStore,
      colorList,
      textList,
    }
  },
  computed: {},
  methods: {
    // =========== Role ================ //

    /**
     * Update
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      const { id } = this.$route.params

      this.isUpdateLoading = true

      this.status.bg_color = this.status?.bg?.tag_color
      this.status.text_color = this.status?.text?.tag_text_color

      const response = await this.updateResiliencePlanStatus(this.status, id)
      this.isUpdateLoading = false
      if (response) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Back to status list
     */
    handleUpdateSuccess() {
      goto("ViewResiliencePlanStatusList")
    },

    /**
     * Cancel update status
     */
    handleClickCancel() {
      goto("ViewResiliencePlanStatusList")
    },

    /**
     * Open delete status modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete status modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete status
     */
    async handleDeleteResilienceStatus() {
      this.isDeleteLoading = true

      const isDeleted = await this.deleteResilienceStatus(this.uid)

      if (isDeleted) {
        setTimeout(goto("ViewResiliencePlanStatusList"), 1000)
      }

      this.isDeleteLoading = false
    },
  },
}
</script>
