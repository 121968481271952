import core from "@/modules/core/locales/en-US"
import auth from "@/modules/auth/locales/en-US"
import dashboard from "@/modules/dashboard/locales/en-US"
import organization from "@/modules/organization/locales/en-US"
import activity from "@/modules/activity/locales/en-US"
import application from "@/modules/application/locales/en-US"
import equipment from "@/modules/equipment/locales/en-US"
import industry from "@/modules/industry/locales/en-US"
import user from "@/modules/user/locales/en-US"
import setting from "@/modules/setting/locales/en-US"
import supplier from "@/modules/supplier/locales/en-US"
import dependency from "@/modules/dependency/locales/en-US"
import businessContinuityPlan from "@/modules/bcp/locales/en-US"
import resiliencePlan from "@/modules/resilience_plan/locales/en-US"
import report from "@/modules/report/locales/en-US"
import assessment from "@/modules/assessment/locales/en-US"
import notification from "@/modules/notification/locales/en-US"

export default {
  ...core,
  ...auth,
  ...dashboard,
  ...organization,
  ...activity,
  ...application,
  ...equipment,
  ...industry,
  ...user,
  ...setting,
  ...supplier,
  ...dependency,
  ...businessContinuityPlan,
  ...resiliencePlan,
  ...report,
  ...assessment,
  ...notification,

  errors: {
    system: "System error, please contact administrator",
    network: "Network Error: Please try again.",
    token: "Please login again.",
    permission: "You are not authorized to do this action.",
    notFound: "Not Found",
    query: "There was a problem.",
    general: "Oops, some errors occured.",
  },
}
