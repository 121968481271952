<template>
  <EcBox>
    <EcBox>
      <!-- Table -->
      <EcBox ref="tableWrapper" class="h-full overflow-x-auto lg:overflow-visible">
        <table ref="table" class="w-full border-collapse border-none" v-columns-resizable="resizeable">
          <thead>
            <slot name="header">
              <tr>
                <th
                  v-for="(item, i) in header"
                  :key="i"
                  class="px-6 py-4 text-left whitespace-no-wrap text-c1-800 md:font-medium"
                >
                  {{ item.label }}
                </th>
              </tr>
            </slot>
          </thead>

          <template v-if="isLoading">
            <tbody class="relative">
              <EcFlex class="absolute w-full items-center justify-items-center justify-center p-4">
                <EcSpinner variant="secondary-lg" type="dots" />
              </EcFlex>
            </tbody>
          </template>
          <template v-else>
            <tbody v-if="useTbody" v-show="list.length > 0" class="overflow-x-scroll rounded-lg bg-cWhite">
              <template v-for="(item, index) in list" :key="item?.id ?? index">
                <slot :item="item" :last="index === list.length - 1" :first="index === 0" :even="index % 2 === 0" />
              </template>
            </tbody>
            <!-- For table don't want to use tbody wrapper-->
            <template v-else v-for="(item, index) in list" :key="item?.id ?? index">
              <slot :item="item" :last="index === list.length - 1" :first="index === 0" :even="index % 2 === 0" />
            </template>

            <!-- No data found -->
            <EcFlex v-show="list.length === 0" class="items-center justify-items-center justify-center absolute w-full">
              <slot name="emptyData">
                <EcText class="text-base text-c0-500">{{ $t("core.noDataHere") }}</EcText>
              </slot>
            </EcFlex>
          </template>
        </table>
      </EcBox>
      <!-- Scrollable Arrow -->
      <EcFlex v-if="isScrollable" class="absolute top-0 right-0 items-center h-16 p-4 pr-6 text-c1-200">
        <EcIcon icon="ChevronDoubleRight" />
      </EcFlex>
      <!-- Loading & Empty Table -->
    </EcBox>
  </EcBox>
</template>

<script>
import { ref, watch, nextTick } from "vue"

export default {
  name: "RTable",
  props: {
    header: {
      type: Array,
      default: () => [],
    },
    list: {
      type: Array,
      default: () => [],
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    resizeable: {
      type: Boolean,
      default: false,
    },
    useTbody: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const tableWrapper = ref(null)
    const table = ref(null)

    const isScrollable = ref(false)

    function checkScrollable() {
      nextTick(() => {
        const tableWrapperWidth = tableWrapper.value?.$el?.clientWidth
        const tableWidth = table.value?.clientWidth
        // Check if table has horizontal scroll
        isScrollable.value = tableWidth > tableWrapperWidth
      })
    }

    watch(
      () => props.list,
      (value) => {
        if (value.length > 0) checkScrollable()
      }
    )

    watch(
      () => props.isLoading,
      (newVal, oldVal) => {
        if (oldVal === true && newVal === false) checkScrollable()
      }
    )

    return { tableWrapper, table, isScrollable, checkScrollable }
  },
}
</script>
<style>
/*.vue-columns-resizeable th,*/
/*.vue-columns-resizeable td {*/
/*  border-bottom: thin solid rgba(0, 0, 0, 0.12);*/
/*  border-right: 1px solid grey;*/
/*}*/
</style>
