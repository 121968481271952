import * as api from "../../api/dependencyScenarioFetcher"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"

export function useDependencyRelationshipList() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createNewDependencyRelationship = async (payload) => {
    try {
      const { data } = await api.createDependencyRelationship(payload)
      globalStore.addSuccessToastMessage(t("dependencyRelationship.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const deleteDependencyRelationship = async (uid) => {
    try {
      const { data } = await api.deleteDependencyRelationship(uid)
      globalStore.addSuccessToastMessage(t("dependencyRelationship.messages.deleteSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @returns
   */
  async function getDependencyRelationshipList() {
    try {
      const { data } = await api.fetchDependencyScenariosListV2()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.listEquipments"))
    }
  }
  return {
    createNewDependencyRelationship,
    getDependencyRelationshipList,
    deleteDependencyRelationship,
  }
}
