export function useColorMappingList() {
  const colorList = [
    {
      tag_color: "bg-c3-100",
      name: "Grey",
    },
    {
      tag_color: "bg-c3-200",
      tag_text_color: "text-cBlack",
      name: "Yellow",
    },
    {
      tag_color: "bg-cWarning-600",
      name: "Dark Yellow",
    },
    {
      tag_color: "bg-c3-300",
      tag_text_color: "text-cBlack",
      name: "Orange",
    },
    {
      tag_color: "bg-c3-400",
      name: "Red",
    },
    {
      tag_color: "bg-c3-500",
      name: "Light Green",
    },
    {
      tag_color: "bg-cSuccess-600",
      name: "Green",
    },
    {
      tag_color: "bg-c3-600",
      name: "Black",
    },
    {
      tag_color: "bg-c3-700",
      tag_text_color: "text-cBlack",
      name: "Light Grey",
    },
    {
      tag_color: "bg-c4-200",
      name: "Blue",
    },
  ]

  const textList = [
    {
      tag_color: "bg-c1-800",
      tag_text_color: "text-cWhite",
      name: "White",
    },
    {
      tag_color: "bg-cWhite",
      tag_text_color: "text-cBlack",
      name: "Black",
    },
    {
      tag_color: "bg-cWhite",
      tag_text_color: "text-cError-500",
      name: "Red",
    },
    {
      tag_color: "bg-cWhite",
      tag_text_color: "text-cSuccess-500",
      name: "Green",
    },
    {
      tag_color: "bg-cWhite",
      tag_text_color: "text-cWarning-500",
      name: "Yellow",
    },
  ]

  const getBgColorName = function (value) {
    const result = colorList.find((el) => el.tag_color === value)

    return result?.name
  }
  const getTextColorName = function (value) {
    const result = textList.find((el) => el.tag_text_color === value)

    return result?.name
  }

  return {
    colorList,
    textList,
    getBgColorName,
    getTextColorName,
  }
}
