<template>
  <RLayout>
    <!-- Noti row -->
    <EcBox class="w-11/12 ml-6">
      <EcLabel class="font-medium">{{ notification.title }}</EcLabel>
      <EcLabel class="text-base">{{ formatDateTime(notification.created_at) }}</EcLabel>
      <!-- eslint-disable-next-line -->
      <EcRichTextDisplay class="mt-4 text-base" :data="notification.data" />
      <!-- Actions -->
      <EcBox class="width-full mt-8" v-if="!isLoading">
        <!-- Button create -->
        <EcFlex class="mt-6">
          <EcButton variant="tertiary-outline" @click="handleClickBack">
            {{ $t("notification.buttons.back") }}
          </EcButton>

          <EcButton
            v-if="notificationIntentModelUid && notificationIntentRouteName"
            variant="primary"
            @click="handleClickDetail"
            class="ml-5"
          >
            {{ $t("notification.buttons.details") }}
          </EcButton>
        </EcFlex>
      </EcBox>
      <!-- End actions -->
    </EcBox>
    <RLoading v-if="isLoading" />
  </RLayout>
</template>
<script>
import RLoading from "@/modules/core/components/common/RLoading.vue"
import { goto } from "@/modules/core/composables"
import { useNotification } from "@/modules/notification/use/useNotification"
import { useGlobalStore } from "@/stores/global"
import EcRichTextDisplay from "@/components/EcRichTextDisplay/index.vue"
export default {
  name: "ViewUserNotificationList",

  props: {
    uid: {
      type: String,
      required: true,
      default: "",
    },
  },
  setup() {
    const globalStore = useGlobalStore()
    const { isLoading, notification, getNotificationDetail } = useNotification()
    return {
      globalStore,
      notification,
      isLoading,
      getNotificationDetail,
    }
  },
  mounted() {
    this.fetchNotificationDetail()
  },
  computed: {
    notificationIntentModelUid() {
      return this.notification?.modelable?.uid ?? null
    },
    notificationIntentRouteName() {
      const type = this.notification?.modelable_type ?? null
      const routes = {
        BIA: "ViewBIADetail",
        BCP: "ViewBCPDetail",
      }

      return routes[type] ?? null
    },
  },
  methods: {
    /**
     *
     * @param {*} string
     */
    formatDateTime(string) {
      return this.globalStore.formatDateTime(string)
    },

    /**
     *
     */
    async fetchNotificationDetail() {
      this.isLoading = true
      this.notification = await this.getNotificationDetail(this.uid)
      this.globalStore.decreaseNotificationCnt(1)
      this.isLoading = false
    },

    /**
     * Back to list
     */
    handleClickBack() {
      goto("ViewUserNotificationList")
    },

    /**
     * Click to detail
     */
    handleClickDetail() {
      goto(this.notificationIntentRouteName, {
        params: {
          uid: this.notificationIntentModelUid,
        },
      })
    },
  },
  components: { EcRichTextDisplay, RLoading },
}
</script>
