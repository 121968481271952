<template>
  <RLayout>
    <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
      {{ $t("supplier.title.newSupplier") }}
    </EcHeadline>
    <RLayoutTwoCol>
      <template #left>
        <EcBox class="width-full sm:px-10" variant="card-1">
          <EcText class="mb-4 font-bold text-lg"> {{ $t("supplier.title.supplierDetail") }}</EcText>
          <!-- Name -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.name"
              :label="$t('supplier.name')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.name"
              @input="supplierValidator$.supplier.name.$touch()"
            />
          </EcBox>

          <!-- description -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.description"
              :label="$t('supplier.desc')"
              :rows="2"
              :validator="supplierValidator$"
              componentName="EcInputLongText"
              field="supplier.description"
              @input="supplierValidator$.supplier.description.$touch()"
            />
          </EcBox>

          <!-- Divisions -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12">
              <RFormInput
                v-model="supplier.divisions"
                :label="$t('activity.division.label')"
                componentName="EcMultiSelect"
                :options="divisions"
                :valueKey="'uid'"
                :validator="supplierValidator$"
              />
            </EcBox>
            <EcSpinner class="my-auto mb-1" v-if="isLoadingDivisions"></EcSpinner>
          </EcFlex>

          <!-- Business Units -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12">
              <RFormInput
                v-model="supplier.business_units"
                :label="$t('activity.bu.label')"
                componentName="EcMultiSelect"
                :options="filteredBusinessUnits"
                :valueKey="'uid'"
                :validator="supplierValidator$"
              />
            </EcBox>
            <EcSpinner class="my-auto mb-1" v-if="isLoadingBusinessUnits"></EcSpinner>
          </EcFlex>

          <!-- Status -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12">
              <!-- select box -->
              <RFormInput
                v-model="supplier.status"
                componentName="EcMultiSelect"
                :label="$t('supplier.labels.status')"
                :valueKey="'id'"
                :allowSelectNothing="false"
                :isSingleSelect="true"
                :options="statuses"
                :validator="supplierValidator$"
                field="supplier.status"
                @change="supplierValidator$.supplier.status.$touch()"
              />
            </EcBox>
            <EcSpinner class="ml-2 mt-5" v-if="isLoadingStatus" />
          </EcFlex>
        </EcBox>

        <EcBox class="width-full mt-8 px-4 sm:px-10" variant="card-1">
          <EcText class="mb-4 font-bold text-lg">Contact point</EcText>
          <!-- Contact name -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.contact_name"
              :label="$t('supplier.contactName')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.contact_name"
              @input="supplierValidator$.supplier.contact_name.$touch()"
            />
          </EcBox>
          <!-- email -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.email"
              :label="$t('supplier.email')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.email"
              @input="supplierValidator$.supplier.email.$touch()"
            />
          </EcBox>

          <!-- phone -->
          <EcBox class="w-1/2 mb-6">
            <RFormInput
              v-model="supplier.phone_number"
              :label="$t('supplier.phone')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.phone_number"
              @input="supplierValidator$.supplier.phone_number.$touch()"
            />
          </EcBox>

          <!-- address -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.address"
              :label="$t('supplier.address')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.address"
              @input="supplierValidator$.supplier.address.$touch()"
            />
          </EcBox>
        </EcBox>
      </template>

      <template #right>
        <EcBox class="width-full px-4" variant="card-1">
          <EcHeadline as="h3" class="text-c1-800 px-5" variant="h3">
            {{ $t("supplier.title.upload") }}
          </EcHeadline>
          <EcBox class="width-full mt-4">
            <!-- certificate -->
            <EcFlex class="flex-wrap w-full max-w-full">
              <EcBox class="w-full mb-6 sm:pr-6">
                <RUploadFiles
                  :dir="'supplier/certificates'"
                  :documentTitle="$t('supplier.certificate.title')"
                  :isUploadOnSelect="false"
                  :maxFileNum="10"
                  :isParentSubmitting="isFormSubmitting"
                  dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
                  @handleSingleUploadResult="handleCertificateUploaded"
                  @handleBulkFilesUpload="createSupplierAfterUploadedFile"
                  @startUploadFiles="this.isCreating = true"
                  @endUploadFiles="this.isCreating = false"
                />
              </EcBox>
            </EcFlex>
          </EcBox>
          <!-- End upload box -->
          <!-- Uploaded files -->
          <EcBox>
            <EcLabel class="text-base font-medium"> {{ $t("supplier.certificate.certificateName") }} </EcLabel>

            <EcBox v-for="(item, idx) in supplier?.certs" :key="idx">
              <EcFlex>
                <EcText>{{ item?.name }}</EcText>
              </EcFlex>
            </EcBox>
          </EcBox>

          <!-- End uploaded files -->
        </EcBox>
      </template>
    </RLayoutTwoCol>
    <!-- Actions -->
    <EcFlex v-if="!isCreating" class="width-full mt-6 px-4 sm:px-10">
      <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleClickCancel">
        {{ $t("supplier.buttons.cancel") }}
      </EcButton>
      <EcButton v-permissions:supplier-create class="ml-3" variant="primary" id="btn-create" @click="handleClickCreateSupplier">
        {{ $t("supplier.buttons.create") }}
      </EcButton>
    </EcFlex>
    <EcFlex v-else class="items-center justify-start mt-6 ml-10 h-10">
      <EcSpinner type="dots" />
    </EcFlex>
    <!-- Modal add new resource category -->
    <teleport to="#layer1">
      <ModalAddNewCategory
        :isModalAddNewCategoryOpen="isModalAddNewCategoryOpen"
        @handleCloseAddNewCategoryModal="handleCloseAddNewCategoryModal"
        @overlay-click="handleCloseAddNewCategoryModal"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useSupplierNew } from "@/modules/supplier/use/supplier/useSupplierNew"
import { useSupplierStatus } from "@/readybc/composables/use/useSupplierStatus"
import ModalAddNewCategory from "@/modules/supplier/components/ModalAddNewCategory"
import { useBusinessUnitList } from "@/readybc/composables/use/useBusinessUnit"
import { useDivisionList } from "@/readybc/composables/use/useDivisionList"
import isEmpty from "lodash.isempty"

export default {
  name: "ViewSupplierNew",

  data() {
    return {
      isCreating: false,
      isNameUnique: false,
      isUploading: false,
      isModalAddNewCategoryOpen: false,
      isFormSubmitting: false,
    }
  },
  setup() {
    const { supplier, supplierValidator$, createSupplier } = useSupplierNew()
    const { statuses, getAllStatuses, isLoadingStatus } = useSupplierStatus()

    // Division and BU
    const { businessUnits, isLoadingBusinessUnits, getAllBusinessUnitList } = useBusinessUnitList()
    const { divisions, isLoadingDivisions, getAllDivisionList } = useDivisionList()

    return {
      supplier,
      supplierValidator$,
      createSupplier,
      statuses,
      getAllStatuses,
      isLoadingStatus,

      // Division and BU
      divisions,
      isLoadingDivisions,
      getAllDivisionList,

      businessUnits,
      getAllBusinessUnitList,
      isLoadingBusinessUnits,
    }
  },
  computed: {
    /**
     * Filter BU
     */
    filteredBusinessUnits() {
      if (isEmpty(this.supplier?.divisions)) {
        return this.businessUnits
      }

      return this.businessUnits.filter((bu) => {
        return this.supplier.divisions?.map((item) => item.uid).includes(bu.division?.uid)
      })
    },
  },
  mounted() {
    this.fetchSupplierStatuses()
    this.fetchDivisions()
    this.fetchBusinessUnits()
  },
  methods: {
    // Handle create new supplier
    async handleClickCreateSupplier() {
      this.supplierValidator$.$touch()

      if (this.supplierValidator$.supplier.$invalid) {
        return
      }
      // trigger pros isFormSubmitting RUploadFile to auto upload file
      this.isFormSubmitting = true
    },

    /** Handle uploaded logo */
    handleCertificateUploaded(result) {
      this.supplier.certs.push({ uid: result.uid })
    },

    /**
     * Create Supplier After uploaded all file
     */
    async createSupplierAfterUploadedFile() {
      this.isCreating = true
      this.supplier.status_id = this.supplier.status?.id
      const supplierRes = await this.createSupplier(this.supplier)
      if (supplierRes && supplierRes.uid) {
        this.supplier = supplierRes

        goto("ViewSupplierList")
      }
      this.isCreating = false
    },

    // go to view supplier list
    handleClickCancel() {
      goto("ViewSupplierList")
    },

    /**
     * Open addNewCategory
     */
    handleOpenAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = false
    },

    /**
     * Fetch Supplier
     */
    async fetchSupplierStatuses() {
      this.isLoadingStatus = true
      this.statuses = await this.getAllStatuses()
      this.isLoadingStatus = false
    },

    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const response = await this.getAllDivisionList()
      if (response && response.data) {
        this.divisions = response.data
      }
      this.isLoadingDivisions = false
    },

    /**
     * Fetch BU
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const response = await this.getAllBusinessUnitList()

      if (response && response.data) {
        this.businessUnits = response.data
      }
      this.isLoadingBusinessUnits = false
    },
  },
  components: { ModalAddNewCategory },
}
</script>
