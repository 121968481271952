<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("bia.title.editBIA") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Body -->
    <EcBox v-if="!isLoading" variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("bia.title.biaDetail") }}</EcText>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="bia.name"
            componentName="EcInputText"
            :label="$t('bia.labels.name')"
            :validator="v$"
            field="bia.name"
            @input="v$.bia.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Divisions -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="bia.division"
            :label="$t('activity.division.label')"
            componentName="EcMultiSelect"
            :isSingleSelect="true"
            :options="divisions"
            :valueKey="'uid'"
            :validator="v$"
            field="bia.division.uid"
          />
        </EcBox>
        <EcSpinner class="my-auto mb-1" v-if="isLoadingDivisions"></EcSpinner>
      </EcFlex>

      <!-- Business Units -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="bia.business_unit"
            :label="$t('activity.bu.label')"
            componentName="EcMultiSelect"
            :isSingleSelect="true"
            :options="filteredBusinessUnits"
            :valueKey="'uid'"
            :validator="v$"
            field="bia.business_unit.uid"
          />
        </EcBox>
        <EcSpinner class="my-auto mb-1" v-if="isLoadingBusinessUnits"></EcSpinner>
      </EcFlex>

      <!-- Status -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="bia.status"
            componentName="EcMultiSelect"
            :label="$t('bia.labels.status')"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="filteredStatuses"
            :valueKey="'id'"
            :disabled="isApproved"
            :validator="v$"
            field="bia.status"
            @change="v$.bia.status.$touch()"
          />
        </EcBox>
        <EcSpinner v-if="isLoadingStatuses" class="mt-5 ml-1" />
      </EcFlex>

      <!-- Due Date -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="bia.due_date"
            :disabled="!bia?.status?.due_date_editable"
            componentName="EcDatePicker"
            :label="$t('bia.labels.dueDate')"
            :allowSelectNothing="false"
            :validator="v$"
            field="bia.due_date"
            @change="v$.bia.due_date.$touch()"
            :class="[!bia?.status?.due_date_editable ? 'cursor-not-allowed' : '']"
          />
        </EcBox>
      </EcFlex>

      <!-- Reports -->
      <EcFlex class="flex-wrap items-center mb-8">
        <EcBox class="w-full">
          <!-- Title and upload button -->
          <EcFlex class="items-center">
            <EcLabel>{{ $t("bia.plans.reports") }}</EcLabel>
            <EcLabel class="ml-2" v-if="bia.reports?.length > 0">({{ bia.reports?.length }})</EcLabel>
            <EcButton
              variant="transparent"
              class="ml-4 text-c1-800"
              v-tooltip="{ text: 'Upload BIA Reports' }"
              @click="handleOpenBIAFileUploadModal"
            >
              <EcIcon icon="CloudUpload" />
            </EcButton>
          </EcFlex>

          <!-- Report row -->
          <RFileSlider class="mt-4" :files="bia.reports" @fileDeleted="handleRemoveUploadedFile"></RFileSlider>
        </EcBox>
      </EcFlex>
      <!-- End Report-->

      <!-- Activity -->
      <EcFlex class="flex-wrap items-center mb-8">
        <RActivityLog :uid="uid" :fetcher="getBIALogs" />
      </EcFlex>
      <!-- End Activity -->

      <!-- End body -->
      <!-- Actions -->
      <EcBox class="width-full mt-8 px-4 sm:px-10">
        <!-- Button create -->
        <EcFlex v-if="!isUpdateLoading" class="mt-6">
          <EcButton variant="tertiary-outline" @click="handleClickCancel">
            {{ $t("bia.buttons.back") }}
          </EcButton>

          <EcButton v-permissions:bia-update variant="primary" class="ml-4" @click="handleClickUpdate">
            {{ $t("bia.buttons.update") }}
          </EcButton>
        </EcFlex>

        <!-- Loading -->
        <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
      </EcBox>
      <!-- End actions -->
    </EcBox>

    <RLoading v-else />
  </RLayout>

  <teleport to="#layer1">
    <ModalUploadAssessmentFile
      :isModalUploadBIAFileOpen="isModalUploadBIAFileOpen"
      @handleCloseUploadModal="handleCloseUploadModal"
      @handleUploadCallback="handleUploadCallback"
      ref="modalUploadRef"
    />
  </teleport>

  <teleport to="#layer2">
    <!-- Modal preview file -->
    <RModalPreview ref="modalFilePreview" :file="fileX"></RModalPreview>
  </teleport>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useBIADetail } from "@/modules/assessment/use/useBIADetail"
import { useBIALog } from "@/modules/assessment/use/useBIALog"
import { useBIAStatusEnum } from "@/readybc/composables/use/useBIAStatus"
import { useGlobalStore } from "@/stores/global"
import ModalUploadAssessmentFile from "../components/ModalUploadAssessmentFile.vue"
import { ref } from "vue"
import { downloadFromUrl } from "@/readybc/composables/helpers/downloadHelper"
import RFileSlider from "@/modules/core/components/common/RFileSlider.vue"
import { useBusinessUnitList } from "@/readybc/composables/use/useBusinessUnit"
import { useDivisionList } from "@/readybc/composables/use/useDivisionList"
import isEmpty from "lodash.isempty"

export default {
  name: "ViewBIADetail",
  props: {
    uid: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      reportFilesDeleting: [],
      isModalUploadBIAFileOpen: false,
      isModalFilePreviewOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isLoadingStatuses: false,
    }
  },
  mounted() {
    this.fetchBIA()
    this.fetchBIAStatuses()
    this.fetchDivisions()
    this.fetchBusinessUnits()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { bia, v$, getBIA, updateBIA, deleteBIA } = useBIADetail()
    const { removeReportFile } = useBIADetail()
    const { APPROVED, statuses, getStatuses } = useBIAStatusEnum()

    // Division and BU
    const { businessUnits, isLoadingBusinessUnits, getAllBusinessUnitList } = useBusinessUnitList()
    const { divisions, isLoadingDivisions, getAllDivisionList } = useDivisionList()

    const modalUploadRef = ref()

    // Log getter
    const { getBIALogs } = useBIALog()

    return {
      getBIA,
      updateBIA,
      deleteBIA,
      getBIALogs,
      bia,
      v$,
      globalStore,
      APPROVED,
      statuses,
      getStatuses,
      modalUploadRef,
      removeReportFile,

      // Division and BU
      divisions,
      isLoadingDivisions,
      getAllDivisionList,

      businessUnits,
      getAllBusinessUnitList,
      isLoadingBusinessUnits,
    }
  },
  computed: {
    fileX() {
      return this.bia.reports[0]
    },

    isApproved() {
      return this.bia?.status === this.APPROVED
    },

    filteredStatuses() {
      return this.statuses
    },

    /**
     * Filter BU
     */
    filteredBusinessUnits() {
      if (isEmpty(this.bia.division?.uid)) {
        return this.businessUnits
      }

      return this.businessUnits.filter((bu) => {
        return bu.division?.uid === this.bia.division?.uid
      })
    },
  },
  methods: {
    /**
     * Create resource
     *
     */
    async handleClickUpdate() {
      this.v$.$touch()

      if (this.v$.bia.$invalid) {
        return
      }
      this.isUpdateLoading = true
      this.bia.status_id = this.bia.status?.id
      const res = await this.updateBIA(this.bia, this.uid)

      if (res) {
        this.transformData(res)
        setTimeout(this.handleUpdateSuccess, 1000)
      }
      this.isUpdateLoading = false
    },
    /**
     * Update success and back to list
     */
    handleUpdateSuccess() {
      goto("ViewBIAList")
    },
    /**
     * Cancel add new resource
     */
    handleClickCancel() {
      goto("ViewBIAList")
    },

    /**
     * Handle upload file
     */
    handleOpenBIAFileUploadModal() {
      this.isModalUploadBIAFileOpen = true
    },

    /**
     * Close upload modal
     */
    handleCloseUploadModal() {
      this.isModalUploadBIAFileOpen = false
    },

    /**
     *
     * @param {*} url
     */
    handleOpenFilePreview(file) {
      this.$refs.modalFilePreview.file = file
      this.$refs.modalFilePreview.isModalPreviewOpen = true
      //  window.open(url, "_blank")
    },

    /**
     *
     * @param {*} url
     */
    async handleRemoveUploadedFile(uid) {
      this.bia.reports.forEach((item, idx) => {
        if (item?.uid === uid) {
          this.bia.reports.splice(idx, 1)
        }
      })
    },

    /**
     *
     * @param {*} file
     */
    handleDownloadFileItem(file) {
      downloadFromUrl(file?.url)
    },

    /**
     * Upload file callback
     */
    handleUploadCallback(files) {
      files.forEach((file) => {
        if (this.isFileAddedToReports(file)) {
          return
        }
        this.bia.reports.push({
          uid: file?.response?.uid,
          name: file?.response?.name,
          url: file?.response?.url,
          mime_type: file?.response?.mime_type,
        })
      })
    },

    /**
     * Check to see the file already add to report
     * @param {*} file
     */
    isFileAddedToReports(file) {
      return this.bia.reports?.find((item) => {
        return file?.response?.uid === item?.uid
      })
    },

    /** =========== PRE LOAD  */

    /**
     * Get BIA by UID
     */
    async fetchBIA() {
      this.isLoading = true
      const biaRes = await this.getBIA(this.uid)

      if (biaRes) {
        this.transformData(biaRes)
      }

      this.isLoading = false
    },

    async logFetcher() {},

    /**
     * Fetch BIA Statuses
     */
    async fetchBIAStatuses() {
      this.isLoadingStatuses = true
      const data = await this.getStatuses()

      if (data) {
        this.statuses = data
      }

      this.isLoadingStatuses = false
    },

    /**
     *
     * @param {*} response
     */
    transformData(response) {
      this.bia = response

      // Status
      this.bia.status = this.bia?.status || {}
      this.bia.division = this.bia?.division || {}
      this.bia.business_unit = this.bia?.business_unit || {}

      // Files
    },

    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const response = await this.getAllDivisionList()
      if (response && response.data) {
        this.divisions = response.data
      }
      this.isLoadingDivisions = false
    },

    /**
     * Fetch BU
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const response = await this.getAllBusinessUnitList()

      if (response && response.data) {
        this.businessUnits = response.data
      }
      this.isLoadingBusinessUnits = false
    },
  },
  components: { ModalUploadAssessmentFile, RFileSlider },
}
</script>
