import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchMTDPList = async (params) => {
  return fetcher.get("identity/api/v1/tolerable-time-periods", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchMTDP = async (uid) => {
  return fetcher.get(`identity/api/v1/tolerable-time-periods/${uid}`)
}

export const fetchAllMTDP = async () => {
  return fetcher.get(`identity/api/v1/tolerable-time-periods/all`)
}

export const updateMTDP = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/tolerable-time-periods/${uid}`, payload)
}

export const deleteMTDP = async (uid) => {
  return fetcher.delete(`identity/api/v1/tolerable-time-periods/${uid}`)
}

export const createMTDP = async (payload) => {
  return fetcher.post("identity/api/v1/tolerable-time-periods", payload)
}
