import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchRemoteAccessList = async (params) => {
  return fetcher.get("identity/api/v1/remote-access-factors", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchRemoteAccess = async (uid) => {
  return fetcher.get(`identity/api/v1/remote-access-factors/${uid}`)
}

export const fetchAllRemoteAccess = async () => {
  return fetcher.get(`identity/api/v1/remote-access-factors/all`)
}

export const updateRemoteAccess = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/remote-access-factors/${uid}`, payload)
}

export const deleteRemoteAccess = async (uid) => {
  return fetcher.delete(`identity/api/v1/remote-access-factors/${uid}`)
}

export const createRemoteAccess = async (payload) => {
  return fetcher.post("identity/api/v1/remote-access-factors", payload)
}
