<template>
  <!-- AZ -->
  <SortAZ v-if="icon === 'AZ'" />
  <!-- ZA -->
  <SortZA v-if="icon === 'ZA'" />

  <Sort09 v-if="icon === '09'" />

  <Sort90 v-if="icon === '90'" />
</template>
<script>
import Sort09 from "./Sort09.vue"
import Sort90 from "./Sort90.vue"
import SortAZ from "./SortAZ.vue"
import SortZA from "./SortZA.vue"

export default {
  name: "SortIcon",
  props: {
    icon: {
      type: String,
      default: "AZ",
    },
  },
  components: { SortAZ, SortZA, Sort09, Sort90 },
}
</script>
