export function useSetting() {
  const settingItems = [
    {
      key: "general",
      label: "setting.labels.general",
      icon: "Cog",
      routeName: "ViewGeneralSetting",
      visible: true,
    },
    {
      key: "eventNoti",
      label: "setting.labels.eventNoti",
      icon: "Bell",
      routeName: "ViewEventNotificationList",
      visible: true,
    },
    {
      key: "notiTemplates",
      label: "setting.labels.notificationTemplates",
      icon: "Template",
      routeName: "ViewManagedTemplateList",
      visible: true,
    },
    {
      key: "mail",
      label: "setting.labels.mail",
      icon: "Mail",
      routeName: "ViewMailSetting",
      visible: true,
    },
    {
      key: "reportTemplate",
      label: "setting.labels.reportTemplates",
      icon: "Report",
      routeName: "ViewConfigurableReportTemplates",
      visible: true,
    },
  ]

  return {
    settingItems,
  }
}
