import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/bcpStatusFetcher"
import { ref } from "vue"

export function useBCPStatusList() {
  const globalStore = useGlobalStore()
  const listData = ref([])
  // Initial data

  /**
   *
   * @returns
   */
  async function getBCPStatusList(params) {
    try {
      const { data } = await api.fetchBCPStatusList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }
  /**
   *
   * @returns
   */
  async function getAllBCPStatusList() {
    try {
      const { data } = await api.fetchAllBCPStatus()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  return {
    getBCPStatusList,
    getAllBCPStatusList,
    listData,
  }
}
