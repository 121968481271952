import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchLocationList = async (params) => {
  return fetcher.get("identity/api/v1/locations", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchAllLocations = async () => {
  return fetcher.get(`identity/api/v1/locations/all`)
}

export const fetchLocationDetail = async (uid) => {
  return fetcher.get(`identity/api/v1/locations/${uid}`)
}
export const updateLocation = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/locations/${uid}`, payload)
}

export const deleteLocation = async (uid) => {
  return fetcher.delete(`identity/api/v1/locations/${uid}`)
}

export const createLocation = async (payload) => {
  return fetcher.post("identity/api/v1/locations", payload)
}
