import ViewNotFound from "@/modules/core/views/ViewNotFound"
import { createRouter, createWebHistory } from "vue-router"
import { useGlobalStore } from "@/stores/global"
import core from "@/modules/core/router/index"
import auth from "@/modules/auth/router/index"
import dashboard from "@/modules/dashboard/router/index"
import organization from "@/modules/organization/router/index"
import activity from "@/modules/activity/router/index"
import application from "@/modules/application/router/index"
import equipment from "@/modules/equipment/router/index"
import industry from "@/modules/industry/router/index"
import user from "@/modules/user/router/index"
import setting from "@/modules/setting/router/index"
import supplier from "@/modules/supplier/router/index"
import dependency from "@/modules/dependency/router/index"
import businessContinuityPlan from "@/modules/bcp/router/index"
import resiliencePlan from "@/modules/resilience_plan/router/index"
import report from "@/modules/report/router/index"
import assessment from "@/modules/assessment/router/index"
import notification from "@/modules/notification/router/index"

/**
 *
 * @param {*} to
 * @param {*} from
 * @param {*} next
 */
async function redirectRootPage(to, from, next) {
  const globalStore = useGlobalStore()

  if (globalStore.isLandlord) {
    /* global app */
    /* eslint no-undef: "error" */
    if (app.__vue_app__.config.globalProperties.$permissions("menu.dashboard")) {
      next({ name: "ViewDashboard" })
    } else {
      next({ name: "ViewOrganizationList" })
    }
  }

  // Default to ViewBrightDashboad if tenant
  next({ name: "ViewBrightDashboard" })
}

const routes = [
  {
    path: "/",
    name: "root",
    beforeEnter: redirectRootPage,
  },
  ...core,
  ...auth,
  ...dashboard,
  ...organization,
  ...activity,
  ...equipment,
  ...industry,
  ...user,
  ...setting,
  ...supplier,
  ...dependency,
  ...businessContinuityPlan,
  ...resiliencePlan,
  ...report,
  ...assessment,
  ...notification,
  ...application,

  {
    path: "/:catchAll(.*)",
    component: ViewNotFound,
    name: "ViewNotFound",
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() {
    return {
      top: 0,
      left: 0,
    }
  },
})

export default router
