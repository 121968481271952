<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("setting.general.labels.edit") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("setting.general.labels.detail") }}</EcText>

          <!--  name -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="priority.name"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.name')"
              componentName="EcInputText"
              :validator="v$"
              field="priority.name"
              @input="v$.priority.name.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Desc -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="priority.description"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.description')"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="priority.description"
              @input="v$.priority.description.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Weight -->
          <EcFlex class="flex-wrap max-w-full mb-8">
            <EcBox class="w-full sm:w-8/12 sm:pr-6">
              <RFormInput
                v-model="priority.order"
                componentName="EcInputNumberStepper"
                :label="$t('setting.general.labels.priorityOrder')"
                :step="1"
                :min="1"
                :max="100"
                :validator="v$"
                field="priority.order"
                @input="v$.priority.order.$touch()"
              />
            </EcBox>
          </EcFlex>

          <!-- Icon -->
          <EcBox class="mt-4 sm:w-8/12 sm:pr-6">
            <RFormInput
              v-model="priority.icon"
              :label="$t('setting.general.labels.icon')"
              componentName="EcMultiSelect"
              :options="iconOptions"
              :isSingleSelect="true"
              iconKey="value"
              field="priority.description"
            ></RFormInput>
          </EcBox>
          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleClickCancel">
              {{ $t("activity.buttons.back") }}
            </EcButton>

            <EcButton variant="primary" class="ml-4" @click="handleClickConfirm">
              {{ $t("activity.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete  -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("setting.general.buttons.delete") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal">
            {{ $t("setting.general.labels.noteDelete") }}
          </EcText>
          <EcButton class="mx-5" variant="warning" iconPrefix="Trash" @click="handleOpenDeleteModal">
            {{ $t("setting.general.buttons.delete") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete  -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-2xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("setting.confirmToDelete") }}
            </EcHeadline>
            <!--  Name -->
            <EcText class="text-lg font-bold">
              {{ priority.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("setting.general.labels.confirmDelete") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
            <EcButton class="ml-3" variant="warning" @click="handleDelete">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useActivityPriorities } from "@/readybc/composables/use/useActivityPriorities"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewActivityPriorityDetail",
  data() {
    return {
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      uid: "",
    }
  },
  mounted() {
    const { uid } = this.$route.params
    this.uid = uid

    this.fetchDetail()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { priority, v$, getIconOptions, getPriority, updatePriority, deletePriority } = useActivityPriorities()
    return {
      priority,
      v$,
      getIconOptions,
      getPriority,
      updatePriority,
      deletePriority,
      globalStore,
    }
  },
  computed: {
    iconOptions() {
      return this.getIconOptions()
    },
  },
  methods: {
    // =========== Role ================ //

    /**
     * Update
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params

      this.isUpdateLoading = true

      this.priority.icon_url = this.priority?.icon?.value
      const response = await this.updatePriority(this.priority, uid)
      this.isUpdateLoading = false
      if (response) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Back to Utility list
     */
    handleUpdateSuccess() {
      goto("ViewActivityPriorityList")
    },

    /**
     * Cancel update Utility
     */
    handleClickCancel() {
      goto("ViewActivityPriorityList")
    },

    /**
     * Open delete Utility modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete Utility modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete Utility
     */
    async handleDelete() {
      this.isDeleteLoading = true
      const { uid } = this.$route.params

      const isDeleted = await this.deletePriority(uid)

      if (isDeleted) {
        setTimeout(goto("ViewActivityPriorityList"), 1000)
      }

      this.isDeleteLoading = false
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch Utility
     */
    async fetchDetail() {
      this.isLoading = true
      const response = await this.getPriority(this.uid)
      if (response) {
        this.priority = response

        this.priority.icon = this.iconOptions?.find((item) => {
          return item.value === response.icon_url
        })
      }
      this.isLoading = false
    },
  },
}
</script>
