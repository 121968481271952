import { useGlobalStore } from "@/stores/global"
import * as api from "@/modules/setting/api/fetcher"
import { minLength, required } from "@vuelidate/validators"
import { reactive } from "vue"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"

export function useChangePassword() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const formPassword = reactive({
    currentPassword: null,
    newPassword: null,
    newPasswordConfirm: null,
  })

  // Validate
  const rules = {
    formPassword: {
      currentPassword: {
        required,
      },
      newPassword: {
        required,
        minLength: minLength(8),
        diffWith: (value) => {
          if (value?.length > 0) {
            return value !== formPassword?.currentPassword
          }

          return true
        },
      },
      newPasswordConfirm: {
        required,
        sameAs: (value) => value === formPassword?.newPassword,
      },
    },
  }

  const v$ = useVuelidate(rules, { formPassword })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updatePassword = async (payload) => {
    try {
      const { data } = await api.fetchChangePassword(payload)
      if (!data || data.error) {
        throw new Error(data.error)
      }
      globalStore.addSuccessToastMessage(t("setting.updatePasswordSuccessNote"))

      globalStore.logout()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    updatePassword,
    formPassword,
    v$,
  }
}
