export const colorMapping = {
  "text-cWhite": "#FFFFFF",
  "text-cBlack": "#000000",
  "text-cError-500": "#ee3f5f",
  "text-cSuccess-500": "#34c387",
  "text-cWarning-500": "#ebba4a",
  "bg-cWhite": "#FFFFFF",
  "bg-cBlack": "#000000",
  "bg-c1-100": "#ebf0fe",
  "bg-c1-700": "#3a4d9b",
  "bg-c1-800": "#3860E2",
  "bg-c3-100": "#7F879E",
  "bg-c3-200": "#FFF2C3",
  "bg-c3-300": "#FFA95A",
  "bg-c3-400": "#FF6E5A",
  "bg-c3-500": "#6FCF97",
  "bg-c3-600": "#22242a",
  "bg-c3-700": "#d9d9d9",
  "bg-cSuccess-300": "#7eddb5",
  "bg-cSuccess-500": "#34c387",
  "bg-cSuccess-600": "#29996a",
  "bg-cWarning-600": "#e6aa1e",
}

export default {
  colorMapping,
}
