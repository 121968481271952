<template>
  <!-- BIA  -->
  <EcBox class="w-full md:w-8/12 mt-8">
    <SummaryRowSkeleton :rows="2" :loading="isBIASummaryLoading">
      <EcBox class="relative">
        <EcLabel class="font-semibold mt-6 mb-2"> {{ $t("dashboard.bia.title") }}</EcLabel>
        <!-- NO BIA -->
        <EcLabel v-if="isNoBIA" class="text-base italic"> {{ $t("dashboard.bia.noBIA") }}</EcLabel>
        <EcFlex v-else class="items-center mb-2" v-for="(bia, idx) in biaSummary" :key="bia.uid">
          <!-- BIA Row -->
          <EcInputText
            v-model="biaSummary[idx].name"
            variant="primary-dashboard"
            componentName="EcInputText"
            field="form.name"
            class="w-8/12 text-sm"
            :readonly="true"
          />

          <EcBox class="relative ml-3 w-4/12">
            <EcButton
              variant="wrapper"
              :class="[bia.status?.bg_color, bia.status?.text_color || 'text-cWhite']"
              class="w-full text-base rounded-lg h-10 justify-center border-0"
              @click="handleShowContextMenu(idx, bia)"
            >
              {{ bia.status?.name }}
            </EcButton>
          </EcBox>

          <!-- Button Action -->
        </EcFlex>

        <!--Context menu -->
        <BIAContextMenu :statuses="filteredStatuses" ref="contextMenu"></BIAContextMenu>
      </EcBox>
    </SummaryRowSkeleton>
  </EcBox>
</template>

<script>
import SummaryRowSkeleton from "./SummaryRowSkeleton.vue"
// import { ref } from "vue"
import BIAContextMenu from "./BIAContextMenu.vue"
import { storeToRefs } from "pinia"
import useDashboardBIAStore from "../../stores/useDashboardBIAStore"
import { useBIAStatusEnum } from "@/readybc/composables/use/useBIAStatus"

export default {
  name: "DashboardSummaryBIA",

  setup() {
    const dashboardBIAStore = useDashboardBIAStore()
    const { isBIASummaryLoading, biaSummary } = storeToRefs(dashboardBIAStore)

    const { statuses, getStatuses } = useBIAStatusEnum()

    return {
      isBIASummaryLoading,
      dashboardBIAStore,
      biaSummary,

      statuses,
      getStatuses,
    }
  },
  mounted() {
    this.dashboardBIAStore.getBIASummary()
    this.fetchBIAStatuses()
  },
  computed: {
    isNoBIA() {
      return this.biaSummary?.length <= 0
    },
    filteredStatuses() {
      return this.statuses
    },
  },
  methods: {
    /**
     *
     * @param {*} idx
     * @param {*} bia
     */
    handleShowContextMenu(idx, bia) {
      bia.statusObj = this.getStatus(bia)
      this.$refs.contextMenu.bia = bia
      this.$refs.contextMenu.position = ++idx
      this.$refs.contextMenu.isOpen = true
    },

    /**
     *
     * @param {*} bia
     */
    getStatus(bia) {
      return (
        this.statuses.find((item) => {
          return item.value === bia?.status
        }) || { name: "Unknown" }
      )
    },

    /**
     * Fetch BIA Statuses
     */
    async fetchBIAStatuses() {
      if (!this.$permissions("bia.read")) {
        return
      }
      this.isLoadingStatuses = true
      const data = await this.getStatuses()

      if (data) {
        this.statuses = data
      }

      this.isLoadingStatuses = false
    },
  },
  components: { SummaryRowSkeleton, BIAContextMenu },
}
</script>
