import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/remoteAccessFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useRemoteAccessNew() {
  const globalStore = useGlobalStore()
  const remote = ref({})

  const rules = {
    remote: {
      name: { required },
      description: {},
    },
  }

  const validator$ = useVuelidate(rules, { remote })

  /**
   *
   * @param {*} payload
   * @returns
   */
  async function createRemoteAccess(payload) {
    try {
      const { data } = await api.createRemoteAccess(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createRemoteAccess,
    remote,
    validator$,
  }
}
