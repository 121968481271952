import * as api from "../api/reportTemplateFetcher"

export const useReportTemplate = () => {
  const getTemplate = (type, uid) => {
    return api.fetchTemplateByType(type, uid)
  }

  const deleteReportTemplateRevision = (type, uid) => {
    return api.deleteReportTemplateRevision(type, uid)
  }

  const setReportTemplateDefaultRevision = (type, uid) => {
    return api.setReportTemplateDefaultRevision(type, uid)
  }

  const getReportTemplateDefaultRevision = (type) => {
    return api.getReportTemplateDefaultRevision(type)
  }

  const getConfigurableReportTemplateTypes = () => {
    return api.fetchConfigurableTemplateTypes()
  }

  const getRunnableReportTemplateTypes = () => {
    return api.getRunnableReportTemplateTypes()
  }

  const fetchReportTemplateTypeDetails = (type) => {
    return api.fetchReportTemplateTypeDetails(type)
  }

  const fetchReportTemplateRevisions = (type) => {
    return api.fetchReportTemplateRevisions(type)
  }

  const uploadReportTemplate = (type) => {
    return api.uploadReportTemplate(type)
  }

  return {
    getTemplate,
    getConfigurableReportTemplateTypes,
    getRunnableReportTemplateTypes,
    fetchReportTemplateTypeDetails,
    fetchReportTemplateRevisions,
    getReportTemplateDefaultRevision,
    deleteReportTemplateRevision,
    setReportTemplateDefaultRevision,
    uploadReportTemplate,
  }
}
