<template>
  <span v-bind="$attrs" :class="[variantCls?.root]">
    <slot></slot>
  </span>
</template>

<script>
export default {
  inheritAttrs: false,
  name: "EcBadge",
  props: {
    variant: {
      type: String,
      default: "default",
    },
  },

  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "EcBadge",
          variant: this.variant,
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },
  },
}
</script>
