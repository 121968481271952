import isEmpty from "lodash.isempty"
import axios from "axios"
/**
 *
 * @param {*} avatarLetter
 * @param {*} color
 * @returns
 */
export const generateAvatar = (avatarLetter, color) => {
  // Only generate if the name with 2 letters
  if (isEmpty(avatarLetter) || avatarLetter.length < 2 || isEmpty(color)) {
    return ""
  }
  color = color.replace("#", "")
  const url = "https://ui-avatars.com/api/"

  const fontSize = avatarLetter?.length > 2 ? 1.2 / avatarLetter.length : 0.4

  const params = {
    name: avatarLetter.replace(/[^a-z0-9]/gi, ""),
    background: color,
    length: avatarLetter.length,
    "font-size": fontSize,
    color: "fff",
  }

  const res = axios.getUri({ url, params })

  return res
}

/**
 *
 * @param name
 * @returns {*|string}
 */
export const generateAvatarCode = (name) => {
  if (!name) {
    return ""
  }

  return name
    .trim()
    .split(" ")
    .map((nameItem) => nameItem.charAt(0))
    .join("")
    .replace(/[^a-z0-9]/gi, "")
}
