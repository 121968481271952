<template>
  <LayoutAuth>
    <EcHeadline variant="h1" as="h1" class="mb-6 lg:text-4xl">
      {{ computedTitle }}
    </EcHeadline>
    <EcText class="text-c1-800 max-w-md mb-12 leading-tight">
      {{ computedLabel }}
    </EcText>
    <EcBox v-if="!isLoading" class="w-full max-w-md">
      <template v-if="!isFinish">
        <RFormInput
          v-model.trim="form.otp"
          componentName="EcInputText"
          thousandSeparator=""
          variant="primary-lg"
          class="mb-12"
          :label="$t('auth.mfa.otp')"
          dark
          iconPrefix="LockClosed"
          :validator="v$"
          field="form.otp"
          @input="v$.form.otp.$touch()"
          @keypress.enter="handleClickConfirm"
        />

        <EcButton variant="primary" @click="handleClickConfirm">
          {{ $t("auth.confirm") }}
        </EcButton>
      </template>
      <template v-else>
        <EcFlex>
          <EcButton variant="primary" @click="handleClickBackToLogin">
            {{ $t("auth.goToLogin") }}
          </EcButton>
        </EcFlex>
      </template>
    </EcBox>
    <EcFlex v-else class="items-center">
      <EcSpinner type="dots" />
    </EcFlex>
  </LayoutAuth>
</template>

<script>
import LayoutAuth from "@/modules/auth/components/LayoutAuth"
import { useMFAVerification } from "../use/useMFAVerification"
import { useMFA } from "../stores/useMFA"
import { goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewMFA",
  components: {
    LayoutAuth,
  },

  props: {
    username: {
      type: String,
    },
    session: {
      type: String,
    },
    firstName: {
      type: String,
    },
  },
  data() {
    return {
      isLoading: false,
      isFinish: false,
      isSuccess: false,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const useMFAStore = useMFA()
    const { form, v$, respondMFAChallenge } = useMFAVerification()

    return {
      form,
      v$,
      respondMFAChallenge,
      globalStore,
      useMFAStore,
    }
  },

  mounted() {
    this.form.username = this.useMFAStore?.getMFAChallenge?.username
    this.form.session_value = this.useMFAStore?.getMFAChallenge?.session

    if (!this.form.username || !this.form.session_value) {
      this.globalStore.addErrorToastMessage(this.$t("auth.errors.invalidSession"))

      goto("ViewLogin")
    }
  },
  computed: {
    computedTitle() {
      return this.$t("auth.mfa.title")
    },
    computedLabel() {
      return this.$t("auth.mfa.prompt")
    },
  },
  methods: {
    /**
     * New password
     */
    async handleClickConfirm() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.respondMFAChallenge(this.form)

      this.isLoading = false

      if (response) {
        this.isSuccess = true

        if (response?.landing) {
          this.$router.push({
            name: response?.landing,
          })
        } else {
          setTimeout(this.handleClickBackToLogin, 1000)
        }
      }
    },

    /**
     * Back to login
     */
    handleClickBackToLogin() {
      goto("ViewLogin")
    },
  },
}
</script>
