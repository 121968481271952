import { defineStore } from "pinia"
import * as api from "@/modules/auth/api/fetcher"

export const useLogoutStore = defineStore("logout", () => {
  /**
   * Logout
   */
  function logout() {
    // Clear tokens and call the revoke api silently
    // get refresh token  before clear
    const refreshToken = localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN) || ""
    const domain = "." + process.env.VUE_APP_HOST_URL

    window.$cookies.remove(process.env.VUE_APP_TOKEN_EXPIRES, null, domain)
    localStorage.removeItem(process.env.VUE_APP_REFRESH_TOKEN)

    // Revoke token
    api.logout({
      refreshToken,
    })
  }

  return {
    logout,
  }
})
