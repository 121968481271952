export const getSortOptions = (options) => {
  const fields = options
    .map((option) => option?.field)
    .filter((n) => n)
    .join(";")
  const direction =
    options
      .map((option) => option?.direction)
      .filter((n) => n)
      .join(";") || "desc"
  if (fields) {
    return {
      orderBy: fields,
      sortedBy: direction,
    }
  }
  return {}
}
