import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import { email, required } from "@vuelidate/validators"

export function useActivityOwner() {
  const owner = ref({})

  const ruleOwner = {
    owner: {
      email: { email, required },
      first_name: { required },
      last_name: { required },
    },
  }

  const validator$ = useVuelidate(ruleOwner, { owner }, { $scope: false })

  return {
    owner,
    validator$,
  }
}
