<template>
  <RLayout :title="getTitle()">
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-6/12 lg:pr-4 mb-2" rightCls="lg:w-6/12 lg:pr-4 mb-2">
      <template #left>
        <EcBox variant="card-1" class="width-full px-4 sm:px-10 h-[70vh] overflow-y-auto">
          <DivisionList :currentDivisions="user?.divisions" @handleDivisionSelected="handleDivisionSelected" />
        </EcBox>
      </template>
      <template #right>
        <EcBox variant="card-1" class="width-full px-4 sm:px-10 h-[70vh] overflow-y-auto">
          <BusinessUnitList
            :currentBusinessUnits="user?.business_units"
            :divisionSelected="divisionSelected"
            @handleBusinessUnitSelected="handleBusinessUnitSelected"
          />
        </EcBox>
      </template>
    </RLayoutTwoCol>
    <EcBox class="width-full mt-2 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-back" @click="handleClickBack">
          {{ $t("user.button.back") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" id="btn-update" @click="handleClickUpdate">
          {{ $t("user.button.update") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
  </RLayout>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useUserAssign } from "../use/useUserAssign"
import { useDivisionList } from "@/modules/organization/use/division/useDivisionList"
import { useBusinessUnitList } from "@/modules/organization/use/business_unit/useBusinessUnitList"
import { ref } from "vue"
import BusinessUnitList from "@/modules/user/components/assignment/businessUnit/BusinessUnitList.vue"
import DivisionList from "@/modules/user/components/assignment/division/DivisionList.vue"

export default {
  name: "ViewUserAssign",
  components: { DivisionList, BusinessUnitList },
  data() {
    return {
      isLoading: false,
      roleOptions: [],
      isLoadingRoles: false,
      selectedDivisions: [],
      selectedBusinessUnits: [],
      divisionSelected: "",
    }
  },
  setup(props) {
    const { form, v$, getUserDetail, assignUser } = useUserAssign()
    const { getDivisions } = useDivisionList()
    const { getBusinessUnits } = useBusinessUnitList()
    const user = ref({})
    return {
      form,
      v$,
      getUserDetail,
      assignUser,
      getDivisions,
      getBusinessUnits,
      user,
    }
  },
  computed: {},
  mounted() {
    this.getUser()
  },
  methods: {
    /**
     * Get user detail
     */
    async getUser() {
      this.isLoading = true

      const { userId } = this.$route.params
      this.user = await this.getUserDetail(userId)
      this.selectedDivisions = this.user?.divisions?.map((item) => {
        return item.uid
      })
      this.isLoading = false
    },
    async handleClickUpdate() {
      this.isLoading = true
      const payload = {
        divisions: this.selectedDivisions,
        business_units: this.selectedBusinessUnits,
      }
      const res = await this.assignUser(this.user.uid, payload)
      if (res) {
        this.user = res
      }

      this.isLoading = false
    },
    /**
     * Cancel
     */
    handleClickBack() {
      // Go to User List Page
      goto("ViewUserList")
    },
    getTitle() {
      return this.user?.name ? `${this.user?.name} (${this.user?.email})` : ""
    },

    handleDivisionSelected(divisions) {
      this.divisionSelected = divisions?.filter((x) => !this.selectedDivisions.includes(x))[0]
      this.selectedDivisions = divisions
    },
    handleBusinessUnitSelected(businessUnits) {
      this.selectedBusinessUnits = businessUnits
    },
  },
}
</script>
