<template>
  <!-- BCP  -->
  <EcBox class="w-full md:w-8/12 mt-8">
    <EcLabel class="font-semibold mt-6 mb-2">{{ $t("dashboard.resiliencePlan.title") }}</EcLabel>

    <SummaryRowSkeleton :loading="isResiliencePlanSummaryLoading" :rows="2">
      <EcLabel v-if="isNoResiliencePlan" class="text-base italic"> {{ $t("dashboard.resiliencePlan.noData") }}</EcLabel>

      <EcBox v-else class="relative">
        <EcFlex v-for="(resiliencePlan, idx) in resiliencePlanSummary" :key="resiliencePlan.uid" class="items-center mb-2">
          <!-- BCP Row -->
          <EcInputText
            v-model="resiliencePlanSummary[idx].name"
            variant="primary-dashboard"
            componentName="EcInputText"
            field="form.name"
            class="w-8/12 text-base hover:cursor-auto"
            :readonly="true"
          />
          <EcButton
            variant="wrapper"
            class="ml-3 text-base w-4/12 rounded-lg justify-center h-10 border-0"
            :class="[resiliencePlan?.status?.tag_color, resiliencePlan?.status?.tag_text_color || 'text-cWhite']"
            @click="handleShowContextMenu(idx, resiliencePlan)"
          >
            {{ resiliencePlan?.status?.name }}
          </EcButton>
        </EcFlex>
        <!--Context menu -->
        <ResiliencePlanContextMenu :statuses="statuses" ref="contextMenu"></ResiliencePlanContextMenu>
      </EcBox>
    </SummaryRowSkeleton>
  </EcBox>
</template>

<script>
import { useResiliencePlanStatus } from "@/readybc/composables/use/useResiliencePlanStatus"

import ResiliencePlanContextMenu from "./ResiliencePlanContextMenu.vue"
import SummaryRowSkeleton from "./SummaryRowSkeleton.vue"
import useDashboardResiliencePlanStore from "../../stores/useDashboardResiliencePlanStore"
import { storeToRefs } from "pinia"

export default {
  name: "DashboardResiliencePlanSummary",

  setup() {
    const { statuses, getAllStatuses } = useResiliencePlanStatus()

    const resiliencePlanDashboardStore = useDashboardResiliencePlanStore()

    const { isResiliencePlanSummaryLoading, resiliencePlanSummary } = storeToRefs(resiliencePlanDashboardStore)
    return {
      isResiliencePlanSummaryLoading,
      resiliencePlanSummary,
      statuses,
      getAllStatuses,
      resiliencePlanDashboardStore,
    }
  },
  computed: {
    isNoResiliencePlan() {
      return this.resiliencePlanSummary?.length <= 0
    },
  },
  mounted() {
    this.fetchResiliencePlanStatuses()
    this.resiliencePlanDashboardStore.getResiliencePlanSummary()
  },
  methods: {
    /**
     *
     * @param {*} idx
     * @param {*} bia
     */
    handleShowContextMenu(idx, resiliencePlan) {
      this.$refs.contextMenu.resiliencePlan = resiliencePlan
      this.$refs.contextMenu.position = ++idx
      this.$refs.contextMenu.isOpen = true
    },

    /**
     *
     */
    async fetchResiliencePlanStatuses() {
      if (!this.$permissions("bcp.read")) {
        return
      }
      this.statuses = await this.getAllStatuses()
    },
  },
  components: { SummaryRowSkeleton, ResiliencePlanContextMenu },
}
</script>
