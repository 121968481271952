export default {
  equipment: {
    equipment: "Equipment",

    search: "Search by equipment name",

    deleteFullNote: "",

    confirmToDelete: "Confirm to Delete",
    cfDeleteNote: "You are going to delete ",
    cfDeleteNote2: "This action cannot be reverted. The record will be deleted permanently.",
    optional: "Optional",
    filter: "Filter",

    labels: {
      yes: "Yes",
      no: "No",
      name: "Name of Equipment",
      description: "Description",
      entityName: "Entity Name",
      equipmentName: "Equipment Name",
      category: "Category",
      owners: "Equipment owners",
      status: "Status",
      createdAt: "Created At",
      deleteEquipment: "Delete Equipment",
      confirmToDelete: "Confirm to Delete",
      confirmDeleteQuestion: "Are you sure to delete this equipment?",
      noteDeleteEquipment:
        "You can permanently delete the Equipment from database. This action cannot be reverted and you cannot restore the record anymore.",
      linked_activities: "#Linked Activities",
      mtpd: "MTPD",
      shortestMTPD: "Highest MTPD",
    },
    buttons: {
      add: "Add",
      create: "Create",
      update: "Update",
      next: "Next",
      confirm: "Confirm",
      finish: "Finish",
      back: "Back",
      cancel: "Cancel",
      close: "Close",
      exportEquipment: "Export Equipment",
      viewEquipmentCategories: "Equipment Categories",
      viewEquipmentOwners: "Equipment Owners",
      addEquipment: "Add Equipment ",
      edit: "Edit",
      view: "View",
      delete: "Delete",
      deleteEquipment: "Delete Equipment",
    },

    title: {
      newEquipment: "New Equipment",
      editEquipment: "Update Equipment",
      equipmentDetail: "Equipment Detail",
      remote: "Remote access",
      software: "Software / Technology",
      confirmToCancel: "Confirm to cancel",
      cancelQuestion: "Do you wish to cancel doing Equipment?",
    },

    messages: {
      updatedEquipment: "Updated Equipment, redirect to Equipment list...",
      createSuccess: "Create Equipment successfully!",
      deleteSuccess: "Equipment has been delete successfully!",
      code: "Code",
    },
    // Errors
    errors: {
      updateEquipment: "Error to update Equipment, please retry",
      listEquipments: "Unable to get list of Equipments",
      listCategory: "Unable to get list of Equipment Categories",
      listOwner: "Unable to get list of Equipment Owners",
      download: "Error occured, please try again",
    },

    placeholders: {
      category: "--- Equipment Category ---",
      loading: "     Loading...   ",
      bu: "--- Business Unit ---",
    },

    category: {
      label: "Category",
      uid: "uid",
      name: "Category",
      title: {
        newCategory: "New Category",
        editCategory: "Edit Category",
        categoryDetail: "Category detail",
      },
      labels: {
        name: "Name",
        description: "Description",
        createdAt: "Created At",
        deleteCategory: "Delete Category",
        confirmDeleteQuestion: "Are you sure to delete this category",
        noteDeleteCategory:
          "You can permanently delete the category from database. This action cannot be reverted and you cannot restore the record anymore.",
      },
      buttons: {
        addCategory: "Add Category",
        delete: "Delete",
        back: "Back",
        cancel: "Cancel",
      },
      messages: {
        createCategory: "Created category",
      },
      errors: {
        createCategory: "Error! Create category failed",
      },
    },
    owner: {
      label: "Equipment Owners",
      uid: "uid",
      name: "Equipment Owners",
      title: {
        newOwner: "New Owner",
        editOwner: "Edit Owner",
        ownerDetail: "Owner detail",
      },
      labels: {
        name: "Owner name",
        first_name: "Owner name",
        description: "Description",
        invitation_status: "Invite to RBC?",
        createdAt: "Create At",
        createOwner: "Create Equipment Owner",
        firstName: "First name",
        lastName: "Last name",
        email: "Email",
        inviteToUser: "Invite user to ReadyBC?",
        deleteOwner: "Delete Owner",
        confirmToDelete: "Confirm to Delete",
        confirmDeleteQuestion: "Are you sure to delete this owner?",
        noteDeleteOwner:
          "You can permanently delete the Owner from database. This action cannot be reverted and you cannot restore the record anymore.",
      },
      buttons: {
        addOwner: "Add Owner",
        create: "Create",
        confirm: "Confirm",
        delete: "Delete",
        cancel: "Cancel",
        back: "Back",
      },
      messages: {
        createdOwner: "Equipment owner create successfully",
        updateOwner: "Equipment owner has been updated successfully",
        deleteOwner: "Deleted",
      },
      errors: {
        createOwner: "Oops! Unable to create equipment owner",
        fetchOwner: "Error occured, unable to get owner detail",
        updateOwner: "Error occured, unable to update owner data",
        deleteOwner: "Error occured, can not delete equipment owner",
      },
    },
    modal: {
      labels: {},
      buttons: {
        create: "Create",
        cancel: "Cancel",
      },
    },
  },
}
