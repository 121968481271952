<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("equipment.owner.label") }}
        </EcHeadline>

        <!-- Add Owner -->
        <EcButton
          class="mb-3 lg:mb-0"
          iconPrefix="plus-circle"
          variant="primary-sm"
          id="btn-add-equipment-owner"
          @click="handleClickAddOwner"
        >
          {{ $t("equipment.owner.buttons.addOwner") }}
        </EcButton>
      </EcFlex>

      <!-- Search box -->
      <EcFlex class="flex-grow justify-end items-center w-full md:w-auto">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('equipment.search')"
          class="w-full md:max-w-xs"
          id="search-equipment-owner"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Table -->
    <RTable :isLoading="isLoading" :list="owners" class="mt-4 lg:mt-6">
      <template #header>
        <RTableHeaderRow>
          <RTableHeaderCell v-for="(h, idx) in headerData" :key="idx" class="text-cBlack">
            {{ h.label }}
          </RTableHeaderCell>
          <RTableHeaderCell variant="gradient" />
        </RTableHeaderRow>
      </template>
      <template v-slot="{ item, last, first }">
        <RTableRow class="hover:bg-c0-100 equipment-owner-item">
          <RTableCell>
            <EcText class="w-24">
              {{ getEquipmentOwnerFullName(item) }}
            </EcText>
          </RTableCell>

          <!-- Email -->
          <RTableCell>
            <EcText class="w-24">
              {{ item.email }}
            </EcText>
          </RTableCell>

          <!-- status -->
          <RTableCell>
            <EcText :variant="getOwnerInvitationStatusType(item.is_invite)" class="w-32">
              {{ getOwnerInvitationStatus(item.is_invite) }}
            </EcText>
          </RTableCell>

          <!-- created at -->
          <RTableCell>
            <EcText class="pr-5">
              {{ formatData(item.created_at, dateTimeFormat) }}
            </EcText>
          </RTableCell>

          <!-- Action -->
          <RTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }" :isTruncate="false" variant="gradient">
            <EcFlex class="items-center justify-center h-full">
              <RTableAction class="w-30 actions">
                <!-- View action -->
                <!-- <EcFlex class="items-center px-4 py-2 cursor-pointer text-cBlack hover:bg-c0-100">
                  <EcIcon class="mr-3" icon="Eye" />
                  <EcText class="font-medium">{{ $t("equipment.buttons.view") }}</EcText>
                </EcFlex> -->

                <!-- Edit action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-c1-500 hover:bg-c0-100 btn-edit"
                  @click="handleClickEditOwner(item.uid)"
                >
                  <EcIcon class="mr-3" icon="Pencil" />
                  <EcText class="font-medium">{{ $t("equipment.buttons.edit") }}</EcText>
                </EcFlex>
                <!-- Delete action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-cError-500 hover:bg-c0-100 btn-modal-delete"
                  @click="handleOpenDeleteModal(item.uid, this.getEquipmentOwnerFullName(item))"
                >
                  <EcIcon class="mr-3" icon="X" />
                  <EcText class="font-medium">{{ $t("equipment.buttons.delete") }}</EcText>
                </EcFlex>
              </RTableAction>
            </EcFlex>
          </RTableCell>
        </RTableRow>
      </template>
    </RTable>

    <!-- Pagination -->
    <EcFlex class="flex-col my-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="filters.page.size"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="filters.page.size"
        :totalItems="filters.page.total"
        @input="setPage($event)"
      />
    </EcFlex>

    <!-- Actions -->
    <EcFlex class="">
      <EcButton variant="tertiary" id="btn-back" @click="handleBackToEquipmentList">
        {{ $t("equipment.owner.buttons.back") }}
      </EcButton>
    </EcFlex>
    <!-- Modal  delete owner -->
    <teleport to="#layer1">
      <ModalDeleteOwner
        :ownerUid="toDeleteOwnerUid"
        :ownerName="toDeleteOwnerName"
        :isModalDeleteOwnerOpen="isModalDeleteOpen"
        @handleCloseDeleteModal="handleCloseDeleteModal"
        @handleDeleteCallback="handleDeleteCallback"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useOwnerList } from "@/modules/equipment/use/owner/useOwnerList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import { ref } from "vue"
import ModalDeleteOwner from "../../components/ModalDeleteOwner.vue"

export default {
  name: "ViewOwnerList",
  setup() {
    const globalStore = useGlobalStore()
    const { getOwnerList, owners, t } = useOwnerList()
    const equipmentCategories = ref([])
    return {
      globalStore,
      getOwnerList,
      owners,
      t,
      equipmentCategories,
    }
  },
  data() {
    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 10,
        total: 0,
      },
    }
    return {
      headerData: [
        // { label: this.$t("equipment.label.businessUnit") },
        { label: this.$t("equipment.owner.labels.name") },
        { label: this.$t("equipment.owner.labels.email") },
        { label: this.$t("equipment.owner.labels.invitation_status") },
        { label: this.$t("equipment.owner.labels.createdAt") },
      ],
      searchQuery: "",
      isLoading: false,
      isDownloading: false,
      isModalDeleteOpen: false,
      // Owner uid to delete
      toDeleteOwnerUid: null,
      toDeleteOwnerName: "",
      currentPage: 1,
      filters,
    }
  },
  mounted() {
    this.fetchOwners()
  },
  computed: {
    /**
     * Format time
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  watch: {
    currentPage() {
      this.filters.page.number = this.currentPage
      this.fetchOwners()
    },
  },
  methods: {
    formatData,
    /**
     * fetch activities
     * @returns {Promise<void>}
     */
    async fetchOwners() {
      this.isLoading = true
      const ownerRes = await this.getOwnerList(this.filters)

      if (ownerRes && ownerRes.data) {
        this.owners = ownerRes.data

        // Pagination
        this.currentPage = ownerRes.current_page
        this.filters.page.size = ownerRes.per_page
        this.filters.page.total = ownerRes.total
      }
      this.isLoading = false
    },
    /**
     * convert equipment status to string status
     * @param value
     * @returns {string}
     */
    getOwnerInvitationStatus(value) {
      return value ? "Yes" : "No"
    },

    /**
     * get class property
     * @param value
     * @returns {string}
     */
    getOwnerInvitationStatusType(value) {
      return value ? "pill-cSuccess-inv" : "pill-disabled"
    },

    /**
     * Get Owner full name
     */
    getEquipmentOwnerFullName(owner) {
      const fullName = [owner?.first_name, owner?.last_name]
      return fullName.join(" ")
    },

    // Handle events

    /**
     * Add new activity
     */
    handleClickAddOwner() {
      goto("ViewOwnerNew")
    },

    /**
     *
     * @param {*} ownerUid
     */
    handleClickEditOwner(ownerUid) {
      goto("ViewOwnerDetail", {
        params: {
          uid: ownerUid,
        },
      })
    },

    /**
     *
     * @returns {Promise<void>}
     */
    async handleClearSearch() {
      this.searchQuery = ""
      this.filters.filter = []
      this.filters.page = {}

      await this.fetchOwners()
    },

    /**
     *
     * @returns {Promise<void>}
     */
    async handleSearch() {
      this.filters.filter = [
        {
          name: "first_name",
          type: "contain",
          value: this.searchQuery,
        },
      ]
      // Always set current page = 1
      this.filters.page = {}

      await this.fetchOwners()
    },

    /**
     * Open delete equipment modal
     */
    handleOpenDeleteModal(ownerUid, ownerName) {
      this.toDeleteOwnerUid = ownerUid
      this.toDeleteOwnerName = ownerName
      this.isModalDeleteOpen = true
    },
    /**
     * Open delete equipment modal
     */
    handleCloseDeleteModal() {
      this.toDeleteOwnerUid = null
      this.toDeleteOwnerName = ""
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete callback
     */
    handleDeleteCallback() {
      this.fetchOwners()
    },

    /**
     * Back to equipment list
     */
    handleBackToEquipmentList() {
      goto("ViewEquipmentList")
    },
    // ==== PRE-LOAD ==========
  },
  components: { ModalDeleteOwner },
}
</script>
