<template>
  <EcBox variant="card-2" class="relative mb-4 mr-3 lg:inline-flex lg:flex-grow lg:w-auto org-card" style="min-width: 12rem">
    <EcFlex class="justify-items-center justify-center items-center p-4 rounded-full w-full lg:w-32 h-auto overflow-hidden">
      <img :src="organization.logo_url" :alt="organization.name" />
    </EcFlex>
    <EcBox class="mt-4 lg:mt-0 lg:ml-6">
      <EcFlex class="items-center">
        <EcText class="font-medium text-lg text-cBlack">
          {{ organization.name }}
        </EcText>
      </EcFlex>

      <EcText class="font-medium text-c0-500 text-sm mt-2">
        Status:
        <span :class="statusTextColor(organization.is_active)">{{ statusText(organization) }}</span>
      </EcText>
      <EcText class="font-medium text-c0-500 text-sm mt-2">
        Created at: {{ globalStore.formatDate(organization.created_at) }}</EcText
      >

      <!-- Actions -->
      <EcFlex class="w-full items-center mt-2">
        <!-- Edit -->
        <EcBox v-if="organization.name" class="ml-2">
          <EcButton
            class="btn-edit-org"
            variant="transparent-rounded"
            @click="handleClickEdit"
            v-tooltip="{ text: 'Edit Organisation', position: 'bottom' }"
          >
            <EcIcon icon="Pencil" width="20" height="20" class="text-cError-500" />
          </EcButton>
        </EcBox>

        <!-- View -->

        <EcBox v-if="organization.name && !isLandlord" class="ml-2">
          <EcButton
            class="btn-manage-org"
            variant="transparent-rounded"
            @click="handleClickManageOrganization"
            v-tooltip="{ text: 'Manage Organisation', position: 'bottom' }"
          >
            <EcIcon class="text-c0-500" icon="Manage" width="20" height="20" />
          </EcButton>
        </EcBox>

        <EcBox v-if="organization.name && !isLandlord" class="ml-2">
          <EcButton
            class="btn-duplicate-org"
            variant="transparent-rounded"
            @click="handleClickDuplicateOrganization"
            v-tooltip="{ text: 'Duplicate Organisation', position: 'bottom' }"
          >
            <EcIcon class="text-c0-500" icon="Copy" width="16" height="16" />
          </EcButton>
        </EcBox>

        <!-- End view -->

        <!-- View -->

        <!-- <EcBox v-if="organization.name && !isLandlord" class="ml-2">
          <EcButton
            variant="transparent-rounded"
            @click="handleClickViewActivities"
            v-tooltip="{ text: organization.name + '\'s activities', position: 'bottom' }"
          >
            <EcIcon class="text-c0-500" icon="Activity" width="20" height="20" />
          </EcButton>
        </EcBox> -->

        <!-- End view -->
      </EcFlex>
    </EcBox>

    <!-- Landlord indicator or link to client portal -->
    <EcBox class="absolute top-[16px] right-[6px]">
      <!-- Lock Icon for landlord or go to client portal for tenant -->
      <EcIcon v-if="isLandlord" icon="LockClosed" class="text-cError-500" width="18" height="18" />
      <EcBox v-else>
        <!-- Open link -->
        <EcButton
          class="h-1 w-1"
          variant="transparent"
          :href="'https://' + organization?.domain"
          target="_blank"
          v-tooltip="{ text: 'Go to client portal' }"
        >
          <EcIcon icon="OpenUp" width="16" height="16" />
        </EcButton>

        <!-- Archived -->
        <EcButton
          v-if="organization.is_archived"
          class="mt-1 w-1 hover:cursor-auto"
          variant="transparent"
          v-tooltip="{ text: 'Organisation has been archived' }"
        >
          <EcIcon icon="Archive" width="16" height="16" />
        </EcButton>
      </EcBox>
    </EcBox>
  </EcBox>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import EcIcon from "@/components/EcIcon/index.vue"
import * as helpers from "@/readybc/composables/helpers/helpers"
import EcBox from "@/components/EcBox/index.vue"

export default {
  name: "OrganizationListCardItem",
  props: {
    organization: {
      type: Object,
      default: () => {},
    },
  },
  setup() {
    const globalStore = useGlobalStore()
    return {
      globalStore,
    }
  },
  computed: {
    isLandlord() {
      return this.organization?.landlord === true
    },
  },
  methods: {
    statusTextColor(status) {
      return status ? "font-bold text-cSuccess-500" : "font-bold text-cError-500"
    },
    statusText(organization) {
      return organization?.is_active ? "Active" : "Inactive"
    },
    /**
     * Edit organization
     */
    handleClickEdit() {
      helpers.setTenantData(this.organization)

      goto("ViewOrganizationDetail", {
        params: {
          uid: this.organization?.uid,
        },
      })
    },

    /**
     * Manage organization
     */
    handleClickManageOrganization() {
      // Set tenant data
      helpers.setTenantData(this.organization)

      goto("ViewOrganizationManagement", {
        params: {
          organizationUid: this.organization?.uid,
        },
      })
    },

    /**
     * Dưplicate organization
     */
    handleClickDuplicateOrganization() {
      goto("ViewOrganizationDuplication", {
        params: {
          organizationUid: this.organization?.uid,
        },
      })
    },

    /**
     * View Activity list
     */
    handleClickViewActivities() {
      goto("ViewActivityList")
    },
  },
  components: { EcIcon, EcBox },
}
</script>
