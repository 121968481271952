import fetcher from "@/modules/core/api/fetcher"

export const getUserMfa = async () => {
  return fetcher.get("/identity/api/v1/mfa/setting")
}

export const fetchQRUri = async () => {
  return fetcher.get(`/identity/api/v1/mfa/qr-uri`)
}

export const verifyMfa = async (payload) => {
  return fetcher.post("identity/api/v1/mfa/verification", payload)
}

export const preferenceMfa = async (payload) => {
  return fetcher.post("identity/api/v1/mfa/preference", payload)
}

export const settingMfa = async () => {
  return fetcher.get("/identity/api/v1/mfa/setting-mfa")
}
