import { fetchBIASummary, fetchBIAStatistic } from "@/readybc/composables/api/apiDashboard"
import { defineStore } from "pinia"
import { ref } from "vue"

const useDashboardBIAStore = defineStore("useDashboardBIAStore", () => {
  const isBIAStatisticLoading = ref(false)
  const isBIASummaryLoading = ref(false)
  const biaStatistic = ref([])
  const biaSummary = ref([])

  /**
   *
   */
  const getBIAStatistic = () => {
    isBIAStatisticLoading.value = true

    /**
     * Statistic
     */
    fetchBIAStatistic()
      .then(({ data }) => {
        biaStatistic.value = data || []
        isBIAStatisticLoading.value = false
      })
      .catch(() => {
        isBIAStatisticLoading.value = false
      })
  }

  /**
   * Summary
   */
  const getBIASummary = () => {
    isBIASummaryLoading.value = true
    fetchBIASummary()
      .then(({ data }) => {
        biaSummary.value = data || []

        isBIASummaryLoading.value = false
      })
      .catch(() => {
        isBIASummaryLoading.value = false
      })
  }

  return {
    isBIASummaryLoading,
    isBIAStatisticLoading,
    biaSummary,

    biaStatistic,
    getBIAStatistic,
    getBIASummary,
  }
})

export default useDashboardBIAStore
