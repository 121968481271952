<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("setting.general.masterDataModules.activityOwners") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>
    <UserSubMenu />
    <EcFlex class="justify-end mt-4">
      <EcButton variant="primary-sm" class="mb-3 lg:mb-0" id="btn-create" iconPrefix="plus-circle" @click="handleClickAdd()">
        {{ $t("setting.general.buttons.addActivityOwner") }}
      </EcButton>
    </EcFlex>

    <!-- Table -->
    <EcTable
      :columns="columns"
      :dataSource="activityOwners"
      :actionMenu="true"
      :isLoading="isGetRoleLoading"
      @onEdit="handleClickEdit"
      :defaultMenuItemVisible="{ edit: true }"
    >
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = size
          }
        "
      />
    </EcFlex>

    <!-- Actions -->
    <EcFlex class="mt-4">
      <EcButton variant="tertiary" @click="handleBackToMasterList">
        {{ $t("setting.general.buttons.back") }}
      </EcButton>
    </EcFlex>

    <teleport to="#layer1">
      <ModalAddActivityOwner
        :isModalAddNewOpen="isModalAddNewOpen"
        @handleCloseAddNewModal="handleCloseAddNewModal"
        @handleCallbackAddNew="handleCallbackAddNew"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useActivityOwnerList } from "@/modules/user/use/activityOwners/useActivityOwnerList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import ModalAddActivityOwner from "@/modules/user/components/ModalAddActivityOwner.vue"
import UserSubMenu from "@/modules/user/components/UserSubMenu.vue"

export default {
  name: "ViewActivityOwnerList",
  components: { UserSubMenu, ModalAddActivityOwner },
  setup() {
    const globalStore = useGlobalStore()
    const { getActivityOwnerList, t, listData, apiCriteria, activityOwners } = useActivityOwnerList()

    return {
      globalStore,
      getActivityOwnerList,
      t,
      listData,
      apiCriteria,
      activityOwners,
    }
  },
  data() {
    const columns = [
      {
        title: this.$t("setting.general.labels.name"),
        key: "name",
        sortKey: "name",
        sortable: true,
      },

      {
        title: this.$t("setting.general.labels.email"),
        key: "email",
        sortKey: "email",
        sortable: true,
      },
    ]

    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 10,
        total: 0,
      },
    }
    return {
      headerData: [{ label: this.$t("setting.general.labels.name") }, { label: this.$t("setting.general.labels.email") }],
      currentPage: 1,
      searchQuery: "",
      isLoading: false,
      isModalAddNewOpen: false,
      filters,
      columns,
    }
  },
  mounted() {
    this.getActivityOwnerList()
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },

  methods: {
    formatData,

    /**
     * Add new utilities
     */
    handleClickAdd() {
      this.isModalAddNewOpen = true
    },

    /**
     * Cancel add utilities
     */
    handleCloseAddNewModal() {
      this.isModalAddNewOpen = false
    },

    /**
     * Create utilities callback
     */
    handleCallbackAddNew() {
      this.getActivityOwnerList()
    },

    /**
     * Back to master list
     */
    handleBackToMasterList() {
      goto("ViewMasterSetting")
    },

    /**
     * View activity owner detail
     * @param owner
     */
    handleClickEdit(owner) {
      goto("ViewActivityOwnerDetail", {
        params: {
          uid: owner.uid,
        },
      })
    },

    setPage(event) {
      this.currentPage = event.target.value
    },
  },
}
</script>
