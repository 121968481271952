import ViewResiliencePlanList from "@/modules/resilience_plan/views/plan/ViewResiliencePlanList"
import ViewResiliencePlanNew from "@/modules/resilience_plan/views/plan/ViewResiliencePlanNew"
import ViewResiliencePlanDetail from "@/modules/resilience_plan/views/plan/ViewResiliencePlanDetail"

// Resilicience Plan
import ViewResilienceStatusList from "@/modules/resilience_plan/views/status/ViewResilienceStatusList"
import ViewResilienceStatusDetail from "@/modules/resilience_plan/views/status/ViewResilienceStatusDetail"

import ViewResiliencePlanCategoryList from "@/modules/resilience_plan/views/category/ViewResiliencePlanCategoryList"
import ViewResiliencePlanCategoryDetail from "@/modules/resilience_plan/views/category/ViewResiliencePlanCategoryDetail"

export default [
  {
    path: "/resilience-plans/:uid?",
    component: ViewResiliencePlanList,
    name: "ViewResiliencePlanList",
    props: true,
    meta: {
      permissions: "resilience_plan.read",
      module: "resilience_plan",
    },
  },

  {
    path: "/resilience-plans/:cuid?/new",
    component: ViewResiliencePlanNew,
    name: "ViewResiliencePlanNew",
    props: true,
    meta: {
      permissions: "resilience_plan.create",
      module: "resilience_plan",
    },
  },

  {
    path: "/resilience-plans/:uid",
    component: ViewResiliencePlanDetail,
    name: "ViewResiliencePlanDetail",
    props: true,
    meta: {
      module: "resilience_plan",
    },
  },

  // Category and status as master data
  {
    path: "/settings/master/resilience-plan-statuses",
    component: ViewResilienceStatusList,
    name: "ViewResiliencePlanStatusList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/resilience-plan-statuses/:uid",
    component: ViewResilienceStatusDetail,
    name: "ViewResiliencePlanStatusDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/resilience-plan-categories",
    component: ViewResiliencePlanCategoryList,
    name: "ViewResiliencePlanCategoryList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/resilience-plan-categories/:uid",
    component: ViewResiliencePlanCategoryDetail,
    name: "ViewResiliencePlanCategoryDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
]
