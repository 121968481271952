<template>
  <!-- eslint-disable-next-line -->
  <component :is="as" v-html="sanitizedData" v-bind="$attrs" />
</template>

<script>
import DOMPurify from "dompurify"
export default {
  inheritAttrs: false,
  name: "EcRichTextDisplay",
  props: {
    data: {
      type: String,
      required: true,
    },
    as: {
      type: String,
      default: () => "div",
    },
  },
  computed: {
    sanitizedData() {
      return DOMPurify.sanitize(this.data)
    },
  },
}
</script>
