<template>
  <!-- Modals -->
  <EcModalSimple :isVisible="isModalIdleOpen" variant="center-3xl">
    <EcBox class="text-center">
      <EcFlex class="justify-center">
        <EcIcon class="text-cError-500" height="4rem" icon="Clock" width="4rem" />
      </EcFlex>
      <EcHeadline as="h1" variant="h1" class="px-3 text-c1-800">
        {{ $t("core.idleQuestion") }}
      </EcHeadline>

      <!-- Message -->
      <EcText>{{ $t("core.idleTimeout") }}</EcText>

      <EcText class="text-cError-500 font-medium mt-4">{{ countDown }}</EcText>

      <!-- Actions -->
      <EcFlex class="justify-center mt-10">
        <EcButton @click="handleContinueClick()">
          {{ $t("core.continue") }}
        </EcButton>
        <EcButton class="ml-3" @click="handleLogoutClick()">
          {{ $t("core.logout") }}
        </EcButton>
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import IdleJs from "idle-js"
import { useRefreshToken } from "@/modules/auth/use/useRefreshToken"
import { useBrowserTabs } from "@/modules/core/use/useBrowserTabs"
import { useBroadcastChannel } from "@/modules/core/use/useBroadcastChannel"

export default {
  name: "RIdle",
  props: {},

  setup(props) {
    const globalStore = useGlobalStore()

    const isModalIdleOpen = ref(false)
    const countDown = ref(0)
    const countDownInterval = ref(null)
    const checkIdleInterval = ref(null)

    const { submitRefreshToken } = useRefreshToken()
    const { setBrowserTabData, getBrowserTabData } = useBrowserTabs()
    const { createBroadcastChannel, broadcastChannelEnum } = useBroadcastChannel()
    return {
      globalStore,
      isModalIdleOpen,
      countDown,
      countDownInterval,
      submitRefreshToken,

      setBrowserTabData,
      getBrowserTabData,

      broadcastChannelEnum,
      createBroadcastChannel,
      checkIdleInterval,
    }
  },
  data() {
    // Get idle time from env. 15 minutes (900s) in default

    // Set idle time is 3600
    const envTime = this.globalStore?.idleTime ? parseInt(this.globalStore.idleTime) : 900
    const bc = this.createBroadcastChannel("idle-channel")
    // Show the popup before timeout 30 s
    const idleTime = envTime * 1000 - 30 * 1000
    // Events emit when reset idle time
    const events = ["keydown", "mousedown", "touchstart"]
    const keepTracking = true
    const startAtIdle = true
    const idle = new IdleJs({
      idle: idleTime,
      events,
      keepTracking,
      startAtIdle,

      onIdle: () => {
        if (this.globalStore.isOnPublicRoute()) {
          return
        }
        // Set current tab is Idle
        this.setCurrentTabIdleStatus(true)
      },
      onActive: () => {
        if (this.globalStore.isOnPublicRoute()) {
          return
        }
        this.setCurrentTabIdleStatus(false)
      },
    })
    return {
      idle,
      bc,
    }
  },
  mounted() {
    if (this.globalStore?.idleTime > 0) {
      this.idle.start()
    }
    this.bc.onmessage = (event) => {
      this.handleMessage(event)
    }
    this.checkIdleInterval = setInterval(() => {
      // Action when user idle in idleTime
      if (this.isAllTabsIdle()) {
        this.bc.postMessage(this.broadcastChannelEnum.ALL_TABS_IDLE)

        this.handleWhenIdle()
      }
    }, 60 * 1000)
  },
  unmounted() {
    clearInterval(this.checkIdleInterval)
  },
  computed: {},
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    async handleContinueClick() {
      this.isModalIdleOpen = false

      // Clear countdown interval

      clearInterval(this.countDownInterval)
      this.bc.postMessage(this.broadcastChannelEnum.CONTINUE_BROWSING)

      const result = await this.submitRefreshToken()
      if (!result) {
        this.globalStore.logout()
      }
    },
    /**
     *
     * @returns {boolean}
     */
    isAllTabsIdle() {
      const browserTabData = this.getBrowserTabData()
      for (const key in browserTabData) {
        if (!browserTabData[key].isIdle) return false
      }
      return true
    },
    /**
     *
     * @param status
     */
    setCurrentTabIdleStatus(status) {
      this.setBrowserTabData("isIdle", status)
    },
    /**
     *
     */
    handleLogoutClick() {
      this.globalStore.logout()
      this.isModalIdleOpen = false
    },
    /**
     *
     * @param event
     */
    handleMessage(event) {
      switch (event.data) {
        case this.broadcastChannelEnum.ALL_TABS_IDLE:
          this.handleWhenIdle()

          break
        case this.broadcastChannelEnum.CONTINUE_BROWSING:
          this.isModalIdleOpen = false
          // Clear countdown interval
          clearInterval(this.countDownInterval)
          break
      }
    },
    handleWhenIdle() {
      if (!this.isModalIdleOpen && !this.globalStore.isOnPublicRoute()) {
        // Post message to another browser all tabs is idle
        this.isModalIdleOpen = true
        // Set count down
        this.countDown = 30

        this.countDownInterval = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--
          } else {
            clearInterval(this.countDownInterval)
            this.isModalIdleOpen = false
            this.globalStore.logout()
          }
        }, 1000)
      }
    },
  },
}
</script>
