import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import * as api from "../api/activityFetcher"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"

export function useActivityApplicationsAndEquipments() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const form = ref({
    applications: [],
    equipments: [],
  })

  const rules = {
    form: {
      applications: {},
      equipments: {},
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @param {*} activityUid
   * @returns
   */
  const updateApplicationAnEquipments = async (payload, activityUid) => {
    try {
      const { data } = await api.updateApplicationAdnEquipment(payload, activityUid)

      if (!data) {
        globalStore.addErrorToastMessage(t("activity.errors.updateActivity"))
      } else {
        globalStore.addSuccessToastMessage(t("activity.messages.toNextStep"))
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    form,
    v$,
    updateApplicationAnEquipments,
  }
}
