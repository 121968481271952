import { useRoute } from "vue-router"
import * as api from "@/modules/resilience_plan/api/category/apiResiliencePlanCategory"
import { onMounted, ref } from "vue"
import { goto } from "@/modules/core/composables"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useResiliencePlanCategoryDetail() {
  const isLoading = ref(false)
  const isUpdateLoading = ref(false)

  const category = ref({})
  const router = useRoute()

  const rules = {
    category: {
      name: { required },
      order: {},
      description: {},
    },
  }

  const v$ = useVuelidate(rules, { category })

  const { uid } = router.params

  onMounted(async () => {
    await getResilienceCategory(uid)
  })

  /**
   *
   * @returns
   */
  const getResilienceCategory = async (uid) => {
    try {
      isLoading.value = true
      const { data } = await api.fetchResiliencePlanCategory(uid)

      category.value = data || {}

      isLoading.value = false

      return data
    } catch (error) {
      goto("ViewResiliencePlanCategoryList")
      return false
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updatePlanCategory = async (payload) => {
    v$.value.$touch()

    if (v$.value.$invalid) {
      return
    }

    try {
      const { data } = await api.updateResiliencePlanCategory(uid, payload)
      category.value = data || {}

      goto("ViewResiliencePlanCategoryList")
      return data
    } catch (error) {
      goto("ViewResiliencePlanCategoryList")
      return false
    }
  }

  /**
   *
   * @returns
   */
  const deleteResilienceCategory = async (uid) => {
    try {
      const { data } = await api.deleteResiliencePlanCategory(uid)
      category.value = data || {}

      return data
    } catch (error) {
      goto("ViewResiliencePlanCategoryList")
      return false
    }
  }

  return {
    isLoading,
    category,
    v$,
    getResilienceCategory,
    deleteResilienceCategory,
    updatePlanCategory,
    isUpdateLoading,
  }
}
