<template>
  <EcPopup :title="$t('report.modal.title.reportData')" v-model="isOpen" size="5xl" :showFooter="false">
    <template v-slot:body>
      <EcBox class="p-4">
        <!-- Status -->
        <!-- <EcFlex class="flex-wrap max-w-full">
          <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="form.reportType"
              componentName="EcSelect"
              :options="reportTypes"
              :allowSelectNothing="false"
              :label="$t('report.modal.reportType')"
            />
          </EcBox>
        </EcFlex> -->
        <!-- name -->
        <EcLabel class="text-base font-semibold">Activity Created Date</EcLabel>
        <EcFlex class="mt-2">
          <EcBox>
            <EcLabel class="text-base">Start Date</EcLabel>
            <EcDatePicker
              v-model="formFilters.activityCreatedAtObj.start"
              class="xs:min-w-24 xs:max-w-32 min-w-48 max-w-48"
              :maxDate="new Date()"
            >
            </EcDatePicker>
          </EcBox>

          <EcBox class="ml-2">
            <EcLabel class="text-base">End Date</EcLabel>
            <EcDatePicker
              v-model="formFilters.activityCreatedAtObj.end"
              class="xs:min-w-24 xs:max-w-32 min-w-48 max-w-48"
              :maxDate="new Date()"
            >
            </EcDatePicker>
          </EcBox>
        </EcFlex>

        <!-- SBU selection -->

        <EcLabel class="mt-4 text-base font-semibold">Resources Division/Business Unit</EcLabel>
        <EcLabel class="font-medium text-xs italic">
          If no SBU is chosen, the system generates a complete report with all SBUs.
        </EcLabel>

        <EcTree
          v-if="!isLoading"
          :modelValue="divisions"
          :items="divisions"
          labelKey="name"
          valueKey="uid"
          class="max-h-[50vh]"
          :expandingCallback="fetchDivisionBUs"
          :autoSelectChildren="true"
          :showCheckAll="true"
          :checkAllProp="formFilters.checkAll"
          @changed="
            (data) => {
              formFilters.selectedSBUs = data
            }
          "
          @onCheckAll="
            (val) => {
              formFilters.checkAll = val
            }
          "
        />

        <!-- Actions -->
        <EcFlex v-if="!isLoading" class="justify-end mt-10">
          <EcButton variant="tertiary-outline" @click="handleCloseModal">
            {{ $t("report.buttons.cancel") }}
          </EcButton>

          <EcButton class="ml-3" variant="primary" @click="handleClickApplyFilters">
            {{ $t("report.buttons.applyFilter") }}
          </EcButton>
        </EcFlex>

        <!-- End action -->
        <EcFlex v-else class="items-center justify-center mt-10 h-10">
          <EcSpinner type="dots" />
        </EcFlex>
      </EcBox>
    </template>
  </EcPopup>
</template>

<script>
import { reactive } from "vue"
import { useModalReportSbuSelection } from "../use/useModalReportSbuSelection"
import { dateHelper } from "@/readybc/composables/helpers/dateHelper"

export default {
  name: "ModalReportSbuSelection",
  data() {
    return {
      dateTypeOptions: [
        { name: this.$t("core.between"), value: "between" },
        { name: this.$t("core.before"), value: "before" },
        { name: this.$t("core.after"), value: "after" },
      ],
      quickOptions: [
        { label: this.$t("core.lastWeek"), value: "lastWeek" },
        { label: this.$t("core.lastMonth"), value: "lastMonth" },
        { label: this.$t("core.lastThreeMonths"), value: "last3Months" },
        { label: this.$t("core.lastSixMonths"), value: "last6Months" },
        { label: this.$t("core.lastYear"), value: "lastYear" },
      ],
      isOpen: false,
      isShowWarning: false,
    }
  },
  setup() {
    const { divisions, formFilters, fetchDivisionList, isLoading, fetchDivisionBUs, clickGenerateReport, reportTypes } =
      useModalReportSbuSelection()
    const form = reactive({})
    return {
      form,
      fetchDivisionList,
      fetchDivisionBUs,
      divisions,
      formFilters,
      isLoading,
      clickGenerateReport,
      reportTypes,
      dateHelper,
    }
  },
  emits: ["close", "handleApplyFilters"],
  props: {
    isModalOpen: {
      type: Boolean,
      default: false,
    },
  },
  beforeMount() {
    this.isShowWarning = false
  },
  mounted() {
    this.fetchDivisionList()
    this.isShowWarning = false
  },
  methods: {
    /**
     * Close cancel modal
     */
    handleCloseModal() {
      this.$emit("close")
    },

    handleClickApplyFilters() {
      this.$emit("handleApplyFilters", this.formFilters)
      this.handleCloseModal()
    },
    /**
     *
     * @param {*} data
     */
    handleChangeActivityCreatedAt(data) {
      this.formFilters.activityCreatedAtObj = data
    },

    /**
     *
     */
    handleOnConfirmForceApplyFilters() {
      this.$emit("handleApplyFilters", this.formFilters)
      this.handleCloseModal()
    },
  },
  watch: {
    isModalOpen(val) {
      this.isOpen = val
      this.isShowWarning = false
    },

    isOpen(val) {
      if (val === false) {
        this.$emit("close")
      }
    },
  },
}
</script>

<style scoped></style>
