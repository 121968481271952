import * as api from "../api/apiBIAStatus"
import { ref } from "vue"

export function useBIAStatusEnum() {
  const APPROVED = 6

  const statuses = ref([])

  /**
   *
   * @returns
   */
  const getStatuses = async () => {
    try {
      const { data } = await api.fetchAllBIAStatusList()

      return data
    } catch (error) {
      return false
    }
  }

  return {
    APPROVED,
    statuses,
    getStatuses,
  }
}
