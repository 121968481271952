import { reactive, ref, watch } from "vue"
import * as api from "../api/reportTemplateFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useReportTemplateRevisionList() {
  const globalStore = useGlobalStore()

  const listTemplateRevisions = ref([])
  const templateType = ref("")
  const apiCriteria = reactive(new ApiCriteria())
  const isTemplateRevisionLoading = ref(false)

  const { t } = useI18n()

  // Default size
  apiCriteria.pagination.size = 20

  /**
   *
   * @returns
   */
  async function getReportTemplateRevisionList() {
    try {
      isTemplateRevisionLoading.value = true
      const { data } = await api.fetchReportTemplateRevisions(templateType.value, apiCriteria.toQuery())

      if (data && data.data) {
        listTemplateRevisions.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isTemplateRevisionLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("reportTemplate.errors.listReport"))
      listTemplateRevisions.value = []
      isTemplateRevisionLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (current !== oldVal) {
        getReportTemplateRevisionList()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (current !== oldVal) {
        apiCriteria.resetPage()
        getReportTemplateRevisionList()
      }
    },
    { deep: true }
  )

  /**
   * Column Sorting
   */
  const handleColumnSort = async (col, sorted) => {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()
    await getReportTemplateRevisionList()
  }

  return {
    templateType,
    listTemplateRevisions,
    getReportTemplateRevisionList,
    handleColumnSort,
    apiCriteria,
    isTemplateRevisionLoading,
    t,
  }
}
