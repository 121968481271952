import * as api from "../../../api/locationsFetcher"
import { useGlobalStore } from "@/stores/global"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useLocationDetail() {
  const globalStore = useGlobalStore()

  const form = ref({})

  const rules = {
    form: {
      name: { required },
      description: {},
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   * Get location detail
   * @param uid
   * @returns
   */
  const getLocation = async (uid) => {
    try {
      const { data } = await api.fetchLocationDetail(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   * Update location
   * @param payload
   * @param uid
   * @returns
   */
  const updateLocation = async (payload, uid) => {
    try {
      const { data } = await api.updateLocation(payload, uid)
      globalStore.addSuccessToastMessage("Successfully updated!")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   * Delete location
   * @param {*} uid
   * @returns
   */
  const deleteLocation = async (uid) => {
    try {
      const response = await api.deleteLocation(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  return {
    getLocation,
    updateLocation,
    deleteLocation,
    form,
    v$,
  }
}
