import { ref } from "vue"
import * as api from "../../api/businessUnitFetcher"

export const useBusinessUnitList = () => {
  const currentPage = ref(1)

  /**
   *
   * @param {*} organizationUid
   * @returns
   */
  const getBusinessUnits = async (params) => {
    try {
      const { data } = await api.fetchBusinessUnitList(params)

      return data
    } catch (error) {}
  }

  /**
   *
   * @param {*} divisionUid
   * @param {*} filters
   * @returns
   */
  const getBusinessUnitsByDivision = async (divisionUid, filters) => {
    try {
      const { data } = await api.fetchBusinessUnitListByDivision(divisionUid, filters)

      return data
    } catch (error) {}
  }

  return {
    currentPage,
    getBusinessUnits,
    getBusinessUnitsByDivision,
  }
}
