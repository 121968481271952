import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchListRepost = async (params) => {
  return fetcher.get("identity/api/v1/reports", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchReportDetails = async (uid) => {
  return fetcher.get(`identity/api/v1/reports/${uid}`)
}

export const deleteReport = async (uid) => {
  return fetcher.delete(`identity/api/v1/reports/${uid}`)
}
