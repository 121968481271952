import { ref } from "vue"
import * as api from "../../api/dependencyScenarioFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"

export function useDependencyList() {
  const globalStore = useGlobalStore()

  const dependencies = ref([])

  const { t } = useI18n()

  /**
   *
   * @returns
   */
  async function getDependencyList(requestParam) {
    try {
      const { data } = await api.fetchDependencyScenariosList(requestParam)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.listEquipments"))
    }
  }

  /**
   * Download equipments
   * @returns
   */
  async function downloadDependencies(categoryUid) {
    try {
      const { data } = await api.downloadDependencyScenarios(categoryUid)

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("equipments.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Dependency_Scenarios_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("equipment.errors.download"))
      return false
    }
  }

  return {
    getDependencyList,
    downloadDependencies,
    dependencies,
    t,
  }
}
