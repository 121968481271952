<template>
  <svg data-name="Layer 1" viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
    <path
      d="m114 100 49-49a9.9 9.9 0 0 0-14-14l-49 49-49-49a9.9 9.9 0 0 0-14 14l49 49-49 49a9.9 9.9 0 0 0 14 14l49-49 49 49a9.9 9.9 0 0 0 14-14Z"
      fill="#8a8a8a"
      class="fill-000000"
    ></path>
  </svg>
</template>
<script>
export default {
  name: "Clear",
}
</script>
