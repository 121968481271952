import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchActivityOwnerList = async (params) => {
  return fetcher.get("identity/api/v1/activity-owners", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchAllActivityOwners = async () => {
  return fetcher.get(`identity/api/v1/activity-owners/all`)
}

export const fetchActivityOwnerDetail = async (uid) => {
  return fetcher.get(`identity/api/v1/activity-owners/${uid}`)
}
export const updateActivityOwner = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/activity-owners/${uid}`, payload)
}

export const deleteActivityOwner = async (uid) => {
  return fetcher.delete(`identity/api/v1/activity-owners/${uid}`)
}

export const createActivityOwner = async (payload) => {
  return fetcher.post("identity/api/v1/activity-owners", payload)
}
