export const COPY_FIELD = "copyField"
export const PASTE_FIELD = "pasteField"
export const COPY_ROW = "copyRow"
export const PASTE_ROW = "pasteRow"
export const DUPLICATE_ROW = "duplicateRow"
export const INSERT_ROW = "insertNewRow"
export const REMOVE_ROW = "removeRow"
export const PASTE_FROM_EXCEL = "pasteFromExcel"

export default {
  COPY_FIELD,
  PASTE_FIELD,
  COPY_ROW,
  PASTE_ROW,
  DUPLICATE_ROW,
  INSERT_ROW,
  REMOVE_ROW,
  PASTE_FROM_EXCEL,
}
