import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import * as api from "../api/activityFetcher"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"

export function useActivityNew() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const form = ref({
    division: {},
    business_unit: {},
    primary_locations: [],
    roles: [],
    alternative_roles: [],
    utilities: [],
    min_people: 1,
    is_remote: true,
    remote_access_factors: [],
  })

  const rules = {
    form: {
      name: { required },
      description: {},
      min_people: {},
      division: {},
      business_unit: {},
      primary_locations: {},
      is_remote: {},
      roles: {},
      alternative_roles: {},
      remote_access_factors: {},
      on_site_requires: {},
      utilities: {},
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createNewActivity = async (payload, isSave = false) => {
    try {
      const { data } = await api.createNewActivity(payload)
      if (isSave) {
        globalStore.addSuccessToastMessage(t("activity.messages.createSuccess"))
      } else {
        globalStore.addSuccessToastMessage(t("activity.messages.createdToNextStep"))
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    form,
    v$,
    createNewActivity,
  }
}
