<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("organization.organizations") }}
        </EcHeadline>
        <EcButton
          variant="primary-sm"
          class="mb-3 lg:mb-0"
          id="btn-add-org"
          iconPrefix="PlusCircle"
          @click="handleClickAddOrganization"
        >
          {{ $t("organization.add") }}
        </EcButton>
      </EcFlex>
      <!-- Search box -->
      <EcFlex class="flex-grow justify-end items-center w-full md:w-auto">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.search')"
          class="w-full md:max-w-xs"
          id="search-org"
          @update:modelValue="onFilter"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Organization List -->
    <OrganizationList v-if="!isLoading" :listData="organizationList" />
    <RLoading v-else />

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="filters.page.size"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="filters.page.size"
        :totalItems="filters.page.total"
        @input="setPage($event)"
      />
    </EcFlex>
  </RLayout>
</template>

<script>
import debounce from "lodash/debounce"
import { formatData } from "@/modules/core/composables"
import { useOrganizationList } from "./../../use/organization/useOrganizationList"
import OrganizationList from "../../components/organization/OrganizationList.vue"
import { ref } from "vue"
import RLoading from "@/modules/core/components/common/RLoading.vue"

export default {
  name: "ViewOrganizationList",
  data() {
    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 9,
        total: 0,
      },
    }

    return {
      filters,
      isLoading: false,
    }
  },

  setup() {
    const { searchQuery, getOrganizationList, totalItems, skip, limit, currentPage } = useOrganizationList()

    const organizationList = ref([])

    return {
      searchQuery,
      getOrganizationList,
      organizationList,
      totalItems,
      skip,
      limit,
      currentPage,
    }
  },

  watch: {
    currentPage() {
      this.filters.page.number = this.currentPage
      this.fetchOrganizations()
    },
  },

  mounted() {
    this.fetchOrganizations()
  },

  methods: {
    formatData,
    onFilter: debounce(function () {
      this.currentPage = 1
    }, 400),

    /**
     * Handle search
     */
    handleSearch() {
      this.filters.filter[0] = {
        name: "search",
        type: "contain",
        value: this.searchQuery,
      }

      // Always set current page = 1
      this.filters.page = {}
      this.fetchOrganizations()
    },

    /**
     * Clear search
     */
    handleClearSearch() {
      this.searchQuery = ""
      this.filters.filter = []
      this.filters.page = {}
      this.fetchOrganizations()
    },

    /**
     * Add Org
     */
    handleClickAddOrganization() {
      // Go to New Participant Page
      this.$router.push({
        name: "ViewOrganizationNew",
      })
    },
    handleClickOrganization(item) {
      // Go to Participant Detail Page
      this.$router.push({
        name: "ViewOrganizationDetail",
        params: {
          organizationId: item.id,
        },
      })
    },

    async fetchOrganizations() {
      this.isLoading = true
      const response = await this.getOrganizationList(this.filters)
      this.organizationList = response.data

      // Pagination
      // Pagination
      this.currentPage = response.current_page
      this.filters.page.size = response.per_page
      this.filters.page.total = response.total

      this.isLoading = false
    },
  },
  components: { OrganizationList, RLoading },
}
</script>
