import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 *
 * @param {*} payload
 * @returns
 */
export const createNewResiliencePlan = async (payload) => {
  return fetcher.post(`identity/api/v1/resilience-plans`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const fetchResiliencePlan = async (uid) => {
  return fetcher.get(`identity/api/v1/resilience-plans/${uid}`)
}

/**
 *
 * @param {*} payload
 * @param {*} uid
 * @returns
 */
export const updateResiliencePlan = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/resilience-plans/${uid}`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const deleteResiliencePlan = async (uid) => {
  return fetcher.delete(`identity/api/v1/resilience-plans/${uid}`)
}

/**
 *
 * Get list BCP
 * @returns
 */
export const fetchResiliencePlanList = async (filters) => {
  return fetcher.get(`/identity/api/v1/resilience-plans`, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 * Download activities
 * @returns
 */
export const downloadResiliencePlans = async (filters) => {
  return fetcher.get(`/identity/api/v1/resilience-plans/download/all`, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
    responseType: "blob",
  })
}

/**
 *
 * @param {*} uid
 * @returns
 */

export const exportResiliencePlan = async (uid) => {
  return fetcher.get(`/identity/api/v1/resilience-plans/${uid}/export`, {
    responseType: "blob",
  })
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const fetchResiliencePlanLogs = async (uid) => {
  return fetcher.get(`identity/api/v1/resilience-plans/${uid}/logs`)
}
