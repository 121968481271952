import { useGlobalStore } from "@/stores/global"
import * as api from "@/modules/setting/api/fetcher"
import useVuelidate from "@vuelidate/core"
import { useI18n } from "vue-i18n"
import { ref } from "vue"

export function useProfile() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const me = ref({
    mfa_status: false,
  })

  // Validate
  const rules = {
    me: {},
  }

  const v$ = useVuelidate(rules, { me })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateProfile = async (payload) => {
    try {
      const { data } = await api.updateProfile(payload)
      if (!data || data.error) {
        throw new Error(data.error)
      }
      globalStore.addSuccessToastMessage(t("setting.errors.updateProfile"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateProfilePicture = async (payload) => {
    try {
      const { data } = await api.updateProfilePicture(payload)
      if (!data || data.error) {
        throw new Error(data.error)
      }
      globalStore.addSuccessToastMessage(t("setting.general.messages.updateProfilePictureSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getProfile = async () => {
    try {
      const { data } = await api.fetchProfile()
      if (!data || data.error) {
        throw new Error(data.error)
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return false
    }
  }

  return {
    updateProfile,
    updateProfilePicture,
    getProfile,
    me,
    v$,
  }
}
