<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("activity.title.newActivity") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Step bar -->
    <EcStepBar
      :options="filteredSteps"
      :current="form.step"
      :onStep="onStep"
      :clickable="!isLoading"
      @onClick="handleClickSave"
    />
    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <!-- Title and cancel button -->
      <EcFlex>
        <EcText class="w-11/12 font-bold text-lg mb-4">{{ $t("activity.title.software") }}</EcText>
        <EcButton
          class="mx-auto mr-0 my-auto mt-0"
          variant="tertiary-rounded"
          v-tooltip="{ text: 'Cancel doing activity' }"
          @click="handleOpenCancelModal"
        >
          <EcIcon class="text-base text-cError-500" icon="X" />
        </EcButton>
      </EcFlex>

      <!-- Applications -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.software") }}</EcLabel>
          <!-- tooltip -->
          <EcButton class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- Application row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="form.applications"
              componentName="EcMultiSelect"
              :options="filteredApplications"
              :valueKey="'uid'"
              :validator="v$"
              field="form.applications"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'application/software' })"
              @addNewOptionOnNotFound="handleAddNewAppSelectOption"
            />

            <!-- Loading software -->
            <EcSpinner v-if="isLoadingSoftwares" class="ml-2"></EcSpinner>
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="v$.form.applications.$errors?.length > 0">
            <EcText
              class="text-base text-cError-500 text-left"
              v-for="error in v$.form.applications.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new app slot -->
        </EcBox>
      </EcBox>
      <!-- End add more application -->

      <!-- IT data and storage -->
      <EcBox>
        <!-- Rreliant server  -->
        <EcFlex class="flex-wrap max-w-md mb-8">
          <RFormInput
            v-model="form.is_reliant_server"
            :label="$t('activity.labels.reliantOnServers')"
            componentName="EcToggle"
            showLabel
            :labelTrue="$t('activity.labels.yes')"
            :labelFalse="$t('activity.labels.no')"
            :validator="v$"
            field="form.is_reliant_server"
          />
        </EcFlex>
      </EcBox>

      <!-- Equipments -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.equipments") }}</EcLabel>

          <!-- tooltip -->
          <EcButton class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- Equipment row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="form.equipments"
              componentName="EcMultiSelect"
              :options="filteredEquipments"
              :valueKey="'uid'"
              :validator="v$"
              field="form.equipments"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'equipment' })"
              @addNewOptionOnNotFound="handleAddNewEquipmentSelectOption"
            />

            <!-- Loading software -->
            <EcSpinner v-if="isLoadingEquipments" class="ml-2"></EcSpinner>
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="v$.form.equipments.$errors?.length > 0">
            <EcText
              class="text-base text-cError-500 text-left"
              v-for="error in v$.form.equipments.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new equipment slot -->
        </EcBox>
      </EcBox>
      <!-- End Equipments -->

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button back -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-back" @click="handleClickBack">
          {{ $t("activity.buttons.back") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" id="btn-next" @click="handleClickNext">
          {{ $t("activity.buttons.next") }}
        </EcButton>

        <EcButton variant="primary" class="ml-8" id="btn-save" @click="handleClickSave">
          {{ $t("activity.buttons.save") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->

    <!-- Modals -->
    <teleport to="#layer2">
      <ModalCancelAddActivity :isModalCancelOpen="isModalCancelOpen" @handleCloseCancelModal="handleCloseCancelModal" />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
// import { useApplications } from "@/readybc/composables/use/useApplications"
// import { useEquipments } from "@/readybc/composables/use/useEquipments"
import { useApplications } from "../use/useApplications"
import { useActivityEquipments } from "../use/useActivityEquipments"
import { useEquipmentList } from "@/modules/equipment/use/equipment/useEquipmentList"
import { useActivityApplicationsAndEquipments } from "../use/useActivityApplicationsAndEquipments"
import ModalCancelAddActivity from "../components/ModalCancelAddActivity.vue"
import { useActivityDetail } from "../use/useActivityDetail"
import { useActivityStage } from "@/modules/activity/use/useActivityStage"

export default {
  name: "ViewActivityApplication",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalCancelOpen: false,
      isLoading: false,
      isLoadingSoftwares: false,
      isLoadingEquipments: false,
    }
  },
  setup() {
    // PRE LOAD

    const { getApplicationList } = useApplications()
    const { getActivityEquipmentList } = useActivityEquipments()
    const { getAllEquipments } = useEquipmentList()

    // Functions
    const { form, v$, updateApplicationAnEquipments } = useActivityApplicationsAndEquipments()
    const { getActivity } = useActivityDetail()

    const applications = ref([])
    const equipments = ref([])

    return {
      form,
      v$,
      applications,
      equipments,
      getActivity,
      getApplicationList,
      getActivityEquipmentList,
      getAllEquipments,
      updateApplicationAnEquipments,
    }
  },

  mounted() {
    this.fetchActivity()
    this.fetchApplications()
    this.fetchEquipments()
  },

  computed: {
    /**
     * Filter software
     */
    filteredApplications() {
      const selectedAppUids = this.form.applications.map((app) => {
        return app.uid
      })
      return this.applications.map((app) => {
        app.disabled = selectedAppUids.includes(app.uid)

        return app
      })
    },

    /**
     * Filter equipments
     */
    filteredEquipments() {
      const selectedEquipmentUids = this.form.equipments.map((equ) => {
        return equ.uid
      })

      return this.equipments.map((equipment) => {
        equipment.disabled = selectedEquipmentUids.includes(equipment.uid)

        return equipment
      })
    },

    /**
     * Steps
     */
    filteredSteps() {
      return useActivityStage.newActivityStages()
    },

    onStep() {
      return useActivityStage.APPLICATION
    },
  },
  methods: {
    /**
     * Next to step 3
     */
    async handleClickNext() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.updateApplicationAnEquipments(this.form, this.uid)

      if (response && response.uid) {
        setTimeout(this.redirectToNextStep, 1000)
      }
      this.isLoading = false
    },
    /**
     * Save dependencies
     */
    async handleClickSave(redirect = true) {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.updateApplicationAnEquipments(this.form, this.uid)

      if (response && response.uid && redirect) {
        setTimeout(this.redirectToActivityList, 1000)
      }
      this.isLoading = false
    },

    /**
     * Redirect to activity list
     */
    redirectToActivityList() {
      goto("ViewActivityList")
    },

    /**
     * Redirect to activity dependencies
     */
    redirectToNextStep() {
      goto("ViewActivityDependencies")
    },

    /**
     * Back to Activity list
     */
    handleClickBack() {
      goto("ViewActivityNewBack")
    },

    /**
     * Open cancel modal
     */
    handleOpenCancelModal() {
      this.isModalCancelOpen = true
    },

    /**
     * Open cancel modal
     */
    handleCloseCancelModal() {
      this.isModalCancelOpen = false
    },

    // ======== Pre-load =======//

    /**
     * Fetch Activity
     */
    async fetchActivity() {
      this.isLoading = true

      const response = await this.getActivity(this.uid, ["applications", "equipments"])

      if (response && response.uid) {
        this.transformFormData(response)
      }

      this.isLoading = false
    },

    /**
     * Transform data
     */
    transformFormData(response) {
      this.form.step = response.step
      this.form.is_reliant_server = response.is_reliant_server

      // Applications
      if (response.applications.length > 0) {
        this.form.applications = response.applications
      }

      // Equipments
      if (response.equipments.length > 0) {
        this.form.equipments = response.equipments
      }
    },

    /**
     * Applications
     */
    async fetchApplications() {
      this.isLoadingSoftwares = true
      const response = await this.getApplicationList()

      if (response && response.data) {
        this.applications = response.data
      }

      this.isLoadingSoftwares = false
    },

    /**
     * Equipments
     */
    async fetchEquipments() {
      this.isLoadingEquipments = true
      const response = await this.getAllEquipments()

      if (response) {
        this.equipments = response
      }

      this.isLoadingEquipments = false
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewAppSelectOption(option) {
      this.applications.push(option)
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewEquipmentSelectOption(option) {
      this.equipments.push(option)
    },
  },
  components: { ModalCancelAddActivity },
}
</script>
