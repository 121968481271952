<template>
  <RLayout :title="$t('setting.labels.mail')">
    <!-- Modal form -->

    <EcBox variant="card-1" class="wrapper">
      <!-- Enabled -->
      <EcBox class="w-6/12 mt-10 mb-10">
        <RFormInput
          v-model="emailSetting.is_enabled"
          field="emailSetting.is_enabled"
          :validator="emailSettingValidator$"
          componentName="EcToggle"
          :label="$t('setting.mail.enabled')"
          showLabel
          :labelTrue="$t('setting.mail.yes')"
          :labelFalse="$t('setting.mail.no')"
        />
      </EcBox>

      <!-- Host -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_host"
          field="emailSetting.mail_host"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.host')"
        />
      </EcBox>

      <!-- Port -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_port"
          field="emailSetting.mail_port"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.port')"
        />
      </EcBox>

      <!-- Display name -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_from_name"
          field="emailSetting.mail_from_name"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.displayName')"
        />
      </EcBox>

      <!-- From Address -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_from_address"
          field="emailSetting.mail_from_address"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.fromAddress')"
        />
      </EcBox>

      <!-- Encryption -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_encryption"
          field="emailSetting.mail_encryption"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.mailEncryption')"
        />
      </EcBox>

      <!-- Username -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_username"
          field="emailSetting.mail_username"
          :validator="emailSettingValidator$"
          componentName="EcInputText"
          :label="$t('setting.mail.username')"
        />
      </EcBox>

      <!-- Username -->
      <EcBox class="w-6/12 mb-6">
        <RFormInput
          v-model="emailSetting.mail_password"
          field="emailSetting.mail_password"
          :validator="emailSettingValidator$"
          componentName="EcInputPassword"
          :label="$t('setting.mail.password')"
        />
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isUpdating" class="mt-8">
        <EcButton class="mr-4" variant="tertiary-outline" @click="handleClickCancel()">
          {{ $t("setting.cancel") }}
        </EcButton>

        <!-- Test email -->
        <EcButton class="mr-4" variant="primary" @click="handleTestMailSettings()">
          {{ $t("setting.mail.buttons.test") }}
        </EcButton>

        <EcButton variant="primary" @click="handleUpdateMailSettings()">
          {{ $t("setting.update") }}
        </EcButton>
      </EcFlex>
      <EcSpinner v-else />
    </EcBox>
    <RLoading v-if="isLoading" />
  </RLayout>
</template>
<script>
import RLoading from "@/modules/core/components/common/RLoading.vue"
import { goto } from "@/modules/core/composables"
import { useEmailSetting } from "@/modules/setting/use/email_setting/useEmailSetting"

export default {
  name: "ViewMailSetting",
  data() {
    return {
      isLoading: false,
      isUpdating: false,
    }
  },
  setup() {
    const { emailSetting, emailSettingValidator$, getDetailEmailSetting, updateEmailSetting } = useEmailSetting()
    return {
      emailSetting,
      emailSettingValidator$,
      getDetailEmailSetting,
      updateEmailSetting,
    }
  },
  mounted() {
    this.fetchEmailSetting()
  },
  methods: {
    /**
     * Update
     */
    async handleUpdateMailSettings(isTesting = false) {
      this.emailSettingValidator$.$touch()
      if (this.emailSettingValidator$.emailSetting.$invalid) {
        return
      }
      this.isUpdating = true
      this.emailSetting.test = isTesting
      await this.updateEmailSetting(this.emailSetting)
      // trigger pros isFormSubmitting RUploadFile to auto upload file
      this.isUpdating = false
    },
    /**
     * Test mail settings
     */
    async handleTestMailSettings() {
      this.handleUpdateMailSettings(true)
    },
    /**
     * Cancel update
     */
    handleClickCancel() {
      goto("ViewSettingList")
    },
    /**
     * fetch email setting
     * @returns {Promise<void>}
     */
    async fetchEmailSetting() {
      this.isLoading = true
      const response = await this.getDetailEmailSetting()
      if (response) {
        this.emailSetting = response
      }

      this.isLoading = false
    },
  },
  components: { RLoading },
}
</script>
