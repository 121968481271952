<template>
  <EcPopup :title="title" v-model="isShowModal" size="3xl" :showFooter="false">
    <template v-slot:body>
      <EcFlex class="flex-col items-center w-full justify-center mt-3 p-3">
        <EcTable
          name="tbl-activity-by-denpendable"
          :columns="columns"
          :dataSource="activities"
          :isLoading="isLoading"
          :rowClicked="handleClickActivityRow"
          :rowCustomCss="rowCustomeCss"
          @sorted="handleColumnSort"
        ></EcTable>

        <!-- Pagination -->
        <EcFlex class="flex-col mt-auto my-8 sm:mt-12 sm:flex-row" variant="basic">
          <RPaginationStatus
            :currentPage="apiCriteria.pagination.page"
            :limit="apiCriteria.pagination.size"
            :totalCount="apiCriteria.pagination.total"
            class="mb-4 mr-1 sm:mb-0"
          />
          <RPagination
            v-model="apiCriteria.pagination.page"
            :itemPerPage="apiCriteria.pagination.size"
            :totalItems="apiCriteria.pagination.total"
            @pageSizeChanged="
              (size) => {
                apiCriteria.pagination.size = parseInt(size)
              }
            "
          />
        </EcFlex>
      </EcFlex>
    </template>
  </EcPopup>
</template>

<script>
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"
import { useModalActivityByDependency } from "@/readybc/composables/use/useModalActivityByDenpendency"

// import { useActivityList } from "@/modules/activity/use/useActivityList"

export default {
  name: "ModalActivityByDependency",

  props: {
    selectedDependency: {
      type: Object,
      default: () => {},
    },

    dependable: {
      type: String,
      default: "",
    },
  },
  computed: {
    title() {
      return `Linked activities with ${this.selectedDependency?.name}`
    },
  },
  data() {
    return {
      isShowModal: false,
      columns: [
        {
          title: "Activity Name",
          key: "name",
          sortable: true,
        },
        {
          title: "MTPD",
          key: "mtpd_name",
          sortKey: "mtpd_value",
          sortable: true,
        },
        {
          title: "Created Date",
          key: "created_at",
          sortable: true,
          date: true,
        },
      ],
    }
  },

  setup() {
    const { isLoading, activities, apiCriteria, dependableType, dependableUid, listActivityByDependable } =
      useModalActivityByDependency()

    return {
      isLoading,
      activities,
      apiCriteria,
      dependableType,
      dependableUid,
      listActivityByDependable,
    }
  },
  mounted() {
    this.dependableType = this.dependable
  },

  watch: {
    selectedDependency: {
      handler(dependency) {
        if (dependency && dependency?.activities_count > 0) {
          this.apiCriteria.reset()
          this.isShowModal = true
          this.dependableUid = dependency.uid
          this.listActivityByDependable()
        }
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    /**
     *
     * @param {*} col
     * @param {*} sorted
     */
    async handleColumnSort(col, sorted) {
      const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

      this.apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

      this.apiCriteria.resetPage()
      await this.listActivityByDependable()
    },

    /**
     *
     * @param {*} activity
     */
    handleClickActivityRow(activity) {
      const route = this.$router.resolve({ path: `/activities/${activity.uid}` })
      window.open(route.href)
    },

    /**
     *
     * @param {*} item
     */
    rowCustomeCss(item) {
      return "hover:cursor-pointer"
    },
  },
}
</script>
