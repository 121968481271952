export function useDependencyTypeEnum() {
  const dependencyTypes = {
    ACTIVITY: "Activity",
    EQUIPMENT: "Equipment",
    APPLICATION: "Application",
    SUPPLIER: "Supplier",
  }

  return {
    dependencyTypes,
  }
}
