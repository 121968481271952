export const activityMasterDataTypeEnum = {
  APPLICATION: "application",
  EQUIPMENT: "equipment",
  REMOTE_ACCESS_FACTOR: "remote_access_factor",
  ACTIVITY_ROLE: "activity_role",
  LOCATION: "location",
  ACTIVITY_OWNER: "activity_owner",
  SUPPLIER: "supplier",
  UTILITY: "utility",
}
