<template>
  <div></div>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import { useBrowserTabs } from "@/modules/core/use/useBrowserTabs"

export default {
  name: "RBrowserTabs",
  data() {
    return {
      intervalClearUnusedTab: null,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { createBrowserTab, clearCurrentBrowserTab, clearUnusedTab } = useBrowserTabs()
    return {
      globalStore,
      createBrowserTab,
      clearCurrentBrowserTab,
      clearUnusedTab,
    }
  },
  mounted() {
    this.createBrowserTab()
    this.clearUnusedTab()

    this.intervalClearUnusedTab = setInterval(() => {
      this.clearUnusedTab()
    }, 60 * 1000)

    window.addEventListener("beforeunload", () => {
      this.clearCurrentBrowserTab()
    })
  },
  unmounted() {
    clearInterval(this.intervalClearUnusedTab)
  },
  methods: {},
}
</script>
