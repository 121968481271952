import { useGlobalStore } from "@/stores/global"
export function useBrowserTabs() {
  const localStorageKey = "browserTabs"
  const globalStore = useGlobalStore()

  const createBrowserTab = () => {
    const browserTabs = JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
    browserTabs[globalStore.appUid] = {
      uid: globalStore.appUid,
      isIdle: false,
      lastTimeCheck: new Date().getTime(),
    }
    localStorage.setItem(localStorageKey, JSON.stringify(browserTabs))
  }

  const clearCurrentBrowserTab = () => {
    const browserTabs = JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
    delete browserTabs[globalStore.appUid]
    localStorage.setItem(localStorageKey, JSON.stringify(browserTabs))
  }

  const clearAllBrowserTab = () => {
    localStorage.setItem(localStorageKey, JSON.stringify({}))
  }

  const getBrowserTabData = () => {
    return JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
  }
  const getCurrentBrowserTabData = () => {
    const browserTabs = JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
    return browserTabs[globalStore.appUid]
  }
  const setBrowserTabData = (field, data) => {
    const browserTabs = JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
    browserTabs[globalStore.appUid][field] = data
    localStorage.setItem(localStorageKey, JSON.stringify(browserTabs))
  }

  const setAllBrowserTabData = (field, data) => {
    const browserTabs = JSON.parse(localStorage.getItem(localStorageKey)) ?? {}
    for (const key in browserTabs) {
      browserTabs[key][field] = data
    }
    localStorage.setItem(localStorageKey, JSON.stringify(browserTabs))
  }

  const clearUnusedTab = () => {
    const browserTabs = getBrowserTabData()
    const lifeTime = 3 * 60 * 1000
    for (const key in browserTabs) {
      const lastTimeCheck = browserTabs[key].lastTimeCheck ?? 0
      // Tabs is died
      if (new Date().getTime() - lastTimeCheck > lifeTime) {
        delete browserTabs[key]
      }
    }

    if (!browserTabs[globalStore.appUid]) {
      browserTabs[globalStore.appUid] = {
        uid: globalStore.appUid,
        isIdle: false,
        lastTimeCheck: new Date().getTime(),
      }
    }

    browserTabs[globalStore.appUid].lastTimeCheck = new Date().getTime()
    localStorage.setItem(localStorageKey, JSON.stringify(browserTabs))
  }
  return {
    createBrowserTab,
    clearCurrentBrowserTab,
    clearAllBrowserTab,
    getBrowserTabData,
    getCurrentBrowserTabData,
    setBrowserTabData,
    setAllBrowserTabData,
    clearUnusedTab,
  }
}
