<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-2 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("reportTemplates.title") }}
        </EcHeadline>
      </EcFlex>

      <!-- action button -->
    </EcFlex>

    <EcFlex class="mt-8 grid grild-cols-1 md:grid-cols-4 gap-3">
      <!-- Report Template item -->
      <EcBox v-for="reportTemplate in availableReportTemplates" :key="reportTemplate.type">
        <EcBox
          variant="card-2"
          :data-module-key="reportTemplate.type"
          class="hover:cursor-pointer hover:shadow-3 min-h-24"
          @click="handleClickEditReportTemplate(reportTemplate)"
        >
          <EcFlex>
            <EcIcon icon="Report" class="min-w-10" />
            <EcLabel class="ml-2 text-base hover:cursor-pointer">{{ $t(reportTemplate.report_name) }}</EcLabel>
          </EcFlex>
        </EcBox>
      </EcBox>
      <!-- End item -->
    </EcFlex>
  </RLayout>
</template>
<script>
import { useGlobalStore } from "@/stores/global"
import { useReportTemplate } from "@/modules/report/use/useReportTemplate"
import { goto } from "@/modules/core/composables"
import EcHeadline from "@/components/EcHeadline/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import RLayout from "@/modules/core/components/common/RLayout.vue"
import EcBox from "@/components/EcBox/index.vue"
import EcIcon from "@/components/EcIcon/index.vue"

export default {
  name: "ViewConfigurableReportTemplates",
  components: { EcIcon, EcBox, RLayout, EcFlex, EcHeadline },
  data() {
    return {
      headerData: [
        {
          key: "report_name",
          title: this.$t("reportTemplates.labels.reportType"),
        },
        {
          key: "latest_revision",
          title: this.$t("reportTemplates.labels.latestRevision"),
        },
        {
          key: "is_tenant_configurable",
          title: this.$t("reportTemplates.labels.isAllowTenantCustomize"),
        },
      ],
      currentPage: 1,
      isLoading: false,
      availableReportTemplates: [],
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { getConfigurableReportTemplateTypes } = useReportTemplate()

    return {
      globalStore,
      getConfigurableReportTemplateTypes,
    }
  },
  mounted() {
    this.fetchTemplateTypes()
  },
  methods: {
    async fetchTemplateTypes() {
      this.isLoading = true
      const response = await this.getConfigurableReportTemplateTypes()
      if (response) {
        this.availableReportTemplates = response.data
      }
      this.isLoading = false
    },

    handleClickEditReportTemplate(reportTemplate) {
      goto("ViewReportTemplate", {
        params: {
          type: reportTemplate?.type,
        },
      })
    },
  },
}
</script>
