<template>
  <EcModalSimple :isVisible="isModalAddNewOwnerOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("activity.owner.new") }}
        </EcHeadline>
      </EcBox>

      <EcBox class="mt-4">
        <RFormInput
          v-model.trim="owner.first_name"
          componentName="EcInputText"
          :label="$t('activity.owner.first_name')"
          :validator="validator$"
          field="owner.first_name"
          @input="validator$.owner.first_name.$touch()"
        />
      </EcBox>
      <EcBox class="mt-4">
        <RFormInput
          v-model.trim="owner.last_name"
          componentName="EcInputText"
          :label="$t('activity.owner.last_name')"
          :validator="validator$"
          field="owner.last_name"
          @input="validator$.owner.last_name.$touch()"
        />
      </EcBox>
      <!-- Email -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="owner.email"
          componentName="EcInputText"
          :rows="2"
          :label="$t('activity.owner.email')"
          :validator="validator$"
          field="owner.email"
          @input="validator$.owner.email.$touch()"
        />
      </EcBox>

      <!-- Actions -->
      <EcFlex class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewOwnerModal">
          {{ $t("activity.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateOwner">
          {{ $t("activity.buttons.confirm") }}
        </EcButton>
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useActivityOwner } from "@/modules/activity/use/useActivityOwner"

export default {
  name: "ModalAddNewOwner",

  emits: ["handleCloseAddNewOwnerModal", "handleCallbackAddNewOwner"],
  props: {
    isModalAddNewOwnerOpen: {
      type: Boolean,
      default: false,
    },
    activityOwner: {
      type: Object,
      default: () => {},
    },
  },

  mounted() {},
  setup() {
    const { owner, validator$ } = useActivityOwner()
    return {
      owner,
      validator$,
    }
  },
  methods: {
    /**
     *
     */
    async handleClickCreateOwner() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }
      this.owner.name = this.owner.first_name + " " + this.owner.last_name
      this.handleCallbackAddNewOwner(this.owner)
      this.handleCloseAddNewOwnerModal()
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewOwnerModal() {
      this.$emit("handleCloseAddNewOwnerModal")
    },

    handleCallbackAddNewOwner(owner) {
      this.$emit("handleCallbackAddNewOwner", owner)
    },
  },

  watch: {
    isModalAddNewOwnerOpen(value) {
      if (value) {
        this.owner.email = ""
        this.validator$.owner.$reset()
      }
    },
    activityOwner(activityOwner) {
      this.owner.first_name = activityOwner.name.split(" ").slice(0, -1).join(" ")
      this.owner.last_name = activityOwner.name.split(" ").slice(-1).join(" ")
      this.owner.uid = activityOwner.uid
    },
  },
}
</script>
