export default {
  application: {
    applications: "IT Applications / Systems",
    sla: {
      title: "Service Legal Agreement (SLA)",
      docTitle: "SLA - Documents",
      abbr: "SLA",
    },
    labels: {
      name: "Name",
      linked_activities: "#Linked Activities",
      mtpd: "MTPD",
      shortestMTPD: "Highest MTPD",
      createdAt: "Created At",
    },
    buttons: {
      addApplication: "Add Application",
      edit: "Edit",
      back: "Back",
      exportApplication: "Export Applications",
    },
    tooltips: {
      show_application_detail: "Click to show application detail",
    },
    errors: {
      download: "Error occured, please try again",
    },
  },
}
