<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addMTDP") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="mtdp.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="mtdp.name"
          @input="validator$.mtdp.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="mtdp.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="mtdp.description"
          @input="validator$.mtdp.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- value -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="mtdp.value"
          :label="$t('setting.general.labels.valueInHour')"
          componentName="EcInputNumber"
          :validator="validator$"
          field="mtdp.value"
          @input="validator$.mtdp.value.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateMTDP">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useMTDPNew } from "../use/master/mtdp/useMTDPNew"

export default {
  name: "ModalAddMTDP",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { mtdp, validator$, createMTDP } = useMTDPNew()
    return {
      mtdp,
      validator$,
      createMTDP,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateMTDP() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createMTDP(this.mtdp)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewMTDPOpen() {
      this.mtdp.name = null
      this.mtdp.description = null
      this.mtdp.value = null
      this.validator$.mtdp.$reset()
    },
  },
}
</script>
