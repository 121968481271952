<template>
  <EcBox class="overflow-x-auto lg:overflow-visible p-2">
    <!-- Header -->
    <EcFlex class="items-center">
      <EcHeadline as="h4" variant="h4" class="text-c1-800"> Business units</EcHeadline>
    </EcFlex>

    <!-- Add button and Search box -->
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="w-full">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.searchBU')"
          class="w-full md:max-w-xs text-sm"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Business Unit items -->
    <EcFlex v-if="!this.isLoading" class="lg:flex-wrap grid grid-cols-1">
      <template v-for="item in filteredBusinessUnit" :key="item.uid">
        <BusinessUnitCardItem :value="item" v-model="selectedBusinessUnits" />
      </template>

      <!-- No data found -->
      <EcBox v-show="businessUnits.length <= 0">
        <EcText>{{ $t("core.noDataHere") }}</EcText>
      </EcBox>
    </EcFlex>

    <EcFlex v-else class="items-center">
      <EcSpinner />
    </EcFlex>
  </EcBox>
</template>

<script>
import BusinessUnitCardItem from "./BusinessUnitCardItem"
import { useBusinessUnitList } from "@/modules/organization/use/business_unit/useBusinessUnitList"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import EcBox from "@/components/EcBox/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"

export default {
  name: "BusinessUnitList",
  emits: ["handleBusinessUnitSelected"],
  components: {
    BusinessUnitCardItem,
    EcBox,
    EcFlex,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: "",
      filteredBusinessUnit: [],
      initialLoad: false,
    }
  },

  setup() {
    const businessUnits = ref([])

    const { getBusinessUnits } = useBusinessUnitList()
    const selectedBusinessUnits = ref([])

    return {
      getBusinessUnits,
      businessUnits,
      selectedBusinessUnits,
    }
  },
  props: {
    organization: {
      type: Object,
    },
    currentBusinessUnits: {
      type: Array,
    },
    divisionSelected: {
      type: String,
    },
  },

  computed: {},

  mounted() {
    this.fetchBusinessUnits()
  },
  methods: {
    async fetchBusinessUnits() {
      this.isLoading = true

      const response = await this.getBusinessUnits()

      if (response && response.data) {
        this.businessUnits = response.data
        this.filteredBusinessUnit = this.businessUnits
      }
      this.isLoading = false
    },
    isBusinessUnitSelected(item) {},
    handleClickAddBusinessUnit() {
      goto("ViewBusinessUnitNew")
    },

    /**
     *
     */
    async handleDeletedBuItem() {
      await this.fetchBusinessUnits()
    },

    handleClearSearch() {
      this.searchQuery = ""
      this.filteredBusinessUnit = this.businessUnits
    },

    handleSearch() {
      if (this.searchQuery.length > 0) {
        this.filteredBusinessUnit = this.businessUnits.filter((bu) => {
          return (
            bu.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            bu.division?.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        })
      } else {
        this.filteredBusinessUnit = this.businessUnits
      }
    },
  },
  watch: {
    selectedBusinessUnits(items) {
      this.$emit("handleBusinessUnitSelected", items)
    },
    currentBusinessUnits(item) {
      this.selectedBusinessUnits = this.currentBusinessUnits?.map((item) => {
        return item.uid
      })
    },
    divisionSelected(item) {
      if (item !== undefined) {
        this.businessUnits
          .filter((businessUnit) => {
            return businessUnit.division?.uid === item
          })
          .map((businessUnit) => {
            return businessUnit.uid
          })
          .forEach((businessUnit) => {
            this.selectedBusinessUnits.push(businessUnit)
          })
      }
    },
  },
}
</script>
