<template>
  <EcFlex :class="variantCls.root">
    <!--    &lt;!&ndash; Breadcrum &ndash;&gt;-->
    <!--    <EcFlex class="w-1/2 ml-2">-->
    <!--      <RBreadcrumb :items="breadcrumbItems" />-->
    <!--    </EcFlex>-->
    <EcFlex class="w-1/2 ml-8">
      <RSearchBox :placeholder="$t('core.searchHere')" class="w-full md:max-w-26 hidden" />
    </EcFlex>
    <!-- Notification and beyond -->
    <EcFlex class="w-1/2 justify-end mr-4">
      <!-- Notifications -->
      <EcFlex :class="variantCls.notifications" id="btn-user-notifications" @click="handleClickUserNotification">
        <EcBox class="relative">
          <EcIcon icon="Bell" />
          <EcFlex class="absolute -top-3 h-6 w-6 -right-3 rounded-full p-1 bg-c1-800 items-center justify-center">
            <EcText class="text-cWhite text-sm font-semibold">{{ unreadNotificationNumber }}</EcText>
          </EcFlex>
        </EcBox>
      </EcFlex>

      <!-- Settings -->
      <EcFlex v-permissions:menu-setting :class="variantCls.settings" @click="handleClickSettings" id="btn-system-settings">
        <EcIcon icon="Cog" />
      </EcFlex>
      <hr class="w-px h-auto mr-8 bg-c0-200 border-0" />
      <!-- Avartar -->
      <EcFlex
        :class="variantCls.avatar_wrapper"
        @click="handleClickAvatarIcon"
        v-click-outside="
          () => {
            isAccountBoxOpen = false
          }
        "
      >
        <EcFlex :class="[variantCls.avatar_box, profilePictureUrl ? '' : 'bg-c1-800']">
          <img class="rounded-xl" v-if="profilePictureUrl" :src="profilePictureUrl" />
          <EcText v-else class="text-cWhite font-semibold">{{ userAvatarLetters }}</EcText>
        </EcFlex>
        <EcBox class="ml-4">
          <EcText class="text-base text-cBlack font-bold capitalize">{{ userFullName }}</EcText>
          <EcText class="text-base font-normal text-c0-400 capitalize">{{ userRole }}</EcText>
        </EcBox>
        <EcFlex class="items-center justify-center ml-5 mr-5">
          <EcIcon class="w-2 text-cBlack" icon="ArrowDown"></EcIcon>
        </EcFlex>
      </EcFlex>

      <!-- account tool box -->
      <Transition @leave="handleLeaveMenu">
        <EcBox v-if="isAccountBoxOpen" :class="variantCls.menu_box">
          <!-- Menu item -->
          <EcFlex :class="variantCls.menu_item">
            <EcText class="text-base font-semibold">Version: {{ version }}</EcText>
          </EcFlex>

          <!-- Menu item -->
          <EcFlex :class="variantCls.menu_item" @click="handleClickMyAccount">
            <EcIcon icon="User" width="20" height="20" class="text-cSuccess-600" />
            <EcText class="text-base ml-2">My account</EcText>
          </EcFlex>
        </EcBox>
      </Transition>
    </EcFlex>
  </EcFlex>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import EcFlex from "@/components/EcFlex/index.vue"
import { goto } from "../../composables"
import EcText from "@/components/EcText/index.vue"
import EcBox from "@/components/EcBox/index.vue"
import EcIcon from "@/components/EcIcon/index.vue"
import RBreadcrumb from "./RBreadcrumb.vue"
import { useRoute } from "vue-router"
import RFormInput from "@/modules/core/components/common/RFormInput.vue"
import version from "../../../../../version.json"

export default {
  name: "RTopBar",
  inject: ["getComponentVariants"],
  props: {
    unreadNotification: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isAccountBoxOpen: false,
    }
  },
  /* eslint-disable */
  setup() {
    const globalStore = useGlobalStore()

    const route = useRoute()

    const breadcrumbItems = route?.meta?.breadcrumbs || []

    return {
      globalStore,
      breadcrumbItems,
    }
  },
  beforeMount() {},
  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "RTopBar",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },

    profilePictureUrl() {
      return this.globalStore?.me?.profile_picture_url
    },

    userFullName() {
      return this.globalStore?.me?.name || "N/A"
    },
    userRole() {
      return this.globalStore?.me?.roles[0]?.name || "N/A"
    },
    unreadNotificationNumber() {
      return this.globalStore?.me?.unread_notification_number || 0
    },
    userAvatarLetters() {
      return this.userFullName
        .split(" ")
        .map((i) => i.charAt(0))
        .join("")
        .toUpperCase()
    },
    version() {
      return version
    },
  },
  methods: {
    handleClickAvatarIcon() {
      this.isAccountBoxOpen = !this.isAccountBoxOpen
    },
    handleLeaveMenu() {
      this.isAccountBoxOpen = false
    },

    /**
     * User notfitication
     */
    handleClickUserNotification() {
      goto("ViewUserNotificationList")
    },

    /**
     * View Setting
     */
    handleClickSettings() {
      goto("ViewSettingList")
    },

    /**
     * View Profile
     */
    handleClickMyAccount() {
      goto("ViewProfile")
    },

    /**
     * Logout
     */
    handleClickLogout() {
      this.globalStore.logout()
    },
  },
  components: { RFormInput, EcFlex, EcText, EcBox, EcIcon, RBreadcrumb },
}
</script>
