import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/rtoFetcher"
import { ref } from "vue"

export function useRTOList() {
  const globalStore = useGlobalStore()
  const listData = ref([])
  // Initial data

  /**
   *
   * @returns
   */
  async function getRTOList(params) {
    try {
      const { data } = await api.fetchRTOList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }
  /**
   *
   * @returns
   */
  async function getAllRTOList() {
    try {
      const { data } = await api.fetchAllRTO()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  return {
    getRTOList,
    getAllRTOList,
    listData,
  }
}
