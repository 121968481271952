import { useGlobalStore } from "@/stores/global"
import * as api from "../../api/activityOwnersFetcher"
import { ref } from "vue"
import { required, email } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useActivityOwnerNew() {
  const globalStore = useGlobalStore()
  const activityOwner = ref({})

  const rules = {
    activityOwner: {
      first_name: { required },
      last_name: { required },
      email: { required, email },
    },
  }

  const validator$ = useVuelidate(rules, { activityOwner })

  /**
   * Create activityOwner
   * @param {*} payload
   * @returns
   */
  async function createActivityOwner(payload) {
    try {
      const { data } = await api.createActivityOwner(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createActivityOwner,
    activityOwner,
    validator$,
  }
}
