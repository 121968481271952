import * as api from "@/modules/resilience_plan/api/category/apiResiliencePlanCategory"
import { onMounted, ref } from "vue"

export function useResiliencePlanCategoryListAll() {
  const isLoading = ref(false)
  const categories = ref([])

  onMounted(async () => {
    await getAllCategories()
  })

  /**
   *
   * @returns
   */
  const getAllCategories = async () => {
    try {
      isLoading.value = true
      const { data } = await api.fetchAllResiliencePlanCategories()

      categories.value = data || []

      isLoading.value = false

      return data
    } catch (error) {
      return false
    }
  }

  return {
    isLoading,
    categories,
    getAllCategories,
  }
}
