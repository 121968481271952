<template>
  <div :class="variantCls.root">
    <div :class="[variantCls.selectedLabel]" class="sliderLabel" ref="sliderLabel">
      <span class="tooltiptext">{{ options[valueIdx]?.name }} </span>
    </div>
    <input
      type="range"
      class="slider"
      :class="variantCls.slider"
      list="options"
      :disabled="disabled"
      :value="valueIdx"
      :min="0"
      :max="options.length - 1"
      step="1"
      ref="slider"
      @input="handleRangeChange($event.target.value)"
    />

    <datalist class="optionList" :class="variantCls.label" id="options">
      <option
        v-for="(opt, idx) in options"
        :key="idx"
        :value="idx"
        :label="idx == 0 || idx == options.length - 1 ? opt.name : ''"
      ></option>
    </datalist>

    <div class="text-cError-500 text-base">
      <p v-for="(error, idx) in errors" :key="idx">{{ this.$t(`core.${error}`) }}</p>
    </div>
  </div>
</template>

<script>
// import { watchEffect } from "vue"

import { ref } from "vue"
import get from "lodash.get"

export default {
  name: "EcOptionSlider",
  emits: ["update:modelValue", "blur", "focus"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    modelValue: {
      required: true,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    options: {
      required: true,
      default: () => [],
      type: Array,
    },
    nameKey: {
      type: String,
      default: "name",
    },
    valueKey: {
      type: String,
      default: "value",
    },
    field: {
      type: String,
      default: "",
    },
    validator: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const valueIdx = 0
    // watchEffect(() => {
    //   const foundIndex = props.options?.findIndex((item) => {
    //     return item[props.valueKey] === props.modelValue[props.valueKey]
    //   })
    //   if (foundIndex) {
    //     valueIdx = foundIndex
    //   }
    // })
    return {
      valueIdx,
    }
  },
  updated() {
    this.calculateLabelPosition()
  },
  mounted() {
    this.calculateLabelPosition()
  },
  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcOptionSlider",
          variant: this.variant,
        })?.el ?? {}
      )
    },

    errors() {
      if (this.validator && this.field?.length > 0) {
        const field = get(this.validator, this.field) || {}

        return (
          field.$silentErrors?.map((slientErr) => {
            return slientErr?.$validator
          }) || []
        )
      }

      return []
    },
  },
  data() {
    const labelPosition = ref("0px")
    return {
      labelPosition,
    }
  },
  methods: {
    /**
     *
     * @param {*} value
     */
    handleRangeChange(value) {
      this.valueIdx = value
      this.$emit("update:modelValue", this.options[this.valueIdx])
    },
    /**
     * Calculate Label Position
     */
    calculateLabelPosition() {
      const thumbWidth = 20
      const haftThumbWidth = thumbWidth / 2
      const halfLabelWidth = this.$refs.sliderLabel.clientWidth / 2
      const sliderWidth = this.$refs.slider.clientWidth
      const corectPosition = (this.valueIdx / (this.options?.length - 1)) * sliderWidth
      const thumbOffset = haftThumbWidth - (this.valueIdx / (this.options?.length - 1)) * thumbWidth
      const finalLabelPosition = corectPosition + thumbOffset - halfLabelWidth
      const thumbPercentage = (this.valueIdx / (this.options?.length - 1)) * 100
      this.labelPosition = finalLabelPosition + "px"
      // Slider background color
      this.$refs.slider.style.background =
        "linear-gradient(to right, rgb(var(--c1-800)) 0%, rgb(var(--c1-800)) " +
        thumbPercentage +
        "%, #d5d9da " +
        thumbPercentage +
        "%, #d5d9da 100%)"
    },
  },
  watch: {
    modelValue: {
      handler(data) {
        const foundIndex = this.options?.findIndex((item) => {
          return item[this.valueKey] === data[this.valueKey]
        })
        if (foundIndex) {
          this.valueIdx = foundIndex
        }
        this.calculateLabelPosition()
      },
      deep: true,
    },
  },
}
</script>
<style>
.optionList {
  justify-content: space-between;
}

/* The slider itself */
.slider {
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  background: linear-gradient(to right, #d5d9da 0%, #d5d9da 100%);
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #ffffff; /* Green background */
  box-shadow: 1.5px 1.5px 2.5px rgba(0, 0, 0, 0.5), 0px 0px 1.5px rgba(13, 13, 13, 0.5);
  border-radius: 100%;
  border: 0px solid #000000;
  cursor: pointer; /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 20px; /* Set a specific slider handle width */
  height: 20px; /* Slider handle height */
  background: #3860e2; /* Green background */
  border-radius: 100%;
  cursor: pointer; /* Cursor on hover */
}

.sliderLabel {
  display: inline-block;
  min-width: max-content;
  left: v-bind(labelPosition);
}

.sliderLabel .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #3860e2 transparent transparent transparent;
}
</style>
