import ViewUserList from "../views/ViewUserList.vue"
import ViewUserNew from "../views/ViewUserNew.vue"
import ViewUserDetail from "../views/ViewUserDetail.vue"
import ViewUserAssign from "../views/ViewUserAssign.vue"
import ViewRoles from "../views/ViewRoles.vue"
import ViewRoleDetail from "../views/ViewRoleDetail.vue"
import ViewRoleNew from "../views/ViewRoleNew.vue"
import ViewActivityOwnerList from "@/modules/user/views/activityOwners/ViewActivityOwnerList.vue"
import ViewActivityOwnerDetail from "@/modules/user/views/activityOwners/ViewActivityOwnerDetail.vue"

export default [
  {
    path: "/users/manage-users",
    component: ViewUserList,
    name: "ViewUserList",
    props: true,
    meta: {
      permissions: "user.read",
      title: "List User",
      module: "user",
    },
  },
  {
    path: "/users/manage-users/new",
    component: ViewUserNew,
    name: "ViewUserNew",
    props: true,
    meta: {
      permissions: "user.create",
      title: "New User",
      module: "user",
    },
  },
  {
    path: "/users/manage-users/:userId",
    component: ViewUserDetail,
    name: "ViewUserDetail",
    props: true,
    meta: {
      permissions: "user.update",
      title: "Edit User",
      module: "user",
    },
  },
  {
    path: "/users/manage-users/:userId/assignment",
    component: ViewUserAssign,
    name: "ViewUserAssign",
    props: true,
    meta: {
      title: "Assign User",
      module: "user",
    },
  },
  // Location Settings
  {
    path: "/users/activity-owner",
    component: ViewActivityOwnerList,
    name: "ViewActivityOwnerList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/users/activity-owner/:uid",
    component: ViewActivityOwnerDetail,
    name: "ViewActivityOwnerDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/users/roles",
    component: ViewRoles,
    name: "ViewRoles",
    props: true,
    meta: {
      permissions: "role.read",
      title: "Roles",
      module: "user",
    },
  },
  {
    path: "/users/roles/new",
    component: ViewRoleNew,
    name: "ViewRoleNew",
    props: true,
    meta: {
      permissions: "role.create",
      title: "New Role",
      module: "user",
    },
  },
  {
    path: "/users/roles/:uid",
    component: ViewRoleDetail,
    name: "ViewRoleDetail",
    props: true,
    meta: {
      permissions: "role.update",
      title: "Edit Role",
      module: "user",
    },
  },
]
