<template>
  <!-- Supplier  -->
  <EcBox class="w-full md:w-12/12 mt-6 p-2 rounded-sm border border-c1-100">
    <EcLabel class="font-medium text-base mt-2 mb-2">{{ $t("dashboard.supplier.departmentStatusTitle") }}</EcLabel>

    <SummaryRowDivisionSkeleton :rows="2" :loading="isSupplierStatisticLoading">
      <EcLabel v-if="isNoSupplier" class="text-sm italic">{{ $t("dashboard.supplier.noSupplier") }}</EcLabel>
      <EcFlex v-else class="flex-col md:flex-row">
        <!-- Chart -->
        <EcBox class="w-full md:w-4/12">
          <ChartItem :chartInfo="supplierStatusPieChart" boxVariant="" />
        </EcBox>
        <!-- List -->
        <EcBox class="w-full md:w-8/12 grid items-center">
          <!-- Division and BU progresses -->
          <EcBox v-for="division in divisionSupplierByStatusData" :key="division.uid" class="mb-4">
            <!-- Division -->
            <EcFlex>
              <EcLabel class="text-sm w-3/12 font-medium"> {{ division?.name }} </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="division.supplierByStatus || []" valueKey="cnt" labelKey="name" />
              </EcFlex>
            </EcFlex>

            <!-- BU -->
            <EcBox v-for="bu in division?.businessUnits" :key="bu.uid" class="mt-6">
              <EcFlex class="mt-2">
                <EcLabel class="text-sm w-3/12 pl-4 italic">
                  {{ bu?.name }}
                </EcLabel>
                <EcFlex class="w-9/12">
                  <EcColorProgressBar :data="bu?.supplierByStatus || []" valueKey="cnt" labelKey="name" />
                </EcFlex>
              </EcFlex>
            </EcBox>
          </EcBox>

          <!-- Dependent BU -->
          <EcBox v-for="bu in businessUnitWithouDivisionSupplierByStatusData" :key="bu.uid" class="mt-6">
            <EcFlex class="mt-2">
              <EcLabel class="text-base w-3/12 italic">
                {{ bu?.name }}
              </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="bu?.supplierByStatus || []" valueKey="cnt" labelKey="name" />
              </EcFlex>
            </EcFlex>
          </EcBox>

          <!-- End -->
        </EcBox>
      </EcFlex>
    </SummaryRowDivisionSkeleton>
  </EcBox>
</template>

<script>
import { ref } from "vue"
import SummaryRowDivisionSkeleton from "./SummaryRowDivisionSkeleton.vue"
import ChartItem from "../statistic/ChartItem.vue"
import { colorMapping } from "../../use/useColorMapping"
import useDashboardSupplierStore from "../../stores/useDashboardSupplierStore"
import { storeToRefs } from "pinia"

export default {
  name: "DashboardSummaryDepartmentSupplier",

  data() {
    return {
      suppliers: ref([]),
    }
  },
  setup() {
    const dashboardSupplierStore = useDashboardSupplierStore()
    const { isSupplierStatisticLoading, supplierStatistic } = storeToRefs(dashboardSupplierStore)

    return {
      isSupplierStatisticLoading,
      supplierStatistic,
      dashboardSupplierStore,
    }
  },
  mounted() {
    this.dashboardSupplierStore.getSupplierStatistic()
  },
  computed: {
    isNoSupplier() {
      return (
        this.supplierStatistic?.supplierByStatus?.length <= 0 &&
        this.supplierStatistic?.supplierByDivisions?.length <= 0 &&
        this.supplierStatistic?.supplierByBusinessUnits?.length <= 0
      )
    },

    /**
     *
     */
    supplierStatusPieChart() {
      const labels = []
      const data = []
      const bgColors = []

      this.supplierStatistic?.supplierByStatus?.forEach((item) => {
        // Labels
        labels.push(item?.name)

        // Data
        data.push({
          label: item?.name,
          value: item?.cnt,
        })

        bgColors.push(colorMapping[item?.bg_color ?? "bg-c3-100"])
      })

      return {
        id: "activitiesByStatus",
        title: "Supplier Status",
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: bgColors,
            },
          ],
        },
      }
    },

    /**
     * Division Supplier By Status
     */
    divisionSupplierByStatusData() {
      const supplierStatistic = structuredClone(this.supplierStatistic)
      return supplierStatistic?.supplierByDivisions?.map((item) => {
        // Division statuses
        item.supplierByStatus = this.mappingColor(item?.supplierByStatus)

        // Business Unit status
        item?.businessUnits?.map((bu) => {
          bu.supplierByStatus = this.mappingColor(bu?.supplierByStatus)

          return bu
        })

        return item
      })
    },

    /**
     * BU Unit without Division
     */
    businessUnitWithouDivisionSupplierByStatusData() {
      const supplierStatistic = structuredClone(this.supplierStatistic)
      return supplierStatistic?.supplierByBusinessUnits?.map((bu) => {
        // Division statuses
        bu.supplierByStatus = this.mappingColor(bu?.supplierByStatus)

        return bu
      })
    },
  },
  methods: {
    /**
     *
     * @param {*} listItems
     */
    mappingColor(listItems) {
      return listItems?.map((status) => {
        // status.bgColor = colorMapping[status?.bg_color ?? "bg-c3-100"]
        // status.textColor = colorMapping[status?.text_color ?? "text-cWhite"]

        return {
          ...status,
          bgColor: colorMapping[status?.bg_color ?? "bg-c3-100"],
          textColor: colorMapping[status?.text_color ?? "text-cWhite"],
        }
      })
    },
  },
  components: { SummaryRowDivisionSkeleton, ChartItem },
}
</script>
