import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 *
 * @param {*} dependable
 * @param {*} uid
 * @param {*} params
 * @returns
 */
const fetchActivityByDependable = async function (dependable, uid, params) {
  return fetcher.get(`/identity/api/v1/${dependable}/${uid}/activities`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export { fetchActivityByDependable }
