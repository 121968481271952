<template>
  <EcBox
    :variant="cardVariant"
    class="px-2 py-1 mb-4 mr-3 lg:inline-flex lg:flex-grow lg:w-auto hover:shadow-5 bu-item"
    style="min-width: 12rem"
  >
    <EcFlex class="relative items-center ml-2 p-1 w-7/12 h-auto overflow-hidden">
      <EcBox>
        <EcText class="font-medium text-base text-cBlack">
          {{ businessUnit.name }}
        </EcText>

        <EcText v-if="businessUnit?.division" class="font-medium text-c0-500 text-xs mt-2">
          {{ businessUnit?.division?.name }}
        </EcText>
      </EcBox>
    </EcFlex>
    <EcBox class="mt-4 lg:mt-0 lg:ml-6 w-full">
      <!-- Actions -->
      <EcFlex class="items-center mt-2 justify-end">
        <!-- Edit -->
        <EcBox v-if="businessUnit?.name" v-permissions:business_unit-update class="ml-2">
          <EcButton
            variant="transparent-rounded"
            class="btn-edit-bu"
            @click="handleClickEdit"
            v-tooltip="{ text: 'Edit Business Unit' }"
          >
            <EcIcon icon="Pencil" width="20" height="20" class="text-c1-800" />
          </EcButton>
        </EcBox>

        <!-- Delete -->

        <EcBox v-if="businessUnit?.name" v-permissions:business_unit-delete class="ml-2">
          <EcButton
            variant="transparent-rounded"
            class="btn-delete-bu"
            @click="handleOpenModalDeleteBU"
            v-tooltip="{ text: 'Delete Business Unit' }"
          >
            <EcIcon class="text-cError-500" icon="Trash" width="20" height="20" />
          </EcButton>
        </EcBox>

        <!-- List Activity -->
        <EcBox v-if="businessUnit?.name" v-permissions:activity-read class="ml-2">
          <EcButton
            variant="transparent-rounded"
            class="btn-bu-activity"
            @click="handleClickListActivity"
            v-tooltip="{ text: 'BU Activities' }"
          >
            <EcIcon icon="ClipboardList" width="20" height="20" class="text-c1-800" />
          </EcButton>
        </EcBox>

        <!-- Add Activity -->
        <EcBox v-if="businessUnit?.name" v-permissions:activity-create class="ml-2">
          <EcButton
            variant="transparent-rounded"
            class="btn-create-activity"
            @click="handleClickAddActivity(false)"
            v-tooltip="{ text: 'Add Activity' }"
          >
            <EcIcon class="text-c1-800" icon="Activity" width="20" height="20" />
          </EcButton>
        </EcBox>

        <!-- Bulk Add Activity -->
        <EcBox v-if="businessUnit?.name" v-permissions:activity-bulk_modify class="ml-2">
          <EcButton
            variant="transparent-rounded"
            class="btn-bulk-create-activity"
            @click="handleClickAddActivity(true)"
            v-tooltip="{ text: 'Bulk Add Activities' }"
          >
            <EcIcon class="text-cSuccess-800" icon="DActivityDoc" width="20" height="20" />
          </EcButton>
        </EcBox>

        <!-- End view -->
      </EcFlex>
    </EcBox>
  </EcBox>

  <!-- Modals -->
  <teleport to="#layer2">
    <!-- Modal Delete -->
    <EcModalSimple
      :isVisible="isModalDeleteOpen"
      variant="center-3xl"
      id="modal-delete-bu"
      @overlay-click="handleCloseDeleteModal"
    >
      <EcBox class="text-center">
        <EcFlex class="justify-center">
          <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
        </EcFlex>

        <!-- Messages -->
        <EcBox>
          <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
            {{ $t("organization.confirmToDelete") }}
          </EcHeadline>
          <!-- Org name -->
          <EcText class="text-lg">
            {{ businessUnit?.name }}
          </EcText>
          <EcText class="text-c0-500 mt-4">
            {{ $t("organization.bu.confirmDeleteQuestion") }}
          </EcText>
        </EcBox>

        <!-- Actions -->
        <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
          <EcButton variant="warning" @click="handleClickDeleteBU">
            {{ $t("organization.bu.delete") }}
          </EcButton>
          <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
            {{ $t("organization.bu.cancel") }}
          </EcButton>
        </EcFlex>
        <EcFlex v-else class="items-center justify-center mt-10 h-10">
          <EcSpinner type="dots" />
        </EcFlex>
      </EcBox>
    </EcModalSimple>
  </teleport>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useBusinessUnitDetail } from "../../use/business_unit/useBusinessUnitDetail"

export default {
  name: "BusinessUnitCardItem",
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    organizationUid: {
      type: String,
      default: "",
    },
    organization: {
      type: Object,
      default: () => {},
    },
    businessUnit: {
      type: Object,
      default: () => {},
    },
  },

  emits: ["handleDeletedBuItem"],

  data() {
    return {
      isModalDeleteOpen: false,
      isDeleteLoading: false,
    }
  },

  setup() {
    const { deleteBusinessUnit } = useBusinessUnitDetail()
    return {
      deleteBusinessUnit,
    }
  },
  mounted() {},
  computed: {
    cardVariant() {
      return "card-2"
    },
  },
  methods: {
    statusText(status) {
      return status ? "font-bold text-cSuccess-500" : "font-bold text-cError-500"
    },

    /**
     * Click edit business unit
     */
    handleClickEdit() {
      goto("ViewBusinessUnitDetail", {
        params: {
          organizationUid: this.organizationUid,
          divisionUid: this.businessUnit.division?.uid,
          businessUnitUid: this.businessUnit?.uid,
        },
      })
    },
    handleClickListActivity() {
      goto("ViewActivityList", {
        query: {
          selectedDivision: this.businessUnit.division?.uid,
          selectedBU: this.businessUnit?.uid,
        },
      })
    },

    /**
     * Delete BU
     */
    async handleClickDeleteBU() {
      this.isDeleteLoading = true
      await this.deleteBusinessUnit(this.businessUnit.uid)
      this.isDeleteLoading = false
      this.$emit("handleDeletedBuItem")
      this.handleCloseDeleteModal()
    },

    /**
     * Handle click delete BU
     */
    handleOpenModalDeleteBU() {
      this.isModalDeleteOpen = true
    },

    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /** Handle add new activity from current business unit */
    handleClickAddActivity(bulkMode = false) {
      const query = {
        businessUnit: this.businessUnit.uid,
      }

      if (this.businessUnit.division?.uid?.length > 0) {
        query.division = this.businessUnit.division.uid
      }

      const routeName = bulkMode ? "ViewActivityBulkCreate" : "ViewActivityNew"

      goto(routeName, { query })
    },
  },
}
</script>
