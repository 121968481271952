<template>
  <!-- Title -->
  <EcBox>
    <EcLabel class="text-c3-100">{{ date }}</EcLabel>
    <EcLabel class="text-xl font-semibold">{{ greating }}, {{ userFullName }}.</EcLabel>
  </EcBox>

  <!-- Activity for Division and BU  -->
  <DashboardSummaryDepartmentActivity v-permissions:dashboard-activity_status />

  <!-- BIA for Division and BU  -->
  <DashboardSummaryDepartmentBIA v-permissions:dashboard-bia_status />

  <!-- Supplier for Division and BU  -->
  <DashboardSummaryDepartmentSupplier v-permissions:dashboard-supplier_status />

  <!-- BIA  -->
  <DashboardSummaryBIA v-permissions:dashboard-bia_summary />

  <!-- BCP  -->
  <DashboardSummaryResiliencePlan v-permissions:dashboard-bcp_summary />

  <!-- Activities  -->
  <DashboardSummaryActivity v-permissions:dashboard-activity_summary />
</template>

<script>
import { goto } from "@/modules/core/composables"
import { dateHelper } from "@/readybc/composables/helpers/dateHelper"

import DashboardSummaryBIA from "./DashboardSummaryBIA"
import DashboardSummaryActivity from "./DashboardSummaryActivity"
import { useGlobalStore } from "@/stores/global"
import DashboardSummaryDepartmentActivity from "./DashboardSummaryDepartmentActivity.vue"
import DashboardSummaryDepartmentBIA from "./DashboardSummaryDepartmentBIA.vue"
import DashboardSummaryDepartmentSupplier from "./DashboardSummaryDepartmentSupplier.vue"
import DashboardSummaryResiliencePlan from "./DashboardSummaryResiliencePlan.vue"

export default {
  name: "DashboardSummary",

  components: {
    DashboardSummaryBIA,

    DashboardSummaryActivity,
    DashboardSummaryDepartmentActivity,
    DashboardSummaryDepartmentBIA,
    DashboardSummaryDepartmentSupplier,
    DashboardSummaryResiliencePlan,
  },

  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  computed: {
    date() {
      return dateHelper.dashboardDate()
    },

    /**
     *
     */
    greating() {
      const today = new Date()
      const curentHr = today.getHours()

      if (curentHr < 12) {
        return "Good Morning"
      } else if (curentHr > 12 && curentHr < 18) {
        return "Good Afternoon"
      }
      // else if (curentHr > 18) {
      //   return "Good Evening"
      // }

      return "Welcome"
    },
    userFullName() {
      return this.globalStore?.me?.name
    },
  },
  methods: {
    handleClickSeeAll() {
      goto("ViewActivityList")
    },
  },
}
</script>
