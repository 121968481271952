<template>
  <RLayout>
    <!-- Noti row -->
    <EcBox class="w-11/12 ml-6">
      <!-- Empty Notification -->
      <EcBox v-if="isEmptyNotification" class="align-center">
        <EcFlex class="items-center justify-center">
          <EcIcon icon="Empty" width="20%" height="20%" class="opacity-50" />
        </EcFlex>

        <EcLabel class="text-center mt-2">{{ $t("notification.labels.emptyNotification") }}</EcLabel>
      </EcBox>

      <!-- Loop Notification -->
      <EcFlex v-else v-for="(notification, idx) in eventNotificationLogs" :key="idx" class="p-4 bg-c0-100 hover:bg-c1-50">
        <EcText class="grid items-center w-2/12 text-base">
          {{ formatDateTime(notification.created_at) }}
        </EcText>
        <EcText class="w-7/12 text-base">
          {{ notification.title }}.
          <span @click="handleClickNotificationDetail(notification.id)" class="hover:cursor-pointer text-c1-800">
            {{ $t("notification.labels.details") }}
          </span>
        </EcText>

        <EcFlex class="w-3/12 items-center justify-center text-base">
          <EcBox class="w-1/2 hover:cursor-pointer text-c1-800">
            <EcText @click="markAsRead(notification.id, idx)">{{
              !notification.read_at ? $t("notification.labels.markAsRead") : ""
            }}</EcText>
          </EcBox>
          <EcBox class="w-1/2 hover:cursor-pointer ml-4 text-cError-500">
            <EcText @click="handleClickDeleteNotification(notification.id, idx)"> {{ $t("notification.labels.delete") }}</EcText>
          </EcBox>
        </EcFlex>
      </EcFlex>

      <RLoading v-if="isLoading" />
    </EcBox>
  </RLayout>
</template>
<script>
import RLoading from "@/modules/core/components/common/RLoading.vue"
import { goto } from "@/modules/core/composables"
import { useNotification } from "@/modules/notification/use/useNotification"
import { useGlobalStore } from "@/stores/global"
export default {
  name: "ViewUserNotificationList",
  setup() {
    const globalStore = useGlobalStore()
    const { eventNotificationLogs, isLoading, getNotificationLogs, readNotification, deleteNotification } = useNotification()
    return {
      globalStore,
      eventNotificationLogs,
      isLoading,
      getNotificationLogs,
      readNotification,
      deleteNotification,
    }
  },
  mounted() {
    this.fetchNotifications()
  },
  computed: {
    isEmptyNotification() {
      return this.eventNotificationLogs?.length <= 0
    },
  },
  methods: {
    formatDateTime(string) {
      return this.globalStore.formatDateTime(string)
    },

    /**
     *
     */
    async fetchNotifications() {
      this.isLoading = true
      this.eventNotificationLogs = await this.getNotificationLogs()
      this.isLoading = false
    },

    /**
     *
     * @param {*} uid
     * @param {*} idx
     */
    async markAsRead(uid, idx) {
      const response = await this.readNotification(uid)

      if (response) {
        this.eventNotificationLogs[idx].read_at = response.read_at
        this.globalStore.decreaseNotificationCnt(1)
      }
    },

    /**
     *
     * @param {*} uid
     * @param {*} idx
     */
    async handleClickDeleteNotification(uid, idx) {
      const response = await this.deleteNotification(uid)

      if (response) {
        this.eventNotificationLogs.splice(idx, 1)
      }
    },

    /**
     * Notification Detail
     */
    handleClickNotificationDetail(uid) {
      goto("ViewUserNotificationDetail", {
        params: {
          uid,
        },
      })
    },
  },
  components: { RLoading },
}
</script>
