import fetcher from "@/modules/core/api/fetcher"

const fetchBCPStatuses = async function () {
  return fetcher.get("/identity/api/v1/bcp-statuses")
}

const fetchAllBCPStatuses = async function () {
  return fetcher.get("/identity/api/v1/bcp-statuses/all")
}

export { fetchBCPStatuses, fetchAllBCPStatuses }
