import { useGlobalStore } from "@/stores/global"
import * as api from "../../api/roleFetcher"
import { ref } from "vue"

export function useRoleList() {
  const globalStore = useGlobalStore()
  const roles = ref([])
  // Initial data

  /**
   *
   * @returns
   */
  async function getActivityRoleList(params) {
    try {
      const { data } = await api.fetchActivityRoleList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.list"))
    }
  }
  /**
   *
   * @returns
   */
  async function getAllActivityRoleList() {
    try {
      const { data } = await api.fetchAllActivityRole()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.list"))
    }
  }

  return {
    getActivityRoleList,
    getAllActivityRoleList,
    roles,
  }
}
