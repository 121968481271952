// Factory function returning fetcher suitable for CoverGo GraphQL APIs
import fetcher from "@/modules/core/api/fetcher"

export const fetchChangePassword = async (payload) => {
  return fetcher.post("identity/api/v1/change-password-user", payload)
}
export const fetchProfile = async () => {
  return fetcher.get("identity/api/v1/profile")
}

export const updateProfile = async (payload) => {
  return fetcher.post("identity/api/v1/profile")
}

export const updateProfilePicture = async (payload) => {
  return fetcher.post("identity/api/v1/profile/picture", payload)
}
