import fetcher from "@/modules/core/api/fetcher"

const fetchResiliencePlanStatuses = async function () {
  return fetcher.get("/identity/api/v1/resilience-plan-statuses")
}

const fetchAllResiliencePlanStatuses = async function () {
  return fetcher.get("/identity/api/v1/resilience-plan-statuses/all")
}

export { fetchResiliencePlanStatuses, fetchAllResiliencePlanStatuses }
