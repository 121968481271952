<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("resiliencePlan.title.editResiliencePlan") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Body -->
    <EcBox v-if="!isLoading" variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("resiliencePlan.title.editResiliencePlan") }}</EcText>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="resiliencePlan.name"
            componentName="EcInputText"
            :label="$t('resiliencePlan.labels.name')"
            :validator="v$"
            field="resiliencePlan.name"
            @input="v$.resiliencePlan.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- category -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.category"
            componentName="EcMultiSelect"
            :label="$t('resiliencePlan.labels.category')"
            :valueKey="'uid'"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="categories"
            :validator="v$"
            field="resiliencePlan.category"
            @change="v$.resiliencePlan.category.$touch()"
          />
        </EcBox>
        <EcSpinner class="ml-2 mt-5" v-if="isLoadingCategories" />
      </EcFlex>

      <!-- Status -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.status"
            componentName="EcMultiSelect"
            :label="$t('resiliencePlan.labels.status')"
            :valueKey="'id'"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="statuses"
            :validator="v$"
            field="resiliencePlan.status"
            @change="v$.resiliencePlan.status.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Review Date -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.review_date"
            componentName="EcDatePicker"
            :disabled="!resiliencePlan?.status?.review_date_editable"
            :label="$t('resiliencePlan.labels.reviewDate')"
            :allowSelectNothing="false"
            :validator="v$"
            field="resiliencePlan.review_date"
            @change="v$.resiliencePlan.review_date.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Due Date -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.due_date"
            componentName="EcDatePicker"
            :disabled="!resiliencePlan?.status?.due_date_editable"
            :label="$t('resiliencePlan.labels.dueDate')"
            :allowSelectNothing="false"
            :validator="v$"
            field="resiliencePlan.due_date"
            @change="v$.resiliencePlan.due_date.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Reports -->
      <EcFlex class="flex-wrap items-center mb-8">
        <EcBox class="w-full">
          <!-- Title and upload button -->
          <EcFlex class="items-center">
            <EcLabel>{{ $t("resiliencePlan.plans.reports") }}</EcLabel>
            <EcButton
              variant="transparent"
              class="ml-4 text-c1-800"
              v-tooltip="{ text: 'Upload Resilience Plan' }"
              @click="handleOpenResiliencePlanFileUploadModal"
            >
              <EcIcon icon="CloudUpload" />
            </EcButton>
          </EcFlex>

          <!-- Report row -->
          <RFileSlider class="mt-4" :files="resiliencePlan.reports" @fileDeleted="handleRemoveUploadedFile"></RFileSlider>
        </EcBox>

        <!-- End -->
      </EcFlex>

      <!-- Activity -->
      <EcFlex class="flex-wrap items-center mb-8">
        <RActivityLog :uid="uid" :fetcher="getResiliencePlanLogs" />
      </EcFlex>
      <!-- End Activity -->

      <!-- End body -->
      <!-- Actions -->
      <EcBox class="width-full mt-8 px-4 sm:px-10">
        <!-- Button create -->
        <EcFlex v-if="!isUpdateLoading" class="mt-6">
          <EcButton variant="tertiary-outline" @click="handleClickCancel">
            {{ $t("resiliencePlan.buttons.back") }}
          </EcButton>

          <EcButton v-permissions:resilience_plan-update variant="primary" class="ml-4" @click="handleClickUpdate">
            {{ $t("resiliencePlan.buttons.update") }}
          </EcButton>
        </EcFlex>

        <!-- Loading -->
        <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
      </EcBox>
      <!-- End actions -->
    </EcBox>

    <RLoading v-else />
  </RLayout>

  <teleport to="#layer1">
    <ModalUploadResiliencePlanFile
      :isModalUploadFileOpen="isModalUploadFileOpen"
      @handleCloseUploadModal="handleCloseUploadModal"
      @handleUploadCallback="handleUploadCallback"
      ref="modalUploadRef"
    />
  </teleport>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useResiliencePlanDetail } from "@/modules/resilience_plan/use/plan/useResiliencePlanDetail"
import { useResiliencePlanLog } from "@/modules/resilience_plan/use/plan/useResiliencePlanLog"
import { useResiliencePlanCategoryListAll } from "@/modules/resilience_plan/use/category/useResiliencePlanCategoryListAll"
import { useResiliencePlanStatusListAll } from "@/modules/resilience_plan/use/status/useResiliencePlanStatusListAll"
import { useGlobalStore } from "@/stores/global"
import ModalUploadResiliencePlanFile from "@/modules/resilience_plan/components/ModalUploadResiliencePlanFile.vue"
import { ref } from "vue"

export default {
  name: "ViewResiliencePlanDetail",
  props: {
    uid: {
      type: String,
      required: true,
      default: "",
    },
  },
  data() {
    return {
      reportFilesDeleting: [],
      isModalUploadFileOpen: false,
      isLoading: false,
      isUpdateLoading: false,
    }
  },
  mounted() {
    this.getResiliencePlan(this.uid)
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { resiliencePlan, v$, updateResiliencePlan, deleteResiliencePlan, getResiliencePlan, removeReportFile } =
      useResiliencePlanDetail()
    const { getResiliencePlanLogs } = useResiliencePlanLog()

    const { categories, isLoading: isLoadingCategories } = useResiliencePlanCategoryListAll()
    const { statuses, isLoadingStatus } = useResiliencePlanStatusListAll()

    const modalUploadRef = ref()

    return {
      getResiliencePlan,
      updateResiliencePlan,
      deleteResiliencePlan,
      getResiliencePlanLogs,

      resiliencePlan,
      v$,

      globalStore,
      statuses,
      isLoadingStatus,

      categories,
      isLoadingCategories,

      modalUploadRef,
      removeReportFile,
    }
  },
  computed: {},
  methods: {
    /**
     * Create resource
     *
     */
    async handleClickUpdate() {
      this.v$.$touch()

      if (this.v$.resiliencePlan.$invalid) {
        return
      }
      this.isUpdateLoading = true

      this.resiliencePlan.status_id = this.resiliencePlan?.status?.id
      const res = await this.updateResiliencePlan(this.resiliencePlan, this.uid)
      this.isUpdateLoading = false

      if (res) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },
    /**
     * Success update and back to list
     */
    handleUpdateSuccess() {
      goto("ViewResiliencePlanList")
    },
    /**
     * Cancel add new resource
     */
    handleClickCancel() {
      goto("ViewResiliencePlanList")
    },

    /**
     * Handle upload file
     */
    handleOpenResiliencePlanFileUploadModal() {
      this.isModalUploadFileOpen = true
    },

    /**
     * Close upload modal
     */
    handleCloseUploadModal() {
      this.isModalUploadFileOpen = false
    },

    /**
     *
     * @param {*} url
     */
    handleOpenFileUrl(url) {
      window.open(url, "_blank")
    },

    /**
     *
     * @param {*} url
     */
    async handleRemoveUploadedFile(uid) {
      this.resiliencePlan.reports.forEach((item, idx) => {
        if (item?.uid === uid) {
          this.resiliencePlan.reports.splice(idx, 1)
        }
      })
    },

    // Upload file callback
    handleUploadCallback(files) {
      files.forEach((file) => {
        if (this.isFileAddedToReports(file)) {
          return
        }
        this.resiliencePlan.reports.push({
          uid: file?.response?.uid,
          name: file?.response?.name,
          url: file?.response?.url,
          mime_type: file?.response?.mime_type,
        })
      })
    },

    /**
     * Check to see the file already add to report
     * @param {*} file
     */
    isFileAddedToReports(file) {
      return this.resiliencePlan.reports?.find((item) => {
        return file?.response?.uid === item?.uid
      })
    },

    /** =========== PRE LOAD  */

    transformData(response) {
      this.resiliencePlan = response

      // Status
      this.resiliencePlan.statusObj = this.statuses?.find((status) => {
        return status.value === response?.status
      })

      // Files
    },

    // Format Datetime
    formatDateTime(str) {
      return this.globalStore.formatDateTime(str)
    },
  },
  components: { ModalUploadResiliencePlanFile },
}
</script>
