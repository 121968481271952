import * as api from "../../api/resiliencePlanFetcher"
import { useGlobalStore } from "@/stores/global"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useResiliencePlanDetail() {
  const globalStore = useGlobalStore()

  const isLoading = ref(false)

  const resiliencePlan = ref({
    category: {},
    reports: [],
  })

  const rules = {
    resiliencePlan: {
      name: { required },
      due_date: { required },
      review_date: { required },
      category: { required },
      status: { required },
      reports: {},
    },
  }

  const v$ = useVuelidate(rules, { resiliencePlan })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getResiliencePlan = async (uid) => {
    try {
      isLoading.value = true
      const { data } = await api.fetchResiliencePlan(uid)

      if (data) {
        resiliencePlan.value = data
      }

      isLoading.value = true
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateResiliencePlan = async (payload, uid) => {
    try {
      const { data } = await api.updateResiliencePlan(payload, uid)
      globalStore.addSuccessToastMessage("Updated Resilience Plan")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteResiliencePlan = async (uid) => {
    try {
      const response = await api.deleteResiliencePlan(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    getResiliencePlan,
    updateResiliencePlan,
    deleteResiliencePlan,
    resiliencePlan,
    isLoading,
    v$,
  }
}
