import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/bcpStatusFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useBCPStatusNew() {
  const globalStore = useGlobalStore()
  const status = ref({})

  const rules = {
    status: {
      name: { required },
      description: {},
      text_color: {},
      bg_color: {},
    },
  }

  const validator$ = useVuelidate(rules, { status })

  /**
   *
   * @param {*} payload
   * @returns
   */
  async function createBCPStatus(payload) {
    try {
      const { data } = await api.createBCPStatus(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createBCPStatus,
    status,
    validator$,
  }
}
