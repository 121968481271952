import fetcher from "@/modules/core/api/fetcher"

const fetchActivityPriorities = async function () {
  return fetcher.get("/identity/api/v1/activity-priorities")
}

const fetchAllActivityPriorities = async function () {
  return fetcher.get("/identity/api/v1/activity-priorities/all")
}

const fetchPriority = async function (uid) {
  return fetcher.get(`/identity/api/v1/activity-priorities/${uid}`)
}

const createPriority = async function (payload) {
  return fetcher.post("/identity/api/v1/activity-priorities", payload)
}

const updatePriority = async function (payload, id) {
  return fetcher.put(`/identity/api/v1/activity-priorities/${id}`, payload)
}

const deletePriority = async function (uid) {
  return fetcher.delete(`/identity/api/v1/activity-priorities/${uid}`)
}

export { fetchActivityPriorities, fetchAllActivityPriorities, fetchPriority, createPriority, updatePriority, deletePriority }
