import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchUtilitiesList = async (params) => {
  return fetcher.get("identity/api/v1/utilities", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchUtility = async (uid) => {
  return fetcher.get(`identity/api/v1/utilities/${uid}`)
}

export const fetchAllUtilities = async () => {
  return fetcher.get(`identity/api/v1/utilities/all`)
}

export const updateUtility = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/utilities/${uid}`, payload)
}

export const deleteUtility = async (uid) => {
  return fetcher.delete(`identity/api/v1/utilities/${uid}`)
}

export const createUtility = async (payload) => {
  return fetcher.post("identity/api/v1/utilities", payload)
}
