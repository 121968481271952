<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("activity.title.newActivity") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Step bar -->
    <EcStepBar
      :options="filteredSteps"
      :current="form.step"
      :onStep="onStep"
      :clickable="isEditActivity"
      @onClick="handleClickSave"
    />

    <RLoading v-if="isLoading" />
    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("activity.title.activityDetail") }}</EcText>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="form.name"
            componentName="EcInputText"
            :label="$t('activity.labels.name')"
            :validator="v$"
            field="form.name"
            @input="v$.form.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Desc -->
      <!-- <EcFlex class="flex-wrap max-w-full">
        <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
          <RFormInput
            v-model="form.description"
            componentName="EcInputLongText"
            :rows="2"
            :label="$t('activity.labels.description')"
            :validator="v$"
            field="form.description"
            @input="v$.form.description.$touch()"
          />
        </EcBox>
      </EcFlex> -->

      <!-- Assignee -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="form.assignee"
            :label="$t('activity.assignee.label')"
            componentName="EcMultiSelect"
            :options="filteredActivityOwners"
            :isSingleSelect="true"
            :valueKey="'uid'"
            :validator="v$"
            field="form.assignee"
            :addNewOnNotFound="allowAddNewActivityOwner"
            :closeOnAddNew="true"
            :noDataPlaceholder="
              allowAddNewActivityOwner ? $t('activity.placeholders.noData') : $t('activity.placeholders.noDataPermission')
            "
            @addNewOptionOnNotFound="handleAddNewOwnerSelectOption"
          />
        </EcBox>
        <EcSpinner class="my-auto mb-1" v-if="isLoadingActivityOwners"></EcSpinner>
      </EcFlex>

      <!-- Divisions -->
      <EcBox class="flex-wrap max-w-md items-center mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.division.label") }}</EcLabel>
        </EcFlex>

        <EcBox class="block w-full">
          <EcFlex class="items-center">
            <RFormInput
              v-model="form.division"
              componentName="EcMultiSelect"
              class="sm:w-6/12 sm:pr-6"
              :isSingleSelect="true"
              :options="divisions"
              :valueKey="'uid'"
              :validator="v$"
              field="form.division.uid"
              @update:modelValue="clearBusinessUnit"
            />

            <EcSpinner class="my-auto mb-1" v-if="isLoadingDivisions"></EcSpinner>
          </EcFlex>
        </EcBox>
      </EcBox>

      <!-- Business Units -->
      <Ecbox class="block flex-wrap max-w-md items-center mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.bu.label") }}</EcLabel>
          <!-- tooltip -->
          <EcButton class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>
        <EcBox class="block w-full">
          <EcFlex class="items-center">
            <RFormInput
              v-model="form.business_unit"
              class="sm:w-6/12 sm:pr-6"
              componentName="EcMultiSelect"
              :isSingleSelect="true"
              :options="filteredBusinessUnits"
              :valueKey="'uid'"
              :validator="v$"
              field="form.business_unit.uid"
            />

            <EcSpinner class="my-auto mb-1" v-if="isLoadingBusinessUnits"></EcSpinner>
          </EcFlex>
        </EcBox>
      </Ecbox>

      <!-- Primary Location -->
      <EcBox class="block flex-wrap w-full items-center mb-8 primary-location">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.primary_location") }}</EcLabel>
        </EcFlex>
        <EcBox class="block w-full">
          <EcFlex class="items-center">
            <RFormInput
              v-model="form.primary_locations"
              class="sm:w-6/12 sm:pr-6"
              componentName="EcMultiSelect"
              :isSingleSelect="false"
              :options="locations"
              :valueKey="'uid'"
              :validator="v$"
              field="form.primary_locations"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'location' })"
              @addNewOptionOnNotFound="handleAddNewLocationSelectOption"
            />
            <EcSpinner class="my-auto mb-1" v-if="isLoadingLocations"></EcSpinner>
          </EcFlex>
        </EcBox>
      </EcBox>

      <!-- Roles select -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.roles") }}</EcLabel>
          <!-- tooltip -->
          <EcButton class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- Role row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="form.roles"
              componentName="EcMultiSelect"
              :options="filteredRoles"
              :valueKey="'uid'"
              :nameKey="'label'"
              :validator="v$"
              field="form.roles"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'role' })"
              @addNewOptionOnNotFound="handleAddNewRoleSelectOption"
            />

            <!-- Loading roles -->
            <EcSpinner class="my-auto mb-1 ml-2" v-if="isLoadingRoles"></EcSpinner>

            <!-- End role select -->
          </EcFlex>

          <!-- Add new role slot -->
        </EcBox>
      </EcBox>
      <!-- End Role Select -->

      <!-- Alternative roles select -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.alternative_roles") }}</EcLabel>
        </EcFlex>

        <!-- Alternative Role row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="form.alternative_roles"
              componentName="EcMultiSelect"
              :options="filteredAlternativeRoles"
              :valueKey="'uid'"
              :nameKey="'label'"
              :validator="v$"
              field="form.alternative_roles"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'role' })"
              @addNewOptionOnNotFound="handleAddNewRoleSelectOption"
            />

            <!-- Loading roles -->
            <EcSpinner v-if="isLoadingRoles" class="ml-2"></EcSpinner>

            <!-- End alternative role select -->
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="v$.form.alternative_roles.$errors?.length > 0">
            <EcText
              class="text-base text-cError-500 text-left"
              v-for="error in v$.form.alternative_roles.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new role slot -->
        </EcBox>
      </EcBox>
      <!-- End Alternative Roles Select -->

      <!-- Min people -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="form.min_people"
            componentName="EcInputNumberStepper"
            :label="$t('activity.labels.min_people')"
            :step="1"
            :min="1"
            :max="100"
            :validator="v$"
            field="form.min_people"
            @input="v$.form.min_people.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- WFH  -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="form.is_remote"
            componentName="EcToggle"
            :label="$t('activity.labels.wfh')"
            @click="handleResetRemoteAccessFactor"
            showLabel
            :labelTrue="$t('organization.yes')"
            :labelFalse="$t('organization.no')"
          />
        </EcBox>
      </EcFlex>

      <!-- Remote factors -->
      <EcBox class="w-full mb-8" v-show="isRemote">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.enableRemote") }}</EcLabel>
        </EcFlex>

        <!-- remote access row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              :notAllowedCursor="!isRemote"
              v-model="form.remote_access_factors"
              :disabled="!isRemote"
              componentName="EcMultiSelect"
              :options="filteredRemoteAccessFactors"
              :valueKey="'uid'"
              :validator="v$"
              field="form.remote_access_factors"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'remote access factor' })"
              @addNewOptionOnNotFound="handleAddNewRemoteAccessSelectOption"
            />

            <!-- Loading utilities -->
            <EcSpinner v-if="isLoadingRemoteAccessFactors" class="ml-2"></EcSpinner>

            <!-- End Remote access select -->
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="v$.form.remote_access_factors.$errors?.length > 0">
            <EcText
              class="text-base text-cError-500 text-left"
              v-for="error in v$.form.remote_access_factors.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new remote access slot -->
        </EcBox>
      </EcBox>
      <!-- End add more remote access factors -->

      <!-- Unable to enable remote -->
      <EcFlex class="flex-wrap max-w-full mb-8" v-show="!(isRemote || isOnSiteDisabled)">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="form.on_site_requires"
            :notAllowedCursor="isRemote"
            :disabled="isRemote || isOnSiteDisabled"
            componentName="EcInputText"
            :label="$t('activity.labels.unableEnableRemote')"
            :validator="v$"
            field="form.unable_enable_remote"
            @input="v$.form.on_site_requires.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Utilities -->
      <EcBox class="w-full mb-8">
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium"> {{ $t("activity.labels.utilities") }}</EcLabel>
          <!-- tooltip -->
          <EcButton class="ml-2" variant="transparent" v-tooltip="{ text: $t('activity.tooltips.requireField') }">
            <EcIcon class="text-cError-500" icon="QuestionMark" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- Utility row -->
        <EcBox class="items-center mb-2 w-full">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="form.utilities"
              field="form.utilities"
              componentName="EcMultiSelect"
              :options="filteredUtilities"
              :valueKey="'uid'"
              :validator="v$"
              :addNewOnNotFound="true"
              :noDataPlaceholder="$t('activity.placeholders.noDataResource', { resourceName: 'utility' })"
              @addNewOptionOnNotFound="handleAddNewUtilitySelectOption"
            />

            <!-- Loading utilities -->
            <EcSpinner v-if="isLoadingUtilities" class="ml-2"></EcSpinner>

            <!-- End role select -->
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="v$.form.utilities.$errors?.length > 0">
            <EcText
              class="text-base text-cError-500 text-left"
              v-for="error in v$.form.utilities.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new role slot -->
        </EcBox>
      </EcBox>
      <!-- End Utilities Select -->

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleOpenCancelModal">
          {{ $t("activity.buttons.cancel") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" id="btn-next" @click="handleClickNext">
          {{ $t("activity.buttons.next") }}
        </EcButton>

        <EcButton variant="primary" class="ml-8" id="btn-save" @click="handleClickSave">
          {{ $t("activity.buttons.save") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->

    <teleport to="#layer3">
      <ModalAddNewOwner
        id="modalAddNewOwner"
        :activityOwner="tempActivityOwner"
        :isModalAddNewOwnerOpen="isModalAddNewOwnerOpen"
        @handleCloseAddNewOwnerModal="handleCloseAddNewOwnerModal"
        @handleCallbackAddNewOwner="handleCallbackAddNewOwner"
      />
    </teleport>
    <!-- Modals -->
    <teleport to="#layer2">
      <ModalCancelAddActivity
        :isModalCancelOpen="isModalCancelOpen"
        @handleOpenCancelModal="handleOpenCancelModal"
        @handleCloseCancelModal="handleCloseCancelModal"
      />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useActivityOwnerList } from "@/modules/user/use/activityOwners/useActivityOwnerList"
import { useRoleList } from "@/modules/activity/use/role/useRoleList"
import { useActivityNew } from "../use/useActivityNew"
import { useActivityDetail } from "../use/useActivityDetail"
import { useUtilities } from "../use/useUtilities"
import ModalCancelAddActivity from "../components/ModalCancelAddActivity.vue"
import { useDivisionList } from "../use/useDivisionList"
import { useGlobalStore } from "@/stores/global"
import { useBusinessUnitList } from "../use/useBusinessUnitList"
import { useLocationList } from "@/modules/setting/use/master/locations/useLocationList"
import isEmpty from "lodash.isempty"

import { useActivityStage } from "@/modules/activity/use/useActivityStage"
import EcBox from "@/components/EcBox/index"
import { useRemoteAccessFactors } from "@/modules/activity/use/useRemoteAccessFactors"
import ModalAddNewOwner from "@/modules/activity/components/ModalAddNewOwner.vue"
import { ref } from "vue"
export default {
  name: "ViewActivityNew",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalCancelOpen: false,
      isLoading: false,
      isLoadingActivityOwners: false,
      isLoadingRoles: false,
      isLoadingRemoteAccessFactors: false,
      isLoadingUtilities: false,
      isLoadingDivisions: false,
      isLoadingBusinessUnits: false,
      isLoadingLocations: false,
      roles: [],
      remoteAccessFactors: [],
      utilities: [],
      divisions: [],
      businessUnits: [],
      locations: [],
      divisionUid: null,
      businessUnitUid: null,
      filteredActivityOwners: ref([]),
      isModalAddNewOwnerOpen: ref(false),
      tempActivityOwner: ref({}),
    }
  },

  beforeMount() {
    if (this.uid) {
      this.fetchActivity()
    }
    this.divisionUid = this.$route.query.division ?? null
    this.businessUnitUid = this.$route.query.businessUnit ?? null
  },
  mounted() {
    this.fetchActivityOwners()
    this.fetchRoles()
    this.fetchUtilities()
    this.fetchDivisions()
    this.fetchBusinessUnits()
    this.fetchRemoteAccessFactors()
    this.fetchLocations()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { getActivity, updateActivity } = useActivityDetail()
    const { getDivisionList } = useDivisionList()
    const { getBusinessUnitList } = useBusinessUnitList()

    const { getAllActivityOwners } = useActivityOwnerList()
    const { getAllActivityRoleList } = useRoleList()
    const { getUtilitiesList } = useUtilities()
    const { getRemoteAccessFactorList } = useRemoteAccessFactors()
    const { getAllLocations } = useLocationList()

    // New activity
    const { form, v$, createNewActivity } = useActivityNew()

    return {
      // Pre load
      getActivity,
      updateActivity,
      getAllActivityOwners,
      getAllActivityRoleList,
      getUtilitiesList,
      getDivisionList,
      getBusinessUnitList,
      createNewActivity,
      getRemoteAccessFactorList,
      getAllLocations,
      form,
      v$,
      globalStore,
    }
  },

  computed: {
    /**
     * Determine clickable step bar base on activity uid
     */
    isEditActivity() {
      return this.uid?.length > 0
    },

    /**
     * Can be delivered remotely
     */
    isRemote() {
      return this.form?.is_remote
    },
    /**
     * Check disable
     */
    isOnSiteDisabled() {
      return (
        this.form.remote_access_factors.filter((item) => {
          return item?.uid?.length > 0
        }).length > 0
      )
    },
    /**
     * Filtered roles
     */
    filteredRoles() {
      const selectedRoleUids = this.form.roles.map((r) => {
        return r.uid
      })

      return this.roles.map((role) => {
        role.disabled = selectedRoleUids.includes(role.uid)

        return role
      })
    },

    /**
     * Filtered alternative roles
     */
    filteredAlternativeRoles() {
      const selectedAlternativeRoleUids = this.form.roles.map((r) => {
        return r.uid
      })

      return this.filteredRoles
        .filter((role) => {
          return !role.disabled
        })
        .map((role) => {
          role.disabled = selectedAlternativeRoleUids.includes(role.uid)

          return role
        })
    },
    /**
     * Filter remote access factor
     */
    filteredRemoteAccessFactors() {
      const selectedRAFUids = this.form.remote_access_factors.map((raf) => {
        return raf.uid
      })

      return this.remoteAccessFactors.map((remoteAccessFactor) => {
        remoteAccessFactor.disabled = selectedRAFUids.includes(remoteAccessFactor.uid)

        return remoteAccessFactor
      })
    },

    /**
     * Filter utitlities
     */
    filteredUtilities() {
      const selectedUtilityIds = this.form.utilities?.map((item) => {
        return item.uid
      })
      return this.utilities.map((utility) => {
        utility.disabled = selectedUtilityIds.includes(utility.uid)

        return utility
      })
    },

    /**
     * Filter BU
     */
    filteredBusinessUnits() {
      if (isEmpty(this.form.division?.uid)) {
        return this.businessUnits
      }

      return this.businessUnits.filter((bu) => {
        return bu.division?.uid === this.form.division?.uid
      })
    },

    /**
     *
     */
    filteredSteps() {
      return useActivityStage.newActivityStages(!this.isEditActivity)
    },

    onStep() {
      return useActivityStage.GENERAL
    },

    allowAddNewActivityOwner() {
      return this.$permissions(`user.create`)
    },
  },
  methods: {
    // =========== ROLES ================ //
    /**
     * Add more role
     */
    handleAddMoreRole() {
      this.form.roles.push({ uid: "" })
    },
    /**
     * Remove item in array
     * @param {*} index
     */
    handleRemoveRole(index) {
      this.form.roles.splice(index, 1)
    },
    // =========== ALTERNATIVE ROLES ================ //
    /**
     * Add more alternative role
     */
    handleAddMoreAlternativeRole() {
      this.form.alternative_roles.push({ uid: "" })
    },
    /**
     * Remove item in array
     * @param {*} index
     */
    handleRemoveAlternativeRole(index) {
      this.form.alternative_roles.splice(index, 1)
    },

    /**
     * Create next to create activity
     *
     */
    async handleClickNext() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      const response = this.isEditActivity
        ? await this.updateActivity(this.form, this.uid)
        : await this.createNewActivity(this.form)

      this.isLoading = false

      if (response && response.uid) {
        goto("ViewActivityApplication", {
          params: {
            uid: response.uid,
          },
        })
      }
    },

    /**
     * Create save and back to list activities
     *
     */
    async handleClickSave(redirect = true) {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params

      this.isLoading = true

      const response = uid ? await this.updateActivity(this.form, uid) : await this.createNewActivity(this.form, true)

      this.isLoading = false

      if (response && response.uid && redirect) {
        goto("ViewActivityList")
      }
    },

    /**
     * Open cancel modal
     */
    handleOpenCancelModal() {
      this.isModalCancelOpen = true
    },

    /**
     * Open cancel modal
     */
    handleCloseCancelModal() {
      this.isModalCancelOpen = false
    },
    // =========== PRE-LOAD -------//

    /**
     * Fetch Activity
     */
    async fetchActivity() {
      this.isLoading = true

      const response = await this.getActivity(this.uid, [
        "assignee",
        "division",
        "businessUnit",
        "roles",
        "alternativeRoles",
        "remoteAccessFactors",
        "utilities",
        "primaryLocations",
      ])

      if (response && response.uid) {
        this.transformData(response)
      }
      this.isLoading = false
    },

    /**
     *
     * @param {*} response
     */
    transformData(response) {
      // Activity detail

      this.form.step = response.step
      this.form.name = response.name
      this.form.min_people = response.min_people
      this.form.is_remote = response.is_remote

      if (response?.division) {
        this.form.division = response.division
      }

      if (response?.business_unit) {
        this.form.business_unit = response.business_unit
      }

      if (response?.primary_locations) {
        this.form.primary_locations = response.primary_locations
      }

      // Assignee
      if (response?.assignee && response?.assignee?.length > 0) {
        this.form.assignee = response.assignee[0].assignable
        this.form.assignee.type = response.assignee[0].type
      }

      // Roles
      if (response?.roles?.length > 0) {
        this.form.roles = response.roles
      }

      // Alternative Roles
      if (response?.alternative_roles?.length > 0) {
        this.form.alternative_roles = response.alternative_roles
      }

      // Utilities
      if (response?.utilities?.length > 0) {
        this.form.utilities = response.utilities
      }

      // Remote Access
      if (response?.remote_access_factors?.length > 0) {
        this.form.remote_access_factors = response.remote_access_factors
      }
      // On site
      if (response?.on_site_requires?.length > 0) {
        this.form.on_site_requires = response.on_site_requires
      }
    },

    /**
     * Fetch users
     */
    async fetchActivityOwners() {
      this.isLoadingActivityOwners = true
      const response = await this.getAllActivityOwners()
      if (response) {
        this.filteredActivityOwners = response
      }
      this.isLoadingActivityOwners = false
    },

    /**
     * Fetch roles
     */
    async fetchRoles() {
      this.isLoadingRoles = true
      const response = await this.getAllActivityRoleList()
      if (response) {
        this.roles = response
      }
      this.isLoadingRoles = false
    },
    /**
     * Fetch remote access factors
     */
    async fetchRemoteAccessFactors() {
      this.isLoadingRemoteAccessFactors = true

      const response = await this.getRemoteAccessFactorList()

      if (response && response.data) {
        this.remoteAccessFactors = response.data
      }

      this.isLoadingRemoteAccessFactors = false
    },
    /**
     *
     * @param {*} option
     */
    handleAddNewRemoteAccessSelectOption(option) {
      this.remoteAccessFactors.push(option)
    },

    /**
     * Fetch Utilities
     */
    async fetchUtilities() {
      this.isLoadingUtilities = true
      const response = await this.getUtilitiesList()

      if (response && response.data) {
        this.utilities = response.data
      }
      this.isLoadingUtilities = false
    },

    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const response = await this.getDivisionList()
      if (response && response.data) {
        this.divisions = response.data
        this.populateDivision()
      }
      this.isLoadingDivisions = false
    },

    /**
     * Fetch BU
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const response = await this.getBusinessUnitList()

      this.isLoading = false

      if (response && response.data) {
        this.businessUnits = response.data
        this.populateBusinessUnit()
      }
      this.isLoadingBusinessUnits = false
    },

    /**
     * Fetch locations master data
     */
    async fetchLocations() {
      this.isLoadingLocations = true
      const response = await this.getAllLocations()
      if (response) {
        this.locations = response
      }
      this.isLoadingLocations = false
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewUtilitySelectOption(option) {
      this.utilities.push(option)
    },

    /**
     *
     * @param {*} option
     */
    handleAddNewRoleSelectOption(option) {
      this.roles.push(option)
    },
    // =========== REMOTE ACCESS FACTORS ================ //

    /**
     * Reset Remote Access Factor when change is_remote
     * @param {*} index
     */
    handleResetRemoteAccessFactor() {
      this.form.remote_access_factors = []
      this.form.on_site_requires = ""
    },
    clearBusinessUnit() {
      this.form.business_unit = null
    },

    /**
     * Populate pre-selected Division
     */
    populateDivision() {
      if (this.divisionUid?.length > 0) {
        this.form.division = this.divisions.find((item) => item.uid === this.divisionUid) ?? null
      }
    },

    /**
     * Populate pre-selected Business Unit
     */
    populateBusinessUnit() {
      if (this.businessUnitUid?.length > 0) {
        this.form.business_unit = this.businessUnits.find((item) => item.uid === this.businessUnitUid) ?? null
      }
    },
    handleAddNewOwnerSelectOption(option) {
      this.tempActivityOwner = option
      this.isModalAddNewOwnerOpen = true
      // this.filteredActivityOwners.push(option)
    },
    handleCloseAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = false
    },
    handleCallbackAddNewOwner(owner) {
      this.filteredActivityOwners?.push(owner)
      this.form.assignee = owner
    },

    /**
     * Add New Location
     * @param {*} option
     */
    async handleAddNewLocationSelectOption(option) {
      if (option) {
        this.locations.push(option)
      }
    },
  },
  components: { ModalAddNewOwner, EcBox, ModalCancelAddActivity },
}
</script>
