import * as api from "../../../api/rtoFetcher"
import { useGlobalStore } from "@/stores/global"
import { numeric, required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useRTODetail() {
  const globalStore = useGlobalStore()

  const form = ref({})

  const rules = {
    form: {
      name: { required },
      description: {},
      value: {
        required,
        numeric,
      },
      order: {
        numeric,
      },
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getRTO = async (uid) => {
    try {
      const { data } = await api.fetchRTO(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateRTO = async (payload, uid) => {
    try {
      const { data } = await api.updateRTO(payload, uid)
      globalStore.addSuccessToastMessage("Successfully updated!")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteRTO = async (uid) => {
    try {
      const response = await api.deleteRTO(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  return {
    getRTO,
    updateRTO,
    deleteRTO,
    form,
    v$,
  }
}
