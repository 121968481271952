<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("notification.title.newTemplate") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("notification.title.templateDetail") }}</EcText>
      <!-- Template -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8 w-full">
        <EcBox class="w-full md:w-8/12">
          <!-- label -->
          <EcFlex>
            <EcLabel class="text-base font-medium">
              {{ $t("notification.labels.templates") }}
            </EcLabel>
            <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.templates') }">
              <EcIcon icon="QuestionMark" width="14" class="ml-2" />
            </EcButton>
          </EcFlex>
          <!-- select box -->
          <RFormInput
            v-model="template"
            componentName="EcMultiSelect"
            :allowSelectNothing="true"
            :options="filteredTemplates"
            :isSingleSelect="true"
            :valueKey="'uid'"
            :validator="v$"
          />
        </EcBox>

        <EcSpinner v-if="isLoadingTemplateDetail || isLoadingTemplates" class="ml-2 mt-6" />
      </EcFlex>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="eventNotification.name"
            componentName="EcInputText"
            :label="$t('notification.labels.name')"
            :validator="v$"
            field="eventNotification.name"
            @input="v$.eventNotification.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Title -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="eventNotification.title"
            componentName="EcInputText"
            :label="$t('notification.labels.title')"
            :validator="v$"
            field="eventNotification.title"
            @input="v$.eventNotification.title.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Desc -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="eventNotification.description"
            componentName="EcInputLongText"
            :label="$t('notification.labels.desc')"
            :validator="v$"
            :rows="1"
            field="eventNotification.description"
            @input="v$.eventNotification.description.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Type and Methods -->
      <EcFlex class="flex-wrap max-w-md mb-8 w-full">
        <EcBox class="w-full mb-6">
          <!-- select box -->
          <EcBox class="md:w-6/12">
            <RFormInput
              v-model="eventNotification.typeObj"
              componentName="EcMultiSelect"
              :label="$t('notification.labels.type')"
              :allowSelectNothing="false"
              :isSingleSelect="true"
              :options="types"
              :validator="v$"
              field="eventNotification.typeObj"
              @change="v$.eventNotification.typeObj.$touch()"
            />
          </EcBox>
          <EcLabel class="text-xs italic mt-1" v-if="isNotificationTypeManual">
            The notification will be sent out only once upon clicking the Confirm button.
          </EcLabel>
        </EcBox>

        <!-- Methods -->
        <EcBox class="w-full md:w-10/12">
          <!-- label -->
          <EcFlex>
            <EcLabel class="text-base font-medium">
              {{ $t("notification.labels.methods") }}
            </EcLabel>
            <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.methods') }">
              <EcIcon icon="QuestionMark" width="14" class="ml-2" />
            </EcButton>
          </EcFlex>

          <!-- select box -->
          <RFormInput
            v-model="eventNotification.methodObjs"
            componentName="EcMultiSelect"
            :allowSelectNothing="false"
            :options="methods"
            :validator="v$"
            field="eventNotification.methodObjs"
            @change="v$.eventNotification.methodObjs.$touch()"
          />
        </EcBox>

        <EcSpinner v-if="isLoadingConfig" class="ml-2 mt-8" />
      </EcFlex>

      <!-- Action and Model -->
      <EcFlex v-if="isNotificationTypeAuto" class="flex-wrap max-w-md mb-8 w-full">
        <!-- Models -->
        <EcBox class="w-full md:w-5/12">
          <!-- Rule model select box -->
          <RFormInput
            v-model="eventNotification.ruleModels"
            componentName="EcMultiSelect"
            :label="$t('notification.labels.onModel')"
            :allowSelectNothing="false"
            :options="ruleModels"
            :isSingleSelect="true"
            :validator="v$"
            field="eventNotification.ruleModels"
            @update:modelValue="handleRuleModelChanged"
          />
        </EcBox>

        <EcBox class="w-full md:ml-6 md:w-5/12">
          <!-- select box -->
          <RFormInput
            v-model="eventNotification.ruleActions"
            componentName="EcMultiSelect"
            :label="$t('notification.labels.onAction')"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="ruleActions"
            :validator="v$"
            field="eventNotification.ruleActions"
            @change="v$.eventNotification.ruleActions.$touch()"
          />
        </EcBox>

        <EcSpinner v-if="isLoadingConfig" class="ml-2 mt-8" />
      </EcFlex>

      <!-- Pinned and All user -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8 w-full">
        <EcFlex class="w-full md:w-5/12 items-center">
          <EcFlex>
            <EcLabel class="mr-1 text-base font-medium hover:cursor-pointer">
              {{ $t("notification.labels.pinned") }}
            </EcLabel>

            <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.pinNoti') }">
              <EcIcon icon="QuestionMark" width="14" class="mr-2" />
            </EcButton>
          </EcFlex>

          <!-- select box -->
          <RFormInput
            v-model="eventNotification.pinned"
            componentName="EcCheckbox"
            :validator="v$"
            field="eventNotification.pinned"
            @change="v$.eventNotification.pinned.$touch()"
          />
        </EcFlex>

        <!-- All Users -->
        <EcFlex class="w-full md:ml-6 md:w-5/12 items-center">
          <EcLabel class="mr-1 text-base font-medium w-6/12 hover:cursor-pointer">
            {{ $t("notification.labels.allUser") }}
          </EcLabel>

          <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.allUser') }">
            <EcIcon icon="QuestionMark" width="14" class="mr-2" />
          </EcButton>

          <RFormInput
            v-model="eventNotification.all_user"
            componentName="EcCheckbox"
            :validator="v$"
            field="eventNotification.allUser"
            @change="v$.eventNotification.allUser.$touch()"
          />
        </EcFlex>
      </EcFlex>

      <!-- Receivers -->
      <EcBox>
        <EcFlex class="items-center">
          <EcLabel class="text-base font-medium">
            {{ $t("notification.labels.receivers") }}
          </EcLabel>
          <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.receivers') }">
            <EcIcon icon="QuestionMark" width="14" class="ml-1" />
          </EcButton>
        </EcFlex>

        <!-- Industries -->
        <EcFlex v-if="isLandlord" class="flex-wrap max-w-md items-center ml-4 mt-4 mb-4 w-full border-top border-c1-100">
          <EcBox class="w-full md:w-10/12">
            <!-- select box -->
            <RFormInput
              v-model="eventNotification.receivers.industries"
              componentName="EcMultiSelect"
              :label="$t('notification.labels.industries')"
              :allowSelectNothing="false"
              :options="filteredReceiverIndustries"
              :valueKey="'uid'"
              :validator="v$"
              field="eventNotification.receivers.industries"
              @change="v$.eventNotification.receivers.industries.$touch()"
            />
          </EcBox>

          <EcSpinner v-if="isLoadingIndustries" class="ml-2 mt-6" />
        </EcFlex>

        <!-- Clients -->
        <EcFlex v-if="isLandlord" class="flex-wrap max-w-md items-center ml-4 mt-4 mb-4 w-full border-top border-c1-100">
          <EcBox class="w-full md:w-10/12">
            <!-- select box -->
            <RFormInput
              v-model="eventNotification.receivers.organizations"
              componentName="EcMultiSelect"
              :label="$t('notification.labels.clients')"
              :allowSelectNothing="false"
              :options="filteredReceiverClients"
              :valueKey="'uid'"
              :validator="v$"
              field="eventNotification.receivers.clients"
              @change="v$.eventNotification.receivers.organizations.$touch()"
            />
          </EcBox>

          <EcSpinner v-if="isLoadingClients" class="ml-2 mt-6" />
        </EcFlex>

        <!-- Users -->
        <EcFlex
          v-if="!eventNotification.all_user"
          class="flex-wrap max-w-md items-center ml-4 mb-4 w-full border-top border-c1-100"
        >
          <EcBox class="w-full md:w-10/12">
            <!-- select box -->
            <RFormInput
              v-model="eventNotification.receivers.users"
              componentName="EcMultiSelect"
              :label="$t('notification.labels.users')"
              :allowSelectNothing="false"
              :options="filteredReceiverUsers"
              :valueKey="'uid'"
              :validator="v$"
              field="eventNotification.receivers.users"
              @change="v$.eventNotification.receivers.users.$touch()"
            />
          </EcBox>

          <EcSpinner v-if="isLoadingReceivers" class="ml-2 mt-6" />
        </EcFlex>

        <!-- Roles -->
        <EcFlex
          v-if="!eventNotification.all_user"
          class="flex-wrap max-w-md items-center ml-4 mb-8 w-full border-top border-c1-100"
        >
          <EcBox class="w-full md:w-10/12">
            <!-- select box -->
            <RFormInput
              v-model="eventNotification.receivers.roles"
              componentName="EcMultiSelect"
              :label="$t('notification.labels.roles')"
              :allowSelectNothing="false"
              :options="filteredReceiverRoles"
              :valueKey="'uid'"
              :validator="v$"
              field="eventNotification.receivers.roles"
              @change="v$.eventNotification.receivers.users.$touch()"
            />
          </EcBox>

          <EcSpinner v-if="isLoadingRoles" class="ml-2 mt-6" />
        </EcFlex>
      </EcBox>

      <!-- End receivers -->

      <!-- Dispatch Time -->
      <EcFlex v-if="!isNotificationTypeAuto" class="flex-wrap max-w-md items-center mb-8 w-full">
        <EcBox class="w-full md:w-8/12">
          <!-- label -->
          <EcFlex>
            <EcLabel class="text-base font-medium">
              {{ $t("notification.labels.dispatchTime") }}
            </EcLabel>
            <EcButton variant="transparent" v-tooltip="{ text: $t('notification.notes.dispatchAfter') }">
              <EcIcon icon="QuestionMark" width="14" class="ml-2" />
            </EcButton>
          </EcFlex>
          <!-- Input Date time -->
          <EcFlex>
            <RFormInput v-model="eventNotification.dispatch_after" componentName="EcDateTimePicker" />
          </EcFlex>
        </EcBox>
      </EcFlex>

      <!-- Content -->
      <EcFlex class="flex-wrap w-10/12 items-center mb-8">
        <EcEditor
          v-model="eventNotification.data"
          field="eventNotification.data"
          :validator="v$"
          @change="v$.eventNotification.data.$touch()"
          label="Content"
          :modelValue="eventNotification.data"
          @update:modelValue="onUpdateContent"
        />

        <!-- Errors -->
        <EcLabel v-if="v$.eventNotification.data.$errors?.length > 0" class="mt-1 text-cError-500 text-base">
          {{ $t("notification.errors.contentRequired") }}
        </EcLabel>
      </EcFlex>

      <!-- Replacements - Manual -->
      <EcBox class="grid grid-rows gap-2">
        <EcLabel class="text-base font-medium"> Available Replacements</EcLabel>
        <EcLabel class="text-xs"> (Click on item to copy)</EcLabel>

        <!-- List replacements -->
        <EcBox v-if="!isLoadingConfig">
          <EcBox v-for="(replacements, key) in manualReplacements" :key="key" class="mt-4 ml-4">
            <!-- Object name -->
            <EcLabel class="text-base font-medium">
              {{ ucFirst(key) }}
            </EcLabel>

            <!-- Replacements -->
            <EcFlex class="ml-4 mt-3 grid grid-cols-5 gap-2">
              <EcLabel
                class="text-base italic hover:cursor-pointer"
                v-for="(replacement, idx) in replacements"
                :key="idx"
                @click="handleClickReplacementItem(key, replacement)"
              >
                {{ buildReplacement(key, replacement) }}
              </EcLabel>
            </EcFlex>
            <!-- End replacements -->
          </EcBox>

          <!-- Auto event with extra replacements -->
          <EcBox v-for="(replacements, key) in extraSelectedReplacements" :key="key" class="mt-4 ml-4">
            <!-- Object name -->
            <EcLabel class="text-base font-medium">
              {{ ucFirst(key) }}
            </EcLabel>

            <!-- Replacements -->
            <EcFlex class="ml-4 mt-3 grid grid-cols-5 gap-2">
              <EcLabel
                class="text-base italic hover:cursor-pointer"
                v-for="(replacement, idx) in replacements"
                :key="idx"
                @click="handleClickReplacementItem(key, replacement)"
              >
                {{ buildReplacement(key, replacement) }}
              </EcLabel>
            </EcFlex>
            <!-- End replacements -->
          </EcBox>
        </EcBox>
        <EcSpinner v-else />
      </EcBox>

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" @click="handleClickCancel">
          {{ $t("notification.buttons.cancel") }}
        </EcButton>

        <EcButton variant="primary" class="ml-4" @click="handleClickConfirm">
          {{ $t("notification.buttons.confirm") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10">
        <EcSpinner type="dots" />
      </EcBox>
    </EcBox>
    <!-- End actions -->
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useEventNotificationNew } from "@/modules/notification/use/noti/useEventNotificationNew"
import { useGlobalStore } from "@/stores/global"
import { reactive, ref } from "vue"
import { useEventNotificationConfig } from "../../use/noti/useEventNotificationConfig"
import { useEventNotifiactionReceiver } from "../../use/noti/useEventNotificationReceiver"
import { useManagedTemplateDetail } from "../../use/template/useManagedTemplateDetail"
import { useManagedTemplateList } from "../../use/template/useManagedTemplateList"

export default {
  name: "ViewEventNotificationNew",
  data() {
    return {
      template: reactive(),
      reportFilesDeleting: [],
      isModalUploadBCPFileOpen: false,
      isLoading: false,
      isLoadingConfig: false,
      isLoadingReceivers: false,
      isLoadingRoles: false,
      isLoadingIndustries: true,
      isLoadingClients: false,
      isLoadingTemplates: false,
      isLoadingTemplateDetail: false,
      extraSelectedReplacements: ref({}),
    }
  },
  mounted() {
    this.fetchNotificationConfig()
    this.fetchReceiverUsers()
    this.fetchReceiverRoles()
    this.fetchReceiverIndustries()
    this.fetchReceiverClients()
    this.fetchManagedTemplates()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { eventNotification, v$, createEventNotification } = useEventNotificationNew()
    const { getNotificationConfigs, ucFirst, buildReplacement, copyValue, configs } = useEventNotificationConfig()
    const {
      receiverUsers,
      receiverRoles,
      receiverIndustries,
      receiverClients,
      getReceiverUsers,
      getReceiverRoles,
      getReceiverIndustries,
      getReceiverClients,
    } = useEventNotifiactionReceiver()
    const { templateList, getManagedTemplateListAll } = useManagedTemplateList()
    const { getManagedTemplateDetail } = useManagedTemplateDetail()

    return {
      createEventNotification,
      eventNotification,
      v$,
      globalStore,

      // Notification configs
      configs,
      ucFirst,
      buildReplacement,
      copyValue,
      getNotificationConfigs,

      // Receivers
      receiverUsers,
      receiverRoles,
      receiverIndustries,
      receiverClients,
      getReceiverUsers,
      getReceiverRoles,
      getReceiverIndustries,
      getReceiverClients,

      // Templates
      templateList,
      getManagedTemplateListAll,
      getManagedTemplateDetail,
    }
  },
  computed: {
    /**
     * Notification methods
     */
    methods() {
      return this.configs?.method?.map((item) => {
        return {
          name: this.ucFirst(item),
          value: item,
        }
      })
    },

    /**
     * Notification types
     */
    types() {
      return this.configs?.type?.map((item) => {
        return {
          name: this.ucFirst(item),
          value: item,
        }
      })
    },

    /**
     * Rule Actions
     */
    ruleActions() {
      return this.configs?.ruleAction?.map((item) => {
        return {
          name: this.ucFirst(item),
          value: item,
        }
      })
    },

    /**
     * Rule Models
     */
    ruleModels() {
      return this.configs?.ruleModel || []
    },
    /**
     * Manual Replacements
     */
    manualReplacements() {
      return this.configs?.replacements?.manual
    },
    /**
     * Auto replacements
     */
    autoReplacements() {
      return this.configs?.replacements?.auto
    },

    extraReplacements() {
      return this.configs?.extraReplacements
    },

    /**
     * Receivers
     */
    filteredReceiverUsers() {
      return this.receiverUsers?.map((item) => {
        return {
          uid: item.uid,
          name: `${item.name} ( ${item.email})`,
        }
      })
    },

    /**
     * Receivers
     */
    filteredReceiverRoles() {
      return this.receiverRoles?.map((item) => {
        return {
          uid: item.uid,
          name: item.label,
        }
      })
    },

    /**
     * Receivers
     */
    filteredReceiverIndustries() {
      return this.receiverIndustries?.map((item) => {
        return {
          uid: item.uid,
          name: item.name,
        }
      })
    },

    filteredReceiverClients() {
      return this.receiverClients
        ?.filter((item) => !item?.landlord)
        ?.map((item) => {
          return {
            uid: item.uid,
            name: item.name,
          }
        })
    },

    /**
     * Template
     */
    filteredTemplates() {
      return this.templateList
    },

    /**
     * The type of notification is auto
     */
    isNotificationTypeAuto() {
      return this.eventNotification?.typeObj?.value === "auto"
    },

    /**
     * The type of notification is manual
     */
    isNotificationTypeManual() {
      return this.eventNotification?.typeObj?.value === "manual"
    },

    /**
     *
     * @returns {boolean}
     */
    isLandlord() {
      return this.globalStore.isLandlord
    },
  },
  methods: {
    /**
     * Create resource
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()
      if (this.v$.eventNotification.$invalid) {
        return
      }
      this.isLoading = true

      this.mappingPayload()

      const response = await this.createEventNotification(this.eventNotification)
      this.isLoading = false
      if (response) {
        goto("ViewEventNotificationList")
      }
    },

    /**
     *
     */
    mappingPayload() {
      // Mapping data

      // Type
      if (this.eventNotification.typeObj?.value) {
        this.eventNotification.type = this.eventNotification.typeObj?.value
      }

      // Methods
      this.eventNotification.methods = this.eventNotification.methodObjs.map((item) => {
        return item.value
      })

      // Rule for auto

      if (this.isNotificationTypeAuto) {
        const rule = {
          model: this.eventNotification?.ruleModels?.value,
          action: this.eventNotification?.ruleActions?.value,
        }

        this.eventNotification.rules = [rule]
      }
    },

    /**
     *
     * @param {*} content
     */
    onUpdateContent(content) {
      this.eventNotification.data = content
    },

    /**
     * Fetch configs
     */
    async fetchNotificationConfig() {
      this.isLoadingConfig = true
      const configRes = await this.getNotificationConfigs()
      if (configRes) {
        this.configs = configRes
      }
      this.isLoadingConfig = false
    },

    /**
     * Fetch Receiver User
     */
    async fetchReceiverUsers() {
      this.isLoadingReceivers = true

      const receiverRes = await this.getReceiverUsers()
      if (receiverRes) {
        this.receiverUsers = receiverRes
      }
      this.isLoadingReceivers = false
    },

    /**
     * Fetch Receiver User
     */
    async fetchReceiverRoles() {
      this.isLoadingRoles = true

      const receiverRes = await this.getReceiverRoles()
      if (receiverRes) {
        this.receiverRoles = receiverRes
      }
      this.isLoadingRoles = false
    },

    /**
     * Fetch Receiver Industries
     */
    async fetchReceiverIndustries() {
      this.isLoadingIndustries = true

      const receiverRes = await this.getReceiverIndustries()
      if (receiverRes) {
        this.receiverIndustries = receiverRes
      }
      this.isLoadingIndustries = false
    },

    /**
     * Fetch Receiver Industries
     */
    async fetchReceiverClients() {
      this.isLoadingClients = true

      const receiverRes = await this.getReceiverClients()
      if (receiverRes) {
        this.receiverClients = receiverRes
      }
      this.isLoadingClients = false
    },

    /**
     * Fetch Templates
     */
    async fetchManagedTemplates() {
      this.isLoadingTemplates = true

      const templateRes = await this.getManagedTemplateListAll()
      if (templateRes) {
        this.templateList = templateRes
      }
      this.isLoadingTemplates = false
    },

    /**
     * Fetch Template Detail
     */
    async fetchManagedTemplateDetail(uid) {
      this.isLoadingTemplateDetail = true

      const templateDetailRes = await this.getManagedTemplateDetail(uid)
      if (templateDetailRes) {
        // Mapping data
        this.eventNotification.name = templateDetailRes.name
        this.eventNotification.title = templateDetailRes.title
        this.eventNotification.data = templateDetailRes.data
        this.eventNotification.description = templateDetailRes.description
      }
      this.isLoadingTemplateDetail = false
    },

    /**
     * Cancel add new resource
     */
    handleClickCancel() {
      goto("ViewEventNotificationList")
    },

    /**
     * Click all user
     */
    handleClickAllUser() {
      if (this.eventNotification.all_user) {
        this.eventNotification.receivers = []
      }
    },

    /**
     *
     * @param {*} key
     * @param {*} replacement
     */
    handleClickReplacementItem(key, replacement) {
      const replacementStr = this.buildReplacement(key, replacement)
      this.copyValue(replacementStr)
    },

    /**
     * Handle rule model changed
     */
    handleRuleModelChanged(selectedModel) {
      this.v$.eventNotification.ruleModels.$touch()

      if (this.extraReplacements && Object.hasOwn(this.extraReplacements, selectedModel.value)) {
        this.extraSelectedReplacements = ref({})
        this.extraSelectedReplacements[selectedModel.value] = this.extraReplacements[selectedModel.value]
      } else {
        this.extraSelectedReplacements = ref({})
      }
    },
  },

  watch: {
    template(data) {
      if (data?.uid) {
        this.fetchManagedTemplateDetail(data.uid)
      }
    },
  },
}
</script>
