<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <!-- Title -->
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("notification.logs.titleLogDetail") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Content -->
    <EcBox v-if="!isLoading" class="mt-8">
      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.name") }}</EcLabel>
        <EcText class="ml-2 text-base">{{ notification?.title }}</EcText>
      </EcFlex>

      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.type") }}</EcLabel>
        <EcText class="ml-2 text-base">{{ notification?.type }}</EcText>
      </EcFlex>

      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.sendTo") }}</EcLabel>
        <EcBox class="ml-2">
          <EcText class="text-base">{{ notification?.notifiable?.name }}</EcText>
          <EcText class="mt-1 text-base">{{ notification?.notifiable?.email }}</EcText>
        </EcBox>
      </EcFlex>

      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.content") }}</EcLabel>
        <EcEditor class="ml-2" :modelValue="notification?.data" :disabled="true" />
      </EcFlex>

      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.sendAt") }}</EcLabel>
        <EcText class="ml-2 text-base">{{ formatDateTime(notification?.created_at) }}</EcText>
      </EcFlex>

      <!-- Row -->
      <EcFlex class="w-10/12 border-b border-c1-200 p-3 hover:bg-c0-100 hover:cursor-pointer">
        <EcLabel class="w-2/12 font-bold text-base">{{ $t("notification.labels.readAt") }}</EcLabel>
        <EcText class="ml-2 text-base">{{ formatDateTime(notification?.read_at) }}</EcText>
      </EcFlex>
    </EcBox>

    <RLoading v-else />

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <EcButton variant="primary" @click="handleClickBackToNotificationLogs">
        {{ $t("notification.buttons.back") }}
      </EcButton>
    </EcBox>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import { reactive } from "vue"
import { useNotification } from "../../use/useNotification"

export default {
  name: "ViewNotificationLogDetail",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isLoading: false,
      notification: reactive(),
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { getNotificationLogDetail } = useNotification()
    return {
      globalStore,
      getNotificationLogDetail,
    }
  },
  computed: {},
  mounted() {
    this.fetchNotificationLogDetail()
  },

  methods: {
    /***
     * Fetch Event notifications
     */
    async fetchNotificationLogDetail() {
      this.isLoading = true
      this.notification = await this.getNotificationLogDetail(this.uid)

      this.isLoading = false
    },

    /**
     *
     */
    handleClickBackToNotificationLogs() {
      goto("ViewNotificationLog")
    },
    /**
     *
     * @param {*} string
     */
    formatDateTime(string) {
      return this.globalStore.formatDateTime(string)
    },
  },
}
</script>
