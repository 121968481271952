import { useGlobalStore } from "@/stores/global"
import * as api from "../api/apiBusinessUnit"
import { ref } from "vue"

export function useBusinessUnitList() {
  const globalStore = useGlobalStore()
  const isLoadingBusinessUnits = ref(false)
  const businessUnits = ref([])

  /**
   *
   * @returns
   */
  async function getAllBusinessUnitList() {
    try {
      const data = await api.fetchAllBUList()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.listBU"))
    }
  }

  return {
    getAllBusinessUnitList,
    businessUnits,
    isLoadingBusinessUnits,
  }
}
