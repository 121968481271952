// Factory function returning fetcher suitable for CoverGo GraphQL APIs
import fetcher from "@/modules/core/api/fetcher"

export const fetchGeneralSetting = async () => {
  return fetcher.get("identity/api/v1/configs-settings")
}

export const fetchTz = async () => {
  return fetcher.get("identity/api/v1/tz")
}

export const updateGeneralSetting = async (payload) => {
  return fetcher.post("identity/api/v1/configs-settings", payload)
}
