<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h4" variant="h4" class="text-2xl">
          {{ $t("setting.general.buttons.addActivityOwner") }}
        </EcHeadline>
      </EcBox>

      <!-- first name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="activityOwner.first_name"
          :label="$t('setting.general.labels.first_name')"
          componentName="EcInputText"
          :validator="validator$"
          field="activityOwner.first_name"
          @input="validator$.activityOwner.first_name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- last name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="activityOwner.last_name"
          :label="$t('setting.general.labels.last_name')"
          componentName="EcInputText"
          :validator="validator$"
          field="activityOwner.last_name"
          @input="validator$.activityOwner.last_name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- activityOwner -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="activityOwner.email"
          :label="$t('setting.general.labels.email')"
          componentName="EcInputText"
          :validator="validator$"
          field="activityOwner.email"
          @input="validator$.activityOwner.email.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateActivityOwner">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useActivityOwnerNew } from "@/modules/user/use/activityOwners/useActivityOwnerNew"

export default {
  name: "ModalAddActivityOwner",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { activityOwner, validator$, createActivityOwner } = useActivityOwnerNew()
    return {
      activityOwner,
      validator$,
      createActivityOwner,
    }
  },
  methods: {
    /**
     * Cancel add new activityOwner
     */
    async handleClickCreateActivityOwner() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createActivityOwner(this.activityOwner)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
        this.handleClearModalData()
      }
      this.isLoading = false
    },

    /**
     * Clean modal add activityOwner data
     */
    handleClearModalData() {
      this.activityOwner.first_name = null
      this.activityOwner.last_name = null
      this.activityOwner.email = null
      this.validator$.activityOwner.$reset()
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },
}
</script>
