<template>
  <EcFlex class="w-full h-screen items-center justify-center bg-c4-200">
    <EcBox class="text-cWhite">
      <EcText class="text-6xl text-cWhite font-bold"> 401 </EcText>
      <EcText class="text-xl text-cWhite">Sorry, you are not authorized to access this page</EcText>
      <EcText class="text-xl text-cWhite"
        >Please contact <a class="font-bold font-2xl" href="mailto:info@readybc.com.au">info@readybc.com.au</a> if you think this
        is in error</EcText
      >
      <EcText v-if="isInvalidSession" class="text-cWhite mt-6"> Will be redirect in {{ countdown }} s</EcText>
    </EcBox>
  </EcFlex>
</template>

<script>
import { isMtOrganizations } from "@/readybc/composables/helpers/helpers"
import { useGlobalStore } from "@/stores/global"
import isEmpty from "lodash.isempty"

export default {
  name: "ViewUnauthorized",

  data() {
    return {
      countdown: 5,
    }
  },
  setup() {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  computed: {
    isInvalidSession() {
      return isEmpty(this.globalStore?.me?.roles) && !isMtOrganizations()
    },
  },
  mounted() {
    this.checkRoles()
  },
  methods: {
    checkRoles() {
      if (this.isInvalidSession) {
        setTimeout(this.globalStore.logout, this.countdown * 1000)
        setInterval(() => {
          if (this.countdown > 0) {
            this.countdown = this.countdown - 1
          }
        }, 1000)
      }
    },
  },
}
</script>
