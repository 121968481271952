<template>
  <EcBox class="overflow-x-auto lg:overflow-visible mt-8 lg:mt-8 p-2">
    <!-- Header -->
    <EcFlex class="items-center">
      <EcHeadline as="h4" variant="h4" class="text-c1-800"> Divisions </EcHeadline>
    </EcFlex>

    <!-- Add button and Search box -->
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="justify-start w-1/2">
        <!-- Add button -->
        <EcButton
          v-permissions:division-create
          variant="primary-sm"
          class="mb-3 lg:mb-0"
          id="btn-add-division"
          iconPrefix="PlusCircle"
          @click="handleClickAddDivision"
        >
          {{ $t("organization.add") }}
        </EcButton>
      </EcFlex>

      <!-- Search box -->
      <EcFlex class="justify-end w-1/2">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.searchDivision')"
          class="w-full md:max-w-xs text-sm"
          id="search-division"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Divison list -->
    <EcFlex v-if="!isLoading" class="lg:flex-wrap grid grid-cols-1 md:grid-cols-2 gap-2">
      <DivisionListCardItem
        v-for="item in divisions"
        :division="item"
        :key="item.uid"
        :isActive="selectedDivision?.uid === item.uid"
        @handleDivisionCardChange="handleDivisionCardChange"
      />

      <!-- No data found -->
      <EcBox v-show="divisions.length === 0">
        <EcText>{{ $t("core.noDataHere") }}</EcText>
      </EcBox>
    </EcFlex>

    <EcFlex v-else class="mt-4">
      <EcSpinner />
    </EcFlex>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="filters.page.size"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="filters.page.size"
        :totalItems="filters.page.total"
        @input="setPage($event)"
      />
    </EcFlex>
  </EcBox>
</template>

<script>
import DivisionListCardItem from "./DivisionListCardItem"
import { useDivisionList } from "../../use/division/useDivisionList"
import EcFlex from "@/components/EcFlex/index.vue"
import EcSpinner from "@/components/EcSpinner/index.vue"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import { APIQueryBuilder } from "@/readybc/composables/helpers/apiQuery/apiQuery"
import { TypeCompareEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export default {
  name: "DivisionList",
  components: {
    DivisionListCardItem,
    EcFlex,
    EcSpinner,
  },
  data() {
    // Filters
    const filters = {
      filter: [],
      page: {
        number: 1,
        total: 0,
      },
    }

    return {
      isLoading: false,
      searchQuery: "",
      onFilter: "",
      apiQueryBuilder: new APIQueryBuilder(),
      filters,
    }
  },

  setup() {
    const divisions = ref([])
    const { currentPage, getDivisions } = useDivisionList()

    return {
      currentPage,
      getDivisions,
      divisions,
    }
  },

  emits: ["handleDivisionCardChangeOnManagement"],

  props: {
    selectedDivision: {
      type: Object,
    },
  },

  computed: {},

  mounted() {
    this.fetchDivisions()
  },

  methods: {
    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoading = true

      const response = await this.getDivisions(this.apiQueryBuilder.toQuery())

      if (response && response.data) {
        this.divisions = response.data
      }

      // Pagination
      this.currentPage = response.current_page
      this.filters.page.size = response.per_page
      this.filters.page.total = response.total

      this.isLoading = false
    },

    /**
     * Click Add division
     */
    handleClickAddDivision() {
      goto("ViewDivisionNew")
    },

    handleDivisionCardChange(division) {
      this.$emit("handleDivisionCardChangeOnManagement", division)
    },

    /**
     * clear search input
     */
    handleClearSearch() {
      this.searchQuery = ""
      this.apiQueryBuilder.clearFilter("name")
      this.fetchDivisions()
    },

    /**
     *
     * @returns {Promise<void>}
     */
    async handleSearch() {
      if (this.searchQuery.length > 0) {
        this.apiQueryBuilder.setFilter("name", this.searchQuery, TypeCompareEnum.LIKE)
      } else {
        this.apiQueryBuilder.clearFilter("name")
      }
      await this.fetchDivisions()
    },
  },

  watch: {
    selectedDivision(division) {
      this.$emit("handleDivisionCardChangeOnManagement", division)
    },

    currentPage() {
      this.apiQueryBuilder.setPage(this.currentPage)
      this.fetchDivisions()
    },
  },
}
</script>
