<template>
  <EcBox class="main-body" :class="variantCls.root">
    <RSidebar id="sidebar" :unreadNotification="unreadNotificationCount" />
    <RSidebarMobile :unreadNotification="unreadNotificationCount" />
    <RTopBar />
    <RNotification />
    <EcBox :class="containerCls">
      <RBreadcrumb v-if="breadcrumbItems.length" :items="breadcrumbItems" />
      <RQuoteHeadline v-if="title" :class="variantCls.headline">
        {{ title }}
      </RQuoteHeadline>
      <slot />
    </EcBox>
  </EcBox>
</template>

<script>
import { apiNotifications } from "@/readybc/composables/api/apiNotifications"
import RSidebar from "./RSidebar"
import RSidebarMobile from "./RSidebarMobile"
import RBreadcrumb from "./RBreadcrumb"
import RQuoteHeadline from "./RQuoteHeadline"
import { useGlobalStore } from "@/stores/global"
import EcBox from "@/components/EcBox/index.vue"

export default {
  name: "RLayoutDefault",
  inject: ["getComponentVariants"],
  components: {
    RSidebar,
    RSidebarMobile,
    RBreadcrumb,
    RQuoteHeadline,
    EcBox,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    variant: {
      type: String,
      default: "",
    },
    breadcrumbItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      unreadNotificationCount: 0,
      intervalNotificationFetch: null,
    }
  },

  setup(props) {
    const globalStore = useGlobalStore()

    return {
      globalStore,
    }
  },
  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "RLayoutDefault",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },

    containerCls() {
      return this.variant?.length > 0 ? this.variantCls[this.variant] : this.variantCls.container
    },
  },
  created() {
    // this.fetchNotifications()
  },
  mounted() {
    // this.intervalNotificationFetch = setInterval(() => this.fetchNotifications(), 30000)
  },
  unmounted() {
    clearInterval(this.intervalNotificationFetch)
  },
  methods: {
    async fetchNotifications(view) {
      const fragment = ""

      const variables = {}

      try {
        const { data } = await apiNotifications({
          variables,
          fragment,
        })

        this.unreadNotificationCount = data.totalCount || 0
      } catch (error) {
        console.log(error)
        // this.globalStore.addToastMessage({
        //   type: "error",
        //   content: {
        //     type: "message",
        //     text: error?.message,
        //   },
        // })
      }
    },
  },
}
</script>
<style>
@media (min-width: 1024px) {
  .sidebar-collapse .main-body {
    padding-left: 100px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
  }
  .sidebar-collapse #sidebar {
    width: 100px;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
  }
  .sidebar-collapse #sidebar-button-toggled {
    display: none;
  }
  .sidebar-collapse #sidebar-logo {
    margin: auto;
    padding-left: 0;
  }
  .sidebar-collapse #sidebar-logo-container {
    justify-content: center;
  }
  .sidebar-collapse #sidebar-footer {
    display: none;
  }

  #sidebar-button-toggled {
    display: flex;
  }

  #sidebar-logo {
    padding-left: 2.5rem;
  }

  #sidebar-logo-container {
    justify-content: space-between;
  }
  #sidebar-footer {
    display: block;
  }

  #sidebar {
    width: 18rem;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
  }
  .main-body {
    padding-left: 18rem;
    transition: all 0.25s ease;
    -webkit-transition: all 0.25s ease;
  }
}
</style>
