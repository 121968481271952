<template>
  <EcSpinner v-if="isLoading" />
  <RTabs
    v-else
    class="mt-4 md:mt-6"
    :options="subMenuOptions"
    :isTabCnt="true"
    :activeTab="activeTab"
    @change="onSubmenuChange($event)"
  />
</template>

<script>
import { useRoute, useRouter } from "vue-router"
// import { useI18n } from "vue-i18n"
import { useResiliencePlanCategoryListAll } from "@/modules/resilience_plan/use/category/useResiliencePlanCategoryListAll"
export default {
  name: "ResiliencePlanSubMenu",
  emits: ["menuChanged"],
  setup() {
    // const { t } = useI18n()
    const route = useRoute()
    const router = useRouter()

    const { categories, isLoading } = useResiliencePlanCategoryListAll()

    return {
      route,
      router,
      categories,
      isLoading,
    }
  },

  computed: {
    subMenuOptions() {
      const menuItems = []

      this.categories?.forEach((category) => {
        menuItems.push({
          uid: category?.uid,
          name: category?.name,
          key: category?.key,
          label: category?.name || "N/A",
          cnt: category?.plan_cnt || 0,
          routeName: "ViewResiliencePlanList",
        })
      })

      return menuItems
    },

    activeTab() {
      return this.subMenuOptions?.length > 0 ? this.subMenuOptions[0]?.name : this.route.name
    },

    activeMenuItem() {
      return this.subMenuOptions?.length > 0 ? this.subMenuOptions[0] : null
    },
  },

  methods: {
    onSubmenuChange(payload) {
      this.$emit("menuChanged", payload)
      this.router.push({ name: payload?.routeName })
    },
  },

  watch: {
    activeMenuItem(payload) {
      this.$emit("menuChanged", payload)
    },
  },
}
</script>
