import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 * Get categories with pagination
 * @param {*} params
 * @returns
 */
const fetchResiliencePlanStatuses = async function (params) {
  return fetcher.get("/identity/api/v1/resilience-plan-statuses", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 * Get all categories
 * @returns
 */
const fetchAllResiliencePlanStatuses = async function () {
  return fetcher.get("/identity/api/v1/resilience-plan-statuses/all")
}

/**
 * Get a category
 * @returns
 */
const fetchResiliencePlanStatus = async function (uid) {
  return fetcher.get(`/identity/api/v1/resilience-plan-statuses/${uid}`)
}

/**
 * Create a new category
 * @returns
 */
const createResiliencePlanStatus = async function (payload) {
  return fetcher.post(`/identity/api/v1/resilience-plan-statuses`, payload)
}

/**
 * Get all categories
 * @returns
 */
const updateResiliencePlanStatus = async function (uid, payload) {
  return fetcher.put(`/identity/api/v1/resilience-plan-statuses/${uid}`, payload)
}

/**
 * Get a category
 * @returns
 */
const deleteResiliencePlanStatus = async function (uid) {
  return fetcher.delete(`/identity/api/v1/resilience-plan-statuses/${uid}`)
}

export {
  fetchResiliencePlanStatuses,
  fetchAllResiliencePlanStatuses,
  fetchResiliencePlanStatus,
  createResiliencePlanStatus,
  updateResiliencePlanStatus,
  deleteResiliencePlanStatus,
}
