<template>
  <!-- Modal Delete -->
  <EcModalSimple
    :isVisible="isModalDeleteDependencyRelationshipOpen"
    variant="center-3xl"
    @overlay-click="handleCloseDeleteModal"
  >
    <EcBox class="text-center">
      <EcFlex class="justify-center">
        <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
      </EcFlex>

      <!-- Messages -->
      <EcBox>
        <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
          {{ $t("dependencyScenario.modal.labels.confirmToDelete") }}
        </EcHeadline>
        <!-- DependencyRelationship name -->
        <p class="text-lg" v-html="$t('dependencyScenario.modal.labels.warningContent', { nodesName })"></p>
        <EcText class="text-c0-500 mt-4">
          {{ $t("dependencyScenario.modal.labels.confirmDeleteQuestion") }}
        </EcText>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
        <EcButton variant="warning" @click="handleDeleteDependencyRelationship">
          {{ $t("bia.buttons.delete") }}
        </EcButton>
        <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseDeleteModal">
          {{ $t("bia.buttons.cancel") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useDependencyRelationshipList } from "@/modules/dependency/use/dependency/useDependencyRelationshipList"

export default {
  name: "ModalDeleteDependencyRelationship",

  emits: ["handleCloseDeleteModal", "handleDeleteCallback"],
  data() {
    return {
      isDeleteLoading: false,
      deletedIds: [],
    }
  },
  props: {
    isModalDeleteDependencyRelationshipOpen: {
      type: Boolean,
      default: false,
    },

    selectedEdges: {
      type: Object,
      default: () => {},
    },
    nodes: {
      type: Object,
      required: true,
      default: () => {},
    },
    edges: {
      type: Object,
      required: true,
      default: () => {},
    },
  },

  mounted() {},
  computed: {
    nodesName() {
      const result = []
      for (const edgeId of this.selectedEdges) {
        const edge = this.edges[edgeId]
        const source = this.nodes[edge?.source]
        const target = this.nodes[edge?.target]
        if (source?.name && target?.name) {
          result.push(`<b class="italic"> ${source.name}</b> and <b class="italic">${target.name}</b>`)
        }
      }
      return result.join("<br>")
    },
  },
  setup() {
    const { deleteDependencyRelationship } = useDependencyRelationshipList()
    return {
      deleteDependencyRelationship,
    }
  },
  methods: {
    /**
     * Delete Dependency relationship
     */
    async handleDeleteDependencyRelationship() {
      this.isDeleteLoading = true
      for (const edgeId of this.selectedEdges) {
        await this.deleteDependencyRelationship(edgeId)
        this.deletedIds.push(edgeId)
      }

      this.handleCloseDeleteModal()
      this.handleDeleteCallback()
      this.isDeleteLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseDeleteModal() {
      this.$emit("handleCloseDeleteModal")
    },

    /**
     * Delete callback
     */
    handleDeleteCallback() {
      this.$emit("handleDeleteCallback", this.deletedIds)
    },
  },
}
</script>
