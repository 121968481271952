<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-3xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addActivityPriority") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="priority.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="v$"
          field="priority.name"
          @input="v$.priority.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="priority.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="v$"
          :rows="2"
          field="priority.description"
          @input="v$.priority.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Weight -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full">
          <RFormInput
            v-model="priority.order"
            componentName="EcInputNumberStepper"
            :label="$t('setting.general.labels.priorityOrder')"
            :step="1"
            :min="1"
            :max="100"
            :validator="v$"
            field="priority.order"
            @input="v$.priority.order.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Icon -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="priority.icon"
          :label="$t('setting.general.labels.icon')"
          componentName="EcMultiSelect"
          :options="iconOptions"
          :isSingleSelect="true"
          iconKey="value"
          field="priority.description"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreatePriority">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useActivityPriorities } from "@/readybc/composables/use/useActivityPriorities"

export default {
  name: "ModalAddUtilities",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { priority, getIconOptions, createPriority, v$ } = useActivityPriorities()

    return {
      getIconOptions,
      priority,
      v$,
      createPriority,
    }
  },

  computed: {
    iconOptions() {
      return this.getIconOptions()
    },
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreatePriority() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      this.priority.icon_url = this.priority.icon.value

      const response = await this.createPriority(this.priority)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewUtilityOpen() {
      this.priority.name = null
      this.priority.description = null
      this.priority.order = null
      this.priority.priority = null
      this.v$.priority.$reset()
    },
  },
}
</script>
