<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("setting.general.masterDataModules.locations") }}
        </EcHeadline>

        <!-- Add  -->
        <EcButton class="mb-3 lg:mb-0" iconPrefix="plus-circle" variant="primary-sm" @click="handleClickAdd">
          {{ $t("setting.general.buttons.addLocation") }}
        </EcButton>
      </EcFlex>
    </EcFlex>

    <!-- Table -->
    <RTable :isLoading="isLoading" :list="listData" class="mt-4 lg:mt-6">
      <template #header>
        <RTableHeaderRow>
          <RTableHeaderCell v-for="(h, idx) in headerData" :key="idx" class="text-cBlack">
            {{ h.label }}
          </RTableHeaderCell>
          <RTableHeaderCell variant="gradient" />
        </RTableHeaderRow>
      </template>
      <template v-slot="{ item, last, first }">
        <RTableRow class="hover:bg-c0-100">
          <RTableCell>
            <EcText class="w-24">
              {{ item.name }}
            </EcText>
          </RTableCell>

          <!-- Desc -->
          <RTableCell>
            <EcText class="w-24">
              {{ item.description }}
            </EcText>
          </RTableCell>

          <!-- Action -->
          <RTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }" :isTruncate="false" variant="gradient">
            <EcFlex class="items-center justify-center h-full">
              <RTableAction class="w-30">
                <!-- Edit action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-c1-500 hover:bg-c0-100"
                  @click="handleClickEdit(item.uid)"
                >
                  <EcIcon class="mr-3" icon="Pencil" />
                  <EcText class="font-medium">{{ $t("setting.general.buttons.edit") }}</EcText>
                </EcFlex>
              </RTableAction>
            </EcFlex>
          </RTableCell>
        </RTableRow>
      </template>
    </RTable>

    <!-- Pagination -->
    <EcFlex class="flex-col my-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="filters.page.size"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="filters.page.size"
        :totalItems="filters.page.total"
        @input="setPage($event)"
      />
    </EcFlex>

    <!-- Actions -->
    <EcFlex class="">
      <EcButton variant="tertiary" @click="handleBackToMasterList">
        {{ $t("setting.general.buttons.back") }}
      </EcButton>
    </EcFlex>

    <teleport to="#layer1">
      <ModalAddLocation
        :isModalAddNewOpen="isModalAddNewOpen"
        @handleCloseAddNewModal="handleCloseAddNewModal"
        @handleCallbackAddNew="handleCallbackAddNew"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useLocationList } from "@/modules/setting/use/master/locations/useLocationList"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import ModalAddLocation from "@/modules/setting/components/ModalAddLocation.vue"

export default {
  name: "ViewLocationList",
  components: { ModalAddLocation },
  setup() {
    const globalStore = useGlobalStore()
    const { getLocationList, t, listData } = useLocationList()

    return {
      globalStore,
      getLocationList,
      t,
      listData,
    }
  },
  data() {
    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 10,
        total: 0,
      },
    }
    return {
      headerData: [{ label: this.$t("setting.general.labels.name") }, { label: this.$t("setting.general.labels.description") }],
      currentPage: 1,
      searchQuery: "",
      isLoading: false,
      isModalAddNewOpen: false,
      filters,
    }
  },
  mounted() {
    this.fetchListData()
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  watch: {
    currentPage() {
      this.filters.page.number = this.currentPage
      this.fetchListData()
    },
  },
  methods: {
    formatData,

    /**
     * Fetch list locations
     * @returns {Promise<void>}
     */
    async fetchListData() {
      this.isLoading = true

      const locationList = await this.getLocationList(this.filters)

      if (locationList && locationList.data) {
        this.listData = locationList.data

        // Pagination
        this.currentPage = locationList.current_page
        this.filters.page.per_page = locationList.per_page
        this.filters.page.total = locationList.total
      }
      this.isLoading = false
    },

    /**
     * Add new utilities
     */
    handleClickAdd() {
      this.isModalAddNewOpen = true
    },

    /**
     * Cancel add utilities
     */
    handleCloseAddNewModal() {
      this.isModalAddNewOpen = false
    },

    /**
     * Create utilities callback
     */
    handleCallbackAddNew() {
      this.fetchListData()
    },

    /**
     * Back to master list
     */
    handleBackToMasterList() {
      goto("ViewMasterSetting")
    },

    /**
     * View location detail
     * @param uid
     */
    handleClickEdit(uid) {
      goto("ViewLocationDetail", {
        params: {
          uid: uid,
        },
      })
    },

    setPage(event) {
      this.currentPage = event.target.value
    },
  },
}
</script>
