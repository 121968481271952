import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import { helpers } from "@vuelidate/validators"
import * as api from "../api/bulkActivitiesFetcher"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"

export function useCreateBulkActivities() {
  const globalStore = useGlobalStore()

  const activities = ref([])
  const { t } = useI18n()
  const otherFieldContainsMe = (value) => {
    const names = activities.value.map((activity) => activity.name)
    return value === "" || names.filter((name) => name === value).length === 1
  }

  /**
   * Custom rule lessThanMTPD
   * @param value
   * @param siblings
   * @returns {boolean}
   */
  const lessThanMTPD = (value, siblings) => {
    if (!siblings.name || siblings.name?.length === 0) {
      return true
    }

    const mtpd = siblings?.mtpdValue ?? null
    return value <= mtpd
  }

  /**
   * Custom rule greaterThanRTO
   * @param value
   * @param siblings
   * @returns {boolean}
   */
  const greaterThanRTO = (value, siblings) => {
    if (!siblings.name || siblings.name?.length === 0) {
      return true
    }

    const rto = siblings?.recoveryTimeValue ?? null
    return value >= rto
  }

  const rules = {
    activities: {
      $each: helpers.forEach({
        name: { otherFieldContainsMe },
        description: {},
        min_people: {},
        division: {},
        business_unit: {},
        is_remote: {},
        roles: {},
        alternative_roles: {},
        remote_access_factors: {},
        on_site_requires: {},
        utilities: {},
        applications: {},
        equipments: {},
        mtpdValue: { greaterThanRTO },
        recoveryTimeValue: { lessThanMTPD },
        is_rto_tested: {},
        disruption_scenarios: {},
      }),
    },
    // Step 1
  }
  const v$ = useVuelidate(rules, { activities })
  const customRuleMessage = {
    name: {
      otherFieldContainsMe: t("activity.errors.duplicateName"),
      greaterThanRTO: t("activity.errors.invalidMTPD"),
      lessThanMTPD: t("activity.errors.invalidRTO"),
    },
    mtpdValue: {
      greaterThanRTO: t("activity.errors.invalidMTPD"),
    },
    recoveryTimeValue: {
      lessThanMTPD: t("activity.errors.invalidRTO"),
    },
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const getBulkDraftDetail = async (uid) => {
    try {
      const { data } = await api.fetchDetailDraftBulkActivities(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const persistBulkActivitiesData = async (payload) => {
    try {
      const { data } = await api.saveBulkActivities(payload)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteBulkActivitiesDraft = async (uid) => {
    try {
      const { data } = await api.deleteDraftBulkActivity(uid)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createNewDraftBulkActivity = async (payload) => {
    try {
      const { data } = await api.createDraftBulkActivity(payload)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createActivityMasterData = async (payload) => {
    try {
      const { data } = await api.createActivityMasterData(payload)
      return data?.data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
      return null
    }
  }

  return {
    activities,
    v$,
    customRuleMessage,
    persistBulkActivitiesData,
    createNewDraftBulkActivity,
    getBulkDraftDetail,
    createActivityMasterData,
    deleteBulkActivitiesDraft,
  }
}
