import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchSupplierStatusList = async (params) => {
  return fetcher.get("identity/api/v1/supplier-statuses", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchSupplierStatus = async (id) => {
  return fetcher.get(`identity/api/v1/supplier-statuses/${id}`)
}

export const fetchAllSupplierStatus = async () => {
  return fetcher.get(`identity/api/v1/supplier-statuses/all`)
}

export const updateSupplierStatus = async (payload, id) => {
  return fetcher.put(`identity/api/v1/supplier-statuses/${id}`, payload)
}

export const deleteSupplierStatus = async (id) => {
  return fetcher.delete(`identity/api/v1/supplier-statuses/${id}`)
}

export const createSupplierStatus = async (payload) => {
  return fetcher.post("identity/api/v1/supplier-statuses", payload)
}
