import ViewSettingList from "@/modules/setting/views/ViewSettingList"
import ViewGeneralSetting from "@/modules/setting/views/general/ViewGeneralSetting"

import ViewMailSetting from "@/modules/setting/views/mail/ViewMailSetting"
import ViewProfile from "@/modules/setting/views/profile/ViewProfile"
import ViewMasterSetting from "@/modules/setting/views/general/ViewMasterSetting"
import ViewRTOList from "@/modules/setting/views/general/master/rto/ViewRTOList"
import ViewRTODetail from "@/modules/setting/views/general/master/rto/ViewRTODetail"
import ViewMTDPList from "@/modules/setting/views/general/master/mtdp/ViewMTDPList"
import ViewMTDPDetail from "@/modules/setting/views/general/master/mtdp/ViewMTDPDetail"
// import ViewActivityEquipmentList from "@/modules/setting/views/general/master/activityEquipment/ViewActivityEquipmentList"
// import ViewActivityEquipmentDetail from "@/modules/setting/views/general/master/activityEquipment/ViewActivityEquipmentDetail"
import ViewRemoteAccessList from "@/modules/setting/views/general/master/remote/ViewRemoteAccessList"
import ViewRemoteAccessDetail from "@/modules/setting/views/general/master/remote/ViewRemoteAccessDetail"
import ViewUtilitiesList from "@/modules/setting/views/general/master/utilities/ViewUtilitiesList"
import ViewUtilitiesDetail from "@/modules/setting/views/general/master/utilities/ViewUtilitiesDetail"
import ViewActivityPriorityList from "@/modules/setting/views/general/master/priorities/ViewActivityPriorityList"
import ViewActivityPriorityDetail from "@/modules/setting/views/general/master/priorities/ViewActivityPriorityDetail"

// BIA
import ViewBIAStatusList from "@/modules/setting/views/general/master/bia/ViewBIAStatusList"
import ViewBIAStatusDetail from "@/modules/setting/views/general/master/bia/ViewBIAStatusDetail"

// BCP
import ViewBCPStatusList from "@/modules/setting/views/general/master/bcp/ViewBCPStatusList"
import ViewBCPStatusDetail from "@/modules/setting/views/general/master/bcp/ViewBCPStatusDetail"

// Supplier
import ViewSupplierStatusList from "@/modules/setting/views/general/master/supplier/ViewSupplierStatusList"
import ViewSupplierStatusDetail from "@/modules/setting/views/general/master/supplier/ViewSupplierStatusDetail"

// Locations
import ViewLocationList from "@/modules/setting/views/general/master/locations/ViewLocationList"
import ViewLocationDetail from "@/modules/setting/views/general/master/locations/ViewLocationDetail"

export default [
  {
    path: "/settings",
    component: ViewSettingList,
    name: "ViewSettingList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/general",
    component: ViewGeneralSetting,
    name: "ViewGeneralSetting",
    props: true,
    meta: {
      module: "settings",
    },
  },

  {
    path: "/settings/master",
    component: ViewMasterSetting,
    name: "ViewMasterSetting",
    props: true,
    meta: {
      module: "settings",
    },
  },
  // RTO
  {
    path: "/settings/master/rto",
    component: ViewRTOList,
    name: "ViewRTOList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/rto/:uid",
    component: ViewRTODetail,
    name: "ViewRTODetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  // MTDP
  {
    path: "/settings/master/mtdp",
    component: ViewMTDPList,
    name: "ViewMTDPList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/mtdp/:uid",
    component: ViewMTDPDetail,
    name: "ViewMTDPDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  // Temporarily hidden because of RBC-587
  // Equipment
  // {
  //   path: "/settings/master/activity-equipments",
  //   component: ViewActivityEquipmentList,
  //   name: "ViewActivityEquipmentList",
  //   props: true,
  //   meta: {
  //     module: "settings",
  //   },
  // },
  // {
  //   path: "/settings/master/activity-equipments/:uid",
  //   component: ViewActivityEquipmentDetail,
  //   name: "ViewActivityEquipmentDetail",
  //   props: true,
  //   meta: {
  //     module: "settings",
  //   },
  // },
  // Remote Access Factor
  {
    path: "/settings/master/remote-access-factors",
    component: ViewRemoteAccessList,
    name: "ViewRemoteAccessList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/remote-access-factors/:uid",
    component: ViewRemoteAccessDetail,
    name: "ViewRemoteAccessDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  // Utilities
  {
    path: "/settings/master/utilities",
    component: ViewUtilitiesList,
    name: "ViewUtilitiesList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/utilities/:uid",
    component: ViewUtilitiesDetail,
    name: "ViewUtilitiesDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
  // Priorities
  {
    path: "/settings/master/activity-priorities",
    component: ViewActivityPriorityList,
    name: "ViewActivityPriorityList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/activity-priorities/:uid",
    component: ViewActivityPriorityDetail,
    name: "ViewActivityPriorityDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },

  // ViewBIAStatusList
  {
    path: "/settings/master/bia-statuses",
    component: ViewBIAStatusList,
    name: "ViewBIAStatusList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/bia-statuses/:id",
    component: ViewBIAStatusDetail,
    name: "ViewBIAStatusDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },

  // ViewBCPStatusList
  {
    path: "/settings/master/bcp-statuses",
    component: ViewBCPStatusList,
    name: "ViewBCPStatusList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/bcp-statuses/:id",
    component: ViewBCPStatusDetail,
    name: "ViewBCPStatusDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },

  // ViewSupplierStatusList
  {
    path: "/settings/master/supplier-statuses",
    component: ViewSupplierStatusList,
    name: "ViewSupplierStatusList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/supplier-statuses/:id",
    component: ViewSupplierStatusDetail,
    name: "ViewSupplierStatusDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },

  // Mail settings
  {
    path: "/settings/mail",
    component: ViewMailSetting,
    name: "ViewMailSetting",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/profile",
    component: ViewProfile,
    name: "ViewProfile",
    props: true,
    meta: {
      module: "settings",
    },
  },

  // Location Settings
  {
    path: "/settings/master/locations",
    component: ViewLocationList,
    name: "ViewLocationList",
    props: true,
    meta: {
      module: "settings",
    },
  },
  {
    path: "/settings/master/locations/:uid",
    component: ViewLocationDetail,
    name: "ViewLocationDetail",
    props: true,
    meta: {
      module: "settings",
    },
  },
]
