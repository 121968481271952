<template>
  <EcBox v-if="isGrantedToAcces(item)" :class="variantCls.button.for" @click="handleClickMenuItem(item)">
    <EcNavLink :to="routerLink">
      <EcButton
        variant="wrapper"
        class="w-full"
        :class="[
          variantCls.button.wrapper,
          item?.module === currentRouteModule ? variantCls.button.selected : variantCls.button.idle,
          { 'flex-row-reverse': menuDirection === 'rtl' },
        ]"
      >
        <EcBox :class="variantCls.button.box">
          <!-- Menu Icon -->
          <EcIcon
            :icon="item.icon"
            :width="item?.iconWidth || '20'"
            :height="item?.iconHeight || '20'"
            :class="[
              variantCls.button.icon.wrapper,
              item?.module === currentRouteModule ? variantCls.button.icon.selected : variantCls.button.icon.idle,
              { 'mr-5': menuDirection === 'ltr' },
              { 'ml-5': menuDirection === 'rtl' },
              item?.placeholder ? 'cursor-not-allowed' : '',
            ]"
          />
          <!-- Menu text -->
          <EcText
            :class="[
              variantCls.button.text.wrapper,
              item?.module === currentRouteModule ? variantCls.button.text.selected : variantCls.button.text.idle,
              item?.placeholder ? 'cursor-not-allowed !text-c0-300' : '',
            ]"
          >
            {{ item.text }}
          </EcText>

          <!-- tooltip -->
          <EcButton v-if="item?.tooltip?.length > 0" variant="transparent" :class="[]" v-tooltip="{ text: $t(item?.tooltip) }">
            <EcIcon icon="QuestionMark" width="16" />
          </EcButton>

          <!-- Menu data -->
          <EcBox
            v-if="item?.data"
            :class="[
              variantCls.data.wrapper,
              item?.module === currentRouteModule ? variantCls.data.text.selected : variantCls.data.text.idle,
            ]"
          >
            {{ item?.data }}</EcBox
          >

          <!-- Menu sub item -->
          <EcIcon
            @click="handleExpandMenu(item)"
            v-if="item?.subItems?.length > 0"
            icon="CaretRight"
            :class="[
              variantCls.subMenu.wrapper,
              item?.module === currentRouteModule ? variantCls.subMenu.text.selected : variantCls.subMenu.text.idle,
              subItemExpansion[item.module] ? 'rotate-90' : '',
            ]"
            width="11"
            height="11"
          />
        </EcBox>
      </EcButton>
    </EcNavLink>

    <EcBox v-if="item?.subItems?.length > 0 && subItemExpansion[item.module]">
      <RSidebarMenuItem
        class="ml-4 border-l border-c3-50"
        v-for="(subItem, idx) in item.subItems"
        :key="idx"
        :item="subItem"
        :componentName="componentName"
        :currentRouteModule="currentRouteModule"
        :menuDirection="menuDirection"
      />
    </EcBox>
  </EcBox>
</template>

<script>
import { useRouter } from "vue-router"
import EcBox from "@/components/EcBox/index.vue"
import EcIcon from "@/components/EcIcon/index.vue"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "RSidebarMenuItem",
  inject: ["getComponentVariants"],
  emits: ["click-menu-item"],
  props: {
    item: {
      type: Object,
      required: true,
      default: () => {},
    },
    componentName: {
      type: String,
    },
    currentRouteModule: {
      type: String,
    },
    menuDirection: {
      type: String,
    },
  },

  data() {
    return {
      subItemExpansion: {},
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const router = useRouter()

    return {
      router,
      globalStore,
    }
  },
  mounted() {
    if (this.item?.subItems?.length > 0) {
      this.item?.subItems?.forEach((subItem) => {
        if (subItem?.module === this.currentRouteModule) {
          this.subItemExpansion[this.item.module] = true
        }
      })
    }
  },
  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "RSidebarMenuItem",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },

    routerLink() {
      if (this.item?.placeholder) {
        return "#"
      }

      const route = this.router.resolve({ name: this.item.routeName })

      return route ? route?.fullPath : "/"
    },
  },
  methods: {
    /**
     *
     * @param {*} item
     */
    handleClickMenuItem(item) {
      if (item?.placeholder) {
        return
      }

      this.subItemExpansion[item.module] = !this.subItemExpansion[item.module]
      this.router.push({
        name: item.routeName,
      })
      this.$emit("click-menu-item")
    },

    /**
     * Check to the user has access right to menu
     */
    isGrantedToAcces(item) {
      if (this.globalStore.isLandlord && item?.landlord === false) {
        return false
      }

      return this.$permissions(`menu.${item.module}`) || item?.subItems?.length > 0 || item?.placeholder
    },

    /**
     *
     * @param {*} item
     */
    handleExpandMenu(item) {
      // console.log(this.subItemExpansion)
    },

    /**
     *
     * @param {*} item
     */
    isSubMenuExpanded(item) {
      return this.subItemExpansion[item.module]
    },
  },
  components: { EcIcon, EcBox },
}
</script>
