<template>
  <EcModalSimple :isVisible="isMFAModalOpen" variant="center-3xl">
    <!-- Modal Header -->
    <EcBox class="mb-6">
      <EcHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
        {{ $t("setting.mfa.title") }}
      </EcHeadline>
    </EcBox>

    <!-- QR and Code -->
    <EcBox v-if="!isLoading">
      <!-- For enable MFA -->
      <EcBox v-if="enable">
        <!-- QR and url -->
        <EcBox v-if="qr.qrUri?.length > 0">
          <!-- Label -->
          <EcFlex class="mb-4">
            <EcLabel class="font-medium">{{ $t("setting.mfa.labels.qr") }}</EcLabel>
            <EcButton variant="transparent" v-tooltip="$t('setting.mfa.tooltips.copy')">
              <EcIcon icon="QuestionMark" width="16" />
            </EcButton>
          </EcFlex>

          <!-- QR Image -->
          <img :src="qr.qrImageUri" class="mb-4 hover:cursor-pointer" @click="handleCopyQRUri" />

          <RFormInput
            v-model.trim="qr.otp"
            componentName="EcInputText"
            thousandSeparator=""
            :label="$t('setting.mfa.labels.code')"
            :validator="v$"
            @input="v$.qr.otp.$touch()"
            field="qr.otp"
          />
        </EcBox>

        <!-- Can not retrieve QR -->
        <EcText v-else>{{ $t("setting.mfa.errors.loadQR") }}</EcText>
      </EcBox>

      <!-- For disable -->
      <EcBox v-if="!enable">
        <RFormInput
          v-model.trim="qr.otp"
          componentName="EcInputText"
          thousandSeparator=""
          :label="$t('setting.mfa.labels.code')"
          :validator="v$"
          @input="v$.qr.otp.$touch()"
          field="qr.otp"
        />
      </EcBox>

      <!-- Actions -->
      <EcBox class="mt-6">
        <EcFlex v-if="!isCheckingOTP" class="mt-10">
          <EcButton class="mr-4" variant="primary" @click="handleVerifyMFA">
            {{ $t("setting.mfa.buttons.confirm") }}
          </EcButton>
          <EcButton variant="tertiary-outline" @click="handleCloseMFAModal">
            {{ $t("setting.mfa.buttons.cancel") }}
          </EcButton>
        </EcFlex>
        <EcSpinner v-else />
      </EcBox>
    </EcBox>

    <!-- Loading box -->
    <EcBox v-else class="flex items-center mt-6 h-10">
      <Loading size="2rem" class="text-c1-500" />
    </EcBox>
  </EcModalSimple>
</template>

<script>
import Loading from "@/modules/activity/components/Loading.vue"
import { useUserMFA } from "../use/mfa/useUserMFA"
import { copyValue } from "@/readybc/composables/helpers/helpers"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "UserMFAModal",
  components: {
    Loading,
  },

  data() {
    return {
      isLoading: false,
      isCheckingOTP: false,
    }
  },
  props: {
    me: {
      type: Object,
      default: () => {},
    },
    isMFAModalOpen: {
      type: Boolean,
      default: false,
    },
    enable: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const globalStore = useGlobalStore()
    const { qr, getUserMfa, getQRUri, verifyMfa, selectedMfa, preferenceMfa, DISABLED, ENABLED, SUCCESS, v$ } = useUserMFA()

    return {
      globalStore,
      DISABLED,
      ENABLED,
      SUCCESS,
      selectedMfa,
      getUserMfa,
      v$,
      qr,
      getQRUri,
      verifyMfa,
      preferenceMfa,
    }
  },

  methods: {
    /**
     * Fetch QR URI
     */
    async fetchQRUri() {
      this.isLoading = true
      const res = await this.getQRUri()

      if (res && res.qrUri) {
        this.qr = res
      } else {
        this.$emit("updateMFAToggle", false)
      }
      this.isLoading = false
    },

    /**
     * Update MFA setting
     */
    async handleVerifyMFA() {
      // Enable Case

      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      this.isCheckingOTP = true

      const payload = {
        otp: this.qr.otp,
        enable: this.enable,
      }
      const result = await this.verifyMfa(payload)

      if (result?.success) {
        this.handleCloseMFAModal(false)
        this.globalStore.addSuccessToastMessage(this.$t("setting.mfa.messages.success"))
      }

      this.isCheckingOTP = false
    },

    // Change enable/disable dropdown in modal
    async selectEnableMfa(value) {},

    /**
     * Close MFA Modal
     */
    handleCloseMFAModal(isCancelModal = true) {
      this.$emit("closeMFASettingsModal", isCancelModal)

      // Clear qr
      this.v$.qr.$reset()
      this.qr = {}
    },

    // Clear form after update success
    async clearAfterUpdateMfa() {},

    handleCopyQRUri() {
      copyValue(this.qr.qrUri)
    },
  },

  watch: {
    isMFAModalOpen(status) {
      if (status === true && this.enable) {
        this.fetchQRUri()
      }
    },
  },
}
</script>
