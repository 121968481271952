import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchCategoryList = (params) => {
  return fetcher.get("identity/api/v1/supplier-categories", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchCategoryDetail = (supplierUid) => {
  return fetcher.get(`identity/api/v1/supplier-categories/${supplierUid}`)
}

export const createCategory = (payload) => {
  return fetcher.post("/identity/api/v1/supplier-categories", payload)
}

export const updateCategory = (supplierUid, payload) => {
  return fetcher.put(`/identity/api/v1/supplier-categories/${supplierUid}`, payload)
}

export const deleteCategory = (supplierUid) => {
  return fetcher.delete(`/identity/api/v1/supplier-categories/${supplierUid}`)
}
