import * as api from "@/modules/resilience_plan/api/status/apiResiliencePlanStatus"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useResiliencePlanStatusNew() {
  const isLoading = ref(false)
  const isUpdateLoading = ref(false)

  const status = ref({
    review_date_editable: true,
    due_date_editable: true,
  })

  const rules = {
    status: {
      name: { required },
      order: {},
      description: {},
      review_date_editable: {},
      due_date_editable: {},
    },
  }

  const v$ = useVuelidate(rules, { status })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createResiliencePlanStatus = async (payload) => {
    v$.value.$touch()

    if (v$.value.$invalid) {
      return
    }

    try {
      isLoading.value = true
      const { data } = await api.createResiliencePlanStatus(payload)

      return data
    } catch (error) {
      return false
    }
  }

  return {
    isLoading,
    status,
    v$,
    createResiliencePlanStatus,
    isUpdateLoading,
  }
}
