import * as api from "@/modules/resilience_plan/api/status/apiResiliencePlanStatus"
import { onMounted, ref } from "vue"

export function useResiliencePlanStatusListAll() {
  const isLoading = ref(false)
  const statuses = ref([])

  onMounted(async () => {
    await getAllResilenceStatuses()
  })

  /**
   *
   * @returns
   */
  const getAllResilenceStatuses = async () => {
    try {
      isLoading.value = true
      const { data } = await api.fetchAllResiliencePlanStatuses()

      statuses.value = data || []

      isLoading.value = false

      return data
    } catch (error) {
      return false
    }
  }

  return {
    isLoading,
    statuses,
    getAllResilenceStatuses,
  }
}
