import { useGlobalStore } from "@/stores/global"
import * as api from "../../api/categoryFetcher"
import { ref } from "vue"

export function useCategoryList() {
  const globalStore = useGlobalStore()
  const categories = ref([])
  // Initial data

  /**
   *
   * @returns
   */
  async function getEquipmentCategoryList(params) {
    try {
      const { data } = await api.fetchCategoryList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("equipment.errors.listCategory"))
    }
  }

  return {
    getEquipmentCategoryList,
    categories,
  }
}
