import * as api from "../../api/resiliencePlanFetcher"
import { useGlobalStore } from "@/stores/global"
import { downloadFromBlob } from "@/readybc/composables/helpers/downloadHelper"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"

export function useResiliencePlanExport() {
  const globalStore = useGlobalStore()

  /**
   * Download bcps
   * @returns
   */
  async function downloadResiliencePlans(category) {
    const apiCriteria = new ApiCriteria()
    if (category?.uid) {
      apiCriteria.setRelationSearch("category", "uid", category?.uid)
    }

    try {
      const { data } = await api.downloadResiliencePlans(apiCriteria.toQuery())

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("bcps.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Business_Continuity_Plans_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("bcp.errors.download"))
      return false
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  async function exportResiliencePlanRecord(uid) {
    try {
      const { data } = await api.exportResiliencePlan(uid)

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("bcp.errors.download"))
        return
      }

      downloadFromBlob(data, `Business_Continuity_Plan`, "zip")
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("bcp.errors.download"))
      return false
    }
  }

  return {
    downloadResiliencePlans,
    exportResiliencePlanRecord,
  }
}
