<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h3" variant="h3" class="text-4xl">
          {{ $t("setting.general.buttons.addApplication") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="application.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="application.name"
          @input="validator$.application.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="application.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="application.description"
          @input="validator$.application.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- RTO -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="application.rto"
          :label="'RTO'"
          componentName="EcInputText"
          :validator="validator$"
          :rows="2"
          field="application.rto"
        ></RFormInput>
      </EcBox>

      <!-- Comments -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="application.comments"
          :label="'Comments'"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="application.comments"
        ></RFormInput>
      </EcBox>

      <!-- SLA -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="application.sla"
          :label="$t('application.sla.title')"
          componentName="EcCheckbox"
          :validator="validator$"
          :rows="2"
          field="application.comments"
        ></RFormInput>

        <!-- Documents -->
        <EcFlex v-if="application.sla" class="flex-wrap w-full max-w-full">
          <EcBox class="w-full mb-6 sm:pr-6">
            <RUploadFiles
              :dir="'application/slas'"
              :documentTitle="$t('application.sla.docTitle')"
              :isUploadOnSelect="false"
              :maxFileNum="10"
              :type="'sla_documents'"
              :isParentSubmitting="isFormSubmitting"
              dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
              @handleSingleUploadResult="handleSlaDocUploaded"
              @handleBulkFilesUpload="createSupplierAfterUploadedFile"
              @startUploadFiles="this.isCreating = true"
              @endUploadFiles="this.isCreating = false"
            />
          </EcBox>
        </EcFlex>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateApplication">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useApplicationNew } from "@/modules/application/use/useApplicationNew"

export default {
  name: "ModalAddApplication",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { application, validator$, createApplication } = useApplicationNew()
    return {
      application,
      validator$,
      createApplication,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateApplication() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createApplication(this.application)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },

    /** Handle uploaded SLA */
    handleSlaDocUploaded(result) {
      this.application.sla_documents.push({ uid: result.uid })
    },

    /**
     * Bulk upload
     * @param {*} result
     */
    handleSlaDocBulkUploaded(result) {
      console.log(result)
    },
  },

  watch: {
    isModalAddNewApplicationOpen() {
      this.application.name = null
      this.application.description = null
      this.validator$.application.$reset()
    },
  },
}
</script>
