// Factory function returning fetcher suitable for CoverGo GraphQL APIs
import fetcher from "@/modules/core/api/fetcher"

export const fetchEmailSetting = async () => {
  return fetcher.get("identity/api/v1/email-settings")
}

export const updateEmailSetting = async (payload) => {
  return fetcher.post("identity/api/v1/email-settings", payload)
}
