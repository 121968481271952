import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"
/**
 *
 * @param {*} payload
 * @returns
 */
export const createDependencySenario = async (payload) => {
  return fetcher.post(`identity/api/v1/dependency-scenarios`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const fetchDependencyScenario = async (uid) => {
  return fetcher.get(`identity/api/v1/dependency-scenarios/${uid}`)
}

/**
 *
 * @param {*} payload
 * @param {*} uid
 * @returns
 */
export const updateDependencyScenario = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/dependency-scenarios/${uid}`, payload)
}

/**
 *
 * @param {*} uid
 * @returns
 */
export const deleteDependencyScenario = async (uid) => {
  return fetcher.delete(`identity/api/v1/dependency-scenarios/${uid}`)
}

/**
 *
 * Get list resources
 * @returns
 */
export const fetchDependencyScenariosList = async (params) => {
  return fetcher.get(`/identity/api/v1/dependency-scenarios`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 *
 * Get list resources
 * @returns
 */
export const fetchDependencyScenariosListV2 = async () => {
  return fetcher.get(`/identity/api/v1/dependency-relationships/all`)
}

/**
 *
 * @param {*} payload
 * @returns
 */
export const createDependencyRelationship = async (payload) => {
  return fetcher.post(`identity/api/v1/dependency-relationships`, payload)
}

/**
 *
 * @param {*} payload
 * @returns
 */
export const deleteDependencyRelationship = async (uid) => {
  return fetcher.delete(`identity/api/v1/dependency-relationships/${uid}`)
}

/**
 * Download activities
 * @returns
 */
export const downloadDependencyScenarios = async () => {
  return fetcher.get(`/identity/api/v1/dependency-scenarios/download/all?`, {
    responseType: "blob",
  })
}
