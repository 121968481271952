<template>
  <RLayout>
    <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
      {{ supplier?.name }}
    </EcHeadline>
    <RLayoutTwoCol :isLoading="isLoading">
      <template #left>
        <EcBox class="width-full px-4 sm:px-10" variant="card-1">
          <EcText class="mb-4 font-bold text-lg">{{ $t("supplier.title.supplierDetail") }}</EcText>

          <!-- Name -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.name"
              :label="$t('supplier.name')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.name"
              @input="supplierValidator$.supplier.name.$touch()"
            />
            <!-- error message name has been used -->
            <EcBox v-if="isNameUnique" class="mt-2">
              <EcText class="text-cError-600 text-sm mt-1"> {{ $t("supplier.nameUnique") }} </EcText>
            </EcBox>
          </EcBox>

          <!-- description -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.description"
              :label="$t('supplier.desc')"
              :rows="2"
              :validator="supplierValidator$"
              componentName="EcInputLongText"
              field="supplier.description"
              @input="supplierValidator$.supplier.description.$touch()"
            />
          </EcBox>

          <!-- Divisions -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12 sm:pr-6">
              <RFormInput
                v-model="supplier.divisions"
                :label="$t('activity.division.label')"
                componentName="EcMultiSelect"
                :options="divisions"
                :valueKey="'uid'"
                :validator="supplierValidator$"
              />
            </EcBox>
            <EcSpinner class="my-auto mb-1" v-if="isLoadingDivisions"></EcSpinner>
          </EcFlex>

          <!-- Business Units -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12 sm:pr-6">
              <RFormInput
                v-model="supplier.business_units"
                :label="$t('activity.bu.label')"
                componentName="EcMultiSelect"
                :options="filteredBusinessUnits"
                :valueKey="'uid'"
                :validator="supplierValidator$"
              />
            </EcBox>
            <EcSpinner class="my-auto mb-1" v-if="isLoadingBusinessUnits"></EcSpinner>
          </EcFlex>

          <!-- Status -->
          <EcFlex class="flex-wrap max-w-md items-center mb-8">
            <EcBox class="w-full sm:w-6/12">
              <!-- select box -->
              <RFormInput
                v-model="supplier.status"
                componentName="EcMultiSelect"
                :label="$t('supplier.labels.status')"
                :valueKey="'id'"
                :allowSelectNothing="false"
                :isSingleSelect="true"
                :options="statuses"
                :validator="supplierValidator$"
                field="supplier.status"
                @change="supplierValidator$.supplier.status.$touch()"
              />
            </EcBox>
          </EcFlex>
        </EcBox>

        <EcBox class="width-full px-4 mt-8 sm:px-10" variant="card-1">
          <EcText class="mb-4 font-bold text-lg">Contact point</EcText>
          <!-- Contact name -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.contact_name"
              :label="$t('supplier.contactName')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.contact_name"
              @input="supplierValidator$.supplier.contact_name.$touch()"
            />
          </EcBox>
          <!-- email -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.email"
              :label="$t('supplier.email')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.email"
              @input="supplierValidator$.supplier.email.$touch()"
            />
          </EcBox>

          <!-- phone -->
          <EcBox class="w-1/2 mb-6">
            <RFormInput
              v-model="supplier.phone_number"
              :label="$t('supplier.phone')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.phone_number"
              @input="supplierValidator$.supplier.phone_number.$touch()"
            />
          </EcBox>

          <!-- address -->
          <EcBox class="w-full 2xl:w-10/12 mb-6 sm:pr-6">
            <RFormInput
              v-model="supplier.address"
              :label="$t('supplier.address')"
              :validator="supplierValidator$"
              componentName="EcInputText"
              field="supplier.address"
              @input="supplierValidator$.supplier.address.$touch()"
            />
          </EcBox>
        </EcBox>

        <!-- Actions -->
        <EcFlex v-if="!isUpdating" class="width-full mt-6 px-4 sm:px-10">
          <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleClickCancel">
            {{ $t("supplier.buttons.cancel") }}
          </EcButton>

          <EcButton
            v-permissions:supplier-update
            variant="primary"
            class="ml-3"
            id="btn-update"
            @click="handleClickUpdateSupplier"
          >
            {{ $t("supplier.buttons.update") }}
          </EcButton>
        </EcFlex>
        <EcFlex v-else class="items-center justify-start mt-6 ml-10 h-10">
          <EcSpinner type="dots" />
        </EcFlex>
      </template>

      <template #right>
        <EcBox class="width-full px-4 mb-8" variant="card-1">
          <!-- certificate -->
          <EcBox class="w-full mb-6 sm:pr-6 mt-4">
            <EcFlex class="flex-wrap w-full max-w-full">
              <EcBox class="w-full mb-6 sm:pr-6">
                <RUploadFiles
                  :dir="'supplier/certificates'"
                  :documentTitle="$t('supplier.certificate.title')"
                  :isUploadOnSelect="false"
                  :maxFileNum="10"
                  dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
                  @handleSingleUploadResult="handleCertificateUploaded"
                  @startUploadFiles="this.isUpdating = true"
                  @endUploadFiles="this.isUpdating = false"
                />
              </EcBox>
            </EcFlex>

            <RTable
              v-if="certs?.length > 0"
              :isLoading="isCertificateLoading"
              :list="certs"
              class="flex-wrap w-full max-w-full mt-2 lg:mt-4 overflow-scroll"
            >
              <template #header>
                <RTableHeaderRow>
                  <RTableHeaderCell v-for="(h, idx) in headerCertificate" :key="idx" class="text-cBlack">
                    {{ h.label }}
                  </RTableHeaderCell>
                </RTableHeaderRow>
              </template>

              <template v-slot="{ item }">
                <RTableRow class="hover:bg-c0-100 supplier-cert-item">
                  <!-- name file -->
                  <RTableCell class="hover:text-sky-500 w-4/5">
                    <EcText class="cursor-pointer break-words text-md font-medium">
                      <a :href="item.url" target="_blank">
                        {{ item.name }}
                      </a>
                    </EcText>
                    <EcText class="text-sm">{{ formatDateTime(item.created_at) }}</EcText>
                  </RTableCell>

                  <!-- action -->
                  <RTableCell>
                    <!-- Delete action -->
                    <EcFlex
                      class="w-full items-end cursor-pointer text-cError-500 hover:bg-c0-100 btn-delete-cert"
                      @click="handleOpenCertificateDeleteModal(item)"
                    >
                      <EcIcon class="mr-1" icon="X" width="16" />
                    </EcFlex>
                  </RTableCell>
                </RTableRow>
              </template>
            </RTable>
          </EcBox>
        </EcBox>

        <!-- delete supplier-->
        <EcBox v-permissions:supplier-delete class="width-full px-4" variant="card-1">
          <EcHeadline as="h2" class="text-c1-800 px-5" variant="h2">
            {{ $t("supplier.deleteSupplier") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal">
            {{ $t("supplier.deleteFullNote") }}
          </EcText>
          <EcButton
            class="mx-5"
            iconPrefix="Trash"
            variant="warning"
            id="btn-modal-delete"
            @click="handleOpenSupplierDeleteModal"
          >
            {{ $t("supplier.buttons.deleteSupplier") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal add new resource category -->
    <teleport to="#layer1">
      <ModalAddNewCategory
        :isModalAddNewCategoryOpen="isModalAddNewCategoryOpen"
        @handleCloseAddNewCategoryModal="handleCloseAddNewCategoryModal"
        @overlay-click="handleCloseAddNewCategoryModal"
      />
    </teleport>

    <!-- Modal Delete -->
    <teleport to="#layer2">
      <ModalConfirmDeleteSupplier
        :isModalDeleteOpen="isSupplierModalDeleteOpen"
        :supplier="this.supplier"
        @handleCallBackDeletedModal="handleSupplierCallBackDeletedModal"
        @handleCloseDeleteModal="handleCloseSupplierDeleteModal"
        @overlay-click="handleCloseSupplierDeleteModal"
      >
      </ModalConfirmDeleteSupplier>
    </teleport>

    <!-- Modal Certificate Delete -->
    <teleport to="#layer3">
      <ModalConfirmDeleteCertificate
        :certificate="certificateToDelete"
        :isModalDeleteOpen="isCertificateModalDeleteOpen"
        :supplierUid="this.supplier.uid"
        @handleCallBackDeletedModal="handleCertificateCallBackDeletedModal"
        @handleCloseDeleteModal="handleCloseCertificateDeleteModal"
      >
      </ModalConfirmDeleteCertificate>
    </teleport>
  </RLayout>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useSupplierDetail } from "@/modules/supplier/use/supplier/useSupplierDetail"
import ModalAddNewCategory from "@/modules/supplier/components/ModalAddNewCategory"
import ModalConfirmDeleteSupplier from "@/modules/supplier/components/ModalConfirmDeleteSupplier"
import { useCertificateList } from "@/modules/supplier/use/certificate/useCertificateList"
import ModalConfirmDeleteCertificate from "@/modules/supplier/components/ModalConfirmDeleteCertificate"
import { useGlobalStore } from "@/stores/global"
import { useSupplierStatus } from "@/readybc/composables/use/useSupplierStatus"
import { useBusinessUnitList } from "@/readybc/composables/use/useBusinessUnit"
import { useDivisionList } from "@/readybc/composables/use/useDivisionList"
import isEmpty from "lodash.isempty"

export default {
  name: "ViewSupplierDetail",
  data() {
    return {
      isUpdating: false,
      isNameUnique: false,
      isLoading: false,
      isUploading: false,
      isCertificateLoading: false,
      isFormSubmitting: false,

      isModalAddNewCategoryOpen: false,
      isSupplierModalDeleteOpen: false,
      isCertificateModalDeleteOpen: false,

      certificateToDelete: {},

      certs: [],

      headerCertificate: [{ label: this.$t("supplier.certificate.certificateName") }],
    }
  },
  setup() {
    const globalStore = useGlobalStore()

    const { supplier, supplierValidator$, getDetailSupplier, updateSupplier } = useSupplierDetail()

    const { getCertificateListBySupplier } = useCertificateList()

    const { statuses, getAllStatuses, isLoadingStatus } = useSupplierStatus()

    // Division and BU
    const { businessUnits, isLoadingBusinessUnits, getAllBusinessUnitList } = useBusinessUnitList()
    const { divisions, isLoadingDivisions, getAllDivisionList } = useDivisionList()
    return {
      globalStore,
      supplier,
      supplierValidator$,
      getDetailSupplier,
      updateSupplier,
      getCertificateListBySupplier,
      statuses,
      getAllStatuses,
      isLoadingStatus,

      // Division and BU
      divisions,
      isLoadingDivisions,
      getAllDivisionList,

      businessUnits,
      getAllBusinessUnitList,
      isLoadingBusinessUnits,
    }
  },

  props: {
    uid: {
      type: String,
      require: true,
    },
  },

  mounted() {
    this.fetchSupplier()
    // get certificate
    this.fetchCertificates()
    this.fetchSupplierStatuses()
    this.fetchDivisions()
    this.fetchBusinessUnits()
  },
  computed: {
    /**
     * Filter BU
     */
    filteredBusinessUnits() {
      if (isEmpty(this.supplier?.divisions)) {
        return this.businessUnits
      }
      return this.businessUnits.filter((bu) => {
        return this.supplier.divisions?.map((item) => item.uid).includes(bu.division?.uid)
      })
    },
  },
  methods: {
    /**
     * fetch detail supplier
     * @returns {Promise<void>}
     */
    async fetchSupplier() {
      this.isLoading = true
      const response = await this.getDetailSupplier(this.uid)

      if (response && response.uid) {
        this.supplier = response
      }
      this.isLoading = false
    },

    /**
     * @returns {Promise<void>}
     */
    async fetchCertificates() {
      this.isCertificateLoading = true
      const res = await this.getCertificateListBySupplier(this.uid)
      if (res) {
        this.certs = res
      }

      this.isCertificateLoading = false
    },

    /**
     * update category
     */
    async handleClickUpdateSupplier() {
      this.supplierValidator$.$touch()

      if (this.supplierValidator$.supplier.$invalid) {
        return
      }

      this.isUpdating = true
      this.supplier.status_id = this.supplier?.status?.id
      const res = await this.updateSupplier(this.supplier.uid, this.supplier)

      if (res) {
        setTimeout(this.handleUpdateSuccess, 500)
      }

      // trigger pros isFormSubmitting RUploadFile to auto upload file
      this.isUpdating = false
    },

    /**
     * Update Supplier After uploaded all file
     */
    async updateSupplierCertsAfterUploadedFile() {
      this.isUpdating = true

      // Set certs
      this.supplier.certs = this.certs

      await this.updateSupplier(this.supplier.uid, this.supplier)

      this.isUpdating = false
    },

    /** Handle uploaded logo */
    handleCertificateUploaded(result) {
      this.certs.push({
        uid: result.uid,
        name: result.name,
        created_at: result.created_at,
        url: result.url,
      })

      this.updateSupplierCertsAfterUploadedFile()
    },

    // go to view supplier list
    handleUpdateSuccess() {
      goto("ViewSupplierList")
    },

    // go to view supplier list
    handleClickCancel() {
      goto("ViewSupplierList")
    },

    /**
     * Open addNewCategory
     */
    handleOpenAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = false
    },

    /**
     * Open confirm delete modal
     */
    handleOpenSupplierDeleteModal() {
      this.isSupplierModalDeleteOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseSupplierDeleteModal() {
      this.isSupplierModalDeleteOpen = false
    },

    /**
     * Callback after delete supplier
     *
     */
    handleSupplierCallBackDeletedModal() {
      goto("ViewSupplierList")
    },

    /**
     * get original fileName
     * @param url
     * @returns {string}
     */
    getOriginalFileName(url) {
      let fileName = ""
      for (let i = url.length - 1; i >= 0; i--) {
        if (url[i] === "/") break
        fileName = url[i] + fileName
      }
      return fileName
    },

    /**
     *
     * @param {*} certificate
     */
    handleOpenCertificateDeleteModal(certificate) {
      this.certificateToDelete = certificate
      this.isCertificateModalDeleteOpen = true
    },

    /**
     * Handle callback delete cert
     */
    async handleCertificateCallBackDeletedModal() {
      await this.fetchCertificates()
      this.isCertificateModalDeleteOpen = false
    },

    /**
     * Close cert delete modal
     */
    handleCloseCertificateDeleteModal() {
      this.isCertificateModalDeleteOpen = false
    },

    // Format data time
    formatDateTime(date) {
      return this.globalStore.formatDateTime(date)
    },
    // PRE LOAD
    async fetchSupplierStatuses() {
      this.isLoadingStatus = true
      this.statuses = await this.getAllStatuses()
      this.isLoadingStatus = false
    },
    /**
     * Fetch Divisions
     */
    async fetchDivisions() {
      this.isLoadingDivisions = true
      const response = await this.getAllDivisionList()
      if (response && response.data) {
        this.divisions = response.data
      }
      this.isLoadingDivisions = false
    },

    /**
     * Fetch BU
     */
    async fetchBusinessUnits() {
      this.isLoadingBusinessUnits = true
      const response = await this.getAllBusinessUnitList()

      if (response && response.data) {
        this.businessUnits = response.data
      }
      this.isLoadingBusinessUnits = false
    },
  },

  components: { ModalAddNewCategory, ModalConfirmDeleteSupplier, ModalConfirmDeleteCertificate },
}
</script>
