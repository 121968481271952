import { useRoute } from "vue-router"
import * as api from "@/modules/resilience_plan/api/status/apiResiliencePlanStatus"
import { onMounted, ref } from "vue"
import { goto } from "@/modules/core/composables"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import { useColorMappingList } from "@/readybc/composables/use/useColorMappingList"

export function useResiliencePlanStatusDetail() {
  const isLoading = ref(false)
  const isUpdateLoading = ref(false)

  const status = ref({
    review_date_editable: true,
    due_date_editable: true,
  })

  const router = useRoute()

  const rules = {
    status: {
      name: { required },
      description: {},
      review_date_editable: {},
      due_date_editable: {},
    },
  }

  const { getBgColorName, getTextColorName } = useColorMappingList()

  const v$ = useVuelidate(rules, { status })

  const { uid } = router.params

  onMounted(async () => {
    await getResilienceStatus(uid)
  })

  /**
   *
   * @returns
   */
  const getResilienceStatus = async (uid) => {
    try {
      isLoading.value = true
      const { data } = await api.fetchResiliencePlanStatus(uid)

      if (data) {
        status.value = data

        status.value.bg = {
          tag_color: data?.bg_color,
          tag_text_color: data?.text_color,
          name: getBgColorName(data?.bg_color),
        }

        status.value.text = {
          tag_color: data?.bg_color,
          tag_text_color: data?.text_color,
          name: getTextColorName(data?.text_color),
        }
      }

      isLoading.value = false

      return data
    } catch (error) {
      goto("ViewResiliencePlanStatusList")
      return false
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateResiliencePlanStatus = async (payload) => {
    v$.value.$touch()

    if (v$.value.$invalid) {
      return
    }

    try {
      const { data } = await api.updateResiliencePlanStatus(uid, payload)
      status.value = data || {}

      goto("ViewResiliencePlanStatusList")
      return data
    } catch (error) {
      goto("ViewResiliencePlanStatusList")
      return false
    }
  }

  /**
   *
   * @returns
   */
  const deleteResilienceStatus = async (uid) => {
    try {
      const { data } = await api.deleteResiliencePlanStatus(uid)
      status.value = data || {}

      return data
    } catch (error) {
      goto("ViewResiliencePlanStatusList")
      return false
    }
  }

  return {
    isLoading,
    status,
    v$,
    getResilienceStatus,
    deleteResilienceStatus,
    updateResiliencePlanStatus,
    isUpdateLoading,
  }
}
