import { useGlobalStore } from "@/stores/global"
import * as api from "../api/BUFetcher"
import { ref } from "vue"

export function useBusinessUnitList() {
  const globalStore = useGlobalStore()
  const bu = ref([])

  /**
   *
   * @returns
   */
  async function getBusinessUnitList(ignoreScope = false) {
    try {
      const data = await api.fetchBUList(ignoreScope)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.listBU"))
    }
  }

  return {
    getBusinessUnitList,
    bu,
  }
}
