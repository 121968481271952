<template>
  <div class="wrapper" v-click-outside="handleCloseActionMenu">
    <button
      type="button"
      @click="
        () => {
          isMenuOpen = true
        }
      "
    >
      <Icon icon="spinner" v-if="isLoading" :class="variantCls.spinner.icon" />
      <Icon icon="action" v-else class="w-4" />
    </button>
    <!-- Dropdown menu-->
    <div
      v-show="isMenuOpen"
      ref="dropdownRef"
      :class="variantCls.actionMenu.wrapper"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
    >
      <div v-if="menuItems?.length > 0" class="py-1" role="none">
        <button
          v-for="(menuItem, menuIdx) in menuItems"
          :key="menuIdx"
          :class="[variantCls.actionMenu.item, generateMenuClassFromName(menuItem)]"
          role="menu-item"
          tabindex="-1"
          :id="'menu-item-' + menuIdx"
          @click="handleClickPropsMenuItem(menuItem, item)"
        >
          <span class="mr-1"> <Icon :icon="menuItem.icon" :class="menuItem?.iconClass" /></span>
          {{ menuItem?.label || "N/a" }}
        </button>
      </div>
      <!-- Default menu item -->
      <div v-else class="py-1" role="none">
        <!-- Edit menu -->
        <button
          v-if="defaultMenuItemVisible.edit ?? false"
          :class="variantCls.actionMenu.item"
          role="menuitem"
          tabindex="-1"
          id="menu-item-0"
          @click="handleClickEdit"
        >
          <span class="mr-1"> <Icon icon="edit" width="14" /></span>
          Edit
        </button>

        <!-- Delete menu -->
        <button
          v-if="defaultMenuItemVisible.delete ?? false"
          :class="variantCls.actionMenu.item"
          role="menuitem"
          tabindex="-1"
          id="menu-item-1"
          @click="handleClickDelete"
        >
          <span :class="variantCls.actionMenu.icon"> <Icon icon="delete" width="14" /></span>
          Delete
        </button>
      </div>
    </div>
  </div>
  <!-- End menu-->
</template>

<script>
import Icon from "../Icon"

export default {
  name: "ActionMenu",
  $emit: ["update:modelValue", "onEdit", "onDelete", "onMenuClick"],
  props: {
    variantCls: {
      type: Object,
      default: () => {},
    },

    modelValue: {
      type: Boolean,
      default: false,
    },

    item: {
      type: Object,
      default: () => {},
    },

    menuItems: {
      type: Array,
      default: () => [],
    },

    defaultMenuItemVisible: {
      type: Object,
      default: () => {
        return {
          edit: true,
          delete: true,
        }
      },
    },
  },

  data() {
    return {
      isMenuOpen: false,
      isLoading: false,
    }
  },
  methods: {
    handleCloseActionMenu() {
      this.isMenuOpen = false
    },

    handleClickEdit() {
      this.$emit("onEdit")
    },

    handleClickDelete() {
      this.$emit("onDelete")
    },

    /**
     *
     * @param {*} menuItem
     */
    async handleClickPropsMenuItem(menuItem, item) {
      if (menuItem?.callback) {
        try {
          this.isLoading = true
          this.isMenuOpen = false

          await menuItem.callback(item)

          this.isLoading = false

          this.$emit("onMenuClick", item)
        } catch (err) {
          this.isLoading = false
          console.log(err)
        }
      }
    },

    /**
     *
     * @param {*} item
     */
    generateMenuClassFromName(item) {
      const regex = /\./gi

      return item?.label?.replaceAll(regex, "-")?.toLowerCase()
    },
  },

  components: { Icon },
}
</script>
