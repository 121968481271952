import dayjs from "dayjs"

/** Format data */
const formatDate = (value, dateFormat) => {
  if (!value) return "-"
  // If dayjs || Date object
  if (dateFormat) {
    const dayObj = dayjs(value)
    if (dayObj.$d.toString() !== "Invalid Date") {
      return dayObj.format(dateFormat)
    }
  }
  return value
}

export { formatDate }
