import { fetchResiliencePlanSummary } from "@/readybc/composables/api/apiDashboard"
import { defineStore } from "pinia"
import { ref } from "vue"

const useDashboardResiliencePlanStore = defineStore("useDashboardResiliencePlanStore", () => {
  const isResiliencePlanSummaryLoading = ref(false)

  const resiliencePlanSummary = ref([])

  const getResiliencePlanSummary = () => {
    isResiliencePlanSummaryLoading.value = true
    fetchResiliencePlanSummary()
      .then(({ data }) => {
        resiliencePlanSummary.value = data || []

        isResiliencePlanSummaryLoading.value = false
      })
      .catch(() => {
        isResiliencePlanSummaryLoading.value = false
      })
  }

  return {
    isResiliencePlanSummaryLoading,
    resiliencePlanSummary,

    getResiliencePlanSummary,
  }
})

export default useDashboardResiliencePlanStore
