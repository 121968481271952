import fetcher from "@/modules/core/api/fetcher"

const fetchMe = async function () {
  return fetcher.get("identity/api/v1/profile")
}

/**
 * User organizations
 */

const fetchMyOrganizations = async function () {
  return fetcher.get("/identity/api/v1/my-organizations")
}

export default { fetchMe, fetchMyOrganizations }
