<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("setting.general.labels.edit") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("setting.general.labels.detail") }}</EcText>

          <!--  name -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.name"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.name')"
              componentName="EcInputText"
              :validator="v$"
              field="form.name"
              @input="v$.form.name.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Desc -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.description"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.description')"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="form.description"
              @input="v$.form.description.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Color picker -->
          <EcBox class="mt-4">
            <RFormInput
              class="w-full sm:w-4/12 sm:pr-6"
              :label="$t('setting.general.labels.bgColor')"
              v-model="form.bg"
              componentName="EcMultiSelect"
              :options="colorList"
              :isSingleSelect="true"
              :valueKey="'tag_color'"
              :allowSelectNothing="false"
            />
          </EcBox>

          <EcBox class="mt-4">
            <RFormInput
              class="w-full sm:w-4/12 sm:pr-6"
              :label="$t('setting.general.labels.textColor')"
              v-model="form.text"
              componentName="EcMultiSelect"
              :options="textList"
              :isSingleSelect="true"
              :valueKey="'tag_text_color'"
              :allowSelectNothing="false"
            />
          </EcBox>

          <!-- isDefault -->
          <RFormInput
            class="w-6/12 mt-4"
            :label="$t('setting.general.labels.isDefault')"
            v-model="form.is_default"
            componentName="EcCheckbox"
          />

          <!-- Preview -->
          <EcBox class="mt-4">
            <EcLabel class="text-base font-medium"> {{ $t("setting.general.labels.preview") }}</EcLabel>
            <EcText
              variant="square-pill-wrapper"
              class="grid items-center h-8 w-32"
              :class="[form?.bg?.tag_color, form?.text?.tag_text_color]"
            >
              {{ $t("setting.general.labels.lorem") }}</EcText
            >
          </EcBox>
          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleClickCancel">
              {{ $t("activity.buttons.back") }}
            </EcButton>

            <EcButton variant="primary" class="ml-4" @click="handleClickConfirm">
              {{ $t("activity.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete  -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("setting.general.buttons.delete") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal">
            {{ $t("setting.general.labels.noteDelete") }}
          </EcText>
          <EcButton class="mx-5" variant="warning" iconPrefix="Trash" @click="handleOpenDeleteModal">
            {{ $t("setting.general.buttons.delete") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete  -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-2xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("setting.confirmToDelete") }}
            </EcHeadline>
            <!--  Name -->
            <EcText class="text-lg font-bold">
              {{ form.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("setting.general.labels.confirmDelete") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
            <EcButton class="ml-3" variant="warning" @click="handleDeleteSupplierStatus">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useSupplierStatusDetail } from "@/modules/setting/use/master/supplier/useSupplierStatusDetail"
import { useColorMappingList } from "@/readybc/composables/use/useColorMappingList"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewSupplierStatusDetail",
  data() {
    return {
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      uid: "",
    }
  },
  mounted() {
    const { id } = this.$route.params
    this.id = id

    this.fetchDetail()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { form, v$, getSupplierStatus, updateSupplierStatus, deleteSupplierStatus } = useSupplierStatusDetail()
    const { colorList, textList, getBgColorName, getTextColorName } = useColorMappingList()
    return {
      getSupplierStatus,
      updateSupplierStatus,
      deleteSupplierStatus,
      getBgColorName,
      getTextColorName,
      form,
      v$,
      globalStore,
      colorList,
      textList,
    }
  },
  computed: {},
  methods: {
    // =========== Role ================ //

    /**
     * Update
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      const { id } = this.$route.params

      this.isUpdateLoading = true

      this.form.bg_color = this.form?.bg?.tag_color
      this.form.text_color = this.form?.text?.tag_text_color

      const response = await this.updateSupplierStatus(this.form, id)
      this.isUpdateLoading = false
      if (response) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Back to status list
     */
    handleUpdateSuccess() {
      goto("ViewSupplierStatusList")
    },

    /**
     * Cancel update status
     */
    handleClickCancel() {
      goto("ViewSupplierStatusList")
    },

    /**
     * Open delete status modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete status modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete status
     */
    async handleDeleteSupplierStatus() {
      this.isDeleteLoading = true
      const { id } = this.$route.params

      const isDeleted = await this.deleteSupplierStatus(id)

      if (isDeleted) {
        setTimeout(goto("ViewSupplierStatusList"), 1000)
      }

      this.isDeleteLoading = false
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch status
     */
    async fetchDetail() {
      this.isLoading = true
      const response = await this.getSupplierStatus(this.id)
      if (response) {
        this.form = response
        this.form.is_default = Boolean(this.form.is_default)
        this.form.bg = {
          tag_color: response?.bg_color,
          tag_text_color: response?.text_color,
          name: this.getBgColorName(response?.bg_color),
        }

        this.form.text = {
          tag_color: response?.bg_color,
          tag_text_color: response?.text_color,
          name: this.getTextColorName(response?.text_color),
        }
      }
      this.isLoading = false
    },
  },
}
</script>
