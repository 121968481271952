<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("resiliencePlan.title.newResiliencePlan") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("resiliencePlan.title.resiliencePlanDetail") }}</EcText>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="resiliencePlan.name"
            componentName="EcInputText"
            :label="$t('resiliencePlan.labels.name')"
            :validator="vldator$"
            field="resiliencePlan.name"
            @input="vldator$.resiliencePlan.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- category -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.category"
            componentName="EcMultiSelect"
            :label="$t('resiliencePlan.labels.category')"
            :valueKey="'uid'"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="categories"
            :validator="vldator$"
            field="resiliencePlan.category"
            @change="vldator$.resiliencePlan.category.$touch()"
          />
        </EcBox>
        <EcSpinner class="ml-2 mt-5" v-if="isLoadingCategories" />
      </EcFlex>

      <!-- Status -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.status"
            componentName="EcMultiSelect"
            :label="$t('resiliencePlan.labels.status')"
            :valueKey="'id'"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="statuses"
            :validator="vldator$"
            field="resiliencePlan.status"
            @change="vldator$.resiliencePlan.status.$touch()"
          />
        </EcBox>
        <EcSpinner class="ml-2 mt-5" v-if="isLoadingStatus" />
      </EcFlex>

      <!-- Due Date -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.review_date"
            componentName="EcDatePicker"
            :label="$t('resiliencePlan.labels.reviewDate')"
            :allowSelectNothing="false"
            :validator="vldator$"
            field="resiliencePlan.review_date"
            @change="vldator$.resiliencePlan.review_date.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Due Date -->
      <EcFlex class="flex-wrap max-w-md items-center mb-8">
        <EcBox class="w-full sm:w-6/12">
          <!-- select box -->
          <RFormInput
            v-model="resiliencePlan.due_date"
            componentName="EcDatePicker"
            :label="$t('resiliencePlan.labels.dueDate')"
            :allowSelectNothing="false"
            :validator="vldator$"
            field="resiliencePlan.due_date"
            @change="vldator$.resiliencePlan.due_date.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Reports -->
      <EcFlex class="flex-wrap items-center mb-8">
        <EcBox class="w-full">
          <!-- Title and upload button -->
          <EcFlex class="items-center">
            <EcLabel>{{ $t("resiliencePlan.plans.reports") }}</EcLabel>
            <EcButton
              variant="transparent"
              class="ml-4 text-c1-800"
              v-tooltip="{ text: 'Upload Resilience Plan' }"
              @click="handleOpenFileUploadModal"
            >
              <EcIcon icon="CloudUpload" />
            </EcButton>
          </EcFlex>

          <!-- Report row -->
          <RFileSlider class="mt-4" :files="resiliencePlan.reports" @fileDeleted="handleRemoveUploadedFile"></RFileSlider>
        </EcBox>

        <!-- End -->
      </EcFlex>

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" @click="handleClickCancel">
          {{ $t("resiliencePlan.buttons.cancel") }}
        </EcButton>

        <EcButton v-permissions:resilience_plan-create variant="primary" class="ml-4" @click="handleClickConfirm">
          {{ $t("resiliencePlan.buttons.confirm") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->
  </RLayout>

  <teleport to="#layer1">
    <ModalUploadResiliencePlanFile
      :isModalUploadFileOpen="isModalUploadFileOpen"
      @handleCloseUploadModal="handleCloseUploadModal"
      @handleUploadCallback="handleUploadCallback"
      ref="modalUploadRef"
    />
  </teleport>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useResiliencePlanNew } from "@/modules/resilience_plan/use/plan/useResiliencePlanNew"
import { useResiliencePlanDetail } from "@/modules/resilience_plan/use/plan/useResiliencePlanDetail"
import { useResiliencePlanCategoryListAll } from "@/modules/resilience_plan/use/category/useResiliencePlanCategoryListAll"
import { useResiliencePlanStatusListAll } from "@/modules/resilience_plan/use/status/useResiliencePlanStatusListAll"
import { useGlobalStore } from "@/stores/global"
import ModalUploadResiliencePlanFile from "@/modules/resilience_plan/components/ModalUploadResiliencePlanFile.vue"
import { ref } from "vue"

export default {
  name: "ViewResiliencePlanNew",
  props: {
    cuid: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      reportFilesDeleting: [],
      isModalUploadFileOpen: false,
      isLoading: false,
    }
  },

  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { resiliencePlan, vldator$, createNewResiliencePlan } = useResiliencePlanNew()
    const { removeReportFile } = useResiliencePlanDetail()
    const { statuses, isLoadingStatus } = useResiliencePlanStatusListAll()
    const { categories, isLoading: isLoadingCategories } = useResiliencePlanCategoryListAll()

    const modalUploadRef = ref()

    return {
      createNewResiliencePlan,
      resiliencePlan,
      vldator$,
      globalStore,

      statuses,
      isLoadingStatus,

      categories,
      isLoadingCategories,

      modalUploadRef,
      removeReportFile,
    }
  },
  computed: {},

  methods: {
    /**
     * Create resource
     *
     */
    async handleClickConfirm() {
      this.vldator$.$touch()

      if (this.vldator$.resiliencePlan.$invalid) {
        return
      }
      this.isLoading = true
      this.resiliencePlan.status_id = this.resiliencePlan.status?.id
      const response = await this.createNewResiliencePlan(this.resiliencePlan)
      this.isLoading = false
      if (response) {
        goto("ViewResiliencePlanList")
      }
    },
    /**
     * Cancel add new resource
     */
    handleClickCancel() {
      goto("ViewResiliencePlanList")
    },

    /**
     * Handle upload file
     */
    handleOpenFileUploadModal() {
      this.isModalUploadFileOpen = true
    },

    /**
     * Close upload modal
     */
    handleCloseUploadModal() {
      this.isModalUploadFileOpen = false
    },

    /**
     *
     * @param {*} url
     */
    async handleRemoveUploadedFile(uid) {
      this.resiliencePlan.reports.forEach((item, idx) => {
        if (item?.uid === uid) {
          this.resiliencePlan.reports.splice(idx, 1)
        }
      })
    },

    // Upload file callback
    handleUploadCallback(files) {
      files.forEach((file) => {
        if (this.isFileAddedToReports(file)) {
          return
        }
        this.resiliencePlan.reports.push({
          uid: file?.response?.uid,
          name: file?.response?.name,
          url: file?.response?.url,
          size: file?.response?.size,
          mime_type: file?.response?.mime_type,
        })
      })
    },

    isFileAddedToReports(file) {
      return this.resiliencePlan.reports?.find((item) => {
        return file?.response?.uid === item?.uid
      })
    },
  },

  watch: {
    categories(data) {
      const selectedCategory = data?.filter((cat) => cat?.uid === this.cuid)

      this.resiliencePlan.category = selectedCategory?.length > 0 ? selectedCategory[0] : null
    },
  },
  components: { ModalUploadResiliencePlanFile },
}
</script>
