<template>
  <EcFlex class="min-h-screen" id="org-login-wrapper">
    <EcBox class="w-0 md:w-2/6"></EcBox>
    <EcBox class="w-full md:w-2/6 mt-10 mb-10 rounded-md p-6 bg-cWhite">
      <EcFlex class="flex-col items-center w-full">
        <EcHeadline variant="h2" as="h2" :class="variantCls.title" class="mt-10">
          <EcFlex class="items-center align-middle">
            <EcBox>{{ $t("auth.loginTitle") }} </EcBox> &nbsp;
            <EcBox :class="variantCls.readyTitle">{{ $t("auth.ready") }}</EcBox>
            <EcBox :class="variantCls.bcTitle">{{ $t("auth.bc") }}</EcBox>
          </EcFlex>
        </EcHeadline>
        <EcBox>Organisations associated with your account</EcBox>
      </EcFlex>

      <EcBox class="w-full mt-6">
        <EcFlex v-if="isLoading" class="w-full items-center justify-center">
          <EcSpinner />
        </EcFlex>
        <EcBox v-else class="wrapper" v-for="(org, idx) in organizations" :key="idx">
          <EcNavLink :to="`https://${org.domain}`" externalLinkTarget="_self">
            <EcFlex class="relative hover:shadow hover:bg-c0-100 p-4 bg-cWhite round items-center hover:cursor-pointer">
              <!-- Logo -->
              <EcBox class="w-20 h-auto mr-2">
                <img :src="org.logo" class="h-12 object-contain" />
              </EcBox>
              <!-- Name -->
              <EcBox>
                <EcLabel class="">{{ org.name }}</EcLabel>
                <EcLabel class="text-sm text-c0-500">{{ org.domain }}</EcLabel>
              </EcBox>

              <!-- Button -->
              <EcButton variant="transparent-rounded" class="absolute right-4">
                <EcIcon class="text-c0-500" icon="ArrowRight" width="20" height="20"
              /></EcButton>
            </EcFlex>
          </EcNavLink>
        </EcBox>

        <!-- Logout -->
        <EcBox class="text-right mt-6">
          <EcLabel @click="handleClickLogout" class="hover:cursor-pointer text-c1-800">Log out</EcLabel>
        </EcBox>
      </EcBox>
    </EcBox>
  </EcFlex>
</template>

<script>
import { goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import isEmpty from "lodash.isempty"
import { useOrganizationLogin } from "../use/useOrganizationLogin"
import { isMtOrganizations } from "@/readybc/composables/helpers/helpers"

export default {
  name: "ViewOrganizationLogin",
  inject: ["getComponentVariants"],
  components: {},
  data() {
    return {
      isLoading: false,
    }
  },

  setup() {
    const globalStore = useGlobalStore()
    const { organizations, getMyOrgniaztions } = useOrganizationLogin()

    return {
      globalStore,
      organizations,
      getMyOrgniaztions,
    }
  },
  beforeMount() {
    if (isMtOrganizations() === undefined || !isMtOrganizations()) {
      goto("ViewLogin")
    }
  },

  mounted() {
    this.fetchMyOrganizations()
  },
  computed: {
    tenantId() {
      return this.globalStore.getTenantId
    },
    clientId() {
      return this.globalStore.getClientId
    },

    variants() {
      return (
        this.getComponentVariants({
          componentName: "ViewLogin",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },
  },
  methods: {
    async handleClickLogout() {
      this.globalStore.logout()
    },

    /**
     * Get organization list
     */
    async fetchMyOrganizations() {
      this.isLoading = true
      const res = await this.getMyOrgniaztions()

      this.organizations = res.data

      this.isLoading = false
      if (isEmpty(this.organizations)) {
        this.globalStore.logout()
      }
    },
  },
}
</script>
<style>
#org-login-wrapper {
  background: rgb(2, 0, 36);
  background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(9, 21, 121, 1) 0%, rgba(0, 212, 255, 1) 100%);
}
</style>
