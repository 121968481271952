<template>
  <div></div>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import dayjs from "dayjs"
import { useRefreshToken } from "@/modules/auth/use/useRefreshToken"

export default {
  name: "RRefreshToken",
  data() {
    return {
      intervalCheckExpireToken: null,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { submitRefreshToken } = useRefreshToken()
    return {
      globalStore,
      submitRefreshToken,
    }
  },
  mounted() {
    this.intervalCheckExpireToken = setInterval(() => this.checkExpireToken(), 60 * 1000)
  },
  unmounted() {
    clearInterval(this.intervalCheckExpireToken)
  },
  methods: {
    checkExpireToken() {
      if (window.$cookies.get(process.env.VUE_APP_TOKEN_EXPIRES) && !this.globalStore.isOnPublicRoute()) {
        const expireAt = dayjs(window.$cookies.get(process.env.VUE_APP_TOKEN_EXPIRES))
        const now = dayjs()

        // Refresh token when have only 5 min left
        if (expireAt.diff(now, "minute") < 5) {
          this.submitRefreshToken(true)
        }
      }
    },
  },
}
</script>
