<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <!-- Title -->
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("setting.general.matterData") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <GeneralSubMenu />

    <EcBox v-for="group in masterDataGroups" :key="group.id">
      <!-- Group label -->
      <EcLabel>{{ group.name }}</EcLabel>

      <!-- Group modules -->
      <EcFlex class="grid grid-cols-2 gap-4 items-center mt-4 mb-10">
        <EcButton
          v-for="module in group?.modules"
          :key="module.id"
          class="flex items-center text-c1-800 px-5 py-3 p-3 bg-cWhite rounded text-base hover:shadow"
          variant="wrapper"
          :iconPrefix="module.icon || 'menu'"
          @click="handleMasterItemDataClick(module.view)"
        >
          {{ $t(module.name) }}
        </EcButton>
      </EcFlex>
      <!-- End group modules -->
    </EcBox>
  </RLayout>
</template>
<script>
import GeneralSubMenu from "./GeneralSubMenu.vue"
import { goto } from "@/modules/core/composables"
import EcFlex from "@/components/EcFlex/index"
export default {
  name: "ViewMasterSetting",
  components: { EcFlex, GeneralSubMenu },
  setup() {
    const activityGroup = [
      {
        id: "rto",
        name: "setting.general.masterDataModules.rto",
        icon: "Activity",
        view: "ViewRTOList",
      },
      {
        id: "mtdp",
        name: "setting.general.masterDataModules.mtdp",
        icon: "Activity",
        view: "ViewMTDPList",
      },
      // Temporarily hidden because of RBC-587
      // {
      //   id: "equipment",
      //   name: "setting.general.masterDataModules.equipment",
      //   icon: "Activity",
      //   view: "ViewActivityEquipmentList",
      // },
      {
        id: "activityRemoteFactor",
        name: "setting.general.masterDataModules.remoteAccess",
        icon: "Activity",
        view: "ViewRemoteAccessList",
      },
      {
        id: "activityUtility",
        name: "setting.general.masterDataModules.utilities",
        icon: "Activity",
        view: "ViewUtilitiesList",
      },
    ]

    const biaGroup = [
      {
        id: "biaStatus",
        name: "setting.general.masterDataModules.biaStatus",
        icon: "Assessment",
        view: "ViewBIAStatusList",
      },
    ]

    const supplierGroup = [
      {
        id: "supplierStatus",
        name: "setting.general.masterDataModules.supplierStatus",
        icon: "Supplier",
        view: "ViewSupplierStatusList",
      },
    ]

    const bcpGroup = [
      {
        id: "biaStatus",
        name: "setting.general.masterDataModules.bcpStatus",
        icon: "BCP",
        view: "ViewBCPStatusList",
      },
    ]

    const resilienceGroup = [
      {
        id: "resiliticeneCategory",
        name: "setting.general.masterDataModules.resiliencePlanCategories",
        icon: "TBC",
        view: "ViewResiliencePlanCategoryList",
      },
      {
        id: "resiliticeneStatus",
        name: "setting.general.masterDataModules.resiliencePlanStatuses",
        icon: "TBC",
        view: "ViewResiliencePlanStatusList",
      },
    ]

    const locationGroup = [
      {
        id: "locations",
        name: "setting.general.masterDataModules.locations",
        icon: "LocationMarker",
        view: "ViewLocationList",
      },
    ]

    const masterDataGroups = [
      {
        id: "activity",
        name: "Activity",
        modules: activityGroup,
      },

      {
        id: "bia",
        name: "Business Impact Assessment",
        modules: biaGroup,
      },
      {
        id: "bcp",
        name: "Business Continuity Plan",
        modules: bcpGroup,
      },
      {
        id: "resiliencePlan",
        name: "Resilience Plans",
        modules: resilienceGroup,
      },
      {
        id: "supplier",
        name: "Supplier",
        modules: supplierGroup,
      },
      {
        id: "location",
        name: "Location",
        modules: locationGroup,
      },
    ]
    return {
      masterDataGroups,
    }
  },
  methods: {
    handleMasterItemDataClick(view) {
      goto(view)
    },
  },
}
</script>
