<template>
  <!-- Modal Delete -->
  <EcModalSimple
    :isVisible="isModalDeleteEquipmentOpen"
    variant="center-2xl"
    id="modal-delete-equipment"
    @overlay-click="handleCloseDeleteModal"
  >
    <EcBox class="text-center">
      <EcFlex class="justify-center">
        <EcIcon class="text-cError-500" width="3rem" height="3rem" icon="TrashAlt" />
      </EcFlex>

      <!-- Messages -->
      <EcBox>
        <EcHeadline as="h3" variant="h3" class="text-cError-500 text-4xl mb-3">
          {{ $t("equipment.labels.confirmToDelete") }}
        </EcHeadline>
        <!-- Org name -->
        <EcText class="text-lg font-bold">
          {{ equipmentName }}
        </EcText>
        <EcText class="text-c0-500 mt-4">
          {{ $t("equipment.labels.confirmDeleteQuestion") }}
        </EcText>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
        <EcButton variant="warning" id="btn-delete-equipment" @click="handleDeleteEquipment">
          {{ $t("equipment.buttons.delete") }}
        </EcButton>
        <EcButton class="ml-3" variant="tertiary-outline" id="btn-cancel-delete" @click="handleCloseDeleteModal">
          {{ $t("equipment.buttons.cancel") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useEquipmentDetail } from "@/modules/equipment/use/equipment/useEquipmentDetail"

export default {
  name: "ModalDeleteEquipment",

  emits: ["handleCloseDeleteModal", "handleDeleteCallback"],
  data() {
    return {
      isDeleteLoading: false,
    }
  },
  props: {
    isModalDeleteEquipmentOpen: {
      type: Boolean,
      default: false,
    },

    equipmentUid: {
      type: String,
      default: null,
    },

    equipmentName: {
      type: String,
      default: "",
    },
  },

  mounted() {},
  setup() {
    const { form, v$, deleteEquipment } = useEquipmentDetail()
    return {
      form,
      v$,
      deleteEquipment,
    }
  },
  methods: {
    /**
     * Cancel add new equipment
     */
    async handleDeleteEquipment() {
      const { uid } = this.$route.params

      if (!uid && !this.equipmentUid) {
        goto("ViewEquipmentList")

        return
      }

      this.isDeleteLoading = true
      const isDeleted = await this.deleteEquipment(uid ?? this.equipmentUid)

      if (isDeleted) {
        this.handleCloseDeleteModal()
        this.handleDeleteCallback()
      }
      this.isDeleteLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseDeleteModal() {
      this.$emit("handleCloseDeleteModal")
    },

    /**
     * Delete callback
     */
    handleDeleteCallback() {
      this.$emit("handleDeleteCallback")
    },
  },
}
</script>
