import apiMe from "@/readybc/composables/api/apiMe"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"

export const useOrganizationLogin = () => {
  const { t } = useI18n()
  const globalStore = useGlobalStore()
  const organizations = ref([])

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getMyOrgniaztions = async () => {
    try {
      return apiMe.fetchMyOrganizations()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("auth.errors.fetchOrg"))

      return false
    }
  }

  return {
    organizations,
    getMyOrgniaztions,
  }
}
