import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 * Get categories with pagination
 * @param {*} params
 * @returns
 */
const fetchResiliencePlanCategories = async function (params) {
  return fetcher.get("/identity/api/v1/resilience-plan-categories", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 * Get all categories
 * @returns
 */
const fetchAllResiliencePlanCategories = async function () {
  return fetcher.get("/identity/api/v1/resilience-plan-categories/all")
}

/**
 * Get a category
 * @returns
 */
const fetchResiliencePlanCategory = async function (uid) {
  return fetcher.get(`/identity/api/v1/resilience-plan-categories/${uid}`)
}

/**
 * Create a new category
 * @returns
 */
const createResiliencePlanCategory = async function (payload) {
  return fetcher.post(`/identity/api/v1/resilience-plan-categories`, payload)
}

/**
 * Get all categories
 * @returns
 */
const updateResiliencePlanCategory = async function (uid, payload) {
  return fetcher.put(`/identity/api/v1/resilience-plan-categories/${uid}`, payload)
}

/**
 * Get a category
 * @returns
 */
const deleteResiliencePlanCategory = async function (uid) {
  return fetcher.delete(`/identity/api/v1/resilience-plan-categories/${uid}`)
}

export {
  fetchResiliencePlanCategories,
  fetchAllResiliencePlanCategories,
  fetchResiliencePlanCategory,
  createResiliencePlanCategory,
  updateResiliencePlanCategory,
  deleteResiliencePlanCategory,
}
