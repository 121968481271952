<template>
  <!-- BIA  -->
  <EcBox class="w-full md:w-12/12 mt-6 p-2 rounded-md border border-c1-100">
    <EcLabel class="font-medium text-base mt-2 mb-2">{{ $t("dashboard.bia.departmentStatusTitle") }}</EcLabel>

    <SummaryRowDivisionSkeleton :rows="2" :loading="isBIAStatisticLoading">
      <EcLabel v-if="isNoBIA" class="text-base italic">{{ $t("dashboard.bia.noBIA") }}</EcLabel>
      <EcFlex v-else class="flex-col md:flex-row">
        <!-- Chart -->
        <EcBox class="w-full md:w-4/12">
          <ChartItem :chartInfo="biaStatusPieChart" boxVariant="" />
        </EcBox>
        <!-- List -->
        <EcBox class="w-full md:w-8/12 grid items-center">
          <!-- Division and BU progresses -->
          <EcBox v-for="division in divisionBIAByStatusData" :key="division.uid" class="mb-3">
            <!-- Division -->
            <EcFlex>
              <EcLabel class="text-sm w-3/12 font-medium"> {{ division?.name }} </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="division.biaByStatus || []" valueKey="cnt" labelKey="name" />
              </EcFlex>
            </EcFlex>

            <!-- BU -->
            <EcBox v-for="bu in division?.businessUnits" :key="bu.uid" class="mt-2">
              <EcFlex class="mt-2">
                <EcLabel class="text-sm w-3/12 pl-4 italic">
                  {{ bu?.name }}
                </EcLabel>
                <EcFlex class="w-9/12">
                  <EcColorProgressBar :data="bu?.biaByStatus || []" valueKey="cnt" labelKey="name" />
                </EcFlex>
              </EcFlex>
            </EcBox>
          </EcBox>

          <!-- Dependent BU -->
          <EcLabel v-if="businessUnitWithouDivisionBIAByStatusData?.length > 0" class="text-sm font-medium mt-2">{{
            $t("dashboard.activity.denepdentBU")
          }}</EcLabel>
          <EcBox v-for="bu in businessUnitWithouDivisionBIAByStatusData" :key="bu.uid" class="mt-2">
            <EcFlex class="mt-2">
              <EcLabel class="text-sm w-3/12 italic">
                {{ bu?.name }}
              </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="bu?.biaByStatus || []" valueKey="cnt" labelKey="name" />
              </EcFlex>
            </EcFlex>
          </EcBox>

          <!-- End -->
        </EcBox>
      </EcFlex>
    </SummaryRowDivisionSkeleton>
  </EcBox>
</template>

<script>
import { ref } from "vue"
import SummaryRowDivisionSkeleton from "./SummaryRowDivisionSkeleton.vue"
import ChartItem from "../statistic/ChartItem.vue"
import { colorMapping } from "../../use/useColorMapping"
import useDashboardBIAStore from "../../stores/useDashboardBIAStore"
import { storeToRefs } from "pinia"

export default {
  name: "DashboardSummaryDepartmentBIA",

  data() {
    return {
      bias: ref([]),
    }
  },
  setup() {
    const dashboardBIAStore = useDashboardBIAStore()

    const { isBIAStatisticLoading, biaStatistic } = storeToRefs(dashboardBIAStore)
    return {
      isBIAStatisticLoading,
      biaStatistic,
      dashboardBIAStore,
    }
  },
  mounted() {
    this.dashboardBIAStore.getBIAStatistic()
  },
  computed: {
    isNoBIA() {
      return (
        this.biaStatistic?.biaByStatus?.length <= 0 &&
        this.biaStatistic?.biaByDivisions?.length <= 0 &&
        this.biaStatistic?.biaByBusinessUnits?.length <= 0
      )
    },

    /**
     *
     */
    biaStatusPieChart() {
      const labels = []
      const data = []
      const bgColors = []

      this.biaStatistic?.biaByStatus?.forEach((item) => {
        // Labels
        labels.push(item?.name)

        // Data
        data.push({
          label: item?.name,
          value: item?.cnt,
        })

        bgColors.push(colorMapping[item?.bg_color])
      })

      return {
        id: "activitiesByStatus",
        title: "Program Status",
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: bgColors,
            },
          ],
        },
      }
    },

    /**
     * Division BIA By Status
     */
    divisionBIAByStatusData() {
      const biaStatistic = structuredClone(this.biaStatistic)
      return biaStatistic?.biaByDivisions?.map((item) => {
        // Division statuses
        item.biaByStatus = this.mappingColor(item?.biaByStatus)

        // Business Unit status
        item?.businessUnits?.map((bu) => {
          bu.biaByStatus = this.mappingColor(bu?.biaByStatus)

          return bu
        })

        return item
      })
    },

    /**
     * BU Unit without Division
     */
    businessUnitWithouDivisionBIAByStatusData() {
      const biaStatistic = structuredClone(this.biaStatistic)
      return biaStatistic?.biaByBusinessUnits?.map((bu) => {
        // Division statuses
        bu.biaByStatus = this.mappingColor(bu?.biaByStatus)

        return bu
      })
    },
  },
  methods: {
    /**
     *
     * @param {*} idx
     * @param {*} bia
     */
    handleShowContextMenu(idx, bia) {
      bia.statusObj = this.getStatus(bia)
      this.$refs.contextMenu.bia = bia
      this.$refs.contextMenu.position = ++idx
      this.$refs.contextMenu.isOpen = true
    },

    /**
     *
     * @param {*} bia
     */
    getStatus(bia) {
      return (
        this.statuses.find((item) => {
          return item.value === bia?.status
        }) || { name: "Unknown" }
      )
    },

    /**
     *
     * @param {*} listItems
     */
    mappingColor(listItems) {
      return listItems?.map((status) => {
        status.bgColor = colorMapping[status?.bg_color]
        status.textColor = colorMapping[status?.text_color]

        return status
      })
    },
  },
  components: { SummaryRowDivisionSkeleton, ChartItem },
}
</script>
