import * as api from "../api/applicationFetcher"
import { useGlobalStore } from "@/stores/global"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useApplicationDetail() {
  const globalStore = useGlobalStore()

  const form = ref({})

  const rules = {
    form: {
      name: { required },
      description: {},
      sla: false,
      sla_documents: [],
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getApplication = async (uid) => {
    try {
      const { data } = await api.fetchApplication(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateApplication = async (payload, uid, mess = true) => {
    try {
      const { data } = await api.updateApplication(payload, uid)
      if (mess) {
        globalStore.addSuccessToastMessage("Successfully updated!")
      }

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteApplication = async (uid) => {
    try {
      const response = await api.deleteApplication(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   * @param uid
   * @returns {Promise<undefined>}
   */
  const deleteSlaDoc = async (uid, slaDocUid) => {
    try {
      const { data } = await api.deleteApplicationSlaDoc(uid, slaDocUid)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    getApplication,
    updateApplication,
    deleteApplication,
    deleteSlaDoc,
    form,
    v$,
  }
}
