import { useGlobalStore } from "@/stores/global"
import * as api from "../api/apiDivision"
import { ref } from "vue"

export function useDivisionList() {
  const globalStore = useGlobalStore()
  const divisions = ref([])
  const isLoadingDivisions = ref(false)

  /**
   *
   * @returns
   */
  async function getAllDivisionList(params = {}) {
    try {
      const data = await api.fetchAllDivisionList(params)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.listDivision"))
    }
  }

  return {
    getAllDivisionList,
    divisions,
    isLoadingDivisions,
  }
}
