<template>
  <div>
    <!-- Confirmation dialog -->
    <div v-if="modelValue" class="fixed inset-0 flex items-center justify-center bg-c0-500 bg-opacity-50 z-10">
      <div class="bg-cWhite p-6 rounded shadow-md">
        <p class="text-base">{{ title }}</p>
        <div class="mt-4 flex justify-end">
          <button @click="handleCancelAction" class="text-base mr-2 text-c0-500 font-bold py-2 px-4 rounded">
            {{ cancelLabel }}
          </button>
          <button @click="handleConfirmAction" class="text-base text-c1-700 font-bold py-2 px-4 rounded">
            {{ confirmLabel }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["onCancel", "onConfirm"],
  props: {
    title: {
      type: String,
      default: "Are you sure you want to proceed?",
    },
    cancelLabel: {
      type: String,
      default: "Cancel",
    },
    confirmLabel: {
      type: String,
      default: "Confirm",
    },

    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showConfirmation: false,
    }
  },
  methods: {
    handleCancelAction() {
      this.$emit("update:modelValue", false)
      this.$emit("onCancel", false)
      this.showConfirmation = false
    },
    handleConfirmAction() {
      // Perform the action here
      this.$emit("update:modelValue", false)
      this.$emit("onConfirm", true)
      this.showConfirmation = false
    },
  },
}
</script>
