import useVuelidate from "@vuelidate/core"
import { email, required } from "@vuelidate/validators"
import { defineStore } from "pinia"
import { computed, ref } from "vue"
import * as api from "../api/fetcher"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"
export const useLoginStore = defineStore("login", () => {
  const { t } = useI18n()

  const CHALLENGE_CHANGE_PASSWORD = "NEW_PASSWORD_REQUIRED"
  const CHALLENGE_SOFTWARE_TOKEN_MFA = "SOFTWARE_TOKEN_MFA"

  const form = ref({
    username: "",
    password: "",
  })

  const rules = computed(() => ({
    form: {
      username: {
        required,
        email,
      },
      password: {
        required,
      },
    },
  }))

  // Validations
  const v = useVuelidate(rules, { form })

  // == ACTIONS ==
  async function login() {
    const globalStore = useGlobalStore()

    try {
      const { data } = await api.login({
        username: this.form.username,
        password: this.form.password,
      })

      if (data && data.challengeName) {
        return data
      }

      if (!data || !data.accessToken) {
        throw new Error(t("auth.errors.login"))
      }
      // await saveRefreshToken(data.refreshToken)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("auth.errors.login"))
    }
  }
  //
  // /**
  //  *
  //  * @param refreshToken
  //  * @returns {Promise<void>}
  //  */
  // async function saveRefreshToken(refreshToken) {
  //   // save to cookie
  //   localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, refreshToken)
  // }

  return {
    form,
    v,
    login,
    CHALLENGE_CHANGE_PASSWORD,
    CHALLENGE_SOFTWARE_TOKEN_MFA,
  }
})
