<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
      <EcHeadline class="w-full mb-3 mr-4 text-cBlack lg:mb-0">
        {{ $t("reportTemplates.labels.titleReportTemplateRevisionDetail") }}
      </EcHeadline>
      <EcFlex class="flex w-full">
        <EcFlex class="w-10/12">
          <EcBox v-if="reportTemplateData" class="mt-4">
            <EcHeadline as="h5" class="w-full text-xl">
              {{ reportTemplateData?.name }}
              <EcIcon
                icon="CheckCircle"
                class="text-cSuccess-500 inline-block"
                width="16"
                height="16"
                v-if="this.reportTemplateData?.is_default"
              />
            </EcHeadline>
            <EcLabel class="text-base">
              {{ this.$t("reportTemplates.labels.createdBy") }}:
              {{
                this.reportTemplateData?.user
                  ? `${this.reportTemplateData?.user?.first_name} ${this.reportTemplateData?.user?.last_name}`
                  : "N/A"
              }}
            </EcLabel>
            <EcLabel class="text-base">
              {{ this.$t("reportTemplates.labels.createdAt") }}:
              {{ formatData(this.reportTemplateData?.created_at, this.dateTimeFormat) }}
            </EcLabel>
          </EcBox>
        </EcFlex>
        <EcFlex class="w-2/12">
          <EcButton
            v-permissions:report_template-update
            v-if="!this.reportTemplateData?.is_default"
            class="mb-3 lg:mb-0 float-end h-10"
            id="btn-set-default"
            iconPrefix="report"
            variant="primary-sm"
            @click="handleOpenSetDefaultModal"
          >
            {{ $t("reportTemplates.buttons.setDefaultTemplate") }}
          </EcButton>
        </EcFlex>
      </EcFlex>
    </EcFlex>

    <EcFlex class="w-full items-center">
      <!-- Viewer -->
      <EcBox class="w-full h-full">
        <EcDocumentPreview v-if="reportTemplateData && reportTemplateDocument" :document="reportTemplateDocument" />
      </EcBox>
    </EcFlex>

    <!-- Modals -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalSetDefaultOpen" variant="center-3xl" @overlay-click="handleCloseSetDefaultModal">
        <EcBox class="text-center">
          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-xl">
              {{ $t("reportTemplates.modal.title.confirmToSetDefaultTemplate") }}
            </EcHeadline>
            <EcText class="text-c0-500 mt-4">
              {{ $t("reportTemplates.modal.cfSetDefaultTemplate") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="warning" @click="handleSetDefaultTemplate">
              {{ $t("reportTemplates.buttons.setDefaultTemplate") }}
            </EcButton>
            <EcButton class="ml-3" variant="tertiary-outline" @click="handleCloseSetDefaultModal">
              {{ $t("reportTemplates.buttons.cancel") }}
            </EcButton>
          </EcFlex>

          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
    <!-- End modal -->
  </RLayout>
</template>

<script>
import { useI18n } from "vue-i18n"
import { useReportTemplate } from "@/modules/report/use/useReportTemplate"
import { formatData, goto } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import EcHeadline from "@/components/EcHeadline/index.vue"
import EcDocumentPreview from "@/components/EcDocumentPreview/index.vue"
import EcIcon from "@/components/EcIcon/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"
import { ReportType } from "@/modules/report/constants/report_type"

export default {
  name: "ViewReportTemplateRevision",
  components: { EcFlex, EcIcon, EcHeadline, EcDocumentPreview },
  data() {
    return {
      isLoading: false,
      reportTemplateData: null,
      reportTemplateDocument: null,
      templateType: null,
      isModalSetDefaultOpen: false,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { getTemplate, setReportTemplateDefaultRevision } = useReportTemplate()
    const { t } = useI18n()

    return {
      getTemplate,
      setReportTemplateDefaultRevision,
      globalStore,
      t,
    }
  },
  beforeMount() {
    const { type, uid } = this.$route.params
    this.templateType = type
    if (this.templateType === ReportType.BIA && !this.globalStore.isLandlord) {
      goto("ViewConfigurableReportTemplates")
      return
    }
    this.fetchReportTemplateRevisionData(uid)
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  methods: {
    formatData,
    async fetchReportTemplateRevisionData(uid) {
      this.isLoading = true

      const template = await this.getTemplate(this.templateType, uid)

      if (template && template?.data) {
        this.reportTemplateData = template?.data
        this.reportTemplateDocument = template?.data?.document
      }

      this.isLoading = false
    },

    async handleSetDefaultTemplate() {
      this.isLoading = true
      this.handleCloseSetDefaultModal()
      const response = await this.setReportTemplateDefaultRevision(this.templateType, this.reportTemplateData.uid)

      if (response) {
        this.globalStore.addSuccessToastMessage(this.t("reportTemplates.messages.setTemplateDefaultSuccess"))
        await this.fetchReportTemplateRevisionData(this.reportTemplateData.uid)
      }
      this.isLoading = false
    },

    handleOpenSetDefaultModal() {
      this.isModalSetDefaultOpen = true
    },

    handleCloseSetDefaultModal() {
      this.isModalSetDefaultOpen = false
    },
  },
}
</script>
