import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 *
 * @param {*} payload
 * @returns
 */
export const createNewBIAStatus = async (payload) => {
  return fetcher.post(`identity/api/v1/bias-statuses`, payload)
}

/**
 *
 * @param {*} id
 * @returns
 */
export const fetchBIAStatus = async (id) => {
  return fetcher.get(`identity/api/v1/bias-statuses/${id}`)
}

/**
 *
 * @param {*} payload
 * @param {*} id
 * @returns
 */
export const updateBIAStatus = async (payload, id) => {
  return fetcher.put(`identity/api/v1/bias-statuses/${id}`, payload)
}

/**
 *
 * @param {*} id
 * @returns
 */
export const deleteBIAStatus = async (id) => {
  return fetcher.delete(`identity/api/v1/bias-statuses/${id}`)
}

/**
 *
 * Get list BIA
 * @returns
 */
export const fetchBIAStatusList = async (filters) => {
  return fetcher.get(`/identity/api/v1/bias-statuses`, {
    params: filters,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 *
 * Get list BIA
 * @returns
 */
export const fetchAllBIAStatusList = async () => {
  return fetcher.get(`/identity/api/v1/bias-statuses/all`)
}
