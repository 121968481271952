export default {
  notification: {
    title: {
      newTemplate: "New Notification",
      updateEvent: "Update Notification",
      templateDetail: "Notification Detail",
    },
    labels: {
      eventNotifications: "Event Notifications",
      name: "Name",
      type: "Type",
      sendTo: "Send To",
      content: "Content",
      methods: "Notify by methods",
      subject: "Subject",
      desc: "Description",
      add: "Add",
      title: "Subject",
      pinned: "Pinned",
      allUser: "All User",
      onAction: "With Action",
      onModel: "On Object",
      receivers: "Receivers",
      templates: "Templates",
      dispatchTime: "Dispatch After",
      sendAt: "Sent At",
      readAt: "Read At",

      confirmToDelete: "Confirm to Delete",
      confirmDeleteQuestion: "Are you sure to delete this Event Notification?",
      users: "Users",
      roles: "Roles",
      industries: "Industries",
      clients: "Clients",

      details: "Details",
      markAsRead: "Mark as read",
      delete: "Delete",
      emptyNotification: "You have no notifications to review",
    },

    buttons: {
      export: "Export",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      confirm: "Confirm",
      back: "Back",
      update: "Update",
      view: "View Log detail",
      details: "Details",
    },

    notes: {
      dispatchAfter: "Set a time to send the notification or leave empty to send the notification immediately.",
      templates: "Use a pre-configured template to load content quickly. The template name won't be saved.",
      pinNoti: "The notification will pinned in the receiver dashboard",
      allUser: "The notification will send to all users",
      receivers: "You can select a specific user to send the notification or you can send to a list of users.",
      methods: " Select the preferred methods for sending notifications, such as email or web notifications",
    },

    tabs: {
      manageEventNotifications: "Manage Event Notifications",
      notificationLogs: "Notification Logs",
    },

    logs: {
      title: "Notification Logs",
      titleLogDetail: "Notification Log Detail",
    },

    template: {
      managedTemplates: "Managed Templates",
      newTemplate: "New Template",
      updateTemplate: "Update Template",
      templateDetail: "Template Detail",

      labels: {
        confirmDeleteQuestion: "Are you sure to delete this Template?",
        name: "Name",
        title: "Subject",
        desc: "Description",
      },
      errors: {
        contentRequired: "Notification content is required!",
      },
    },

    errors: {
      contentRequired: "Notification content is required!",
      templateDetail: "Fetch template detail error",
      fetchIndustries: "Fetch industries error",
      listEventNotifications: "Fetch event notifications error",
      createEventNotifications: "Create event notifications error",
      deleteEventNoti: "Delete event notification error",
      deleteTemplate: "Delete template error",
    },
  },
}
