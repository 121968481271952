<template>
  <EcBox
    :variant="cardVariant"
    class="px-2 py-1 mb-4 mr-3 lg:inline-flex lg:flex-grow lg:w-auto hover:shadow-5"
    style="min-width: 12rem"
  >
    <EcFlex class="relative items-center ml-2 p-1 w-7/12 h-auto overflow-hidden">
      <EcBox>
        <EcCheckbox class="m-3" :modelValue="modelValue" :customValue="value?.uid" v-model="model" />
      </EcBox>
      <EcBox>
        <EcText class="text-md text-cBlack">
          {{ value?.name }}
        </EcText>

        <EcText v-if="value?.division" class="text-c0-500 text-xs mt-2">
          {{ value?.division?.name }}
        </EcText>
      </EcBox>
    </EcFlex>
  </EcBox>
</template>

<script>
import EcCheckbox from "@/components/EcCheckbox/index.vue"

export default {
  name: "BusinessUnitCardItem",
  components: { EcCheckbox },
  props: {
    modelValue: {
      type: [Array, Boolean],
    },

    value: {
      type: Object,
      default: () => {},
    },
  },

  emits: ["update:modelValue"],

  data() {
    return {
      isModalDeleteOpen: false,
      isDeleteLoading: false,
      checked: false,
    }
  },

  setup() {},
  mounted() {},
  computed: {
    model: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit("update:modelValue", value)
      },
    },
    cardVariant() {
      return "card-2"
    },
  },
  methods: {
    statusText(status) {
      return status ? "font-bold text-cSuccess-500" : "font-bold text-cError-500"
    },
  },
}
</script>
