import fetcher from "@/modules/core/api/fetcher"

/**
 * ----------- BIA
 * @returns
 */
const fetchBIAStatistic = async function () {
  return fetcher.get("/identity/api/v1/dashboard/bia-statistic")
}

const fetchBIASummary = async function () {
  return fetcher.get("/identity/api/v1/dashboard/bia-summary")
}

/**
 * --------- Resilience Plan
 */

const fetchResiliencePlanSummary = async function () {
  return fetcher.get("/identity/api/v1/dashboard/resilience-plans?with=category")
}

/**
 * ------- Activity -------
 * @returns
 */
const fetchCriticalActivities = async () => {
  return fetcher.get("/identity/api/v1/dashboard/critical-activities")
}

const fetchActivityStatistic = async () => {
  return fetcher.get("/identity/api/v1/dashboard/activity-statistic")
}

/**
 * Supplier
 */

const fetchSupplierStatistic = async () => {
  return fetcher.get("/identity/api/v1/dashboard/supplier-statistic")
}

export {
  // BIA
  fetchBIASummary,
  fetchBIAStatistic,

  // Resilience plan
  fetchResiliencePlanSummary,

  // Activity
  fetchActivityStatistic,
  fetchCriticalActivities,

  // Supplier
  fetchSupplierStatistic,
}
