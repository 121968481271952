<template>
  <RLayout :title="$t('setting.profile')" class="bg-c0-100">
    <RLayoutTwoCol :isLoading="isLoading">
      <template #left>
        <EcBox variant="card-1" class="pt-8">
          <EcHeadline as="h2" variant="h2" class="px-5 text-c1-800">
            {{ $t("setting.accountInfo") }}
          </EcHeadline>
          <EcBox class="mt-4">
            <!-- Logo -->
            <EcFlex class="flex-wrap max-w-full">
              <EcBox class="w-full mb-6 sm:pr-6">
                <RUploadFiles
                  :documentTitle="profilePictureTitle"
                  :maxFileNum="1"
                  :isImage="true"
                  dir="profile_picture"
                  docType="profile/picture"
                  :isUploadOnSelect="true"
                  :uploadedFileUrls="uploadedFileUrls"
                  dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
                  @handleSingleUploadResult="handleProfilePictureUploaded"
                />
              </EcBox>
            </EcFlex>
            <REditableField
              class="p-0 cursor-pointer"
              v-for="item in accountInformation"
              :key="item.key"
              :label="item.label"
              :value="item.value"
              :canDelete="false"
              :canEdit="item.key === 'fullName'"
              @edit="decideUpdateModal(item)"
            />
          </EcBox>

          <!-- Change password button -->
          <EcButton variant="primary-sm" class="uppercase py-3 mt-3" @click="openChangePasswordModal()">
            {{ $t("setting.changePassword") }}
          </EcButton>
        </EcBox>

        <!-- MFA settings -->
        <EcBox variant="card-1" class="mt-8">
          <EcHeadline as="h2" variant="h2" class="px-5 text-c1-800">
            {{ $t("setting.mfa.labels.mfa") }}
          </EcHeadline>

          <EcFlex class="mt-4 ml-4 items-center">
            <!-- Toggle -->
            <RFormInput
              v-model="me.mfa_status"
              componentName="EcToggle"
              showLabel
              :labelTrue="$t('setting.mfa.enabled')"
              :labelFalse="$t('setting.mfa.disabled')"
              @update:modelValue="handleChangeMFAToggle"
            />
          </EcFlex>
        </EcBox>

        <!-- Language settings -->
        <EcBox variant="card-1" class="mt-8">
          <EcHeadline as="h2" variant="h2" class="px-5 text-c1-800">
            {{ $t("setting.language") }}
          </EcHeadline>
          <EcBox class="mt-4">
            <REditableField
              :label="$t('setting.language')"
              :value="dictionary?.[currentLocale]"
              :canEdit="false"
              :canDelete="false"
              class="cursor-pointer"
              @click="openLangModal()"
            />
          </EcBox>
        </EcBox>
        <!-- End language settings -->
      </template>
    </RLayoutTwoCol>

    <!-- Update user Modal  -->
    <teleport to="#layer2">
      <EcModalSimple zIndex="50" variant="center-3xl" :isVisible="isEntityModalOpen" @overlay-click="closeModalAndClearForms()">
        <RFormInput v-model="me.first_name" componentName="EcInputText" label="First Name" class="mb-6" />
        <RFormInput v-model="me.last_name" componentName="EcInputText" label="Last Name" class="mb-6" />
        <EcFlex>
          <EcButton @click="handleUpdateEntity" class="mr-2">
            {{ $t("core.update") }}
          </EcButton>
          <EcButton variant="tertiary-outline" @click="closeModalAndClearForms()">
            {{ $t("core.cancel") }}
          </EcButton>
        </EcFlex>
      </EcModalSimple>
    </teleport>
  </RLayout>

  <!-- Change language -->
  <teleport to="#layer2">
    <EcModalSimple :isVisible="isLangModalOpen" variant="center-2xl" @overlay-click="closeLangModal()">
      <!-- Modal Header -->
      <EcBox class="mb-6">
        <EcText class="text-c0-300 font-medium">
          {{ $t("setting.editFor") }}
        </EcText>
        <EcHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
          {{ $t("setting.language") }}
        </EcHeadline>
      </EcBox>

      <!-- Modal form -->
      <EcBox class="w-full mb-10">
        <RFormInput
          v-model="selectedLocale"
          class="mt-4"
          componentName="EcSelect"
          :placeholder="$t('setting.pleaseSelect')"
          :options="options"
          :label="$t('setting.displayLanguage')"
        />
      </EcBox>

      <EcFlex>
        <EcButton class="mr-4" variant="primary" @click="handleUpdateLocale()">
          {{ $t("setting.update") }}
        </EcButton>
        <EcButton variant="tertiary-outline" @click="closeLangModal()">
          {{ $t("setting.cancel") }}
        </EcButton>
      </EcFlex>
    </EcModalSimple>
  </teleport>

  <!-- Change password -->
  <teleport to="#layer2">
    <UserChangePasswordModal :isPasswordModalOpen="isPasswordModalOpen" @closeChangePasswordModal="closeChangePasswordModal" />
  </teleport>

  <!-- MFA Settings -->
  <teleport to="#layer3">
    <UserMFAModal
      :me="me"
      :isMFAModalOpen="isMFAModalOpen"
      :enable="me.mfa_status"
      @closeMFASettingsModal="closeMFASettingsModal"
      @updateMFAToggle="updateMFAToggle"
    />
  </teleport>
</template>
<script>
import { computed, nextTick, readonly, ref } from "vue"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import UserChangePasswordModal from "../../components/UserChangePasswordModal.vue"
import UserMFAModal from "../../components/UserMFAModal.vue"
import { useProfile } from "../../use/profile/useProfile"

export default {
  name: "ViewProfile",
  data() {
    const accountInformation = [
      { key: "fullName", label: this.$t("setting.fullName"), value: this.me?.name },
      { key: "email", label: this.$t("setting.email"), value: this.me?.email },
      { key: "tenantId", label: this.$t("setting.tenantId"), value: this.globalStore.getTenantId },
    ]

    return {
      isChangingPassword: false,
      isPasswordModalOpen: false,
      isMFAModalOpen: false,
      isLoading: false,
      profilePictureTitle: "Profile Picture",
      accountInformation,
      uploadedFileUrls: [],
    }
  },
  mounted() {
    this.fetchProfile()
  },

  setup() {
    const { locale } = useI18n()
    const { me, getProfile, updateProfilePicture } = useProfile()

    // const store = useStore()
    const globalStore = useGlobalStore()
    const isEntityModalOpen = ref(false)
    const selectedLocale = ref(null)
    const isLangModalOpen = ref(false)
    const updateEntityObj = ref(null)

    /**
     * Computed
     */

    const currentLocale = computed(() => {
      return locale.value || localStorage?.coverAdminLocale || "en-US"
    })
    const dictionary = readonly({
      "en-US": "English",
    })
    const options = computed(() => {
      const availableLocales = globalStore.getTenantSettings?.availableLocales ?? ["en-US"]
      return Object.entries(dictionary)
        .filter(([key, value]) => availableLocales.includes(key))
        .map(([key, value]) => {
          return {
            name: value,
            value: key,
          }
        })
    })

    /**
     *
     * @param {*} item
     */
    const decideUpdateModal = (item) => {
      updateEntityObj.value = { ...me?.value?.associatedUser }
      openUpdateModal()
    }

    /**
     * Handle update modal
     */
    const openUpdateModal = () => {
      isEntityModalOpen.value = true
    }

    /**
     * Handle update entity
     */
    const handleUpdateEntity = async () => {}
    /**
     * Close modal and clear form update user
     */
    const closeModalAndClearForms = () => {
      isEntityModalOpen.value = false
      updateEntityObj.value = {}
    }

    /**
     * Change language
     */
    function openLangModal() {
      selectedLocale.value = currentLocale.value
      isLangModalOpen.value = true
    }
    /**
     * Close lang modal
     */
    function closeLangModal() {
      isLangModalOpen.value = false
    }
    /**
     * Handle update locale
     */
    function handleUpdateLocale() {
      this.globalStore.setLocale(selectedLocale.value)
      nextTick(() => {
        closeLangModal()
      })
    }

    return {
      isEntityModalOpen,
      selectedLocale,
      isLangModalOpen,
      updateEntityObj,

      me,
      // computed
      currentLocale,
      dictionary,
      options,

      // Profile
      getProfile,

      // update modal
      decideUpdateModal,
      closeModalAndClearForms,
      handleUpdateEntity,

      // change language
      openLangModal,
      closeLangModal,
      handleUpdateLocale,
      globalStore,
      updateProfilePicture,
    }
  },
  computed: {},
  methods: {
    /**
     * Fetch Profile
     */
    async fetchProfile() {
      this.isLoading = true
      const res = await this.getProfile()

      if (res) {
        this.me = res

        this.accountInformation = [
          { key: "fullName", label: this.$t("setting.fullName"), value: this.me?.name },
          { key: "email", label: this.$t("setting.email"), value: this.me?.email },
          { key: "tenantId", label: this.$t("setting.tenantId"), value: this.globalStore.getTenantId },
        ]
        this.uploadedFileUrls = [this.me?.profile_picture_url]
      }

      this.isLoading = false
    },

    /**
     * Open password modal
     */
    openChangePasswordModal() {
      this.isPasswordModalOpen = true
    },

    /**
     * Clear modal password
     */
    closeChangePasswordModal() {
      this.isPasswordModalOpen = false
    },

    // ====== MFA Settings ======
    handleChangeMFAToggle(status) {
      this.isMFAModalOpen = true
    },

    closeMFASettingsModal(isCancelModal) {
      this.isMFAModalOpen = false
      // Revert status if user click cancel
      if (isCancelModal) this.me.mfa_status = !this.me.mfa_status
    },

    /**
     * MFA Disable
     */
    updateMFAToggle(value) {
      this.me.mfa_status = value
    },

    /**
     * Handle profile picture uploaded
     */
    async handleProfilePictureUploaded(result) {
      await this.updateProfilePicture({ profile_picture_uid: result.uid })
    },
  },
  components: { UserChangePasswordModal, UserMFAModal },
}
</script>
