<template>
  <RLayout :title="computedUsername">
    <RLayoutTwoCol :isLoading="isLoading">
      <template #left>
        <!-- Role -->
        <EcBox variant="card-7" class="mb-8">
          <EcHeadline as="h5" variant="h5" class="px-3 text-c1-800">
            {{ $t("user.role") }}
          </EcHeadline>

          <REditableField
            :canDelete="false"
            :label="$t('user.label.role')"
            :value="currentRoleLabel"
            :isReadOnly="!canEditRole"
            @edit="handleClickEditRole()"
          />
        </EcBox>

        <!-- User Entity -->
        <EcBox variant="card-7" class="mb-4">
          <EcHeadline as="h5" variant="h5" class="px-3 mb-4 text-c1-800">
            {{ $t("user.associatedEntity") }}
          </EcHeadline>

          <REditableField
            v-for="(item, index) in associatedUser"
            :key="item.key"
            :label="item.label"
            :value="item.value"
            :inputCustomClass="this.isEditableField(item.key) ? 'text-base text-c1-800 cursor-pointer' : ''"
            :canEdit="this.isEditableField(item.key)"
            :canDelete="false"
            :data-key="item.key"
            :class="{ 'mb-6': index !== associatedUser.length - 1 }"
            @value-click="handleClickAssociatedUser(item.key)"
            @click="handleClickAssociatedUser(item.key)"
          />
        </EcBox>
      </template>
      <template #right>
        <!-- SBUs -->
        <EcBox v-if="!globalStore.isLandlord" variant="card-7" class="max-w-2xl px-4 sm:px-10 mb-3">
          <EcHeadline as="h5" variant="h5" class="px-3 text-c1-800"> SBUs Assignment </EcHeadline>

          <EcTree
            v-if="!isSbuLoading"
            :modelValue="divisions"
            :items="divisions"
            childrenKey="business_units"
            labelKey="name"
            valueKey="uid"
            class="max-h-[50vh]"
            :autoExpand="true"
            :autoSelectChildren="true"
            :checkAllProp="formFilters.checkAll"
            @changed="
              (data) => {
                formFilters.selectedSBUs = data
              }
            "
            @onCheckAll="
              (val) => {
                formFilters.checkAll = val
              }
            "
          />

          <!-- Save SBUs -->
          <EcButton
            variant="primary-sm"
            class="btn-update-sbu mt-8"
            :iconPrefix="isUpdatingSBU ? 'spinner' : 'save'"
            @click="handleUpdateSBUs"
            >Update SBUs</EcButton
          >
        </EcBox>

        <!-- Delete User -->
        <EcBox v-permissions:user-delete variant="card-7" class="mb-8">
          <EcHeadline as="h5" variant="h5" class="px-5 text-c1-800">
            {{ $t("user.deleteUser") }}
          </EcHeadline>
          <EcText class="px-5 my-6 leading-snug text-base">
            {{ $t("user.deleteFullNote") }}
          </EcText>
          <EcButton class="mx-5" variant="warning-sm" iconPrefix="trash" @click="handleClickDelete()">
            {{ $t("user.button.deleteUser") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal Delete User -->
    <teleport to="#layer2">
      <ModalDeleteUser
        :username="computedUsername"
        :modalDeleteOpen="this.modalDeleteOpen"
        @handleClickCancelDelete="handleClickCancelDelete"
        @handleDeleteUser="handleDeleteUser"
      />
    </teleport>

    <!-- Modal Edit Role -->
    <teleport to="#layer2">
      <ModalEditUserRole
        :username="this.computedUsername"
        :modalEditRoleOpen="this.modalEditRoleOpen"
        :roleOptions="this.roleOptions"
        :user="this.user"
        @handleClickCancelEditRole="handleClickCancelEditRole"
        @handleRoleChanged="handleRoleChanged"
      />
    </teleport>

    <!-- Modal Edit User detail -->
    <teleport to="#layer2">
      <ModalEditUserDetail
        :user="user"
        :modalEditUserDetailOpen="modalEditUserDetailOpen"
        @handleClickCancelEditUserDetail="handleClickCancelEditUserDetail"
        @handleUpdateUserDetail="handleUpdateUserDetail"
      />
    </teleport>
  </RLayout>
</template>

<script>
import dayjs from "dayjs"
import { reactive } from "vue"
import { useGlobalStore } from "@/stores/global"
import { useUserDetail } from "../use/useUserDetail"
import ModalDeleteUser from "../components/ModalDeleteUser.vue"
import ModalEditUserRole from "../components/ModalEditUserRole.vue"
import ModalEditUserDetail from "../components/ModalEditUserDetail.vue"
import { useRoleList } from "../use/useRoleList"
import { useSBU } from "../use/useSBU"
import { useUserAssign } from "../use/useUserAssign"

export default {
  name: "ViewUserDetail",

  components: {
    ModalDeleteUser,
    ModalEditUserRole,
    ModalEditUserDetail,
  },

  data() {
    const associatedUser = [
      { key: "status", label: this.$t("user.label.status"), value: null },
      { key: "created_at", label: this.$t("user.label.createdAt"), value: null },
      { key: "email", label: this.$t("user.label.username"), value: "" },
      { key: "name", label: this.$t("user.label.name"), value: "" },
      { key: "first_name", label: this.$t("user.label.firstName"), value: "" },
      { key: "last_name", label: this.$t("user.label.lastName"), value: "" },
      { key: "isActive", label: this.$t("user.label.active"), value: "" },
    ]

    return {
      currentRoles: reactive([]),
      associatedUser,
      isLoading: true,
      isUpdatingSBU: false,

      // Modal edit user detail
      modalEditUserDetailOpen: false,

      // Modal delete user
      modalDeleteOpen: false,

      // Modal edit role
      modalEditRoleOpen: false,

      roleOptions: [],

      user: reactive({}),
    }
  },
  setup(props) {
    const globalStore = useGlobalStore()
    const { getUserDetail, updateUser, deleteUser } = useUserDetail()
    const { getRoles } = useRoleList()
    const { divisions, fetchSBUs, formFilters, isLoading: isSbuLoading } = useSBU()
    const { assignUser: userSbuAssignment } = useUserAssign()

    return {
      globalStore,
      getUserDetail,
      updateUser,
      deleteUser,
      getRoles,
      divisions,
      fetchSBUs,
      formFilters,
      isSbuLoading,
      userSbuAssignment,
    }
  },

  computed: {
    /** Computed username */
    computedUsername() {
      return this.user?.name
    },

    /** Editable field */
    editableFields() {
      return ["name"]
    },

    currentRoleLabel() {
      return this.user?.roles
        ?.map((item) => {
          return item.label
        })
        .join(", ")
    },
  },
  mounted() {
    this.getUser()
    this.fetchRoles()
    this.fetchSBUs()
  },

  methods: {
    /**
     * Get user detail
     */
    async getUser() {
      this.isLoading = true

      const { userId } = this.$route.params
      this.user = await this.getUserDetail(userId)
      if (this.user) {
        this.currentRoles = this.user.roles
        this.mapUserToAssociatedUser(this.user)
        this.mapSelectedSBUs()
      }

      this.isLoading = false
    },

    /**
     * Map object to array fields list
     * @param {Map object to array fields list} user
     */
    mapUserToAssociatedUser(user) {
      this.associatedUser = [
        {
          key: "isActive",
          label: this.$t("user.label.active"),
          value: user.is_suspended ? "Disabled" : "Enabled",
        },
        { key: "status", label: this.$t("user.label.status"), value: user.status },
        {
          key: "created_at",
          label: this.$t("user.label.createdAt"),
          value: dayjs(user.created_at),
        },
        {
          key: "email",
          label: this.$t("user.label.username"),
          value: user.email,
        },
        {
          key: "name",
          label: this.$t("user.label.fullName"),
          value: user.name,
        },
        {
          key: "first_name",
          label: this.$t("user.label.firstName"),
          value: user.first_name,
        },
        {
          key: "last_name",
          label: this.$t("user.label.lastName"),
          value: user.last_name,
        },
      ]
    },

    /**
     *
     * @param {*} user
     */
    mapSelectedSBUs() {
      const userDivisionUids = this.user?.divisions?.map((division) => division.uid)
      const userBuUids = this.user?.business_units?.map((bu) => bu.uid)

      this.divisions = this?.divisions.map((division) => {
        division.selected = userDivisionUids.includes(division.uid)

        // BUs
        division.business_units = division?.business_units?.map((bu) => {
          bu.selected = userBuUids.includes(bu.uid)

          return bu
        })

        return division
      })
    },

    /**
     * Check is editable field
     * @param {*} field
     */
    isEditableField(field) {
      return this.editableFields.includes(field)
    },

    // ======== Edit user role ===

    /**
     * Check is user able to edit role
     */
    canEditRole() {
      return true
    },

    /**
     * Click edit permimssion button
     */
    async handleClickEditRole() {
      // await this.getrole()
      this.modalEditRoleOpen = true
    },

    /**
     * Click cancel edit role on modal
     */
    handleClickCancelEditRole() {
      this.modalEditRoleOpen = false
    },

    /**
     *
     * @param {*} user
     */
    handleRoleChanged(user) {
      if (user.roles) {
        this.user.roles = user.roles
      }
    },

    // ======== Edit user data =====
    handleClickAssociatedUser(key) {
      if (!this.isEditableField(key)) {
        return
      }

      this.modalEditUserDetailOpen = true
    },

    // Update user detail
    async handleUpdateUserDetail(data) {
      this.modalEditUserDetailOpen = false
      this.isLoading = true

      const res = await this.updateUser(this.user.uid, data)

      if (res) {
        this.user = res
        this.mapUserToAssociatedUser(this.user)
      }

      this.isLoading = false
    },

    // Cancel edit user data
    handleClickCancelEditUserDetail() {
      this.modalEditUserDetailOpen = false
    },

    // ======== Open modal delete delete
    handleClickDelete() {
      this.modalDeleteOpen = true
    },

    // Cancel deletet user
    handleClickCancelDelete() {
      this.modalDeleteOpen = false
    },

    // User confirmed delete
    async handleDeleteUser() {
      this.modalDeleteOpen = false
      this.isLoading = true
      await this.deleteUser(this.user.uid)

      this.isLoading = false
    },

    /**
     * Handle Update SBUs
     */
    async handleUpdateSBUs() {
      const selectedDivisionUids = []
      const selectedBuUids = []

      this.isUpdatingSBU = true
      this.formFilters?.selectedSBUs?.forEach((division) => {
        if (division?.selected) {
          selectedDivisionUids.push(division.uid)
        }

        // BU
        division?.business_units?.forEach((bu) => {
          if (bu?.selected) {
            selectedBuUids.push(bu.uid)
          }
        })
      })
      const payload = {
        divisions: selectedDivisionUids,
        business_units: selectedBuUids,
      }
      await this.userSbuAssignment(this.user.uid, payload)
      this.isUpdatingSBU = false
    },

    /**
     * Fetch roles
     */
    async fetchRoles() {
      this.roleOptions = await this.getRoles()
    },
  },
}
</script>
