<template>
  <component :width="width" :height="height" :is="icon" :title="title" v-bind="$attrs" />
</template>

<script>
import { defineAsyncComponent } from "vue"

const lazyLoad = (iconName) => defineAsyncComponent(() => import(`./Icons/${iconName}.vue`))

export default {
  name: "EcIcon",
  props: {
    icon: {
      type: String,
      required: true,
      default: "Default",
    },
    width: {
      type: String,
      default: "24",
    },
    height: {
      type: String,
      default: "24",
    },
    title: {
      type: String,
      default: "24",
    },
  },
  components: {
    Adjustments: lazyLoad("Adjustments"),
    ArrowDown: lazyLoad("ArrowDown"),
    ArrowLeft: lazyLoad("ArrowLeft"),
    ArrowRight: lazyLoad("ArrowRight"),
    CaretDown: lazyLoad("CaretDown"),
    CaretRight: lazyLoad("CaretRight"),
    Ban: lazyLoad("Ban"),
    Bell: lazyLoad("Bell"),
    Calendar: lazyLoad("Calendar"),
    ChartSquareBar: lazyLoad("ChartSquareBar"),
    ChatAlt: lazyLoad("ChatAlt"),
    Check: lazyLoad("Check"),
    CheckCircle: lazyLoad("CheckCircle"),
    ChevronDoubleLeft: lazyLoad("ChevronDoubleLeft"),
    ChevronDoubleRight: lazyLoad("ChevronDoubleRight"),
    ChevronDown: lazyLoad("ChevronDown"),
    ChevronLeft: lazyLoad("ChevronLeft"),
    ChevronRight: lazyLoad("ChevronRight"),
    ChevronUp: lazyLoad("ChevronUp"),
    Clipboard: lazyLoad("Clipboard"),
    ClipboardCheck: lazyLoad("ClipboardCheck"),
    ClipboardList: lazyLoad("ClipboardList"),
    Clock: lazyLoad("Clock"),
    Close: lazyLoad("Close"),
    CloudUpload: lazyLoad("CloudUpload"),
    Cog: lazyLoad("Cog"),
    CreditCard: lazyLoad("CreditCard"),
    CurrencyDollar: lazyLoad("CurrencyDollar"),
    Default: lazyLoad("Default"),
    DocumentDownload: lazyLoad("DocumentDownload"),
    DocumentDuplicate: lazyLoad("DocumentDuplicate"),
    DotsHorizontal: lazyLoad("DotsHorizontal"),
    Download: lazyLoad("Download"),
    Exclamation: lazyLoad("Exclamation"),
    ExclamationCircle: lazyLoad("ExclamationCircle"),
    Eye: lazyLoad("Eye"),
    EyeSlashed: lazyLoad("EyeSlashed"),
    Home: lazyLoad("Home"),
    LocationMarker: lazyLoad("LocationMarker"),
    LockClosed: lazyLoad("LockClosed"),
    Logout: lazyLoad("Logout"),
    Mail: lazyLoad("Mail"),
    MailOpen: lazyLoad("MailOpen"),
    Menu: lazyLoad("Menu"),
    Minus: lazyLoad("Minus"),
    MinusCircle: lazyLoad("MinusCircle"),
    OfficeBuilding: lazyLoad("OfficeBuilding"),
    PaperAirPlane: lazyLoad("PaperAirPlane"),
    PaperClip: lazyLoad("PaperClip"),
    Pencil: lazyLoad("Pencil"),
    Percent: lazyLoad("Percent"),
    Phone: lazyLoad("Phone"),
    Plus: lazyLoad("Plus"),
    PlusCircle: lazyLoad("PlusCircle"),
    QuestionMark: lazyLoad("QuestionMark"),
    Refresh: lazyLoad("Refresh"),
    Search: lazyLoad("Search"),
    Share: lazyLoad("Share"),
    Template: lazyLoad("Template"),
    ShieldCheck: lazyLoad("ShieldCheck"),
    ShieldDollar: lazyLoad("ShieldDollar"),
    Trash: lazyLoad("Trash"),
    TrashAlt: lazyLoad("TrashAlt"),
    User: lazyLoad("User"),
    UserGroup: lazyLoad("UserGroup"),
    Users: lazyLoad("Users"),
    Resource: lazyLoad("Resource"),
    Supplier: lazyLoad("Supplier"),
    Industry: lazyLoad("Industry"),
    Activity: lazyLoad("Activity"),
    Dashboard: lazyLoad("Dashboard"),
    Folder: lazyLoad("Folder"),
    X: lazyLoad("X"),
    Spinner: lazyLoad("Spinner"),
    Key: lazyLoad("Key"),
    OpenUp: lazyLoad("OpenUp"),
    Dependency: lazyLoad("Dependency"),
    Graph: lazyLoad("Graph"),
    Stop: lazyLoad("Stop"),
    Archive: lazyLoad("Archive"),
    Report: lazyLoad("Report"),
    Assessment: lazyLoad("Assessment"),
    BCP: lazyLoad("BCP"),
    Document: lazyLoad("Document"),
    CSV: lazyLoad("CSV"),
    PDF: lazyLoad("PDF"),
    TXT: lazyLoad("TXT"),
    OfficeWord: lazyLoad("OfficeWord"),
    OfficeExcel: lazyLoad("OfficeExcel"),
    OfficePPT: lazyLoad("OfficePPT"),
    NotiPinned: lazyLoad("NotiPinned"),
    NotiStar: lazyLoad("NotiStar"),
    NotiCard: lazyLoad("NotiCard"),
    NotiMessage: lazyLoad("NotiMessage"),
    NotiClose: lazyLoad("NotiClose"),
    NotiExpanded: lazyLoad("NotiExpanded"),
    NotiCollapsed: lazyLoad("NotiCollapsed"),
    DashboardBg: lazyLoad("DashboardBg"),
    DActivityDoc: lazyLoad("DActivityDoc"),
    TriangleUp: lazyLoad("TriangleUp"),
    Save: lazyLoad("Save"),
    LinkNode: lazyLoad("LinkNode"),
    Empty: lazyLoad("Empty"),
    Copy: lazyLoad("Copy"),
    Manage: lazyLoad("Manage"),
    Warning: lazyLoad("Warning"),
    Window: lazyLoad("Window"),
    Grid: lazyLoad("Grid"),
    SortAZ: lazyLoad("SortAZ"),
    SortZA: lazyLoad("SortZA"),
    Sort09: lazyLoad("Sort09"),
    Sort90: lazyLoad("Sort90"),
    Filter: lazyLoad("Filter"),
    TBC: lazyLoad("TBC"),
    Critical5: lazyLoad("Critical5"),
    People: lazyLoad("People"),
    Location: lazyLoad("Location"),
  },
}
</script>
