<template>
  <EcModalSimple
    :isVisible="isShowInstructionModal"
    variant="center-5xl-wrapper"
    @overlay-click="handleCloseBulkActivityInstructionModal"
  >
    <EcBox class="h-full">
      <iframe
        style="width: 100%; height: 100%"
        src="https://docs.google.com/document/d/e/2PACX-1vTl1s3Nzm_kPN_3RaKecOXf2oShLTzJFCxJnDOsGzH0FvQCnj1-XPXPqwJdysFeKKkaj9Z-9s-Mz4Ol/pub?embedded=true"
      ></iframe>
    </EcBox>
  </EcModalSimple>
</template>
<script>
export default {
  name: "ModalBulkActivityInstruction",

  emits: ["handleCloseBulkActivityInstructionModal"],
  data() {
    return {}
  },
  props: {
    isShowInstructionModal: {
      type: Boolean,
      default: false,
    },
  },

  setup() {
    return {}
  },
  methods: {
    /**
     * Close cancel modal
     */
    handleCloseBulkActivityInstructionModal() {
      this.$emit("handleCloseBulkActivityInstructionModal")
    },
  },
}
</script>
