import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchTemplateByType = async (type, uid) => {
  return fetcher.get(`identity/api/v1/reports/templates/${type}/revisions/${uid}`)
}

export const deleteReportTemplateRevision = async (type, uid) => {
  return fetcher.delete(`identity/api/v1/reports/templates/${type}/revisions/${uid}`)
}

export const setReportTemplateDefaultRevision = async (type, uid) => {
  return fetcher.post(`identity/api/v1/reports/templates/${type}/revisions/${uid}/set-default`)
}

export const getReportTemplateDefaultRevision = async (type) => {
  return fetcher.get(`identity/api/v1/reports/templates/${type}/revisions-default`)
}

export const fetchConfigurableTemplateTypes = async () => {
  return fetcher.get(`identity/api/v1/reports/configurable-templates`)
}

export const getRunnableReportTemplateTypes = async () => {
  return fetcher.get(`identity/api/v1/reports/runnable-templates`)
}

export const fetchReportTemplateTypeDetails = async (type) => {
  return fetcher.get(`identity/api/v1/reports/templates/${type}`)
}

export const fetchReportTemplateRevisions = async (type, params) => {
  return fetcher.get(`identity/api/v1/reports/templates/${type}/revisions`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const uploadReportTemplate = async function (payload) {
  const { data, options } = payload

  const config = options ?? {}
  config.headers = {
    "Content-Type": "multipart/form-data",
  }
  return fetcher.post(`identity/api/v1/reports/templates/${payload?.type}`, data, config)
}
