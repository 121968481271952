import { ref } from "vue"
import { required, email } from "@vuelidate/validators"
import * as api from "../../api/organizationFetcher"
import useVuelidate from "@vuelidate/core"
import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"
import { phoneValidator } from "@/readybc/composables/helpers/validator"
export function useOrganizationDuplication() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()
  // Initial data

  const organization = ref({
    owner: {},
  })

  const relativeData = ref([
    { key: "users", label: "Users", checked: true },
    { key: "roles", label: "Roles", checked: true },
    { key: "divisions", label: "Divisions", checked: true },
    { key: "business_units", label: "Business Units", checked: true },
    {
      key: "activities",
      label: "Activities",
      children: [
        { key: "activityRoles", label: "Activity Roles", checked: true },
        { key: "activityOwners", label: "Activity Owners", checked: true },
        { key: "activityRTOs", label: "Activity RTOs", checked: true },
        { key: "activityMTPDs", label: "Activity MTPDs", checked: true },
        { key: "activityRemoteFactors", label: "Activity Remote Factors", checked: true },
        { key: "activityApplications", label: "Activity Applications", checked: true },
        { key: "activityUtilities", label: "Activity Utilities", checked: true },
        // { key: "activityEquipments", label: "Activity Equipments", checked: true },
      ],
      checked: true,
    },
    {
      key: "equipments",
      label: "Equipments",
      children: [
        { key: "equipmentCategories", label: "Equipment Categories", checked: true },
        { key: "equipmentOwners", label: "Equipment Owners", checked: true },
      ],
      checked: true,
    },
    { key: "dependencyScenarios", label: "Dependencies", checked: true },
    { key: "suppliers", label: "Suppliers", checked: true },
    { key: "supplierCategories", label: "Supplier Categories", checked: true },
    {
      key: "bcps",
      label: "BCP",
      checked: true,
      children: [{ key: "bcpStatuses", label: "BCP Statuses", checked: true }],
    },
    {
      key: "bias",
      label: "BIA Summary",
      checked: true,
      children: [{ key: "biaStatuses", label: "BIA Statuses", checked: true }],
    },
    { key: "emailTemplates", label: "Email Templates", checked: true },
    { key: "eventNotifications", label: "Event Notification Configuration", checked: true },
    { key: "disruptionScenarios", label: "Disruption Scenarios (Threat Ranking Options)", checked: true },
  ])

  const rules = {
    organization: {
      name: { required },
      friendly_url: {},
      description: {},
      address: {},
      logo: {},

      owner: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
        phone: { required, phoneValidator },
      },
    },
  }

  const v$ = useVuelidate(rules, { organization })

  /**
   *
   * @param {*} organizationId
   * @param {*} payload
   * @returns
   */
  const duplicateOrganization = async (payload) => {
    try {
      const { data } = await api.duplicateOrganization(payload)

      globalStore.addSuccessToastMessage(t("organization.duplicated"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    organization,
    relativeData,
    v$,
    duplicateOrganization,
  }
}
