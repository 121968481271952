import { useGlobalStore } from "@/stores/global"
import * as api from "../api/applicationFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useApplicationNew() {
  const globalStore = useGlobalStore()
  const application = ref({
    sla: false,
    sla_documents: [],
  })

  const rules = {
    application: {
      name: { required },
      description: {},
    },
  }

  const validator$ = useVuelidate(rules, { application })

  /**
   *
   * @param {*} payload
   * @returns
   */
  async function createApplication(payload) {
    try {
      const { data } = await api.createApplication(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createApplication,
    application,
    validator$,
  }
}
