import { ref } from "vue"
import { fetchReplacementTokens } from "@/readybc/composables/api/reports/apiReportReplacementTokens"
import { useGlobalStore } from "@/stores/global"

export const useReplacementTokens = () => {
  const globalStore = useGlobalStore()
  const replacementTokens = ref({})
  const isLoading = ref(false)

  /**
   *
   * @param {*} type
   */
  const getReplacementTokens = async (type) => {
    isLoading.value = true

    try {
      const data = await fetchReplacementTokens(type)

      if (data) {
        replacementTokens.value = data.data
      }

      isLoading.value = false
    } catch (error) {
      globalStore.addErrorToastMessage("Unable to get replacement tokens")
    }
  }

  return {
    replacementTokens,
    isLoading,
    getReplacementTokens,
  }
}
