import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/biaStatusFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useBIAStatusNew() {
  const globalStore = useGlobalStore()
  const status = ref({
    due_date_editable: true,
  })

  const rules = {
    status: {
      name: { required },
      description: {},
      text_color: {},
      bg_color: {},
      due_date_editable: {},
    },
  }

  const validator$ = useVuelidate(rules, { status })

  /**
   *
   * @param {*} payload
   * @returns
   */
  async function createBIAStatus(payload) {
    try {
      const { data } = await api.createBIAStatus(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createBIAStatus,
    status,
    validator$,
  }
}
