import { fetchSupplierStatistic } from "@/readybc/composables/api/apiDashboard"
import { defineStore } from "pinia"
import { ref } from "vue"

const useDashboardSupplierStore = defineStore("useDashboardSupplierStore", () => {
  const isSupplierStatisticLoading = ref(false)
  const supplierStatistic = ref([])

  /**
   *
   */
  const getSupplierStatistic = () => {
    isSupplierStatisticLoading.value = true

    /**
     * Statistic
     */
    fetchSupplierStatistic()
      .then(({ data }) => {
        supplierStatistic.value = data || []
        isSupplierStatisticLoading.value = false
      })
      .catch(() => {
        isSupplierStatisticLoading.value = false
      })
  }

  return {
    isSupplierStatisticLoading,
    supplierStatistic,
    getSupplierStatistic,
  }
})

export default useDashboardSupplierStore
