<template>
  <EcBox class="overflow-x-auto lg:overflow-visible p-2">
    <!-- Header -->
    <EcFlex class="items-center">
      <EcHeadline as="h4" variant="h4" class="text-c1-800"> Divisions </EcHeadline>
    </EcFlex>

    <!-- Add button and Search box -->
    <EcFlex class="flex-grow items-center w-full md:w-auto mt-8 mb-4 mr-4">
      <EcFlex class="w-full">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.searchDivision')"
          class="w-full md:max-w-xs text-sm"
          @search="handleSearch"
          @clear-search="handleClearSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Divisions items -->
    <EcFlex v-if="!this.isLoading" class="lg:flex-wrap grid grid-cols-1">
      <template v-for="item in filteredDivision" :key="item.uid">
        <DivisionCardItem :value="item" v-model="selectedDivisions" />
      </template>

      <!-- No data found -->
      <EcBox v-show="divisions.length <= 0">
        <EcText>{{ $t("core.noDataHere") }}</EcText>
      </EcBox>
    </EcFlex>

    <EcFlex v-else class="items-center">
      <EcSpinner />
    </EcFlex>
  </EcBox>
</template>

<script>
import DivisionCardItem from "./DivisionCardItem"
import { useDivisionList } from "@/modules/organization/use/division/useDivisionList"
import { goto } from "@/modules/core/composables"
import { ref } from "vue"
import EcBox from "@/components/EcBox/index.vue"
import EcFlex from "@/components/EcFlex/index.vue"

export default {
  name: "DivisionList",
  emits: ["handleDivisionSelected"],
  components: {
    DivisionCardItem,
    EcBox,
    EcFlex,
  },
  data() {
    return {
      isLoading: true,
      searchQuery: "",
      filteredDivision: [],
      initialLoad: false,
    }
  },

  setup() {
    const divisions = ref([])

    const { getDivisions } = useDivisionList()
    const selectedDivisions = ref([])

    return {
      getDivisions,
      divisions,
      selectedDivisions,
    }
  },
  props: {
    organization: {
      type: Object,
    },
    currentDivisions: {
      type: Array,
    },
  },

  computed: {},

  mounted() {
    this.fetchDivisions()
  },
  methods: {
    async fetchDivisions() {
      this.isLoading = true

      const response = await this.getDivisions()

      if (response && response.data) {
        this.divisions = response.data
        this.filteredDivision = this.divisions
      }
      this.isLoading = false
    },
    isDivisionSelected(item) {},
    handleClickAddDivision() {
      goto("ViewDivisionNew")
    },

    /**
     *
     */
    async handleDeletedBuItem() {
      await this.fetchDivisions()
    },

    handleClearSearch() {
      this.searchQuery = ""
      this.filteredDivision = this.divisions
    },

    handleSearch() {
      if (this.searchQuery.length > 0) {
        this.filteredDivision = this.divisions.filter((bu) => {
          return (
            bu.name.toLowerCase().includes(this.searchQuery.toLowerCase()) ||
            bu.division?.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        })
      } else {
        this.filteredDivision = this.divisions
      }
    },
  },
  watch: {
    selectedDivisions(items) {
      this.$emit("handleDivisionSelected", items)
      this.initialLoad = false
    },
    currentDivisions(item) {
      this.selectedDivisions = this.currentDivisions?.map((item) => {
        return item.uid
      })
      this.initialLoad = true
    },
  },
}
</script>
