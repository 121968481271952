import { useGlobalStore } from "@/stores/global"

/**
 *
 * @param {*} tenantData
 */
export const setTenantData = (tenantData) => {
  window.$cookies.set("tenantUid", tenantData?.uid)
  window.$cookies.set("tenantName", tenantData?.name)
}

/**
 *
 * @returns
 */
export const getTenantData = () => {}

/**
 *
 * @returns
 */
export const getTenantUid = () => {
  return window.$cookies.get("tenantUid")
}

/**
 *
 * @param {*} value
 */
export const copyValue = (value) => {
  const globalStore = useGlobalStore()

  const el = document.createElement("textarea")

  el.value = value
  el.setAttribute("readonly", "")
  el.style.position = "absolute"
  el.style.left = "-9999px"
  document.body.appendChild(el)

  const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false

  el.select()
  document.execCommand("copy")
  document.body.removeChild(el)
  if (selected) {
    document.getSelection().removeAllRanges()
    document.getSelection().addRange(selected)
  }

  globalStore.addSuccessToastMessage("Copied")
}

/**
 *
 * @param {*} str
 * @returns
 */
export const hyphen = (str) => {
  const regex = /[^a-z0-9]|\s+|\r?\n|\r/gim

  return str?.replaceAll(regex, "-")?.replace(/-+/g, "-").toLowerCase()
}

/**
 * Sub domain
 * @param {*} str
 * @returns
 */
export const subdomain = (str) => {
  return hyphen(str)
    ?.replace(/[^a-zA-Z0-9-_]/g, "")
    ?.replace(/^-+|-+$/g, "")
    ?.replace(/^_+|_+$/g, "")
}

export const isMtOrganizations = () => {
  return window.$cookies.get("mt_organizations") === "true"
}
export default {
  copyValue,
  setTenantData,
  getTenantUid,
  hyphen,
  isMtOrganizations,
}
