export const TypeCompareEnum = {
  EQUAL: "=",
  LIKE: "like",
  BETWEEN: "between",
  GREATER: ">",
  GREATER_THAN: ">=",
  LESS: "<",
  LESS_THAN: "<=",
  IN: "in",
}
export const SearchJoinTypeEnum = {
  OR: "or",
  AND: "and",
}

export const SortDirectionEnum = {
  DESC: "desc",
  ASC: "asc",
}
