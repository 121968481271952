import * as api from "../../api/equipmentFetcher"
import { useGlobalStore } from "@/stores/global"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useEquipmentDetail() {
  const globalStore = useGlobalStore()

  const equipment = ref({
    category: {},
    owners: [{ uid: "" }],
  })

  const rules = {
    equipment: {
      name: { required },
      description: {},
      status: {},
      category: {},
      owners: {},
    },
  }

  const v$ = useVuelidate(rules, { equipment })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getEquipment = async (uid) => {
    try {
      const { data } = await api.fetchEquipment(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateEquipment = async (payload, uid) => {
    try {
      const { data } = await api.updateEquipment(payload, uid)
      globalStore.addSuccessToastMessage("Updated equipment")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteEquipment = async (uid) => {
    try {
      const response = await api.deleteEquipment(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  return {
    getEquipment,
    updateEquipment,
    deleteEquipment,
    equipment,
    v$,
  }
}
