<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("dependencyScenario.dependencies") }}
        </EcHeadline>
      </EcFlex>

      <!-- Search box -->
      <EcFlex class="flex-grow justify-end items-center w-full md:w-auto">
        <RSearchBox
          v-model="searchQuery"
          :isSearched="searchQuery !== ''"
          :placeholder="$t('organization.search')"
          class="w-full md:max-w-xs"
          @update:modelValue="onFilter"
          @clear-search="handleClearSearch"
          @search="handleSearch"
        />
      </EcFlex>
    </EcFlex>

    <!-- Filter-->
    <EcBox class="flex justify-end mt-8 lg:mt-16">
      <EcBox class="grid grid-cols-1 sm:grid-cols-2 gap-2 flex justify-end">
        <!-- Export Dependency -->
        <EcButton
          class="mb-3 lg:mb-0"
          :iconPrefix="exportResourcesIcon"
          variant="primary-sm"
          @click="handleClickDownloadDependencyScenarios"
        >
          {{ $t("dependencyScenario.buttons.exportDependencies") }}
        </EcButton>

        <!-- Add Dependency -->
        <EcButton class="mb-3 lg:mb-0" iconPrefix="plus-circle" variant="primary-sm" @click="handleClickAddDependency">
          {{ $t("dependencyScenario.buttons.addDependency") }}
        </EcButton>
      </EcBox>
    </EcBox>

    <!-- Table -->
    <RTable :isLoading="isLoading" :list="filteredDependencies" class="mt-4 lg:mt-6">
      <template #header>
        <RTableHeaderRow>
          <RTableHeaderCell v-for="(h, idx) in headerData" :key="idx" class="text-cBlack">
            {{ h.label }}
          </RTableHeaderCell>
          <RTableHeaderCell variant="gradient" />
        </RTableHeaderRow>
      </template>
      <template v-slot="{ item, last, first }">
        <RTableRow class="hover:bg-c0-100">
          <RTableCell>
            <EcText class="w-24">
              {{ item.name }}
            </EcText>
          </RTableCell>

          <!-- Desc -->
          <RTableCell>
            <EcText class="w-32">
              {{ item.description }}
            </EcText>
          </RTableCell>

          <!-- status -->
          <RTableCell>
            <EcText :variant="getDependencyScenarioStatusType(item.status)" class="w-24">
              {{ getDependencyScenarioStatus(item.status) }}
            </EcText>
          </RTableCell>

          <!-- Targets -->
          <RTableCell>
            <EcText class="w-4"> {{ item.targetCount }} </EcText>
          </RTableCell>

          <!-- Upstream -->
          <RTableCell>
            <EcText class="w-4"> {{ item.upstreamCount }} </EcText>
          </RTableCell>

          <!-- Downstream -->
          <RTableCell>
            <EcText class="w-4"> {{ item.downstreamCount }} </EcText>
          </RTableCell>

          <!-- created at -->
          <RTableCell>
            <EcText class="pr-2">
              {{ formatData(item.created_at, dateTimeFormat) }}
            </EcText>
          </RTableCell>

          <!-- Action -->
          <RTableCell :class="{ 'rounded-tr-lg': first, 'rounded-br-lg': last }" :isTruncate="false" variant="gradient">
            <EcFlex class="items-center justify-center h-full">
              <RTableAction class="w-30">
                <!-- View Graph -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-cBlack hover:bg-c0-100"
                  @click="handleViewDependencyGraph(item.uid)"
                >
                  <EcIcon class="mr-3" icon="Graph" />
                  <EcText class="font-medium">{{ $t("dependencyScenario.buttons.viewGraph") }}</EcText>
                </EcFlex>

                <!-- Edit action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-c1-500 hover:bg-c0-100"
                  @click="handleClickEditDependency(item.uid)"
                >
                  <EcIcon class="mr-3" icon="Pencil" />
                  <EcText class="font-medium">{{ $t("dependencyScenario.buttons.edit") }}</EcText>
                </EcFlex>
                <!-- Delete action -->
                <EcFlex
                  class="items-center px-4 py-2 cursor-pointer text-cError-500 hover:bg-c0-100"
                  @click="handleOpenDeleteModal(item.uid, item.name)"
                >
                  <EcIcon class="mr-3" icon="X" />
                  <EcText class="font-medium">{{ $t("dependencyScenario.buttons.delete") }}</EcText>
                </EcFlex>
              </RTableAction>
            </EcFlex>
          </RTableCell>
        </RTableRow>
      </template>
    </RTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="currentPage"
        :limit="filters.page.size"
        :totalCount="filters.page.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="currentPage"
        :itemPerPage="filters.page.size"
        :totalItems="filters.page.total"
        @input="setPage($event)"
      />
    </EcFlex>

    <!-- Modal  delete dependency -->
    <teleport to="#layer1">
      <ModalDeleteResource
        :dependencyScenarioUid="toDeleteDependencyScenarioUid"
        :dependencyScenarioName="toDeleteDependencyScenarioName"
        :isModalDeleteResourceOpen="isModalDeleteOpen"
        @handleCloseDeleteModal="handleCloseDeleteModal"
        @handleDeleteCallback="handleDeleteCallback"
      />
    </teleport>

    <!-- Modal view dependency graph -->
    <teleport to="#layer2">
      <ModalViewDependencyGraph
        :isModalViewGraphOpen="isModalShowViewGrapOpen"
        :dependencyScenarioUid="toViewDependencyScenarioUid"
        @handleCloseViewGraphModal="handleCloseViewGraphModal"
      />
    </teleport>
  </RLayout>
</template>

<script>
import { useDependencyList } from "@/modules/dependency/use/dependency/useDependencyList"
import { useDependencyFactor } from "@/modules/dependency/use/dependencyFactor/useDependencyFactor"
import { useGlobalStore } from "@/stores/global"
import { formatData, goto } from "@/modules/core/composables"
import { useDependencyScenarioStatusEnum } from "../../use/dependency/useDependencyScenarioStatusEnum"
import ModalDeleteResource from "../../components/ModalDeleteDependencyScenario.vue"
import ModalViewDependencyGraph from "../../components/ModalViewDependencyGraph.vue"

export default {
  name: "ViewDependencyList",
  setup() {
    // Pre load
    const globalStore = useGlobalStore()
    const { getDependencyList, downloadDependencies, dependencies } = useDependencyList()

    const { dependencyFactors, getDependencyFactors } = useDependencyFactor()

    const { statuses } = useDependencyScenarioStatusEnum()

    return {
      globalStore,
      getDependencyList,
      downloadDependencies,
      dependencies,
      dependencyFactors,
      getDependencyFactors,

      statuses,
    }
  },
  data() {
    const filters = {
      filter: [],
      page: {
        number: 1,
        size: 10,
        total: 0,
      },
      sort: [
        {
          name: "created_at",
          direction: "DESC",
        },
      ],
    }
    return {
      headerData: [
        { label: this.$t("dependencyScenario.labels.name") },
        { label: this.$t("dependencyScenario.labels.description") },
        { label: this.$t("dependencyScenario.labels.status") },
        { label: this.$t("dependencyScenario.labels.targets") },
        { label: this.$t("dependencyScenario.labels.upstream") },
        { label: this.$t("dependencyScenario.labels.downstream") },
        { label: this.$t("dependencyScenario.labels.createdAt") },
      ],
      selectedCategory: "",
      searchQuery: "",
      onFilter: "",
      isLoading: false,
      isLoadingCategories: false,
      isDownloading: false,
      isModalDeleteOpen: false,
      isModalShowViewGrapOpen: false,
      // Dependency uid to delete
      toDeleteDependencyScenarioUid: null,
      toViewDependencyScenarioUid: null,
      toDeleteDependencyScenarioName: "",
      filters,
      currentPage: 1,
    }
  },
  mounted() {
    this.fetchDependencies()
    this.fetchDependencyFactors()
  },
  computed: {
    /**
     * Format date
     */
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },

    /**
     * Export Icone
     */
    exportResourcesIcon() {
      return this.isDownloading ? "Spinner" : "DocumentDownload"
    },

    categoryPlaceHolder() {
      return this.isLoadingCategories
        ? this.$t("dependencyScenario.placeholders.loading")
        : this.$t("dependencyScenario.placeholders.category")
    },

    /**
     * Filtered
     */
    filteredDependencies() {
      return this.dependencies
    },
  },
  watch: {
    currentPage() {
      this.filters.page.number = this.currentPage
      this.fetchDependencies()
    },
  },
  methods: {
    formatData,

    /**
     * fetch activities
     * @returns {Promise<void>}
     */
    async fetchDependencies() {
      this.isLoading = true

      const dependenciesRes = await this.getDependencyList(this.filters)

      if (dependenciesRes && dependenciesRes.data) {
        this.dependencies = dependenciesRes.data

        // Pagination
        this.currentPage = dependenciesRes.current_page
        this.filters.page.size = dependenciesRes.per_page
        this.filters.page.total = dependenciesRes.total
      }

      this.isLoading = false
    },
    /**
     * convert resource status to string status
     * @param value
     * @returns {string}
     */
    getDependencyScenarioStatus(value) {
      const status = this.statuses.find((status) => {
        return status.value === value
      })

      return status?.name ?? "Unknown"
    },

    /**
     * get class property
     * @param value
     * @returns {string}
     */
    getDependencyScenarioStatusType(value) {
      switch (value) {
        case 1:
          return "pill-cSuccess-inv"

        case 2:
          return "pill-cWarning-inv"

        case 3:
          return "pill-c2"

        default:
          return "pill-cError-inv"
      }
    },

    // Handle events
    /**
     * Download
     */
    async handleClickDownloadDependencyScenarios() {
      this.isDownloading = true
      await this.downloadDependencies()
      this.isDownloading = false
    },

    /**
     * Add new activity
     */
    handleClickAddDependency() {
      goto("ViewDependencyScenarioNew")
    },

    /**
     *
     * @param {*} dependencyUid
     */
    handleClickEditDependency(dependencyUid) {
      goto("ViewDependencyScenarioDetail", {
        params: {
          uid: dependencyUid,
        },
      })
    },

    /**
     *
     * @param {*} dependencyUid
     */
    handleViewDependencyGraph(dependencyUid) {
      this.toViewDependencyScenarioUid = dependencyUid
      this.isModalShowViewGrapOpen = true
    },

    /**
     * Close view graph
     */
    handleCloseViewGraphModal() {
      this.toViewDependencyScenarioUid = ""
      this.isModalShowViewGrapOpen = false
    },

    async handleClearSearch() {
      this.searchQuery = ""
      this.filters.filter = []
      this.filters.page = {}
      await this.fetchDependencies()
    },

    async handleSearch() {
      // set request param
      this.filters.filter = [
        {
          name: "search",
          type: "contain",
          value: this.searchQuery,
        },
      ]
      this.filters.page = {}

      await this.fetchDependencies()
    },
    /**
     * Open delete resource modal
     */
    handleOpenDeleteModal(dependencyScenarioUid, dependencyScenarioName) {
      this.toDeleteDependencyScenarioUid = dependencyScenarioUid
      this.toDeleteDependencyScenarioName = dependencyScenarioName
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete resource modal
     */
    handleCloseDeleteModal() {
      this.toDeleteDependencyScenarioUid = null
      this.toDeleteDependencyScenarioName = ""
      this.isModalDeleteOpen = false
    },

    /**
     * Callback after delete
     */
    handleDeleteCallback() {
      this.fetchDependencies()
    },
    // ==== PRE-LOAD ==========
    /**
     * Fetch Dependency Factor
     */
    async fetchDependencyFactors() {
      this.isLoadingCategories = true
      const response = await this.getDependencyFactors()
      if (response) {
        this.dependencyFactors = response
      }
      this.isLoadingCategories = false
    },

    setPage(event) {
      this.currentPage = event.target.value
    },
  },
  components: { ModalDeleteResource, ModalViewDependencyGraph },
}
</script>
