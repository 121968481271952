<template>
  <EcBox v-bind="$attrs">
    <EcLabel class="text-base font-medium mb-2">{{ label }}</EcLabel>
    <Editor
      :apiKey="apiKey"
      :init="init"
      :disabled="disabled"
      v-model="internalVal"
      @change="handleContentChange"
      v-bind="$attrs"
    />
  </EcBox>
</template>

<script>
import Editor from "@tinymce/tinymce-vue"
import { ref, watchEffect } from "vue"

export default {
  name: "EcEditor",
  components: { Editor },
  emits: ["update:modelValue"],
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },

    menubar: {
      type: Boolean,
      default: false,
    },
    word: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const plugins = [
      "advlist",
      "autolink",
      "lists",
      "link",
      "image",
      "charmap",
      // "print",
      "preview",
      "anchor",
      "searchreplace",
      "visualblocks",
      // "code",
      "fullscreen",
      "insertdatetime",
      "media",
      "table",
      // "paste",
      // "code",
      "help",
      "wordcount",
      "pagebreak",
    ]

    const toolbar = [
      "undo",
      "redo",
      "formatselect",
      "fontselect",
      "font",
      "bold",
      "italic",
      "backcolor",
      "alignleft",
      "aligncenter",
      "alignright",
      "alignjustify",
      "bullist",
      "removeformat",
      "searchreplace",
      "anchor",
      "link",
      "image",
      "media",
      "numlist",
      "outdent",
      "indent",
      "code",
      "table",
      "pagebreak",
      "help",
    ]

    const contentStyles = `
        body {
          background: #fff;
        }

        /* Disable the blue "focus" border for the editable region */
        .editable-section:focus-visible {
          outline: none !important;
        }

        .header,
        .footer {
          font-size: 0.8rem;
          color: #ddd;
        }

        .header {
          display: flex;
          justify-content: space-between;
          padding: 0 0 1rem 0;
        }

        .header .right-text {
          text-align: right;
        }

        .footer {
          padding:2rem 0 0 0;
          text-align: center;
        }

        /* Apply page-like styling */
        @media (min-width: 840px) {
          html {
            background: #eceef4;
            min-height: 100%;
            padding: 0.5rem;
          }

          body {
            background-color: #fff;
            box-shadow: 0 0 4px rgba(0, 0, 0, .15);
            box-sizing: border-box;
            margin: 1rem auto 0;
            max-width: 80%;;
            min-height: calc(100vh - 1rem);
            padding: 2rem 6rem 2rem 6rem;
          }
        }

        .mce-pagebreak {
          position: absolute;
          background: #eceef4;
          border:none;
          left: 0;
        }
      `
    const init = {
      // selector: "textarea",
      menubar,
      plugins,
      toolbar: toolbar.join(" | "),
      images_upload_url: process.env.VUE_APP_EDITOR_UPLOAD_URL,
      automatic_uploads: true,
      content_style: this.word ? contentStyles : null,
      pagebreak_split_block: true,
      pagebreak_separator: '<br class="page-break" style="page-break-before: always">',
      xss_sanitization: true,
      convert_unsafe_embeds: true,
    }

    return {
      init,
    }
  },
  setup(props) {
    const internalVal = ref("")

    watchEffect(() => {
      internalVal.value = props.modelValue
    })

    return {
      internalVal,
    }
  },
  computed: {
    apiKey() {
      return process.env.VUE_APP_EDITOR_API_KEY
    },
  },
  methods: {
    /**
     * Content change
     */
    handleContentChange() {
      this.$emit("update:modelValue", this.internalVal)
    },

    handleInsertContent(content) {},
  },

  watch: {
    modelValue(value) {
      this.internalVal = value
    },

    internalVal(data) {
      this.$emit("update:modelValue", data)
    },
  },
}
</script>
