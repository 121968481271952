<template>
  <!-- BIA  -->
  <EcBox class="w-full md:w-12/12 mt-6 p-2 rounded-md border border-c1-100">
    <EcLabel class="font-medium text-base mt-2 mb-2"> {{ $t("dashboard.activity.departmentActivityTitle") }}</EcLabel>

    <SummaryRowDivisionSkeleton :rows="2" :loading="isActivityStatisticLoading">
      <EcLabel v-if="isNoActivity" class="text-base italic"> {{ $t("dashboard.activity.noActivity") }}</EcLabel>
      <EcFlex v-permissions:activity-read v-else class="flex-col md:flex-row">
        <!-- Chart -->
        <EcBox class="w-full md:w-4/12">
          <ChartItem :chartInfo="activityStatusPieChart" boxVariant="" />
        </EcBox>
        <!-- List -->
        <EcBox class="w-full md:w-8/12 grid items-center">
          <!-- Division and BU progresses -->
          <EcBox v-for="division in divisionActivityByStatusData" :key="division.uid" class="mb-4">
            <!-- Division -->
            <EcFlex>
              <EcLabel class="text-sm w-3/12 font-medium"> {{ division?.name }} </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="division.activityByStatus || []" valueKey="cnt" labelKey="statusName" />
              </EcFlex>
            </EcFlex>

            <!-- BU -->
            <EcBox v-for="bu in division?.businessUnits" :key="bu.uid" class="mt-6">
              <EcFlex class="mt-2">
                <EcLabel class="text-sm w-3/12 pl-4 italic">
                  {{ bu?.name }}
                </EcLabel>
                <EcFlex class="w-9/12">
                  <EcColorProgressBar :data="bu?.activityByStatus || []" valueKey="cnt" labelKey="statusName" />
                </EcFlex>
              </EcFlex>
            </EcBox>
          </EcBox>

          <!-- Dependent BU -->
          <EcBox class="mt-6" v-if="activityStatistic?.activityByBusinessUnits?.length > 0">
            <EcLabel class="text-sm font-medium">{{ $t("dashboard.activity.denepdentBU") }}</EcLabel>
            <EcFlex v-for="bu in businessUnitWithouDivisionActivityByStatusData" :key="bu.uid" class="mt-2">
              <EcLabel class="text-sm w-3/12 pl-4 italic">
                {{ bu?.name }}
              </EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="bu?.activityByStatus || []" valueKey="cnt" labelKey="statusName" />
              </EcFlex>
            </EcFlex>
          </EcBox>

          <!-- End -->

          <!-- Unassigned SBU -->
          <EcBox class="mt-4" v-if="activityStatistic?.uncategorizedActivitiesByStatus?.length > 0">
            <EcFlex class="mt-2">
              <EcLabel class="text-sm w-3/12 font-medium">{{ $t("dashboard.activity.unassignedSBU") }}</EcLabel>
              <EcFlex class="w-9/12">
                <EcColorProgressBar :data="uncategorizedActivitiesByStatusData || []" valueKey="cnt" labelKey="statusName" />
              </EcFlex>
            </EcFlex>
          </EcBox>

          <!-- End -->
        </EcBox>
      </EcFlex>
    </SummaryRowDivisionSkeleton>
  </EcBox>
</template>

<script>
import { ref } from "vue"
import SummaryRowDivisionSkeleton from "./SummaryRowDivisionSkeleton.vue"
import ChartItem from "../statistic/ChartItem.vue"
import { colorMapping } from "../../use/useColorMapping"
import useDashboardActivityStore from "../../stores/useDashboardActivityStore"
import { storeToRefs } from "pinia"

export default {
  name: "DashboardSummaryDepartmentActivity",

  data() {
    return {
      bias: ref([]),
    }
  },
  setup() {
    const dashboardActivityStore = useDashboardActivityStore()

    const { isActivityStatisticLoading, activityStatistic } = storeToRefs(dashboardActivityStore)

    return {
      isActivityStatisticLoading,
      activityStatistic,
      dashboardActivityStore,
    }
  },
  mounted() {
    this.dashboardActivityStore.getActivityStatistic()
  },
  computed: {
    isNoActivity() {
      return (
        this.activityStatistic?.activityByStatus?.length <= 0 &&
        this.activityStatistic?.activityByDivisions?.length <= 0 &&
        this.activityStatistic?.activityByBusinessUnits?.length <= 0
      )
    },
    /**
     *
     */
    activityStatusPieChart() {
      const labels = []
      const data = []
      const bgColors = []

      this.activityStatistic?.activityByStatus?.forEach((item) => {
        // Labels
        labels.push(item?.statusName)

        // Data
        data.push({
          label: item?.statusName,
          value: item?.cnt,
        })

        bgColors.push(colorMapping[item?.statusColor])
      })

      return {
        id: "activitiesByStatus",
        type: "pie",
        data: {
          labels,
          datasets: [
            {
              data,
              backgroundColor: bgColors,
            },
          ],
        },
      }
    },

    /**
     * Department Acitivities By Status
     */
    divisionActivityByStatusData() {
      const activityStatistic = structuredClone(this.activityStatistic)
      return activityStatistic?.activityByDivisions?.map((item) => {
        // Division statuses
        item.activityByStatus = this.mappingColor(item?.activityByStatus)

        // Business Unit status
        item?.businessUnits?.map((bu) => {
          bu.activityByStatus = this.mappingColor(bu?.activityByStatus)

          return bu
        })

        return item
      })
    },

    /**
     * BU Unit without Division
     */
    businessUnitWithouDivisionActivityByStatusData() {
      const activityStatistic = structuredClone(this.activityStatistic)
      return activityStatistic?.activityByBusinessUnits?.map((bu) => {
        // Division statuses
        bu.activityByStatus = this.mappingColor(bu?.activityByStatus)

        return bu
      })
    },

    /**
     * BU Unit without Division
     */
    uncategorizedActivitiesByStatusData() {
      return this.mappingColor(this.activityStatistic?.uncategorizedActivitiesByStatus)
    },
  },
  methods: {
    /**
     *
     * @param {*} listItems
     */
    mappingColor(listItems) {
      return listItems?.map((item) => {
        item.bgColor = colorMapping[item?.statusColor]
        item.textColor = colorMapping[item?.statusTextColor || "text-cWhite"]

        return item
      })
    },
  },
  components: { SummaryRowDivisionSkeleton, ChartItem },
}
</script>
