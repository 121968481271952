import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchRTOList = async (params) => {
  return fetcher.get("identity/api/v1/recovery-times", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchRTO = async (uid) => {
  return fetcher.get(`identity/api/v1/recovery-times/${uid}`)
}

export const fetchAllRTO = async () => {
  return fetcher.get(`identity/api/v1/recovery-times/all`)
}

export const updateRTO = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/recovery-times/${uid}`, payload)
}

export const deleteRTO = async (uid) => {
  return fetcher.delete(`identity/api/v1/recovery-times/${uid}`)
}

export const createRTO = async (payload) => {
  return fetcher.post("identity/api/v1/recovery-times", payload)
}
