import ViewTenantNotFound from "@/modules/core/views/ViewTenantNotFound"
import ViewUnauthorized from "@/modules/core/views/ViewUnauthorized"

export default [
  {
    path: "/tenant-notfound",
    component: ViewTenantNotFound,
    name: "ViewTenantNotFound",
  },
  {
    path: "/unauthorized",
    component: ViewUnauthorized,
    name: "ViewUnauthorized",
  },
]
