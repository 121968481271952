<template>
  <EcModalSimple :isVisible="modalEditUserDetailOpen" variant="center-md">
    <!-- Header -->
    <EcBox>
      <EcFlex class="mb-6">
        <EcHeadline as="h3" variant="h3">Edit User Detail</EcHeadline>
      </EcFlex>
    </EcBox>

    <!-- Input field -->
    <EcFlex>
      <!-- Firstname -->
      <EcBox>
        <RFormInput
          :value="form.firstName"
          componentName="EcInputText"
          :label="this.$t('user.label.firstName')"
          type="text"
          required="true"
          field="form.firstName"
          :validator="v$"
          @change="
            (event) => {
              form.firstName = event.target.value
              v$.form.firstName.$touch()
            }
          "
        />
      </EcBox>
    </EcFlex>

    <EcFlex class="mt-10">
      <!-- Last name -->
      <EcBox>
        <RFormInput
          :value="form.lastName"
          componentName="EcInputText"
          :label="this.$t('user.label.lastName')"
          type="text"
          required="true"
          field="form.lastName"
          :validator="v$"
          @change="
            (event) => {
              form.lastName = event.target.value
              v$.form.lastName.$touch()
            }
          "
        />
      </EcBox>
    </EcFlex>

    <!-- Buttons -->
    <!-- User data -->
    <EcFlex class="mt-10">
      <EcButton class="mr-4" variant="primary" @click="triggerUpdateUser">
        {{ $t("user.button.update") }}
      </EcButton>
      <EcButton variant="tertiary-outline" @click="$emit('handleClickCancelEditUserDetail')">
        {{ $t("user.button.cancel") }}
      </EcButton>
    </EcFlex>
  </EcModalSimple>
</template>

<script>
import { required } from "@vuelidate/validators"
import { nameValidator } from "@/readybc/composables/helpers/validator"
import useVuelidate from "@vuelidate/core"
import { reactive } from "vue"

export default {
  name: "ModalEditUserDetail",
  emits: ["handleClickCancelEditUserDetail", "handleUpdateUserDetail"],
  props: {
    user: {
      type: Object,
      required: true,
    },
    modalEditUserDetailOpen: Boolean,
  },

  data() {
    return {}
  },

  setup() {
    const form = reactive({})

    const rules = {
      form: {
        firstName: { required, nameValidator },
        lastName: { required, nameValidator },
      },
    }

    const v$ = useVuelidate(rules, { form })

    return {
      form,
      v$,
    }
  },

  methods: {
    triggerUpdateUser() {
      // Validate
      this.v$.form.$touch()
      if (this.v$.form.$invalid) {
        return
      }

      const data = {
        firstName: this.form.firstName,
        lastName: this.form.lastName,
      }
      this.$emit("handleUpdateUserDetail", data)
    },
  },
  watch: {
    user: {
      handler: function (user) {
        this.form.firstName = user.first_name
        this.form.lastName = user.last_name
      },
      deep: true,
    },
  },
}
</script>
