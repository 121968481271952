<template>
  <div :class="variantCls.root">
    <!-- children -->
    <slot name="content">
      <div v-if="showCheckAll" class="flex mt-3 items-center">
        <input
          id="checkedAll"
          type="checkbox"
          v-model="checkAll"
          @change="handleCheckAllChanged"
          :class="[variantCls.checkAllInput]"
        />
        <label for="checkedAll" class="text-base ml-2 font-semibold" title="Select All">
          {{ checkAll ? "Deselect All" : "Select All" }}
        </label>
      </div>
      <div v-for="(item, idx) in populatedItems" :key="idx" :class="[variantCls.wrapper]" class="wrapper">
        <div :class="[variantCls.item, variantCls.parrent]">
          <!-- Caret -->
          <div class="w-3 hover:cursor-pointer mr-1">
            <Caret
              v-if="item[childrenKey]?.length > 0 || item?.hasChildren"
              :class="[getCaretDirectionClass(item, idx)]"
              @click="handleExpandItems(item, idx)"
            />
          </div>

          <input
            type="checkbox"
            :value="item[valueKey]"
            v-model="item.selected"
            :class="[variantCls.input]"
            class="ml-1"
            @change="handleInputChanged($event, item, idx)"
          />
          <label class="ml-1 text-base">{{ item[labelKey] }}</label>
        </div>

        <!-- children -->
        <div v-if="item[childrenKey]?.length > 0 || item?.hasChildren" class="children" v-show="item?.expanded">
          <Spinner v-if="expandingItems[idx]" :class="[variantCls.children]" />

          <div v-else>
            <div v-for="(childItem, cidx) in item[childrenKey]" :key="cidx" :class="[variantCls.item, variantCls.children]">
              <!-- Caret -->
              <div class="w-3 hover:cursor-pointer mr-1">
                <Caret v-if="childItem[childrenKey]?.length > 0"></Caret>
              </div>

              <input
                type="checkbox"
                :value="item[valueKey]"
                v-model="childItem.selected"
                :class="[variantCls.input]"
                class="ml-1"
                @change="handleInputChanged($event, childItem, idx)"
              />
              <label class="ml-1 text-base">{{ childItem[labelKey] }}</label>
            </div>
          </div>
        </div>

        <!-- End children -->
      </div>
    </slot>
  </div>
</template>

<script>
import { reactive, ref } from "vue"
import Caret from "./Caret"
import Spinner from "./Spinner"

export default {
  name: "EcTree",
  emits: ["update:modelValue", "changed", "onCheckAll"],
  props: {
    variant: {
      type: String,
      default: "default",
    },

    valueKey: {
      type: String,
      default: "key",
    },

    checkAllProp: {
      type: Boolean,
      default: false,
    },

    labelKey: {
      type: String,
      default: "label",
    },
    showCheckAll: {
      type: Boolean,
      default: false,
    },

    items: {
      type: Array,
      default: () => [],
    },
    childrenKey: {
      type: String,
      default: "children",
    },
    expandingCallback: {
      type: Function,
      default: (params) => {
        return true
      },
    },
    autoSelectChildren: {
      type: Boolean,
      default: false,
    },
    autoExpand: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    const populatedItems = reactive([])
    const expandingItems = reactive([])

    return {
      populatedItems,
      expandingItems,
    }
  },
  setup() {
    return {
      checkAll: ref(false),
    }
  },
  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcTree",
          variant: this.variant,
        })?.el ?? {}
      )
    },

    /**
     * Selected Items
     */
    selectedItems() {
      const clonedItems = JSON.parse(JSON.stringify(this.populatedItems))
      return clonedItems?.filter((item) => {
        if (item?.selected) {
          item[this.childrenKey] = item[this.childrenKey]?.filter((child) => {
            return child?.selected
          })

          return true
        }

        return false
      })
    },
  },

  mounted() {
    this.checkAll = this.checkAllProp
    this.populatedItems = this.items
    this.expandingItems = Array(this.items?.length).fill(false)

    this.handleCheckAllChanged()
    if (this.autoExpand) {
      this.populatedItems.forEach((item) => {
        item.expanded = true
      })
      this.$emit("update:modelValue", this.populatedItems)
    }
  },

  methods: {
    getCaretDirectionClass(item, idx) {
      return item?.expanded ? "" : "-rotate-90"
    },

    /**
     *
     * @param {*} item
     * @param {*} idx
     */
    async handleExpandItems(item, idx) {
      this.populatedItems[idx].expanded = !this.populatedItems[idx].expanded
      this.$emit("update:modelValue", this.populatedItems)

      if (this.populatedItems[idx].expanded) {
        // Spinner
        this.expandingItems[idx] = true

        await this.expandingCallback(item)

        if (this.autoSelectChildren) {
          item[this.childrenKey]?.forEach((child) => {
            child.selected = item?.selected
          })
        }

        this.expandingItems[idx] = false
      }
    },

    /**
     *
     * @param {*} event
     * @param {*} item
     * @param {*} idx
     */
    handleInputChanged(event, item, idx) {
      if (event.target?.checked) {
        this.populatedItems[idx].selected = true
      }
      item[this.childrenKey]?.forEach((child) => {
        child.selected = event.target?.checked
      })
      this.$emit("update:modelValue", this.populatedItems)
      this.$emit("changed", this.selectedItems)
    },

    /**
     * Handle check all
     */
    handleCheckAllChanged() {
      this.populatedItems?.forEach((item) => {
        item.selected = this.checkAll

        item[this.childrenKey]?.forEach((child) => {
          child.selected = this.checkAll
        })
      })
      this.$emit("onCheckAll", this.checkAll)
      this.$emit("update:modelValue", this.populatedItems)
      this.$emit("changed", this.selectedItems)
    },
  },

  watch: {
    items: {
      handler() {
        this.populatedItems = this.items
      },
      immediate: true,
      deep: true,
    },
  },
  components: {
    Caret,
    Spinner,
  },
}
</script>
