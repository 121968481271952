import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchApplicationList = async (params) => {
  return fetcher.get("identity/api/v1/applications", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchApplication = async (uid) => {
  return fetcher.get(`identity/api/v1/applications/${uid}`)
}

export const fetchAllApplication = async () => {
  return fetcher.get(`identity/api/v1/applications/all`)
}

export const updateApplication = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/applications/${uid}`, payload)
}

export const deleteApplication = async (uid) => {
  return fetcher.delete(`identity/api/v1/applications/${uid}`)
}

export const createApplication = async (payload) => {
  return fetcher.post("identity/api/v1/applications", payload)
}

export const deleteApplicationSlaDoc = (uid, slaDocUid) => {
  return fetcher.delete(`identity/api/v1/applications/${uid}/slas/${slaDocUid}`)
}

export const downloadApplications = () => {
  return fetcher.get(`identity/api/v1/applications/download/all`, {
    responseType: "blob",
  })
}
