<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="items-center flex-wrap">
      <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
        <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
          {{ $t("equipment.title.newEquipment") }}
        </EcHeadline>
      </EcFlex>
    </EcFlex>

    <!-- Body -->
    <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
      <EcText class="font-bold text-lg mb-4">{{ $t("equipment.title.equipmentDetail") }}</EcText>
      <!-- Name -->
      <EcFlex class="flex-wrap max-w-full mb-8">
        <EcBox class="w-full sm:w-6/12 sm:pr-6">
          <RFormInput
            v-model="equipment.name"
            componentName="EcInputText"
            :label="$t('equipment.labels.name')"
            :validator="vldator$"
            field="equipment.name"
            @input="vldator$.equipment.name.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Desc -->
      <EcFlex class="flex-wrap max-w-full">
        <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
          <RFormInput
            v-model="equipment.description"
            componentName="EcInputLongText"
            :rows="2"
            :label="$t('equipment.labels.description')"
            :validator="vldator$"
            field="equipment.description"
            @input="vldator$.equipment.description.$touch()"
          />
        </EcBox>
      </EcFlex>

      <!-- Categories -->
      <EcBox class="flex-wrap max-w-md items-center mb-8">
        <!-- Title and add button -->
        <EcFlex class="mb-3">
          <EcLabel class="text-base font-medium"> {{ $t("equipment.category.label") }}</EcLabel>
          <EcButton
            v-permissions:equipment_category-create
            variant="primary-rounded"
            class="h-6 ml-2"
            id="btn-new-category"
            v-tooltip="{ text: 'New category' }"
            @click="handleOpenAddNewCategoryModal"
          >
            <EcIcon icon="Plus" width="16" height="16" />
          </EcButton>
        </EcFlex>

        <!-- select box -->
        <EcFlex class="items-center">
          <RFormInput
            class="w-full sm:w-6/12"
            v-model="equipment.category"
            componentName="EcMultiSelect"
            :allowSelectNothing="false"
            :isSingleSelect="true"
            :options="categories"
            :valueKey="'uid'"
            :validator="vldator$"
            field="equipment.category"
            @change="vldator$.equipment.category.$touch()"
          />
          <EcSpinner v-if="isLoadingCategories" class="ml-2"></EcSpinner>
        </EcFlex>
      </EcBox>

      <!-- Owners select -->
      <EcBox class="w-full mb-8">
        <EcFlex>
          <EcLabel class="text-base font-medium"> {{ $t("equipment.labels.owners") }}</EcLabel>
          <EcButton
            v-permissions:equipment_owner-create
            variant="primary-rounded"
            class="h-6 ml-2"
            id="btn-new-owner"
            v-tooltip="{ text: 'Did not see owner? Add a new one' }"
            @click="handleOpenAddNewOwnerModal"
          >
            <EcIcon icon="Plus" class="h-6 w-4" />
          </EcButton>
        </EcFlex>

        <!-- Owner row -->
        <EcBox class="items-center mb-2 w-full" v-for="(role, index) in equipment.owners" :key="index">
          <EcFlex class="items-center">
            <RFormInput
              class="w-full sm:w-6/12 sm:pr-6"
              v-model="equipment.owners[index]"
              componentName="EcMultiSelect"
              :isSingleSelect="true"
              :options="filteredOwners"
              :valueKey="'uid'"
              :nameKey="'full_name'"
              :validator="vldator$"
              field="equipment.owners[index].uid"
            />

            <!-- Loading owners -->
            <EcSpinner v-if="isLoadingOwners" class="ml-2"></EcSpinner>

            <!-- Remove button -->
            <EcButton
              v-if="index !== equipment.owners.length - 1"
              class="ml-2 btn-remove-owner"
              variant="tertiary-rounded"
              @click="handleRemoveOwner(index)"
            >
              <EcIcon class="text-c1-400" icon="X" width="16" height="16" />
            </EcButton>

            <!-- Add button -->
            <EcButton
              v-if="index == equipment.owners.length - 1 && equipment.owners.length < owners.length"
              class="ml-2 btn-more-owner"
              variant="primary-rounded"
              v-tooltip="{ text: 'Add more owner who owns this equipment' }"
              @click="handleAddMoreOwner"
            >
              <EcIcon icon="Plus" width="16" height="16" />
            </EcButton>
            <!-- End role select -->
          </EcFlex>

          <!-- Error message -->
          <EcBox v-if="vldator$.equipment.owners.$errors?.length > 0">
            <EcText
              class="text-sm text-cError-500 text-left"
              v-for="error in vldator$.equipment.owners.$each.$response.$errors[index].uid"
              :key="error"
            >
              {{ error.$message }}
            </EcText>
          </EcBox>
          <!-- Add new owner slot -->
        </EcBox>
      </EcBox>
      <!-- End Owners Select -->

      <!-- End body -->
    </EcBox>

    <!-- Actions -->
    <EcBox class="width-full mt-8 px-4 sm:px-10">
      <!-- Button create -->
      <EcFlex v-if="!isLoading" class="mt-6">
        <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleClickCancel">
          {{ $t("equipment.buttons.cancel") }}
        </EcButton>

        <EcButton v-permissions:equipment-create variant="primary" class="ml-4" id="btn-confirm" @click="handleClickConfirm">
          {{ $t("equipment.buttons.confirm") }}
        </EcButton>
      </EcFlex>

      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
    <!-- End actions -->

    <!-- Modal add new equipment owner -->
    <teleport to="#layer1">
      <ModalAddNewOwner
        :isModalAddNewOwnerOpen="isModalAddNewOwnerOpen"
        @handleCloseAddNewOwnerModal="handleCloseAddNewOwnerModal"
        @handleCallbackAddNewOwner="handleCallbackAddNewOwner"
      ></ModalAddNewOwner>
    </teleport>

    <!-- Modal add new equipment category -->
    <teleport to="#layer2">
      <ModalAddNewCategory
        :isModalAddNewCategoryOpen="isModalAddNewCategoryOpen"
        @handleCloseAddNewCategoryModal="handleCloseAddNewCategoryModal"
        @handleCallbackAddNewCategory="handleCallbackAddNewCategory"
      />
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useEquipmentNew } from "@/modules/equipment/use/equipment/useEquipmentNew"
import { useCategoryList } from "@/modules/equipment/use/category/useCategoryList"
import { useEquipmentStatusEnum } from "@/modules/equipment/use/equipment/useEquipmentStatusEnum"
import { useOwnerList } from "@/modules/equipment/use/owner/useOwnerList"
import { useGlobalStore } from "@/stores/global"
import ModalAddNewOwner from "@/modules/equipment/components/ModalAddNewOwner.vue"
import ModalAddNewCategory from "../../components/ModalAddNewCategory.vue"

export default {
  name: "ViewEquipmentNew",
  data() {
    return {
      isModalAddNewOwnerOpen: false,
      isModalAddNewCategoryOpen: false,
      isLoading: false,
      isLoadingOwners: false,
      isLoadingCategories: false,
      categories: [],
      owners: [],
    }
  },
  mounted() {
    this.fetchEquipmentCategories()
    this.fetchOwners()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { getOwnerListAll } = useOwnerList()
    const { getEquipmentCategoryList } = useCategoryList()
    const { equipment, vldator$, createNewEquipment } = useEquipmentNew()
    const { statuses } = useEquipmentStatusEnum()

    return {
      createNewEquipment,
      getOwnerListAll,
      getEquipmentCategoryList,
      equipment,
      vldator$,
      globalStore,
      statuses,
    }
  },
  computed: {
    /**
     * Filtered owners
     */
    filteredOwners() {
      const selectedOwnerUids = this.equipment.owners.map((r) => {
        return r.uid
      })
      return this.owners.map((owner) => {
        owner.disabled = selectedOwnerUids.includes(owner.uid)
        return owner
      })
    },
  },
  methods: {
    // =========== Owners ================ //
    /**
     * Add more owners
     */
    handleAddMoreOwner() {
      this.equipment.owners.push({ uid: "" })
    },

    /**
     * Remove item in array
     * @param {*} index
     */
    handleRemoveOwner(index) {
      this.equipment.owners.splice(index, 1)
    },

    /**
     * Create equipment
     *
     */
    async handleClickConfirm() {
      this.vldator$.$touch()
      if (this.vldator$.equipment.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.createNewEquipment(this.equipment)

      this.isLoading = false
      if (response) {
        goto("ViewEquipmentList")
      }
    },

    /**
     * Cancel add new equipment
     */
    handleClickCancel() {
      goto("ViewEquipmentList")
    },

    /**
     * Open Add New Onwner modal
     */
    handleOpenAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = true
    },

    /**
     * Open cancel Add new Owner modal
     */
    handleCloseAddNewOwnerModal() {
      this.isModalAddNewOwnerOpen = false
    },

    /**
     * Open Add New Category modal
     */
    handleOpenAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = true
    },

    /**
     * Open cancel Add new Category modal
     */
    handleCloseAddNewCategoryModal() {
      this.isModalAddNewCategoryOpen = false
    },

    /**
     * Callback after add new owner
     */
    handleCallbackAddNewOwner() {
      this.fetchOwners()
    },

    /**
     * Callback after add new category
     */
    handleCallbackAddNewCategory() {
      this.fetchEquipmentCategories()
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch roles
     */
    async fetchOwners() {
      this.isLoadingOwners = true
      const response = await this.getOwnerListAll()
      if (response) {
        this.owners = response
      }
      this.isLoadingOwners = false
    },
    /**
     * Fetch Categories
     */
    async fetchEquipmentCategories() {
      this.isLoadingCategories = true
      const response = await this.getEquipmentCategoryList()
      if (response) {
        this.categories = response
      }
      this.isLoadingCategories = false
    },
  },
  components: { ModalAddNewOwner, ModalAddNewCategory },
}
</script>
