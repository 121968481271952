import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchBIAStatusList = async (params) => {
  return fetcher.get("identity/api/v1/bias-statuses", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchBIAStatus = async (uid) => {
  return fetcher.get(`identity/api/v1/bias-statuses/${uid}`)
}

export const fetchAllBIAStatus = async () => {
  return fetcher.get(`identity/api/v1/bias-statuses`)
}

export const updateBIAStatus = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/bias-statuses/${uid}`, payload)
}

export const deleteBIAStatus = async (uid) => {
  return fetcher.delete(`identity/api/v1/bias-statuses/${uid}`)
}

export const createBIAStatus = async (payload) => {
  return fetcher.post("identity/api/v1/bias-statuses", payload)
}
