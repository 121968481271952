import GenerateReport from "@/modules/report/views/GenerateReport.vue"
import ViewReport from "@/modules/report/views/ViewReport"
import ViewListReport from "@/modules/report/views/ViewReportList.vue"
import ViewReportTemplateRevision from "@/modules/report/views/ViewReportTemplateRevision.vue"
import ViewReportTemplates from "@/modules/report/views/ViewReportTemplates"
import ViewConfigurableReportTemplates from "@/modules/report/views/ViewConfigurableReportTemplates"

export default [
  {
    path: "/reports",
    component: ViewListReport,
    name: "ViewReportList",
    props: true,
    meta: {
      permissions: "report.read",
      module: "report",
    },
  },

  {
    path: "/reports/generator",
    component: GenerateReport,
    name: "GenerateReport",
    props: true,
    meta: {
      permissions: "report.create",
      module: "report",
    },
  },

  {
    path: "/reports/:uid",
    component: ViewReport,
    name: "ViewReportDetail",
    props: true,
    meta: {
      permissions: "report.read",
      module: "report",
    },
  },

  {
    path: "/settings/report-templates",
    component: ViewConfigurableReportTemplates,
    name: "ViewConfigurableReportTemplates",
    props: false,
    meta: {
      permissions: "report_template.read",
      module: "settings",
    },
  },

  {
    path: "/settings/report-templates/:type",
    component: ViewReportTemplates,
    name: "ViewReportTemplate",
    props: true,
    meta: {
      permissions: "report_template.read",
      module: "settings",
    },
  },

  {
    path: "/settings/report-templates/:type/:uid",
    component: ViewReportTemplateRevision,
    name: "ViewReportTemplateRevision",
    props: true,
    meta: {
      permissions: "report_template.read",
      module: "settings",
    },
  },
]
