<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="flex-wrap items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("user.users") }}
        </EcHeadline>
      </EcFlex>

      <!-- Search box -->
    </EcFlex>
    <!-- User filter -->

    <UserSubMenu />

    <!-- Filter -->
    <EcBox class="grid grid-cols-2">
      <EcFlex class="items-center">
        <!-- From -->
        <RDataFilter v-model="apiCriteria" :filterOptions="filterOptions" @appliedFilters="fetchUserList" />
      </EcFlex>
      <EcFlex class="justify-end mt-2">
        <EcButton
          v-permissions:user-create
          variant="primary-sm"
          class="mb-3 lg:mb-0"
          id="add-user"
          iconPrefix="plus-circle"
          @click="handleClickAddUser()"
        >
          {{ $t("user.button.addUser") }}
        </EcButton>
      </EcFlex>
    </EcBox>

    <!-- Table -->
    <EcTable
      name="tbl-users"
      :columns="columns"
      :dataSource="users"
      :actionMenu="true"
      :menuItems="menuItems"
      :isLoading="isLoading"
      @sorted="handleSort"
    >
      <template v-slot:is_verified="{ item }">
        <EcBadge :variant="item?.is_verified == 'true' ? 'success' : 'info'" class="px-2 py-1 rounded-md min-w-48 justify-center">
          {{ getEmailConfirmationStatus(item.is_verified) }}
        </EcBadge>
      </template>

      <template v-slot:status="{ item }">
        <EcBadge
          :variant="item?.status == STATUS_CONFIRMED ? 'success' : 'warning'"
          class="px-2 py-1 rounded-md min-w-48 justify-center"
        >
          {{ item?.status }}
        </EcBadge>
      </template>

      <template v-slot:is_suspended="{ item }">
        {{ item?.is_suspended == 1 ? "False" : "True" }}
      </template>
    </EcTable>

    <!-- Pagination -->
    <EcFlex class="flex-col mt-8 sm:mt-12 sm:flex-row" variant="basic">
      <RPaginationStatus
        :currentPage="apiCriteria.pagination.page"
        :limit="apiCriteria.pagination.size"
        :totalCount="apiCriteria.pagination.total"
        class="mb-4 sm:mb-0"
      />
      <RPagination
        v-model="apiCriteria.pagination.page"
        :itemPerPage="apiCriteria.pagination.size"
        :totalItems="apiCriteria.pagination.total"
        @pageSizeChanged="
          (size) => {
            apiCriteria.pagination.size = parseInt(size)
          }
        "
      />
    </EcFlex>
  </RLayout>
</template>

<script>
import UserSubMenu from "@/modules/user/components/UserSubMenu.vue"
import { formatData, goto } from "@/modules/core/composables"
import { handleErrorForUser } from "@/modules/user/api/handleErrorForUser.js"
import { useUserList } from "@/modules/user/use/useUserList"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewUserList",
  components: { UserSubMenu },
  setup() {
    const globalStore = useGlobalStore()

    const {
      apiCriteria,
      users,
      isLoading,
      handleSort,
      fetchUserList,
      reinviteUser,
      entityName,
      permissionGroup,
      STATUS_CONFIRMED,
      STATUS_UNCONFIRMED,
    } = useUserList()

    return {
      globalStore,
      apiCriteria,
      fetchUserList,
      entityName,
      permissionGroup,
      reinviteUser,
      STATUS_CONFIRMED,
      STATUS_UNCONFIRMED,
      users,
      isLoading,
      handleSort,
    }
  },
  data() {
    const columns = [
      {
        title: "Name",
        key: "name",
        sortKey: "first_name",
        sortable: true,
      },
      {
        title: this.$t("user.label.emailOrUsername"),
        key: "email",
        sortable: true,
      },
      {
        title: this.$t("user.label.status"),
        key: "status",
        sortKey: "status",
        sortable: true,
      },
      {
        title: this.$t("user.label.active"),
        key: "is_suspended",
        sortKey: "is_suspended",
        sortable: true,
      },
      {
        title: this.$t("user.label.createdAt"),
        key: "created_at",
        sortable: true,
        date: true,
      },
    ]

    const menuItems = [
      {
        label: "View",
        icon: "edit",
        iconClass: "text-c1-800",
        callback: this.handleClickView,
      },
      ...(this.$permissions("user.reinvite")
        ? [
            {
              label: "Re-Invite",
              icon: "MailOpen",
              iconClass: "text-c1-700",
              callback: this.handleClickReinvite,
            },
          ]
        : []),
      {
        label: "SBU Assignment",
        icon: "OfficeBuilding",
        iconClass: "text-cError-700",
        callback: this.handleClickAssign,
      },
    ]

    const filterOptions = [
      {
        key: "email",
        title: this.$t("user.label.emailOrUsername"),
      },
      {
        key: "created_at",
        title: this.$t("user.label.createdAt"),
        type: "date",
      },
    ]

    return {
      // Table
      columns,
      menuItems,
      filterOptions,
    }
  },

  async beforeMount() {
    await this.fetchUserList()
  },

  async mounted() {
    this.getPermissionGroup()
  },

  computed: {
    clientId() {
      return this.globalStore.getClientId
    },
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },

  methods: {
    formatData,

    /**
     *
     */
    async getPermissionGroup() {
      const data = [{}]
      const error = null
      if (error) handleErrorForUser({ error, $t: this.$t })
      else {
        this.roleOptions = data.map((x) => ({
          name: x.name,
          value: x.id,
        }))
      }
    },

    handleClickAddUser() {
      // Go to User New Page
      goto("ViewUserNew")
    },

    /**
     * Toggle filter
     */
    toggleFilter() {
      this.isFilter = !this.isFilter
    },

    /**
     *
     * @param {*} value
     */
    getEmailConfirmationStatus(value) {
      return value === "true" ? "Confirmed" : "UnConfirmed"
    },

    /**
     *
     * @param {*} value
     */
    getEmailConfirmationStatusType(value) {
      return value === "true" ? "pill-cSuccess-inv" : "pill-c1"
    },

    /**
     *
     * @param {*} value
     */
    getStatusType(value) {
      if (value === this.STATUS_UNCONFIRMED) {
        return "pill-disabled"
      }
      if (value === this.STATUS_CONFIRMED) {
        return "pill-cSuccess-inv"
      }
      return value ? "pill-cSuccess-inv" : "pill-c1"
    },

    /**
     *
     * @param {*} value
     */
    getStatusLabel(value) {
      return value ? "Active" : "Pending"
    },

    /**
     *
     */

    handleClickView(item) {
      // Go to User Detail Page
      goto("ViewUserDetail", {
        params: {
          userId: item.uid,
        },
      })
    },

    async handleClickReinvite(item) {
      const { error } = await this.reinviteUser(item.uid)
      if (error) {
        handleErrorForUser({ error, $t: this.$t })
      } else {
        this.globalStore.addSuccessToastMessage(this.$t("user.message.reinviteSuccess"))
      }
    },

    handleClickAssign(item) {
      // Go to User Detail Page
      goto("ViewUserAssign", {
        params: {
          userId: item.uid,
        },
      })
    },
  },
}
</script>
