export function useBroadcastChannel() {
  const broadcastChannelEnum = {
    ALL_TABS_IDLE: "all_tabs_idle",
    CONTINUE_BROWSING: "continue_browsing",
  }
  const createBroadcastChannel = (name) => {
    return new BroadcastChannel(name)
  }

  return {
    broadcastChannelEnum,
    createBroadcastChannel,
  }
}
