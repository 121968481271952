<template>
  <RLayout>
    <RLayoutTwoCol :title="$t('user.newUser')">
      <template #left>
        <EcBox variant="card-1" class="max-w-2xl mt-8 px-4 sm:px-10 min-h-lg">
          <EcBox class="mb-2">User Detail</EcBox>

          <EcFlex class="flex-wrap max-w-md">
            <EcBox class="w-full sm:w-6/12 mb-6 sm:pr-6">
              <RFormInput
                v-model="form.firstName"
                componentName="EcInputText"
                :label="$t('user.label.firstName')"
                :validator="v$"
                field="form.firstName"
                @input="v$.form.firstName.$touch()"
              />
            </EcBox>
            <EcBox class="w-full sm:w-6/12 mb-6 sm:pl-6">
              <RFormInput
                v-model="form.lastName"
                componentName="EcInputText"
                :label="$t('user.label.lastName')"
                :validator="v$"
                field="form.lastName"
                @input="v$.form.lastName.$touch()"
              />
            </EcBox>
          </EcFlex>

          <EcFlex class="flex-wrap max-w-md">
            <EcBox class="w-full mb-6">
              <RFormInput
                v-model="form.username"
                componentName="EcInputText"
                :label="$t('user.label.email')"
                :validator="v$"
                field="form.username"
                @input="v$.form.username.$touch()"
              />
            </EcBox>
          </EcFlex>

          <EcFlex class="flex-wrap">
            <EcBox class="w-full max-w-md mb-6">
              <RFormInput
                v-model="form.roles"
                componentName="EcMultiSelect"
                :label="$t('user.label.role')"
                :placeholder="$t('role.placeholder.assignRole')"
                :options="roleOptions"
                :valueKey="'uid'"
                :validator="v$"
                field="form.roles"
                @input="v$.form.roles.$touch()"
              />
            </EcBox>
            <EcSpinner v-if="isLoadingRoles" class="ml-2 mt-8" />
          </EcFlex>

          <!-- Buttons -->
          <EcFlex v-if="!isLoading" class="mt-6">
            <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleClickCancel()">
              {{ $t("user.button.cancel") }}
            </EcButton>
            <EcButton v-permissions:user-create class="ml-4" id="btn-create" variant="primary" @click="handleClickCreate()">
              {{ $t("user.button.create") }}
            </EcButton>
          </EcFlex>

          <!-- Loading box -->
          <EcBox v-else class="flex items-center mt-6 h-10">
            <Loading size="2rem" class="text-c1-500" />
          </EcBox>
        </EcBox>
      </template>
      <template #right v-if="!globalStore.isLandlord">
        <EcBox variant="card-1" class="max-w-2xl mt-8 px-4 sm:px-10 min-h-lg">
          <EcBox class="mb-2">SBUs Assignment</EcBox>

          <EcTree
            v-if="!isSbuLoading"
            :modelValue="divisions"
            :items="divisions"
            childrenKey="business_units"
            labelKey="name"
            valueKey="uid"
            class="max-h-[50vh]"
            :autoExpand="true"
            :autoSelectChildren="true"
            :showCheckAll="true"
            :checkAllProp="true"
            @changed="
              (data) => {
                form.selectedSBUs = data
              }
            "
            @onCheckAll="
              (val) => {
                formFilters.checkAll = val
              }
            "
          />
        </EcBox>
      </template>
    </RLayoutTwoCol>
  </RLayout>
</template>

<script>
import Loading from "@/modules/user/components/Loading"
import { goto } from "@/modules/core/composables"
import { useUserNew } from "../use/useUserNew"
import { useRoleList } from "../use/useRoleList"
import { useSBU } from "../use/useSBU"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewUserNew",
  components: { Loading },
  data() {
    return {
      isLoading: false,
      roleOptions: [],
      isLoadingRoles: false,
    }
  },
  setup(props) {
    const { form, v$, createNewUser } = useUserNew()
    const { getRoles } = useRoleList()
    const { divisions, fetchSBUs, formFilters, isLoading: isSbuLoading } = useSBU()
    const globalStore = useGlobalStore()
    return {
      form,
      v$,
      createNewUser,
      getRoles,
      divisions,
      fetchSBUs,
      formFilters,
      isSbuLoading,
      globalStore,
    }
  },
  computed: {},

  async mounted() {
    await this.fetchRoles()

    if (!this.globalStore.isLandlord) {
      await this.fetchSBUs()
    }
  },

  methods: {
    /**
     * Fetch roles
     */
    async fetchRoles() {
      this.isLoadingRoles = true
      const roles = await this.getRoles()

      this.roleOptions = roles.map((role) => {
        return {
          name: role.label,
          uid: role.uid,
        }
      })

      this.isLoadingRoles = false
    },

    /**
     * Create user
     */
    async handleClickCreate() {
      // Validate
      this.v$.form.$touch()
      if (this.v$.form.$invalid) {
        return
      }

      this.isLoading = true

      await this.createNewUser(this.form)

      this.isLoading = false
    },

    /**
     * Invite user
     */
    async inviteInternal() {
      const error = null
      const data = null
      return { data, error }
    },

    /**
     * Cancel
     */
    handleClickCancel() {
      // Go to User List Page
      goto("ViewUserList")
    },
  },
}
</script>
