<template>
  <!-- Activities  -->
  <EcBox class="w-full md:w-10/12 mt-8">
    <EcLabel class="font-semibold mt-6">{{ $t("dashboard.activity.criticalActivityTitle") }}</EcLabel>

    <!-- Activities -->
    <EcBox v-if="!isCriticalActivityLoading">
      <EcLabel v-if="isNoActivity" class="text-base italic mt-1">
        {{ $t("dashboard.activity.noActivity") }}
      </EcLabel>

      <EcFlex v-if="!isNoActivity">
        <EcBox class="w-9/12">
          <EcLabel class="text-base">{{ $t("dashboard.activity.name") }}</EcLabel>
        </EcBox>
        <EcBox class="w-3/12 pl-2">
          <EcLabel class="text-base">{{ $t("dashboard.activity.mtpd") }}</EcLabel>
        </EcBox>
      </EcFlex>
      <EcFlex v-for="(activity, idx) in criticalActivities" :key="activity.uid" class="items-center mb-2">
        <!-- Activity Row -->
        <EcFlex class="w-9/12">
          <RFormInput
            v-model="criticalActivities[idx].name"
            iconPrefix="DActivityDoc"
            variant="primary-dashboard"
            :readonly="true"
            componentName="EcInputText"
            @click="handleClickActivity(activity)"
            class="hover:cursor-pointer text-base"
          />
        </EcFlex>

        <!-- MTPD -->
        <EcFlex class="w-3/12 items-center">
          <EcText class="grid items-center w-full ml-2 h-10 mt-1 bg-c1-800 text-cWhite break-" variant="square-pill-wrapper">
            {{ getMTPD(activity) }}</EcText
          >
        </EcFlex>
      </EcFlex>
    </EcBox>

    <!-- Skeleton -->
    <EcBox v-else>
      <EcFlex v-for="item in 2" :key="item" class="items-center mb-2">
        <!-- Activity Row -->
        <div class="flex items-center input w-full bg-c4-50 h-10 rounded-lg skeleton mt-1 mb-2 py-2">
          <div class="w-6 h-6 ml-6 rounded bg-c4-50"></div>
          <div class="w-10/12 h-4 ml-6 rounded bg-c4-50"></div>
        </div>
      </EcFlex>
    </EcBox>

    <EcFlex
      v-if="!isNoActivity"
      class="mt-6 text-base text-c3-100 justify-end hover:cursor-pointer text"
      @click="handleClickSeeAll"
    >
      {{ $t("dashboard.activity.seeAll") }}
    </EcFlex>
  </EcBox>
</template>

<script>
import { goto } from "@/modules/core/composables"
import useDashboardActivityStore from "../../stores/useDashboardActivityStore"
import { storeToRefs } from "pinia"

export default {
  name: "DashboardSummaryActivity",

  setup() {
    const dashboardActivityStore = useDashboardActivityStore()

    const { isCriticalActivityLoading, criticalActivities } = storeToRefs(dashboardActivityStore)
    return {
      isCriticalActivityLoading,
      criticalActivities,
      dashboardActivityStore,
    }
  },

  mounted() {
    this.dashboardActivityStore.getCriticalActivities()
  },
  computed: {
    isNoActivity() {
      return this.criticalActivities?.length <= 0
    },
  },
  methods: {
    /**
     * See All
     */
    handleClickSeeAll() {
      goto("ViewActivityList")
    },

    handleClickActivity(activity) {
      goto("ViewActivityDetail", {
        params: {
          uid: activity?.uid,
        },
      })
    },

    /**
     *
     * @param {*} activity
     */
    getMTPD(activity) {
      const mtpd = activity?.tolerable_period_disruptions[0]
      return mtpd?.name || "N/A"
    },
  },
}
</script>
