import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/locationsFetcher"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"

export function useLocationNew() {
  const globalStore = useGlobalStore()
  const location = ref({})

  const rules = {
    location: {
      name: { required },
      description: {},
    },
  }

  const validator$ = useVuelidate(rules, { location })

  /**
   * Create location
   * @param {*} payload
   * @returns
   */
  async function createLocation(payload) {
    try {
      const { data } = await api.createLocation(payload)

      globalStore.addSuccessToastMessage(this.$t("setting.general.messages.createSuccess"))

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.general.messages.createError"))
    }
  }

  return {
    createLocation,
    location,
    validator$,
  }
}
