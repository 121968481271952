export const menuModules = [
  // Dashboard for landlord
  {
    module: "dashboard",
    icon: "Dashboard",
    text: "core.dashboard",
    routeName: "ViewDashboard",
  },
  // Dashboard for client
  {
    module: "bright_dashboard",
    icon: "Dashboard",
    text: "core.dashboard",
    routeName: "ViewBrightDashboard",
  },
  {
    module: "dependency_v2",
    icon: "Dependency",
    text: "core.dependenciesv2",
    routeName: "ViewDependencyScenarioListV2",
  },
  {
    module: "organization",
    icon: "OfficeBuilding",
    text: "core.organizations",
    routeName: "ViewOrganizationList",
  },
  {
    module: "department",
    icon: "OfficeBuilding",
    text: "core.departments",
    routeName: "ViewDepartmentManagement",
  },
  {
    module: "activity",
    icon: "Activity",
    text: "core.activities",
    routeName: "ViewActivityList",
    subItems: [],
  },

  {
    module: "critical5",
    icon: "ShieldCheck",
    iconHeight: "24",
    iconWidth: "24",
    text: "core.contunity5",
    landlord: false,
    subItems: [
      {
        module: "application",
        icon: "Window",
        text: "core.applications",
        routeName: "ViewApplicationList",
        subItems: [],
      },
      {
        module: "equipment",
        icon: "Resource",
        text: "core.equipment",
        routeName: "ViewEquipmentList",
      },
      {
        module: "supplier",
        icon: "Supplier",
        text: "core.suppliers",
        routeName: "ViewSupplierList",
      },
      {
        module: "people",
        icon: "People",
        text: "core.people",
        routeName: "#",
        placeholder: true,
        landlord: false,
      },
      {
        module: "location",
        icon: "Location",
        text: "core.location",
        routeName: "#",
        placeholder: true,
        landlord: false,
      },
    ],
  },

  {
    module: "industry",
    icon: "Industry",
    text: "core.industries",
    routeName: "ViewIndustryList",
  },

  {
    module: "assessment",
    icon: "Assessment",
    text: "core.assessment",
    routeName: "ViewBIAList",
  },
  {
    module: "resilience_plan",
    icon: "BCP",
    text: "core.resiliencePlan",
    routeName: "ViewResiliencePlanList",
  },
  {
    module: "report",
    icon: "Report",
    text: "core.reports",
    routeName: "ViewReportList",
    landlordUser: false,
  },
  {
    module: "user",
    icon: "User",
    text: "core.users",
    routeName: "ViewUserList",
  },
  // {
  //   module: "chatroom",
  //   icon: "chat-alt",
  //   text: "core.chatRooms",
  //   routeName: "ViewChatroomListing",
  // },
]

export default { menuModules }
