import { reactive, ref, watch } from "vue"
import * as api from "../../api/supplierFetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ApiCriteria } from "@/readybc/composables/helpers/apiQuery/apiCriteria"
import { SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

export function useSupplierList() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()

  const suppliers = ref([])
  const isLoading = ref(false)
  const apiCriteria = reactive(new ApiCriteria())

  apiCriteria.pagination.size = 20

  /**
   *
   * @param {*} activityStatistic
   * @returns
   */
  async function getSuppliers(activityStatistic = false) {
    if (activityStatistic) {
      apiCriteria.setQueries({ activity_statistic: true })
    }

    try {
      isLoading.value = true
      const { data } = await api.fetchSupplierList(apiCriteria.toQuery())

      if (data && data.data) {
        suppliers.value = data.data

        // Pagination
        apiCriteria.pagination.total = data.total
      }

      isLoading.value = false
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("supplier.errors.listSupplier"))
      isLoading.value = false
    }
  }

  /**
   * Change current page
   */
  watch(
    () => apiCriteria.pagination.page,
    (current, oldVal) => {
      if (current !== oldVal) {
        getSuppliers()
      }
    },
    { deep: true }
  )

  /**
   * Change page size
   */
  watch(
    () => apiCriteria.pagination.size,
    (current, oldVal) => {
      if (current !== oldVal) {
        apiCriteria.resetPage()
        getSuppliers(true)
      }
    },
    { deep: true }
  )

  /**
   *
   * @param {*} data
   */
  const handleSort = (col, sorted) => {
    const direction = sorted ? SortDirectionEnum.ASC : SortDirectionEnum.DESC

    apiCriteria.setSort(col?.sortKey || col?.key, direction, col?.customSort)

    apiCriteria.resetPage()

    getSuppliers()
  }

  /**
   *
   * @returns
   */
  async function downloadSuppliers() {
    try {
      const { data } = await api.downloadSuppliers()

      if (!data) {
        globalStore.addErrorToastMessage(this.$t("supplier.errors.download"))
        return
      }

      const url = window.URL.createObjectURL(new Blob([data]))
      const link = document.createElement("a")
      link.href = url

      link.setAttribute("download", `Suppliers_${Date.now()}.xlsx`)
      document.body.appendChild(link)
      link.click()
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("supplier.errors.download"))
    }
  }

  return {
    downloadSuppliers,
    globalStore,
    getSuppliers,
    handleSort,
    suppliers,
    apiCriteria,
    isLoading,
    t,
  }
}
