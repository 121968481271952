import { ref } from "vue"
import * as api from "../../api/divisionFetcher"

export const useDivisionList = () => {
  const currentPage = ref(1)

  const getDivisions = async (param) => {
    try {
      const { data } = await api.fetchDivisionList(param)

      return data
    } catch (error) {}
  }

  return { currentPage, getDivisions }
}
