import { SearchJoinTypeEnum, TypeCompareEnum, SortDirectionEnum } from "@/readybc/composables/helpers/apiQuery/apiQueryEnum"

/**
 * @deprecated
 * use apiCriteria instead
 */
export class APIQueryBuilder {
  filters = {}
  advancedFilters = {}
  sorts = {}
  pagination = {}
  relations = {}
  searchJoin = SearchJoinTypeEnum.AND
  constructor(
    filters = {},
    sorts = {},
    pagination = {
      page: 1,
      per_page: 10,
      total: 0,
    },
    relations = {}
  ) {
    this.relations = relations
    this.filters = filters
    this.sorts = sorts
    this.pagination = pagination
  }

  /**
   *
   * Set filter values
   * @param {*} field
   * @param {*} value
   * @param {*} typeCompare
   * @returns
   */
  setFilter(field, value, typeCompare = TypeCompareEnum.EQUAL) {
    if (value.length === 0) {
      delete this.filters[field]
    } else {
      this.filters[field] = {
        field,
        value,
        typeCompare,
      }
    }

    return this
  }

  /**
   * Clear sort by field name
   * @param {*} field
   * @returns
   */
  clearSort(field) {
    delete this.sorts[field]
    return this
  }

  /**
   * Clear all sorts
   * @returns
   */
  clearSorts() {
    this.sorts = {}
    return this
  }

  /**
   * Clear filter by field name
   * @param {*} field
   * @returns
   */
  clearFilter(field) {
    delete this.filters[field]
    return this
  }

  /**
   * Clear all filters
   * @returns
   */
  clearFilters() {
    this.filters = {}

    return this
  }

  /**
   * Set sort by field name and direction
   * @param {*} field
   * @param {*} direction
   * @returns
   */
  setSorts(field, direction = SortDirectionEnum.DESC) {
    this.sorts[field] = {
      field,
      direction,
    }

    return this
  }

  /**
   *
   * Set search join
   * @param {*} searchJoin
   * @returns
   */
  setSearchJoin(searchJoin) {
    this.searchJoin = searchJoin
    return this
  }

  /**
   * Return sort options to API query params
   * @returns
   */
  getSortOptions = () => {
    const orderByArray = []
    const sortedByArray = []
    Object.entries(this.sorts)
      .filter((n) => n)
      .forEach(([key, sort]) => {
        orderByArray.push(sort.field)
        sortedByArray.push(sort.direction)
      })
    if (orderByArray.length > 0) {
      return {
        orderBy: orderByArray.join(";"),
        sortedBy: sortedByArray.join(";"),
      }
    }
    return {}
  }

  /**
   *
   * Return filter options for API query params
   * @returns
   */
  getFilterOptions = () => {
    const searchArray = []
    const searchFields = []
    Object.entries(this.filters)
      .filter((n) => n)
      .forEach(([key, filter]) => {
        searchArray.push(`${filter.field}:${filter.value}`)
        searchFields.push(`${filter.field}:${filter.typeCompare}`)
      })

    if (searchArray.length > 0) {
      return {
        search: searchArray.join(";"),
        searchFields: searchFields.join(";"),
        searchJoin: this.searchJoin,
      }
    }
    return {}
  }

  getPaginations = () => {
    return {
      page: this.pagination.page,
      per_page: this.pagination.per_page,
    }
  }

  /**
   *
   * Set advanced filter values
   * @param {*} field
   * @param {*} value
   * @param {*} typeCompare
   * @returns
   */
  setAdvancedFilter(field, value, typeCompare = TypeCompareEnum.EQUAL) {
    if (value.length === 0) {
      delete this.advancedFilters[field]
    } else {
      this.advancedFilters[field] = {
        field,
        value,
        typeCompare,
      }
    }

    return this
  }

  /**
   * Get advanced filter option
   */
  getAdvancedFilterOptions = () => {
    const searchArray = []
    const searchFields = []

    Object.entries(this.advancedFilters)
      .filter((n) => n)
      .forEach(([key, filter]) => {
        searchArray.push(`${filter.field}:${filter.value}`)
        searchFields.push(`${filter.field}:${filter.typeCompare}`)
      })

    if (searchArray.length > 0) {
      return {
        advancedSearch: searchArray.join(";"),
        advancedSearchFields: searchFields.join(";"),
        searchJoin: this.searchJoin,
      }
    }
    return {}
  }

  /**
   * Clear all advanced filters
   * @returns
   */
  clearAdvancedFilters() {
    this.advancedFilters = {}

    return this
  }

  /**
   * Set page to query
   * @param {*} page
   * @returns
   */
  setPage(page) {
    this.pagination.page = page

    return this
  }

  /**
   * Set page to query
   * @param {*} page
   * @returns
   */
  setTotalPage(total) {
    this.pagination.total = total

    return this
  }

  /**
   * ========= Relations ====
   */

  withRelation(relation) {
    this.relations[relation] = relation
  }

  withRelations(relations = []) {
    this.relations = {}
    relations?.forEach((relation) => {
      this.relations[relation] = relation
    })
  }

  removeRelation(relation) {
    delete this.relations[relation]
  }

  removeRelations() {
    this.relations = {}
  }

  getRelations() {
    return { with: Object.keys(this.relations).join(";") }
  }

  /**
   *
   * Build query
   * @returns
   */
  toQuery() {
    return {
      ...this.getFilterOptions(),
      ...this.getAdvancedFilterOptions(),
      ...this.getSortOptions(),
      ...this.getPaginations(),
      ...this.getRelations(),
    }
  }
}
