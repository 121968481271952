<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addRTO") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="rto.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="rto.name"
          @input="validator$.rto.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="rto.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="rto.description"
          @input="validator$.rto.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Value -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="rto.value"
          componentName="EcInputNumber"
          :label="$t('setting.general.labels.valueInHour')"
          :step="1"
          :min="1"
          :max="100"
          :validator="validator$"
          field="rto.value"
          @input="validator$.rto.value.$touch()"
        />
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateRTO">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useRTONew } from "../use/master/rto/useRTONew"

export default {
  name: "ModalAddRTO",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { rto, validator$, createRTO } = useRTONew()
    return {
      rto,
      validator$,
      createRTO,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateRTO() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createRTO(this.rto)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewRTOOpen() {
      this.rto.name = null
      this.rto.description = null
      this.validator$.rto.$reset()
    },
  },
}
</script>
