import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 * Fetch individual BU
 * @param {*} divisionUid
 * @param {*} uid
 * @returns
 */
export const fetchBusinessUnit = async (divisionUid, uid) => {
  return fetcher.get(`identity/api/v1/business-units/${uid}`)
}

/**
 * Fetch all by tenant
 * @returns
 */
export const fetchBusinessUnitList = async (params) => {
  return fetcher.get(`identity/api/v1/business-units`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 *
 * @param {*} divisionUid
 * @param {*} params
 * @returns
 */
export const fetchBusinessUnitListByDivision = async (divisionUid, params) => {
  return fetcher.get(`identity/api/v1/divisions/${divisionUid}/business-units`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

/**
 * Create BU
 * @param {*} payload
 * @param {*} divisionUid
 * @returns
 */
export const createBusinessUnit = async (payload, divisionUid) => {
  return fetcher.post(`identity/api/v1/business-units`, payload)
}

/**
 * Update BU
 * @param {*} payload
 * @param {*} divisionUid
 * @param {*} uid
 * @returns
 */
export const updateBusinessUnit = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/business-units/${uid}`, payload)
}

/**
 * Delete BU
 * @param {*} divisionUid
 * @param {*} uid
 * @returns
 */
export const deleteBusinessUnit = async (uid) => {
  return fetcher.delete(`identity/api/v1/business-units/${uid}`)
}
