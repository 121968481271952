export default {
  supplier: {
    suppliers: "Suppliers",
    search: "Search",
    associatedEntity: "Supplier Detail",
    deleteSupplier: "Delete Supplier",
    deleteFullNote:
      "You can permanently delete the supplier from database. This action cannot be reverted and you cannot restore the record anymore.",

    confirmToDelete: "Confirm to Delete",
    cfDeleteNote: "You are going to delete ",
    cfDeleteNote2: "This action cannot be reverted. The record will be deleted permanently.",
    optional: "Optional",
    context: "Context",
    name: "Name",
    desc: "Description",
    email: "Email",
    contactName: "Contact name",
    phone: "Phone number",
    address: "Address",
    fax: "Tax code",

    labels: {
      supplierName: "Supplier Name",
      desc: "Description",
      status: "Status",
      createdAt: "Created At",
      contactPoint: "Contact",
      fax: "Tax code",
      active: "Active",
      yes: "Yes",
      no: "No",
      name: "Name of supplier",
      description: "Description",
      data: "Data",
      storageLocation: "Storage location",
      confirmDeleteQuestion: "Are you sure you want to delete this supplier?",
      confirmDeleteAction: "If so, type the supplier name into the box below to confirm",
      linked_activities: "#Linked Activities",
      shortestMTPD: "Shortest MTPD",
    },

    buttons: {
      add: "Add",
      create: "Create",
      update: "Update",
      next: "Next",
      finish: "Finish",
      back: "Back",
      upload: "Upload",
      cancel: "Cancel",
      close: "Close",
      delete: "Delete",
      exportSuppliers: "Export Suppliers",
      addSupplier: "Add supplier ",
      edit: "Edit",
      view: "View",
      confirm: "Confirm",
      deleteSupplier: "Delete supplier",
      uploadCertificate: "Upload Certificate",
      viewSupplierCategories: "Supplier Categories",
    },

    category: {
      label: "Category",
      uid: "uid",
      name: "Category",
      title: {
        newCategory: "New Category",
        editCategory: "Edit Category",
        categoryDetail: "Category detail",
      },
      labels: {
        name: "Name",
        description: "Description",
        createdAt: "Created At",
        deleteCategory: "Delete Category",
        confirmDeleteQuestion: "Are you sure to delete this category",
        confirmDelete: "Confirm to Delete",
        noteDeleteCategory:
          "You can permanently delete the category from database. This action cannot be reverted and you cannot restore the record anymore.",
      },
      buttons: {
        addCategory: "Add Category",
        delete: "Delete",
        back: "Back",
        cancel: "Cancel",
      },
      messages: {
        createCategory: "Created category",
      },
      errors: {
        createCategory: "Error! Create category failed",
      },
    },

    certificate: {
      title: "Certificate of Assurance",
      uploadSuccess: "Save certificate to supplier successfully",
      updateSuccess: "Update certificate successfully",
      certificateName: "Uploaded Certs",
      certificateAction: "Action",
      confirmDeleteQuestion: "Are you sure you want to delete this certificate?",
      confirmDeleteAction: "If so, type the certificate file name into the box below to confirm",
      confirmToDelete: "Confirm to Delete",
    },

    title: {
      newSupplier: "New Supplier",
      editSupplier: "Update Supplier",
      supplierDetail: "Supplier Detail",
      confirmToCancel: "Confirm to cancel",
      cancelQuestion: "Do you wish to cancel doing supplier?",
      upload: "Upload Supplier Certificate of Assurance",
    },

    messages: {
      updatedSupplier: "Updated supplier successfully",
      createSuccess: "Create supplier successfully!",
      deleteSuccess: "Supplier has been delete successfully!",
      code: "Code",
    },

    // Errors
    errors: {
      updateSupplier: "Error to update supplier, please retry",
      listSupplier: "Unable get list of Suppliers",
      download: "Error occured, please try again",
    },

    tooltips: {
      newCategory: "Did not see category? Add new one",
    },
  },
}
