export default {
  industry: {
    industry: "Industries",
    name: "Name",
    desc: "Description",
    logo: "Logo",
    address: "Address",
    code: "Code",
    email: "Email",
    active: "Active",
    add: "Add",
    update: "Update",
    createdAt: "Created At",
    search: "Search",
    deleteIndustry: "Delete industry",
    noteDeleteIndustry:
      "You can permanently delete the industry from database. This action cannot be reverted and you cannot restore the record anymore.",
    newIndustry: "New Industry",
    create: "Create",
    cancel: "Cancel",
    back: "Back",
    yes: "Yes",
    no: "No",
    createIndustrySuccess: "Industry  has been created successfully!",
    visitNewIndustry: "Do you want to visit the new industry now?",
    confirmToDelete: "Confirm to Delete",
    youAreGoingToDelete: "You are going to delete",
    Industry: "industry",
    actionCannotBeRevert: "This action cannot be reverted. The record will be deleted permanently.",
    confirmDeleteQuestion: "Are you sure you want to delete this industry?",
    confirmDeleteAction: "If so, type the industry name into the box below to confirm",
    delete: "Delete",
    updated: "Updated Industry",
    created: "Created Industry",
    deleted: "Deleted Industry",
    source: "Source",
    nameUnique: "Name has been used. Please use an other name",
    label: {
      industryName: "Industry Name",
      status: "Status",
      createdAt: "Created At",
    },
    button: {
      edit: "Edit",
      delete: "Delete",
    },
    edit: {
      name: "Edit Industry",
    },
    new: {
      name: "Add Industry",
    },
  },
}
