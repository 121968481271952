import * as api from "@/modules/user/api/userFetcher"
import * as roleApi from "@/readybc/composables/api/apiRoles"
import * as industryApi from "@/readybc/composables/api/apiIndustries"
import * as organizationApi from "@/readybc/composables/api/apiOrganizations"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import { useI18n } from "vue-i18n"

export function useEventNotifiactionReceiver() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()
  const receiverUsers = ref([])
  const receiverRoles = ref([])
  const receiverIndustries = ref([])
  const receiverClients = ref([])

  /**
   *
   */
  const getReceiverUsers = async () => {
    try {
      const { data } = await api.getAll()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.fetchReceivers"))
    }
  }

  const getReceiverRoles = async () => {
    try {
      const { data } = await roleApi.fetchRoles()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.fetchReceiverRoles"))
    }
  }

  const getReceiverIndustries = async () => {
    try {
      const { data } = await industryApi.fetchIndustries()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.fetchIndustries"))
    }
  }

  /**
   *
   * @returns
   */
  const getReceiverClients = async () => {
    try {
      const { data } = await organizationApi.fetchOrganizations()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("notification.errors.fetchClients"))
    }
  }

  return {
    receiverUsers,
    receiverRoles,
    receiverIndustries,
    receiverClients,
    getReceiverUsers,
    getReceiverRoles,
    getReceiverIndustries,
    getReceiverClients,
  }
}
