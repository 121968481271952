import { useGlobalStore } from "@/stores/global"
import * as api from "../../../api/remoteAccessFetcher"
import { ref } from "vue"

export function useRemoteAccessList() {
  const globalStore = useGlobalStore()
  const listData = ref([])
  // Initial data

  /**
   *
   * @returns
   */
  async function getRemoteAccessList(params) {
    try {
      const { data } = await api.fetchRemoteAccessList(params)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }
  /**
   *
   * @returns
   */
  async function getAllRemoteAccessList() {
    try {
      const { data } = await api.fetchAllRemoteAccess()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("setting.errors.list"))
    }
  }

  return {
    getRemoteAccessList,
    getAllRemoteAccessList,
    listData,
  }
}
