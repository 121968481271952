import { useI18n } from "vue-i18n"
export const useActivityStage = {
  GENERAL: 1,
  APPLICATION: 2,
  DEPENDENCY_SUPPLIER: 3,
  MTDP: 4,
  RTO: 5,

  /**
   *
   * @param {*} isStageNew
   * @returns
   */
  newActivityStages(isStageNew = false) {
    const { t } = useI18n()
    const firstStageView = {
      step: this.GENERAL,
      label: "General Info",
      route: "ViewActivityNewBack",
    }

    // If first step the
    if (isStageNew) {
      firstStageView.route = "ViewActivityNew"
    }

    return [
      firstStageView,
      {
        step: this.APPLICATION,
        label: "Applications",
        route: "ViewActivityApplication",
      },
      {
        step: this.DEPENDENCY_SUPPLIER,
        label: "Dependencies/Suppliers",
        route: "ViewActivityDependencies",
      },
      {
        step: this.MTDP,
        label: "MTDP",
        route: "ViewActivityTolerant",
        hint: t("activity.tooltips.tolerant"),
      },
      {
        step: this.RTO,
        label: "RTO",
        route: "ViewActivityRTO",
        hint: t("activity.tooltips.rto"),
      },
    ]
  },
  updateActivityStages() {
    const { t } = useI18n()
    return [
      {
        step: this.GENERAL,
        label: "General Info",
        route: "ViewActivityDetail",
      },
      {
        step: this.APPLICATION,
        label: "Applications",
        route: "ViewActivityUpdateApplication",
      },
      {
        step: this.DEPENDENCY_SUPPLIER,
        label: "Dependencies/Suppliers",
        route: "ViewActivityUpdateDependencies",
      },
      {
        step: this.MTDP,
        label: "MTPD",
        route: "ViewActivityUpdateTolerant",
        hint: t("activity.tooltips.tolerant"),
      },
      {
        step: this.RTO,
        label: "RTO",
        route: "ViewActivityUpdateRTO",
        hint: t("activity.tooltips.rto"),
      },
    ]
  },
}
