<template>
  <EcModalSimple :isVisible="isModalDeleteDraftOpen" variant="center-3xl" id="modal-cancel-activity">
    <EcBox class="text-center">
      <EcFlex class="justify-center">
        <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
      </EcFlex>

      <!-- Messages -->
      <EcBox>
        <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
          {{ $t("activity.title.confirmToDeleteBulkDraft") }}
        </EcHeadline>

        <EcText class="text-c0-500 mt-4">
          {{ $t("activity.title.deleteBulkDraftQuestion") }}
        </EcText>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isDeleting" class="justify-center mt-10">
        <EcButton variant="warning" id="btn-delete-draft-bulk-activities" @click="handleDeleteDraftBulkActivities">
          {{ $t("activity.buttons.yes_delete") }}
        </EcButton>
        <EcButton class="ml-3" variant="tertiary-outline" id="btn-close-modal" @click="handleCloseDeleteDraftlModal">
          {{ $t("activity.buttons.close") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useCreateBulkActivities } from "../use/useCreateBulkActivity"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ModalCancelAddActivity",

  emits: ["handleCloseDeleteDraftlModal"],
  data() {
    return {
      isDeleting: false,
    }
  },
  props: {
    bulkDraftUid: {
      type: String,
      default: "",
    },
    isModalDeleteDraftOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    window.addEventListener("beforeunload", this.leaving)
  },
  setup() {
    const globalStore = useGlobalStore()
    const { deleteBulkActivitiesDraft } = useCreateBulkActivities()

    return {
      globalStore,
      deleteBulkActivitiesDraft,
    }
  },
  methods: {
    /**
     * Cancel add new activity
     */
    async handleDeleteDraftBulkActivities() {
      if (this.bulkDraftUid?.length <= 0) {
        goto("ViewActivityList")

        return
      }

      this.isDeleting = true
      const response = await this.deleteBulkActivitiesDraft(this.bulkDraftUid)

      if (response) {
        this.globalStore.addSuccessToastMessage("Deleted draft!")
        goto("ViewActivityList")
      }
      this.isDeleting = false
    },

    /**
     * Close cancel modal
     */
    handleCloseDeleteDraftlModal() {
      this.$emit("handleCloseDeleteDraftlModal")
    },

    leaving(e) {
      alert(e)
    },
  },
}
</script>
