import { useGlobalStore } from "@/stores/global"
import { useI18n } from "vue-i18n"
import * as api from "../../api/emailSettingFetcher"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"
import { email, maxLength, required, numeric } from "@vuelidate/validators"

export function useEmailSetting() {
  const globalStore = useGlobalStore()
  const { t } = useI18n()
  const emailSetting = ref({
    is_enabled: false,
  })

  // validation
  const rules = {
    emailSetting: {
      is_enabled: { required },
      mail_from_name: { maxLength: maxLength(255) },
      mail_from_address: { email, required, maxLength: maxLength(255) },
      mail_host: { required, maxLength: maxLength(255) },
      mail_port: { required, numeric },
      mail_encryption: { required, maxLength: maxLength(255) },
      mail_username: { required, maxLength: maxLength(255) },
      mail_password: { maxLength: maxLength(255) },
    },
  }
  const emailSettingValidator$ = useVuelidate(rules, { emailSetting })

  /**
   * @param payload
   * @returns industry
   */
  const updateEmailSetting = async (payload) => {
    try {
      const { data } = await api.updateEmailSetting(payload)
      if (payload.test) {
        globalStore.addSuccessToastMessage(t("setting.mail.testEmailSetting"))
      } else {
        globalStore.addSuccessToastMessage(t("setting.mail.updatedEmailSetting"))
      }
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param uid
   * @returns {Promise<undefined>}
   */
  async function getDetailEmailSetting() {
    try {
      const { data } = await api.fetchEmailSetting()
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : this.$t("setting.mail.errors.get"))
    }
  }

  return {
    emailSetting,
    emailSettingValidator$,
    getDetailEmailSetting,
    updateEmailSetting,
  }
}
