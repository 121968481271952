<template>
  <EcModalSimple :isVisible="isPasswordModalOpen" variant="center-2xl">
    <!-- Modal Header -->
    <EcBox class="mb-6">
      <EcText class="text-c0-300 font-medium">
        {{ $t("setting.editFor") }}
      </EcText>
      <EcHeadline as="h2" variant="h2" class="text-c1-500 mt-2">
        {{ $t("setting.password") }}
      </EcHeadline>
    </EcBox>

    <!-- Modal form -->
    <EcBox class="w-full mb-10">
      <RFormInput
        v-model="formPassword.currentPassword"
        class="mt-4"
        componentName="EcInputText"
        :label="$t('setting.currentPassword')"
        :validator="v$"
        field="formPassword.currentPassword"
        type="password"
        @input="v$.formPassword.currentPassword.$touch()"
      />
      <RFormInput
        v-model="formPassword.newPassword"
        class="mt-4"
        componentName="EcInputText"
        :label="$t('setting.newPassword')"
        :validator="v$"
        field="formPassword.newPassword"
        :replacements="{ current: 'current password' }"
        type="password"
        @input="v$.formPassword.newPassword.$touch()"
      />
      <RFormInput
        v-model="formPassword.newPasswordConfirm"
        class="mt-4"
        componentName="EcInputText"
        :label="$t('setting.confirmPassword')"
        :validator="v$"
        field="formPassword.newPasswordConfirm"
        type="password"
        @input="v$.formPassword.newPasswordConfirm.$touch()"
      />
    </EcBox>

    <EcBox>
      <EcFlex v-if="!isChangingPassword">
        <EcButton class="mr-4" variant="primary" @click="handleUpdatePassword">
          {{ $t("setting.update") }}
        </EcButton>
        <EcButton variant="tertiary-outline" @click="closeChangePasswordModal">
          {{ $t("setting.cancel") }}
        </EcButton>
      </EcFlex>
      <!-- Loading -->
      <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
    </EcBox>
  </EcModalSimple>
</template>

<script>
import { ref } from "vue"
import { useChangePassword } from "../use/password/useChangePassword"

export default {
  name: "UserChangePasswordModal",
  props: {
    isPasswordModalOpen: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isChangingPassword: false,
    }
  },
  setup() {
    const { updatePassword, formPassword, v$ } = useChangePassword()

    return {
      updatePassword,
      formPassword,
      v$,
    }
  },
  methods: {
    async handleUpdatePassword() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isChangingPassword = true

      // Send the token along with form
      this.formPassword.token = localStorage.getItem(process.env.VUE_APP_TOKEN_KEY) || ""

      const result = await this.updatePassword(this.formPassword)

      this.isChangingPassword = false

      if (result) {
        this.closeChangePasswordModal()
      }
    },

    /**
     * Reset form
     */
    closeChangePasswordModal() {
      this.v$.formPassword.$reset()
      this.formPassword = ref({})
      this.$emit("closeChangePasswordModal")
    },
  },
}
</script>
