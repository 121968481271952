import * as api from "../api/fetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"

export const useRefreshToken = () => {
  const { t } = useI18n()
  const globalStore = useGlobalStore()
  /**
   *
   * @returns
   * @param refreshSilently
   */
  const submitRefreshToken = async (refreshSilently = false) => {
    try {
      // const refreshToken = localStorage.getItem(process.env.VUE_APP_REFRESH_TOKEN) || ""
      const { data } = await api.refreshToken({})

      if (!data || !data.accessToken) {
        throw new Error(t("auth.errors.invalidSession"))
      }
      if (!refreshSilently) {
        globalStore.addSuccessToastMessage(t("auth.refreshSuccessNote"))
      }

      return true
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("auth.errors.invalidSession"))

      return false
    }
  }

  return {
    submitRefreshToken,
  }
}
