import { useGlobalStore } from "@/stores/global"
import * as api from "../api/activityEquipmentFetcher"
import { ref } from "vue"

export function useActivityEquipments() {
  const globalStore = useGlobalStore()
  const utilities = ref([])

  /**
   *
   * @returns
   */
  async function getActivityEquipmentList() {
    try {
      const data = await api.fetchActivityEquipment()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error.message : this.$t("activity.errors.list"))
    }
  }

  return {
    getActivityEquipmentList,
    utilities,
  }
}
