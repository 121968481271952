<template>
  <EcModalSimple :isVisible="modalRequirePermissionOpen" variant="center-2xl">
    <EcBox class="text-center">
      <EcFlex class="justify-center">
        <EcIcon class="text-cWarning-500" width="4rem" height="4rem" icon="warning" />
      </EcFlex>
      <!-- Messages -->
      <EcBox>
        <EcHeadline as="h2" variant="h2" class="text-cWarning-500 text-3xl">
          {{ $t("user.label.warning") }}
        </EcHeadline>
        <EcText class="text-c0-500 mt-2">
          {{ $t("user.message.warningEmptyPermission") }}
        </EcText>
      </EcBox>
      <EcFlex class="justify-center mt-10">
        <EcButton class="mr-4" variant="primary" @click="handleClickCloseRequirePermissionModal">
          {{ $t("user.button.ok") }}
        </EcButton>
      </EcFlex>
    </EcBox>

    <!-- Buttons -->
    <!-- User data -->
  </EcModalSimple>
</template>

<script>
export default {
  name: "ModalRequirePermissions",
  emits: ["handleClickCloseRequirePermissionModal"],
  props: {
    modalRequirePermissionOpen: Boolean,
    default: () => {
      return false
    },
  },

  data() {
    return {}
  },

  setup() {
    return {}
  },

  methods: {
    handleClickCloseRequirePermissionModal() {
      this.$emit("handleCloseRequirePermissionModal")
    },
  },
}
</script>
