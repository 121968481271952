import fetcher from "@/modules/core/api/fetcher"

export const fetchDetailDraftBulkActivities = async (uid) => {
  return fetcher.get(`identity/api/v1/activity-drafts/${uid}`)
}

export const fetchListDraftBulkActivities = async () => {
  return fetcher.get("identity/api/v1/activity-drafts")
}

export const saveBulkActivities = async (payload) => {
  return fetcher.post(`identity/api/v1/bulk-activities`, payload)
}

export const createDraftBulkActivity = async (payload) => {
  return fetcher.post("identity/api/v1/activity-drafts", payload)
}

export const deleteDraftBulkActivity = async (uid) => {
  return fetcher.delete(`identity/api/v1/activity-drafts/${uid}`)
}

export const createActivityMasterData = async (payload) => {
  return fetcher.post(`identity/api/v1/activities/master-data`, payload)
}
