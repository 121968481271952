<template>
  <meta
    http-equiv="Content-Security-Policy"
    content="default-src * self blob: data: gap:; style-src * self 'unsafe-inline' blob: data: gap:; script-src * 'self' 'unsafe-eval' 'unsafe-inline' blob: data: gap:; object-src * 'self' blob: data: gap:; img-src * self 'unsafe-inline' blob: data: gap:; connect-src self * 'unsafe-inline' blob: data: gap:; frame-src * self blob: data: gap:;"
  />

  <iframe ref="iframeRef" style="width: 99%; height: 99%; color: #fff" :src="encodedSrc" frameBorder="0"> </iframe>
</template>

<script>
export default {
  name: "EcPdfViewer",
  inject: ["getComponentVariants"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    src: {
      type: String,
      default: "",
    },
  },

  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcPdfViewer",
          variant: this.variant,
        })?.el ?? {}
      )
    },

    encodedSrc() {
      const encodedUrl = encodeURIComponent(this.src)

      return `https://drive.google.com/viewerng/viewer?url=${encodedUrl}&embedded=true`
      // Default PDF viewer
    },
  },
}
</script>
