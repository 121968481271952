import * as api from "../api/reportFetcher"

export const useReport = () => {
  const fetchListRepost = () => {
    return api.fetchListRepost()
  }

  const fetchReportDetails = (uid) => {
    return api.fetchReportDetails(uid)
  }

  const deleteReport = (uid) => {
    return api.deleteReport(uid)
  }

  const refineReportStatus = (status) => {
    if (!status) {
      return "pending"
    }
    if (status === "DocumentGenerated") {
      return "success"
    }

    if (status === "GenerateFailed") {
      return "failed"
    }

    return status
  }

  return {
    fetchListRepost,
    fetchReportDetails,
    deleteReport,
    refineReportStatus,
  }
}
