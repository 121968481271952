import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

/**
 * User notification logs
 * @returns
 */
export const fetchNotificationLogs = () => {
  return fetcher.get(`/identity/api/v1/notifications`)
}

/**
 * Tenant notification logs
 * @param {*} params
 * @returns
 */
export const fetchListNotificationLogs = (params) => {
  return fetcher.get(`/identity/api/v1/notifications/logs`, {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchNotificationLogDetail = (uid) => {
  return fetcher.get(`/identity/api/v1/notifications/logs/${uid}`)
}

export const fetchNotificationDetail = (uid) => {
  return fetcher.get(`/identity/api/v1/notifications/${uid}`)
}

export const markNotificationAsRead = (uid) => {
  return fetcher.patch(`/identity/api/v1/notifications/${uid}/mark-as-read`)
}

export const deleteNotification = (uid) => {
  return fetcher.delete(`/identity/api/v1/notifications/${uid}`)
}
