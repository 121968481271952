<template>
  <EcBox class="h-screen overflow-y-scroll" id="permission-panel">
    <EcFlex>
      <!-- Label -->
      <EcLabel class="font-semibold">Permissions</EcLabel>

      <!-- All permissions -->
      <EcCheckbox
        v-if="!isLoading"
        v-model="allPermissions"
        @update:modelValue="handleAllPermissionCheckboxChange"
        class="ml-2"
      ></EcCheckbox>
    </EcFlex>
    <!-- Permission and group -->
    <EcBox v-for="permissionGroup in permissionInGroups" :key="permissionGroup.name">
      <EcBox v-if="!permissionGroup.hide">
        <!-- Group select box -->
        <EcFlex v-if="permissionGroup?.permissions?.length > 0" class="mt-4">
          <EcCheckbox v-model="permissionGroup.checked" @click="handleClickPermissionGroup(permissionGroup)"></EcCheckbox>
          <EcLabel class="ml-2 font-medium text-base">{{ permissionGroup.description }} </EcLabel>
        </EcFlex>

        <!-- Permissions -->
        <EcBox v-for="(permission, index) in permissionGroup.permissions" :key="index" class="mt-2 ml-8">
          <EcFlex v-if="!permission.hide">
            <EcCheckbox
              v-model="permission.checked"
              :disabled="!!permission.is_default"
              class="cb-permission"
              @update:modelValue="handlePermissionCheckboxChange(value, 'permission', permissionGroup?.id)"
            ></EcCheckbox>
            <EcBox>
              <EcLabel class="ml-2 text-base">{{ permission.label }} </EcLabel>
              <EcLabel class="ml-2 text-sm italic permission-label"> {{ permission.description }}</EcLabel>
            </EcBox>
          </EcFlex>
        </EcBox>

        <!-- End Permissions -->
      </EcBox>
    </EcBox>
    <!-- Spinner -->
    <EcSpinner v-if="isLoading" />
  </EcBox>
</template>

<script>
import { usePermissions } from "../use/usePermissions"
import { ref } from "vue"

export default {
  name: "PermissionPanel",
  components: {},
  emits: ["update:modelValue"],
  props: {
    assignedPermissions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isMapFirstTime: true,
      isLoading: false,
      allPermissions: false,
      checkedPermissionCnt: 0,
      checkedPermissions: [],
    }
  },
  setup() {
    const permissionInGroups = ref([])

    const { getPermissionsWithGroup } = usePermissions()

    return {
      permissionInGroups,
      getPermissionsWithGroup,
    }
  },

  computed: {},
  mounted() {
    this.fetchPermissions()
  },

  methods: {
    // Fetch permissions with group
    async fetchPermissions() {
      this.isLoading = true
      this.permissionInGroups = await this.getPermissionsWithGroup()
      this.mapPermissionInGroups()
      this.isLoading = false
    },

    /**
     *
     * @param {*} value
     */
    handleAllPermissionCheckboxChange(value) {
      this.permissionInGroups?.map((permissionGroup) => {
        permissionGroup.checked = value

        permissionGroup?.permissions?.map((item) => {
          if (!item.hide && !item.is_default) {
            item.checked = value
          }

          return item
        })

        return permissionGroup
      })
    },

    handleClickPermissionGroup(group) {
      group?.permissions?.map((item) => {
        if (!item.hide && !item.is_default) {
          item.checked = group.checked
        }

        return item
      })
    },

    /**
     *
     * @param {*} value
     */
    handlePermissionCheckboxChange() {
      // Finaly, emit the data ouside
      this.checkedPermissions = []
      this.permissionInGroups?.forEach((permissionGroup) => {
        permissionGroup?.permissions?.forEach((permission) => {
          if (permission.checked) {
            this.checkedPermissions.push(permission.uid)
          }
        })
      })
      this.$emit("update:modelValue", this.checkedPermissions)
    },

    mapPermissionInGroups() {
      const assignedPermissionUids = this.assignedPermissions?.map((ap) => {
        return ap.uid
      })

      // Group
      this.permissionInGroups = this.permissionInGroups?.map((permissionGroup) => {
        // reset checked permission cnt
        this.checkedPermissionCnt = 0
        // Permissions
        permissionGroup?.permissions?.map((permission) => {
          const permissionChecked = assignedPermissionUids?.includes(permission.uid) || !!permission.is_default
          if (permissionChecked) {
            this.checkedPermissionCnt++
          }

          permission.checked = permissionChecked

          return permission
        })

        if (permissionGroup?.permissions?.length === this.checkedPermissionCnt) {
          permissionGroup.checked = true
        } else {
          permissionGroup.checked = false
        }
        return permissionGroup
      })
    },
  },
  watch: {
    assignedPermissions() {
      if (this.isMapFirstTime && this.assignedPermissions?.length > 0 && this.permissionInGroups?.length > 0) {
        this.isMapFirstTime = false
        this.mapPermissionInGroups()
      }
    },
  },
}
</script>
