export default {
  install(Vue) {
    Vue.directive("columns-resizable", {
      mounted(el, binding) {
        if (!binding.value) {
          return
        }

        let nodeName = el.nodeName

        if (["TABLE", "THEAD"].indexOf(nodeName) < 0) {
          el = el.querySelector("table") // looking for the closest table
          if (!el) return
          nodeName = "TABLE"
        }

        const table = nodeName === "TABLE" ? el : el.parentElement

        // Add class
        table.classList.add("vue-columns-resizeable")
        const row = table.getElementsByTagName("tr")[0]
        const cols = row ? row.children : undefined

        // Table has no column then return
        if (!cols) {
          return
        }

        // Get table heigh
        const tableHeight = table.offsetHeight

        for (let i = 0; i < cols.length; i++) {
          if (!cols[i]?.classList?.contains("disable-resize-columns")) {
            const div = createDiv(tableHeight)
            cols[i].appendChild(div)
            cols[i].style.position = "relative"
            setListeners(div)
          }
        }

        /**
         * Set listener
         * @param {*} div
         */
        function setListeners(div) {
          let pageX, curCol, nxtCol, curColWidth, nxtColWidth

          /**
           * Add mouse down
           */
          div.addEventListener("mousedown", function (e) {
            curCol = e.target.parentElement
            nxtCol = curCol.nextElementSibling
            pageX = e.pageX

            const padding = paddingDiff(curCol)

            curColWidth = curCol.offsetWidth - padding
            if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding
          })

          /**
           *Add mouse over
           */
          div.addEventListener("mouseover", function (e) {
            e.target.classList.add("lg:border-r-2", "lg:border-c1-100")
          })

          /**
           * Add mouse out
           */
          div.addEventListener("mouseout", function (e) {
            e.target.classList.remove("lg:border-r-2", "lg:border-c1-100")
          })

          /**
           * Add mouse move
           */
          document.addEventListener("mousemove", function (e) {
            if (curCol) {
              const diffX = e.pageX - pageX

              if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px"

              curCol.style.width = curColWidth + diffX + "px"
            }
          })

          /**
           * Add mouse up
           */
          document.addEventListener("mouseup", function (e) {
            curCol = undefined
            nxtCol = undefined
            pageX = undefined
            nxtColWidth = undefined
            curColWidth = undefined
          })
        }

        /**
         * Create Div
         * @param {*} height
         * @returns
         */
        function createDiv(height) {
          const div = document.createElement("div")
          div.style.top = "0"
          div.style.right = "-2px"
          div.style.width = "4px"
          div.style.zIndex = "20"
          div.style.position = "absolute"
          div.style.cursor = "col-resize"
          div.style.userSelect = "none"
          div.style.height = height + "px"
          return div
        }

        /**
         *
         * @param {*} col
         * @returns
         */
        function paddingDiff(col) {
          if (getStyleVal(col, "box-sizing") === "border-box") {
            return 0
          }

          const padLeft = getStyleVal(col, "padding-left")
          const padRight = getStyleVal(col, "padding-right")
          return parseInt(padLeft) + parseInt(padRight)
        }

        /**
         *
         * @param {*} elm
         * @param {*} css
         * @returns
         */
        function getStyleVal(elm, css) {
          return window.getComputedStyle(elm, null).getPropertyValue(css)
        }
      },
    })
  },
}
