<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addLocation") }}
        </EcHeadline>
      </EcBox>

      <!-- location -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="location.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="location.name"
          @input="validator$.location.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="location.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="location.description"
          @input="validator$.location.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateLocation">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useLocationNew } from "../use/master/locations/useLocationNew"

export default {
  name: "ModalAddLocation",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const { location, validator$, createLocation } = useLocationNew()
    return {
      location,
      validator$,
      createLocation,
    }
  },
  methods: {
    /**
     * Cancel add new location
     */
    async handleClickCreateLocation() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      const response = await this.createLocation(this.location)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
        this.handleClearModalData()
      }
      this.isLoading = false
    },

    /**
     * Clean modal add location data
     */
    handleClearModalData() {
      this.location.name = null
      this.location.description = null
      this.validator$.location.$reset()
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },
}
</script>
