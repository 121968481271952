import ViewActivityList from "@/modules/activity/views/ViewActivityList"
import ViewActivityNew from "@/modules/activity/views/ViewActivityNew"
import ViewActivityDetail from "@/modules/activity/views/ViewActivityDetail"
import ViewActivityRemoteAccess from "@/modules/activity/views/ViewActivityRemoteAccess"
import ViewActivityApplication from "@/modules/activity/views/ViewActivityApplication"
import ViewActivityTolerant from "@/modules/activity/views/ViewActivityTolerant"
import ViewActivityRTO from "@/modules/activity/views/ViewActivityRTO"
import ViewActivityDependencies from "@/modules/activity/views/ViewActivityDependencies"
import ViewActivityRoleList from "@/modules/activity/views/role/ViewActivityRoleList"
import ViewActivityRoleDetail from "@/modules/activity/views/role/ViewActivityRoleDetail"

// Update
import ViewActivityUpdateRemoteAccess from "@/modules/activity/views/ViewActivityUpdateRemoteAccess"
import ViewActivityUpdateApplication from "@/modules/activity/views/ViewActivityUpdateApplication"
import ViewActivityUpdateTolerant from "@/modules/activity/views/ViewActivityUpdateTolerant"
import ViewActivityUpdateRTO from "@/modules/activity/views/ViewActivityUpdateRTO"
import ViewActivityUpdateDependencies from "@/modules/activity/views/ViewActivityUpdateDependencies"

// Bulk Create Activities
import ViewActivityBulkCreate from "@/modules/activity/views/ViewActivityBulkCreate"

export default [
  {
    path: "/activities",
    component: ViewActivityList,
    name: "ViewActivityList",
    props: true,
    meta: {
      permissions: "activity.read",
      title: "Activity List",
      module: "activity",
      landlordAccess: true,
    },
  },

  {
    path: "/activities/new",
    component: ViewActivityNew,
    name: "ViewActivityNew",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "New Activity",
      module: "activity",
    },
  },

  {
    path: "/activities/new/:uid",
    component: ViewActivityNew,
    name: "ViewActivityNewBack",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "New Activity",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid",
    component: ViewActivityDetail,
    name: "ViewActivityDetail",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Edit Activity",
      module: "activity",
    },
  },

  // For create new

  {
    path: "/activities/:uid/remote-accesses",
    component: ViewActivityRemoteAccess,
    name: "ViewActivityRemoteAccess",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "Activity Remote Access Factor",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/applications",
    component: ViewActivityApplication,
    name: "ViewActivityApplication",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "Activity Applications and Equipments",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/tolerant",
    component: ViewActivityTolerant,
    name: "ViewActivityTolerant",
    props: true,
    meta: {
      title: "Activity Tolerant",
      module: "activity",
    },
  },
  {
    path: "/activities/:uid/recovery-time",
    component: ViewActivityRTO,
    name: "ViewActivityRTO",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "Activity Recovery Time Objective",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/dependencies",
    component: ViewActivityDependencies,
    name: "ViewActivityDependencies",
    props: true,
    meta: {
      permissions: "activity.create",
      title: "Internal Dependencies And Critical Suppliers",
      module: "activity",
    },
  },

  // For update

  {
    path: "/activities/:uid/update-remote-accesses",
    component: ViewActivityUpdateRemoteAccess,
    name: "ViewActivityUpdateRemoteAccess",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Update Activity Remote Access Factor",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/update-applications",
    component: ViewActivityUpdateApplication,
    name: "ViewActivityUpdateApplication",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Update Activity Applications and Equipments",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/update-tolerant",
    component: ViewActivityUpdateTolerant,
    name: "ViewActivityUpdateTolerant",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Update Activity Tolerant",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/update-recovery-time",
    component: ViewActivityUpdateRTO,
    name: "ViewActivityUpdateRTO",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Update Activity RTO",
      module: "activity",
    },
  },

  {
    path: "/activities/:uid/update-dependencies",
    component: ViewActivityUpdateDependencies,
    name: "ViewActivityUpdateDependencies",
    props: true,
    meta: {
      permissions: "activity.update",
      title: "Update Internal Dependencies And Critical Suppliers",
      module: "activity",
    },
  },
  {
    path: "/activity-roles/",
    component: ViewActivityRoleList,
    name: "ViewActivityRoleList",
    props: true,
    meta: {
      title: "Activity Roles Management",
      module: "activity",
      isPublic: true,
    },
  },
  {
    path: "/activity-roles/:uid",
    component: ViewActivityRoleDetail,
    name: "ViewActivityRoleDetail",
    props: true,
    meta: {
      title: "Activity Roles Detail",
      module: "activity",
      isPublic: true,
    },
  },

  // Bulk Activity
  {
    path: "/activities/bulk",
    component: ViewActivityBulkCreate,
    name: "ViewActivityBulkCreate",
    props: true,
    meta: {
      permissions: "activity.bulk_modify",
      title: "Add Bulk Activities",
      module: "activity",
    },
  },
  {
    path: "/activities/bulk/:uid",
    component: ViewActivityBulkCreate,
    name: "ViewActivityBulkDraftDetail",
    props: true,
    meta: {
      permissions: "activity.bulk_modify",
      title: "Add Bulk Activities",
      module: "activity",
    },
  },
]
