import * as api from "../../../api/utilitiesFetcher"
import { useGlobalStore } from "@/stores/global"
import { required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useUtilitiesDetail() {
  const globalStore = useGlobalStore()

  const form = ref({})

  const rules = {
    form: {
      name: { required },
      description: {},
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getUtility = async (uid) => {
    try {
      const { data } = await api.fetchUtility(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateUtility = async (payload, uid) => {
    try {
      const { data } = await api.updateUtility(payload, uid)
      globalStore.addSuccessToastMessage("Successfully updated!")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteUtility = async (uid) => {
    try {
      const response = await api.deleteUtility(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  return {
    getUtility,
    updateUtility,
    deleteUtility,
    form,
    v$,
  }
}
