export default {
  report: {
    title: "Report Generator",
    titleListReport: "List Reports",
    reportDetail: "Report Details",

    buttons: {
      export: "Export Report",
      generateReport: "Generate Report",
      cancel: "Cancel",
      delete: "Delete",
      advanceOptions: "Advance Options",
      applyFilter: "Apply Filters",
      viewReport: "View Report",
    },

    labels: {
      templateHelp: "Below is the report template to review. Please click the 'Generate Report' button to populate the data",
      reportGenerating:
        "Your report is being generated and will be displayed shortly. You can also view the report details here:",
      reportGenerated: "Your report has been generated successfully. You can also view the report details here:",
      reportGenerateError: "Your report encountered an error during generation. You can view more details here:",
      somethingWrong: "Oops! Something went wrong, please retry",
      loadingReport: "Gathering report data...",
      loadingReportTemplate: "Loading report template...",
      title: "Report Templates",
      name: "Name",
      type: "Type",
      isCompleted: "Completed",
      completedAt: "Completed At",
      createdAt: "Created At",
      createdBy: "Created By",
      status: "Status",
    },

    errors: {
      listReport: "Unable to get list report",
    },
    messages: {
      deleteReportSuccess: "The report was deleted successfully.",
    },

    modal: {
      title: {
        reportData: "Advance Options",
        confirmToDelete: "Confirm to Delete",
        reportTokens: "Report Tokens",
      },
      reportType: "Report Type",
      reportTemplate: "Report Template",
      activityCreatedDate: "Activity created date",
      noLimit: "The report will include all data directly from the system, without any filters applied",
      confirmLabel: "Sure, generate now",
      cancelLabel: "Let me check",
      cfDeleteNote: "You are going to delete the report",
      cfDeleteNote2: "This action cannot be reverted. The report will be deleted permanently.",
    },
  },

  reportTemplates: {
    title: "Report Templates",
    labels: {
      title: "Report Templates",
      titleReportTemplateRevisionDetail: "Report Template Revision Details",
      reportType: "Name",
      isAllowTenantCustomize: "Allow tenant customize",
      revision: "Revision",
      latestRevision: "Latest Revision",
      listRevision: "Revisions",
      createdAt: "Created At",
      createdBy: "Created By",
      status: "Status",
    },
    buttons: {
      reUploadTemplate: "Upload Template",
      setDefaultTemplate: "Set default",
      replacementTokens: "Replacement Tokens",
      cancel: "Cancel",
      delete: "Delete",
    },
    modal: {
      title: {
        confirmToDelete: "Confirm to Delete",
        confirmToSetDefaultTemplate: "Confirm to set default template",
      },
      cfDeleteNote: "You are going to delete the report template",
      cfDeleteNote2: "This action cannot be reverted. The report template will be deleted permanently.",
      cfSetDefaultTemplate: "You are going to set this revision to the default template.",
      cfSetDefaultTemplate2: "This action will unset the current default and set this revision as the new default template.",
      confirmLabelSetDefault: "Set as default",
      cancelLabelSetDefault: "Cancel",
    },
    errors: {
      listReportTemplate: "Unable to get list report template revisions",
    },
    messages: {
      setTemplateDefaultSuccess: "The template revision set as the default template successfully.",
      deleteReportTemplateSuccess: "The report template was deleted successfully.",
      uploadReportTemplateSuccess: "Uploaded template successfully",
    },
  },
}
