import * as api from "../api/fetcher"
import { useI18n } from "vue-i18n"
import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import { required, minLength, maxLength } from "@vuelidate/validators"
import useVuelidate from "@vuelidate/core"
import dayjs from "dayjs"

export const useMFAVerification = () => {
  const { t } = useI18n()
  const globalStore = useGlobalStore()

  // Form and validation
  const form = ref({})

  const rules = {
    form: {
      otp: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(6),
      },
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const respondMFAChallenge = async (payload) => {
    try {
      const { data } = await api.mfaChallenge(payload)

      if (!data || !data.accessToken) {
        throw new Error(t("auth.mfa.errors.failed"))
      }

      globalStore.addSuccessToastMessage(t("auth.success"))
      localStorage.setItem(process.env.VUE_APP_TOKEN_EXPIRES, dayjs().add(data.expiresIn, "second"))
      // localStorage.setItem(process.env.VUE_APP_REFRESH_TOKEN, data.refreshToken)
      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error ? error?.message : t("auth.mfa.errors.failed"))
      return false
    }
  }

  return {
    respondMFAChallenge,
    form,
    v$,
  }
}
