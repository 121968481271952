<template>
  <td :class="tableCellClass" :style="tableCellStyle">
    <div :class="[this.isTruncate ? 'lg:truncate' : '']">
      <slot />
    </div>
  </td>
</template>

<script>
export default {
  name: "RTableCell",
  props: {
    isTruncate: {
      type: Boolean,
      default: true,
    },
    variant: {
      type: String,
      default: "default",
    },
    enableTooltip: {
      type: String,
      default: "true",
    },
  },
  computed: {
    tableCellClass() {
      return {
        "text-base whitespace-no-wrap h-12 align-middle w-auto bg-cTransparent p-3 pl-6 ": true,
        "absolute top-auto right-0 px-2": this.variant === "gradient",
      }
    },
    tableCellStyle() {
      return this.variant === "gradient" ? "background: linear-gradient(270deg, #FFFFFF 85%, rgba(255, 255, 255, 0) 100%)" : ""
    },
  },
}
</script>
