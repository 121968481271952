<template>
  <EcFlex class="w-full h-screen items-center justify-center bg-c1-800">
    <EcBox class="text-cWhite">
      <EcText class="text-5xl font-bold"> 404 </EcText>
      <EcText class="text-3xl">Tenant Not Found</EcText>
    </EcBox>
  </EcFlex>
</template>

<script></script>
