<template>
  <RLayout :title="$t('user.label.updateRole')">
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-6/12 lg:pr-4 mb-8" rightCls="lg:w-6/12 lg:pr-4 mb-8">
      <template #left>
        <EcBox class="max-w-2xl px-4 mt-8 sm:px-10">
          <!-- Titile -->

          <!-- Role detail -->
          <EcFlex class="flex-wrap max-w-md">
            <!-- Role key -->
            <EcBox class="w-full mb-6 hidden">
              <RFormInput
                v-model="form.name"
                componentName="EcInputText"
                :label="$t('role.label.key')"
                :validator="v$"
                field="form.name"
                :disabled="true"
                placeholder="Auto generate from Name"
              />
            </EcBox>

            <!-- Role label -->
            <EcBox class="w-full mb-6">
              <RFormInput
                v-model="form.label"
                componentName="EcInputText"
                :label="$t('role.label.name')"
                :validator="v$"
                field="form.label"
                @input="handleRoleNameInput"
              />
            </EcBox>

            <!-- Role desc -->
            <EcBox class="w-full mb-6">
              <RFormInput
                v-model="form.description"
                componentName="EcInputText"
                :label="$t('user.label.description')"
                :validator="v$"
                field="form.description"
                @input="v$.form.description.$touch()"
              />
            </EcBox>
          </EcFlex>

          <EcFlex v-if="!isUpdating" class="mt-6">
            <EcButton v-permissions:role-update variant="primary" @click="handleSubmit">
              {{ $t("user.button.update") }}
            </EcButton>
            <EcButton class="ml-4" variant="tertiary-outline" @click="handleClickBack">
              {{ $t("user.button.back") }}
            </EcButton>
          </EcFlex>
          <EcBox v-else class="flex items-center h-10 mt-6">
            <EcSpinner variant="secondary" type="dots" />
          </EcBox>
        </EcBox>
      </template>

      <template #right>
        <!-- Permissions -->
        <PermissionPanel
          :assignedPermissions="form.permissions"
          class="max-w-2xl px-4 mt-8 sm:px-10"
          @update:modelValue="handlePermissionChange"
        />
      </template>
    </RLayoutTwoCol>
  </RLayout>

  <teleport to="#layer1">
    <ModalRequirePermission
      :modalRequirePermissionOpen="modalRequirePermissionOpen"
      @handleCloseRequirePermissionModal="handleCloseRequirePermissionModal"
    />
  </teleport>
</template>

<script>
import { useRoleDetail } from "../use/useRoleDetail"
import PermissionPanel from "../components/PermissionPanel.vue"
import { goto } from "@/modules/core/composables"
import ModalRequirePermission from "../components/ModalRequirePermission.vue"

export default {
  name: "ViewRoleDetail",
  props: {
    /**
     * Id of Role user wants to update
     */
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      /**
       * List of all available permissions
       * Is fetched once in created and is cached here
       */
      allPermissionsByGroup: [],

      isLoading: false,
      isUpdating: false,
      modalRequirePermissionOpen: false,
    }
  },
  setup() {
    const { form, v$, getRoleDetail, updateRolePermissions } = useRoleDetail()
    return {
      form,
      v$,
      getRoleDetail,
      updateRolePermissions,
    }
  },
  computed: {},
  mounted() {
    this.fetchRoleDetail()
  },
  methods: {
    // Get Role detail
    async fetchRoleDetail() {
      this.isLoading = true
      const { uid } = this.$route.params
      this.form = await this.getRoleDetail(uid)
      this.isLoading = false
    },
    // Fetch permissions with group
    async fetchPermissions() {
      this.isLoading = true
      this.allPermissionsByGroup = await this.getPermissionsWithGroup()
      this.isLoading = false
    },

    /**
     *
     * @param {*} e
     */
    handleRoleNameInput(e) {
      this.form.name = e.target.value.replace(/\s+/g, "-").toLowerCase()
      this.v$.form.label.$touch()
    },

    /**
     *
     */
    async handleSubmit() {
      // Validate first
      this.v$.form.$touch()
      if (this.v$.form.$invalid) {
        return
      }

      if (!this.form?.permissionsUids || this.form?.permissionsUids?.length <= 0) {
        this.modalRequirePermissionOpen = true
        return
      }

      this.isUpdating = true

      const payload = {
        name: this.form.name,
        label: this.form.label,
        description: this.form.description,
        permissionsUids: this.form?.permissionsUids || [],
      }

      const response = await this.updateRolePermissions(payload, this.uid)
      this.isUpdating = false

      if (response) {
        goto("ViewRoles")
      }
    },

    /**
     *
     * @param {*} value
     */
    handlePermissionChange(value) {
      this.form.permissionsUids = value
    },

    handleClickBack() {
      goto("ViewRoles")
    },

    handleCloseRequirePermissionModal() {
      this.modalRequirePermissionOpen = false
    },
  },
  components: { PermissionPanel, ModalRequirePermission },
}
</script>
