import * as api from "../../../api/mtdpFetcher"
import { useGlobalStore } from "@/stores/global"
import { numeric, required } from "@vuelidate/validators"
import { ref } from "vue"
import useVuelidate from "@vuelidate/core"

export function useMTDPDetail() {
  const globalStore = useGlobalStore()

  const form = ref({})

  const rules = {
    form: {
      name: { required },
      description: {},
      value: {
        numeric,
        required,
      },
    },
  }

  const v$ = useVuelidate(rules, { form })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const getMTDP = async (uid) => {
    try {
      const { data } = await api.fetchMTDP(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} payload
   * @returns
   */
  const updateMTDP = async (payload, uid) => {
    try {
      const { data } = await api.updateMTDP(payload, uid)
      globalStore.addSuccessToastMessage("Successfully updated!")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @param {*} uid
   * @returns
   */
  const deleteMTDP = async (uid) => {
    try {
      const response = await api.deleteMTDP(uid)

      return response.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  return {
    getMTDP,
    updateMTDP,
    deleteMTDP,
    form,
    v$,
  }
}
