<template>
  <!-- Main modal -->
  <Transition
    :enterFromClass="variantCls.enterFromClass"
    :enterActiveClass="variantCls.overlayEnterActive"
    :leaveActiveClass="variantCls.overlayLeaveActive"
    :leaveToClass="variantCls.overlayLeaveTo"
  >
    <div v-if="modelValue" :class="variantCls.overlay"></div>
  </Transition>

  <Transition
    :enterFromClass="variantCls.enterFromClass"
    :enterActiveClass="variantCls.overlayEnterActive"
    :leaveActiveClass="variantCls.overlayLeaveActive"
    :leaveToClass="variantCls.overlayLeaveTo"
  >
    <div v-if="modelValue" tabindex="-1" aria-hidden="true" :class="variantCls.wrapper" @click.self="handleClickOverlay">
      <div class="relative p-4 max-h-screen" :class="[sizeCls]">
        <!-- Modal content -->
        <div :class="variantCls.root">
          <!-- Modal header -->
          <div :class="[variantCls.header.wrapper, headerBorderCls]">
            <slot name="header">
              <h3 :class="variantCls.header.title">{{ title }}</h3>
              <button type="button" :class="variantCls.header.button" @click="handleCloseModal">
                <CloseIcon />
              </button>
            </slot>
          </div>
          <!-- Modal body -->
          <slot name="body">
            <div class="p-4 md:p-5 space-y-4 overflow-y-auto">
              <div v-if="icon?.length > 0"><EcIcon :icon="icon" /></div>
              <p :class="variantCls.body.wrapper">
                {{ bodyText }}
              </p>
              <p :class="variantCls.body.wrapper" class="font-bold text-center">
                {{ bodySubText }}
              </p>
            </div>
          </slot>
          <!-- Modal footer -->
          <slot name="footer" v-if="showFooter">
            <div :class="[variantCls.footer.wrapper, footerBorderCls]">
              <EcSpinner v-if="isLoading"></EcSpinner>
              <button v-else type="button" :class="[acceptTypeCls]" @click="handleAccept">
                {{ accept }}
              </button>
              <button type="button" :class="variantCls.footer.cancel" @click="handleCloseModal">
                {{ cancel }}
              </button>
            </div>
          </slot>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script>
import CloseIcon from "./CloseIcon"

export default {
  name: "EcPopup",
  emits: ["update:modelValue", "onAccept", "onCancel", "overlayClick"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Title",
    },
    accept: {
      type: String,
      default: "Accept",
    },
    cancel: {
      type: String,
      default: "Cancel",
    },
    bodyText: {
      type: String,
      default: "Default body",
    },
    bodySubText: {
      type: String,
      default: "",
    },

    headerBorder: {
      type: Boolean,
      default: true,
    },

    footerBorder: {
      type: Boolean,
      default: true,
    },

    showFooter: {
      type: Boolean,
      default: true,
    },

    size: {
      type: String,
      default: "default",
    },

    icon: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "default",
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    headerBorderCls() {
      return this.headerBorder ? this.variantCls.header.border : ""
    },
    footerBorderCls() {
      return this.footerBorder ? this.variantCls.footer.border : ""
    },

    sizeCls() {
      switch (this.size) {
        case "xl":
          return "w-xl  "

        case "2xl":
          return "w-2xl   "

        case "3xl":
          return "w-3xl"

        case "4xl":
          return "w-4xl  "

        case "5xl":
          return "w-5xl  "

        case "full":
          return "w-full h-screen"

        default:
          return this.variantCls.size
      }
    },

    acceptTypeCls() {
      switch (this.type) {
        case "danger":
          return this.variantCls.footer.acceptDanger
        default:
          return this.variantCls.footer.accept
      }
    },

    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcPopup",
          variant: this.variant,
        })?.el ?? {}
      )
    },
  },
  setup() {},

  methods: {
    handleAccept() {
      this.$emit("onAccept")
    },
    handleCloseModal() {
      this.$emit("update:modelValue", false)

      this.$emit("onCancel")
    },

    /**
     * Click overlay
     */
    handleClickOverlay() {
      this.$emit("overlay-click")
      this.$emit("overlay:click")
    },
  },
  components: { CloseIcon },
}
</script>
