import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchActivityRoleList = async (params) => {
  return fetcher.get("identity/api/v1/activity-roles", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchActivityRole = async (uid) => {
  return fetcher.get(`identity/api/v1/activity-roles/${uid}`)
}

export const fetchAllActivityRole = async () => {
  return fetcher.get(`identity/api/v1/activity-roles/all`)
}

export const updateActivityRole = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/activity-roles/${uid}`, payload)
}

export const deleteActivityRole = async (uid) => {
  return fetcher.delete(`identity/api/v1/activity-roles/${uid}`)
}

export const createActivityRole = async (payload) => {
  return fetcher.post("identity/api/v1/activity-roles", payload)
}
