import fetcher from "@/modules/core/api/fetcher"
import qs from "qs"

export const fetchOwnerList = async (params) => {
  return fetcher.get("identity/api/v1/equipment-owners", {
    params,
    paramsSerializer: (params) => {
      return qs.stringify(params)
    },
  })
}

export const fetchOwnerListAll = async () => {
  return fetcher.get("identity/api/v1/equipment-owners/all")
}

export const fetchOwner = async (uid) => {
  return fetcher.get(`identity/api/v1/equipment-owners/${uid}`)
}

export const createOwner = async (payload) => {
  return fetcher.post(`identity/api/v1/equipment-owners`, payload)
}

export const updateOwner = async (payload, uid) => {
  return fetcher.put(`identity/api/v1/equipment-owners/${uid}`, payload)
}

export const deleteOwner = async (uid) => {
  return fetcher.delete(`identity/api/v1/equipment-owners/${uid}`)
}
