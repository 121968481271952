<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("setting.general.labels.edit") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("setting.general.labels.detail") }}</EcText>

          <!--  name -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.name"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.name')"
              componentName="EcInputText"
              :validator="v$"
              field="form.name"
              @input="v$.form.name.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Desc -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.description"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.description')"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="form.description"
              @input="v$.form.description.$touch()"
            >
            </RFormInput>
          </EcBox>

          <!-- RTO -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.rto"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="'RTO'"
              :rows="2"
              componentName="EcInputText"
              :validator="v$"
              field="form.rto"
            >
            </RFormInput>
          </EcBox>

          <!-- Comments -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.comments"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="'Comments'"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="form.comments"
            >
            </RFormInput>
          </EcBox>

          <!-- SLA -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.sla"
              :label="$t('application.sla.title')"
              componentName="EcCheckbox"
              :validator="v$"
              :rows="2"
              field="application.sla"
            ></RFormInput>

            <EcBox v-if="form.sla">
              <!-- Documents -->
              <EcFlex class="flex-wrap w-full max-w-full">
                <EcBox class="w-full mb-6 sm:pr-6">
                  <RUploadFiles
                    :dir="'application/slas'"
                    :type="'sla_documents'"
                    :documentTitle="$t('application.sla.docTitle')"
                    :isUploadOnSelect="false"
                    :maxFileNum="10"
                    dropZoneCls="border-c0-500 border-dashed border-2 bg-cWhite p-2 md:py-4"
                    @handleSingleUploadResult="handleSlaDocUploaded"
                    @startUploadFiles="this.isCreating = true"
                    @endUploadFiles="this.isCreating = false"
                  />
                </EcBox>
              </EcFlex>

              <EcTable name="tbl-activity-by-denpendable" :columns="columns" :dataSource="form.sla_documents" :actionMenu="true">
                <template v-slot:name="{ item }">
                  <EcNavLink class="text-c1-800 cursor-alias" :to="item?.url">{{ item.name }}</EcNavLink>
                </template>

                <template v-slot:actionMenu="{ item }">
                  <EcFlex class="items-center justify-center">
                    <EcIcon
                      icon="Trash"
                      class="text-cError-600 hover:cursor-pointer"
                      width="16"
                      @click="
                        () => {
                          selectedDocToDelete = item
                          isShowDeleteSlaDocModal = true
                        }
                      "
                    ></EcIcon>
                  </EcFlex>
                </template>
              </EcTable>
            </EcBox>
          </EcBox>

          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" id="btn-cancel" @click="handleClickCancel">
              {{ $t("activity.buttons.back") }}
            </EcButton>

            <EcButton variant="primary" class="ml-4" id="btn-confirm" @click="handleClickConfirm">
              {{ $t("activity.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete  -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h3" variant="h3" class="text-c1-800 px-5">
            {{ $t("setting.general.buttons.delete") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal text-base">
            {{ $t("setting.general.labels.noteDelete") }}
          </EcText>
          <EcButton class="mx-5" variant="warning" id="btn-modal-delete" iconPrefix="Trash" @click="handleOpenDeleteModal">
            {{ $t("setting.general.buttons.delete") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete  -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-2xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("setting.confirmToDelete") }}
            </EcHeadline>
            <!--  Name -->
            <EcText class="text-lg font-bold">
              {{ form.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("setting.general.labels.confirmDelete") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="tertiary-outline" id="btn-cancel-delete" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
            <EcButton class="ml-3" variant="warning" id="btn-delete" @click="handleDeleteApplication">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>

    <teleport to="#layer2">
      <EcPopup
        title="Delete this document?"
        :bodyText="`${selectedDocToDelete?.name} will be deleted`"
        accept="Yes"
        v-model="isShowDeleteSlaDocModal"
        type="danger"
        :isLoading="isDeletingSlaDoc"
        @onAccept="handleDeleteSlaDoc"
        @onCancel="
          () => {
            selectedDocToDelete = null
          }
        "
      >
      </EcPopup>
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useApplicationDetail } from "@/modules/application/use/useApplicationDetail"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewApplicationDetail",
  props: {
    uid: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      isShowDeleteSlaDocModal: false,
      selectedDocToDelete: null,
      isDeletingSlaDoc: false,

      columns: [
        {
          title: "SLA Document Name",
          key: "name",
        },

        {
          title: "Uploaded Date",
          key: "created_at",
          date: true,
        },
      ],
    }
  },
  mounted() {
    this.fetchDetail()
  },
  setup() {
    const globalStore = useGlobalStore()

    // Pre-loaded
    const { form, v$, getApplication, updateApplication, deleteApplication, deleteSlaDoc } = useApplicationDetail()
    return {
      getApplication,
      updateApplication,
      deleteApplication,
      deleteSlaDoc,
      form,
      v$,
      globalStore,
    }
  },
  computed: {},
  methods: {
    // =========== Role ================ //

    /**
     * Update
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params

      this.isUpdateLoading = true
      const response = await this.updateApplication(this.form, uid)
      this.isUpdateLoading = false
      if (response) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Back to application list
     */
    handleUpdateSuccess() {
      goto("ViewApplicationList")
    },

    /**
     * Cancel update application
     */
    handleClickCancel() {
      goto("ViewApplicationList")
    },

    /**
     * Open delete application modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete application modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete application
     */
    async handleDeleteApplication() {
      this.isDeleteLoading = true
      const { uid } = this.$route.params

      const isDeleted = await this.deleteApplication(uid)

      if (isDeleted) {
        setTimeout(goto("ViewApplicationList"), 1000)
      }

      this.isDeleteLoading = false
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch application
     */
    async fetchDetail() {
      this.isLoading = true
      const response = await this.getApplication(this.uid)
      if (response) {
        this.form = response
      }
      this.isLoading = false
    },

    /** Handle uploaded SLA */
    async handleSlaDocUploaded(result) {
      if (!this.form.sla_documents) {
        this.form.sla_documents = []
      }
      this.form.sla_documents.push(result)

      await this.handleSaveApplicationOnUpload()
    },

    /**
     *
     * @param {*} item
     */
    async handleDeleteSlaDoc() {
      this.isDeletingSlaDoc = true
      const res = await this.deleteSlaDoc(this.uid, this.selectedDocToDelete?.uid)

      if (res === 1) {
        this.form.sla_documents = this.form.sla_documents?.filter((item) => item?.uid !== this.selectedDocToDelete?.uid)
      }

      this.selectedDocToDelete = null
      this.isShowDeleteSlaDocModal = false
      this.isDeletingSlaDoc = false
    },

    /**
     *
     */
    async handleSaveApplicationOnUpload() {
      this.isUpdateLoading = true
      await this.updateApplication(this.form, this.uid, false)
      this.isUpdateLoading = false
    },
  },
}
</script>
