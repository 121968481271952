<template>
  <RLayout>
    <RLayoutTwoCol :isLoading="isLoading" leftCls="lg:w-8/12 lg:pr-4 mb-8" rightCls="lg:w-4/12 lg:pr-4 mb-8">
      <template #left>
        <!-- Header -->
        <EcFlex class="items-center flex-wrap">
          <EcFlex class="items-center justify-between w-full flex-wrap lg:w-auto lg:mr-4">
            <EcHeadline class="text-cBlack mr-4 mb-3 lg:mb-0">
              {{ $t("setting.general.labels.edit") }}
            </EcHeadline>
          </EcFlex>
        </EcFlex>

        <!-- Body -->
        <EcBox variant="card-1" class="width-full mt-8 px-4 sm:px-10">
          <EcText class="font-bold text-lg mb-4">{{ $t("setting.general.labels.detail") }}</EcText>

          <!--  name -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.name"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.name')"
              componentName="EcInputText"
              :validator="v$"
              field="form.name"
              @input="v$.form.name.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Desc -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.description"
              class="w-full sm:w-8/12 sm:pr-6"
              :label="$t('setting.general.labels.description')"
              :rows="2"
              componentName="EcInputLongText"
              :validator="v$"
              field="form.description"
              @input="v$.form.description.$touch()"
            ></RFormInput>
          </EcBox>

          <!-- Value -->
          <EcBox class="mt-4">
            <RFormInput
              v-model="form.value"
              class="w-full sm:w-8/12 sm:pr-6"
              componentName="EcInputNumber"
              :label="$t('setting.general.labels.valueInHour')"
              :validator="v$"
              field="form.value"
              @input="v$.form.value.$touch()"
            />
          </EcBox>

          <!-- Order -->
          <!-- <EcBox class="mt-4">
            <RFormInput
              v-model="form.order"
              class="w-full sm:w-8/12 sm:pr-6"
              componentName="EcInputNumberStepper"
              :label="$t('setting.general.labels.order')"
              :step="1"
              :min="1"
              :max="100"
              :validator="v$"
              field="form.order"
              @input="v$.form.order.$touch()"
            />
          </EcBox> -->

          <!-- End body -->
        </EcBox>

        <!-- Actions -->
        <EcBox class="width-full mt-8 px-4 sm:px-10">
          <!-- Button create -->
          <EcFlex v-if="!isUpdateLoading" class="mt-6">
            <EcButton variant="tertiary-outline" @click="handleClickCancel">
              {{ $t("activity.buttons.back") }}
            </EcButton>

            <EcButton variant="primary" class="ml-4" @click="handleClickConfirm">
              {{ $t("activity.buttons.confirm") }}
            </EcButton>
          </EcFlex>

          <!-- Loading -->
          <EcBox v-else class="flex items-center mt-6 h-10"> <EcSpinner variant="secondary" type="dots" /> </EcBox>
        </EcBox>
        <!-- End actions -->
      </template>

      <template #right>
        <!-- Delete Role -->
        <EcBox variant="card-1" class="mb-8 mt-20">
          <EcHeadline as="h2" variant="h2" class="text-c1-800 px-5">
            {{ $t("setting.general.buttons.delete") }}
          </EcHeadline>
          <EcText class="px-5 my-6 text-c0-500 leading-normal">
            {{ $t("setting.general.labels.noteDelete") }}
          </EcText>
          <EcButton class="mx-5" variant="warning" iconPrefix="Trash" @click="handleOpenDeleteModal">
            {{ $t("setting.general.buttons.delete") }}
          </EcButton>
        </EcBox>
      </template>
    </RLayoutTwoCol>

    <!-- Modal  delete Role -->
    <teleport to="#layer1">
      <!-- Modal Delete -->
      <EcModalSimple :isVisible="isModalDeleteOpen" variant="center-2xl" @overlay-click="handleCloseDeleteModal">
        <EcBox class="text-center">
          <EcFlex class="justify-center">
            <EcIcon class="text-cError-500" width="4rem" height="4rem" icon="TrashAlt" />
          </EcFlex>

          <!-- Messages -->
          <EcBox>
            <EcHeadline as="h2" variant="h2" class="text-cError-500 text-4xl">
              {{ $t("setting.confirmToDelete") }}
            </EcHeadline>
            <!--  Name -->
            <EcText class="text-lg font-bold">
              {{ form.name }}
            </EcText>
            <EcText class="text-c0-500 mt-4">
              {{ $t("setting.general.labels.confirmDelete") }}
            </EcText>
          </EcBox>

          <!-- Actions -->
          <EcFlex v-if="!isDeleteLoading" class="justify-center mt-10">
            <EcButton variant="tertiary-outline" @click="handleCloseDeleteModal">
              {{ $t("activity.buttons.cancel") }}
            </EcButton>
            <EcButton class="ml-3" variant="warning" @click="handleDeleteRTO">
              {{ $t("activity.buttons.delete") }}
            </EcButton>
          </EcFlex>
          <EcFlex v-else class="items-center justify-center mt-10 h-10">
            <EcSpinner type="dots" />
          </EcFlex>
        </EcBox>
      </EcModalSimple>
    </teleport>
  </RLayout>
</template>
<script>
import { goto } from "@/modules/core/composables"
import { useRTODetail } from "@/modules/setting/use/master/rto/useRTODetail"
import { useGlobalStore } from "@/stores/global"

export default {
  name: "ViewRTODetail",
  data() {
    return {
      isModalDeleteOpen: false,
      isLoading: false,
      isUpdateLoading: false,
      isDeleteLoading: false,
      uid: "",
    }
  },
  mounted() {
    const { uid } = this.$route.params
    this.uid = uid

    this.fetchDetail()
  },
  setup() {
    const globalStore = useGlobalStore()
    // Pre-loaded
    const { form, v$, getRTO, updateRTO, deleteRTO } = useRTODetail()
    return {
      getRTO,
      updateRTO,
      deleteRTO,
      form,
      v$,
      globalStore,
    }
  },
  computed: {},
  methods: {
    // =========== Role ================ //

    /**
     * Update RTO
     *
     */
    async handleClickConfirm() {
      this.v$.$touch()

      if (this.v$.$invalid) {
        return
      }

      const { uid } = this.$route.params

      this.isUpdateLoading = true
      const response = await this.updateRTO(this.form, uid)
      this.isUpdateLoading = false
      if (response) {
        setTimeout(this.handleUpdateSuccess, 1000)
      }
    },

    /**
     * Back to RTO list
     */
    handleUpdateSuccess() {
      goto("ViewRTOList")
    },

    /**
     * Cancel update RTO
     */
    handleClickCancel() {
      goto("ViewRTOList")
    },

    /**
     * Open delete RTO modal
     */
    handleOpenDeleteModal() {
      this.isModalDeleteOpen = true
    },

    /**
     * Open delete RTO modal
     */
    handleCloseDeleteModal() {
      this.isModalDeleteOpen = false
    },

    /**
     * Handle delete RTO
     */
    async handleDeleteRTO() {
      this.isDeleteLoading = true
      const { uid } = this.$route.params

      const isDeleted = await this.deleteRTO(uid)

      if (isDeleted) {
        setTimeout(goto("ViewRTOList"), 1000)
      }

      this.isDeleteLoading = false
    },

    // =========== PRE-LOAD -------//
    /**
     * Fetch RTO
     */
    async fetchDetail() {
      this.isLoading = true
      const response = await this.getRTO(this.uid)
      if (response) {
        this.form = response
      }
      this.isLoading = false
    },
  },
}
</script>
