import * as api from "../../api/supplierCategoryFetcher"
import { ref } from "vue"

export function useCategoryList() {
  const categories = ref([])
  // Initial data

  const getSupplierCategories = async (params) => {
    try {
      const { data } = await api.fetchCategoryList(params)
      return data
    } catch (error) {
      return error
    }
  }

  return {
    categories,
    getSupplierCategories,
  }
}
