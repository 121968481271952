<template>
  <EcFlex @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeave" :class="variantCls.root">
    <EcFlex :class="variantCls.image.wrapper">
      <div id="sidebar-logo-container" class="flex items-center w-full">
        <EcNavLink id="sidebar-logo" class="w-full overflow-hidden block" to="/">
          <img :src="computedLogo" alt="Logo" :class="variantCls.image.img" />
        </EcNavLink>
        <div
          @click="toggleMenu"
          id="sidebar-button-toggled"
          class="flex content-center flex-wrap justify-self-end cursor-pointer pr-4 text-c1-500"
          v-tooltip="{ text: 'Toggle menu sidebar' }"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <circle v-if="isMenuOpen" cx="12" cy="12" r="3"></circle>
          </svg>
        </div>
      </div>
      <!-- class="flex-0 w-16 cursor-pointer" style="min-height: 78px" -->
    </EcFlex>
    <RSidebarMenu :unreadNotification="unreadNotification" class="flex-grow" />
  </EcFlex>
</template>

<script>
import RSidebarMenu from "./RSidebarMenu"
import { useGlobalStore } from "@/stores/global"
import EcFlex from "@/components/EcFlex/index.vue"
import { ref } from "vue"

export default {
  name: "RSidebar",
  inject: ["getComponentVariants"],
  components: {
    EcFlex,
    RSidebarMenu,
  },
  props: {
    unreadNotification: {
      type: Number,
      default: 0,
    },
  },
  /* eslint-disable */
  setup() {
    const globalStore = useGlobalStore()
    const logoImg = ""

    return {
      logoImg,
      globalStore,
    }
  },

  data() {
    return {
      isMenuOpen: ref(true),
      isMenuToggled: true,
    }
  },
  mounted() {
    if (localStorage.getItem("isMenuOpen") === "false") {
      this.isMenuOpen = false
    } else {
      this.isMenuOpen = true
    }
  },
  beforeMount() {
    this.decideImageToRender()
  },
  computed: {
    variants() {
      return (
        this.getComponentVariants({
          componentName: "RSidebar",
          variant: "default",
        }) ?? {}
      )
    },
    variantCls() {
      return this.variants?.el || {}
    },

    computedLogo() {
      return this.logoImg || "https://via.placeholder.com/64x78"
    },
  },

  methods: {
    /**
     * Render image
     */
    async decideImageToRender() {
      const { tenantId } = this.globalStore?.getTenantClientId
      const tenantConfig = this.globalStore?.getTenantSettings

      try {
        const fileName = tenantConfig?.default?.logo?.sidebar

        this.logoImg = tenantConfig?.server?.logo || require(`@/tenants/${tenantId}/assets/${fileName}`)
        // eslint-disable-next-line no-empty
      } catch (error) {
        console.groupCollapsed(
          "%c Tenant config file does not exist",
          "padding: 1px 6px 1px 0px; background: yellow; color: black"
        )
        console.log(`tenantId: ${tenantId}`)
        console.log(error)
        console.groupEnd()
      }
    },
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
      localStorage.setItem("isMenuOpen", this.isMenuOpen)
    },
    handleMouseLeave() {
      this.isMenuToggled = true
      if (!this.isMenuOpen) {
        document.body.classList.add("sidebar-collapse")
        document.body.classList.remove("toggle-hover")
      }
    },
    handleMouseEnter() {
      this.isMenuToggled = false
      if (!this.isMenuOpen) {
        document.body.classList.add("toggle-hover")
        document.body.classList.remove("sidebar-collapse")
      }
    },
  },
  watch: {
    isMenuOpen() {
      if (this.isMenuOpen) {
        document.body.classList.remove("sidebar-collapse")
        document.body.classList.remove("toggle-hover")
      } else {
        document.body.classList.add("sidebar-collapse")
        document.body.classList.remove("toggle-hover")
      }
    },
  },
  /* eslint-enable */
}
</script>
<style></style>
