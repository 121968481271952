<template>
  <!-- Main modal -->

  <Transition
    :enterFromClass="variantCls.enterFromClass"
    :enterActiveClass="variantCls.overlayEnterActive"
    :leaveActiveClass="variantCls.overlayLeaveActive"
    :leaveToClass="variantCls.overlayLeaveTo"
  >
    <div v-show="true" :class="variantCls.root" v-click-outside="handleClosePopover">
      <!-- Button -->
      <div @click="handleOpenPopover">
        <slot name="button">
          <button :class="variantCls.button" type="button">Button</button>
        </slot>
      </div>

      <!-- Start popup -->
      <div v-if="isOpen" data-popover role="tooltip" :class="variantCls.popup.wrapper" v-bind="$attrs">
        <slot name="popup">
          <slot name="popup-header">
            <div :class="variantCls.popup.header">
              <h3 :class="variantCls.popup.headerTitle">
                {{ title }}
              </h3>
              <h4 :class="variantCls.popup.headerSubTitle">{{ subTitle }}</h4>
            </div>
          </slot>
          <!-- Content -->

          <slot name="popup-content">
            <div class="px-3 py-2">
              <p>And here's some amazing content. It's very engaging. Right?</p>
            </div>
            <div data-popper-arrow></div>
          </slot>
        </slot>
      </div>

      <!-- End popup -->
    </div>
  </Transition>
</template>

<script>
export default {
  name: "EcPopover",
  emits: ["update:modelValue", "onOpen"],
  props: {
    variant: {
      type: String,
      default: "default",
    },
    modelValue: {
      type: Boolean,
      default: true,
    },
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "Popover hover title",
    },
    subTitle: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      isOpen: false,
    }
  },

  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcPopover",
          variant: this.variant,
        })?.el ?? {}
      )
    },
  },

  methods: {
    handleOpenPopover() {
      this.isOpen = true
      this.$emit("onOpen")
    },

    handleClosePopover() {
      if (!this.closeOnClickOutside) {
        return
      }

      this.isOpen = false
    },
  },
  components: {},
}
</script>
