import { fetchActivityStatistic, fetchCriticalActivities } from "@/readybc/composables/api/apiDashboard"
import { defineStore } from "pinia"
import { ref } from "vue"

const useDashboardActivityStore = defineStore("useDashboardActivityStore", () => {
  const isActivityStatisticLoading = ref(false)
  const activityStatistic = ref([])

  const isCriticalActivityLoading = ref(false)
  const criticalActivities = ref([])

  const getActivityStatistic = () => {
    isActivityStatisticLoading.value = true
    fetchActivityStatistic()
      .then(({ data }) => {
        activityStatistic.value = data || []
        isActivityStatisticLoading.value = false
      })
      .catch(() => {
        isActivityStatisticLoading.value = false
      })
  }

  /**
   *
   */
  const getCriticalActivities = () => {
    isCriticalActivityLoading.value = true
    fetchCriticalActivities()
      .then(({ data }) => {
        criticalActivities.value = data || []
        isCriticalActivityLoading.value = false
      })
      .catch(() => {
        isCriticalActivityLoading.value = false
      })
  }

  return {
    isActivityStatisticLoading,
    activityStatistic,
    getActivityStatistic,

    isCriticalActivityLoading,
    criticalActivities,
    getCriticalActivities,
  }
})

export default useDashboardActivityStore
