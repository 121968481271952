<template>
  <div class="relative">
    <button class="z-10 relative flex items-center focus:outline-none select-none" @click="open = !open">
      <slot name="button"></slot>
    </button>

    <!--dropdown menu-->
    <transition
      enterActiveClass="transition-all duration-200 ease-out"
      leaveActiveClass="transition-all duration-750 ease-in"
      enterClass="opacity-0 scale-75"
      enterToClass="opacity-100 scale-100"
      leaveClass="opacity-100 scale-100"
      leaveToClass="opacity-0 scale-75"
    >
      <div
        v-if="open"
        v-click-outside="close"
        class="absolute rounded text-sm bg-cWhite z-20 py-1"
        :class="[
          placement === 'right' ? 'right-0' : 'left-0',
          position === 'top' ? 'bottom-full mb-1' : 'mt-1',
          isButtonGroup ? 'min-w-full  shadow-dropdown' : 'shadow-3xl px-2',
        ]"
      >
        <slot name="content"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "EcDropdown",
  data() {
    return {
      open: false,
    }
  },
  props: {
    placement: {
      type: String,
      default: "right",
      validator: (value) => ["right", "left"].indexOf(value) !== -1,
    },
    position: {
      type: String,
      default: "bottom",
      validator: (value) => ["top", "bottom", "right"].indexOf(value) !== -1,
    },
    isButtonGroup: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    // document.addEventListener("click", this.close)
    // document.addEventListener("keydown", this.onEscape)
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.onEscape)
    document.removeEventListener("click", this.close)
  },
  methods: {
    close(e) {
      if (e) {
        e.stopPropagation()
      }

      const collapseExcept = e.target.getAttribute("collapse") === "false"
      if (!this.$el.contains(e.target) && !collapseExcept) {
        this.open = false
      }
    },
    onEscape(e) {
      if (e.key === "Esc" || e.key === "Escape") {
        this.open = false
      }
    },
  },
}
</script>
