import ViewEquipmentList from "@/modules/equipment/views/resource/ViewEquipmentList"
import ViewEquipmentNew from "@/modules/equipment/views/resource/ViewEquipmentNew"
import ViewEquipmentDetail from "@/modules/equipment/views/resource/ViewEquipmentDetail"

// == Owners ==
import ViewOwnerList from "@/modules/equipment/views/owner/ViewOwnerList"
import ViewOwnerNew from "@/modules/equipment/views/owner/ViewOwnerNew"
import ViewOwnerDetail from "@/modules/equipment/views/owner/ViewOwnerDetail"

// == Categories ==
import ViewCategoryList from "@/modules/equipment/views/category/ViewCategoryList"
import ViewCategoryDetail from "@/modules/equipment/views/category/ViewCategoryDetail"

export default [
  {
    path: "/equipment",
    component: ViewEquipmentList,
    name: "ViewEquipmentList",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  {
    path: "/equipment/new",
    component: ViewEquipmentNew,
    name: "ViewEquipmentNew",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  {
    path: "/equipment/:uid",
    component: ViewEquipmentDetail,
    name: "ViewEquipmentDetail",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  // ============ OWNERS =========
  {
    path: "/equipment-owners",
    component: ViewOwnerList,
    name: "ViewOwnerList",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  {
    path: "/equipment-owners/new",
    component: ViewOwnerNew,
    name: "ViewOwnerNew",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  {
    path: "/equipment-owners/:uid",
    component: ViewOwnerDetail,
    name: "ViewOwnerDetail",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  // ============ CATEGORIES =========
  {
    path: "/equipment-categories",
    component: ViewCategoryList,
    name: "ViewCategoryList",
    props: true,
    meta: {
      module: "equipment",
    },
  },

  {
    path: "/equipment-categories/:uid",
    component: ViewCategoryDetail,
    name: "ViewCategoryDetail",
    props: true,
    meta: {
      module: "equipment",
    },
  },
]
