<template>
  <tr class="overflow-hidden text-cBlack">
    <slot />
  </tr>
</template>

<script>
export default {
  name: "RTableRow",
}
</script>
