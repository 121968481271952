import { useGlobalStore } from "@/stores/global"
import { ref } from "vue"
import * as api from "../api/notificationFetcher"

export function useNotification() {
  const globalStore = useGlobalStore()
  const eventNotificationLogs = ref()
  const notification = ref({})
  const isLoading = ref(false)

  /**
   * User's notificaation
   * @returns
   */
  const getNotificationLogs = async () => {
    try {
      const { data } = await api.fetchNotificationLogs()

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   * Get list notification log in tenant
   * @returns
   */
  const getListNotificationLogs = async (requestParams) => {
    try {
      const { data } = await api.fetchListNotificationLogs(requestParams)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  /**
   *
   * @returns
   */
  const getNotificationLogDetail = async (uid) => {
    try {
      const { data } = await api.fetchNotificationLogDetail(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  /**
   *
   * @returns
   */
  const getNotificationDetail = async (uid) => {
    try {
      const { data } = await api.fetchNotificationDetail(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }
  /**
   *
   * @returns
   */
  const readNotification = async (uid) => {
    try {
      const { data } = await api.markNotificationAsRead(uid)

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  /**
   *
   * @returns
   */
  const deleteNotification = async (uid) => {
    try {
      const response = await api.deleteNotification(uid)

      return response?.status === 200
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)

      return false
    }
  }

  return {
    eventNotificationLogs,
    notification,
    getNotificationLogs,
    getListNotificationLogs,
    getNotificationLogDetail,
    getNotificationDetail,
    readNotification,
    deleteNotification,
    isLoading,
  }
}
