<template>
  <div :class="variantCls.root">
    <div class="flex pb-3">
      <!-- Finished Rounded -->
      <template v-for="(option, idx) in options" :key="option.step">
        <!-- Finished Rounded -->
        <div class="flex-1 relative" @click="isClickable(option.step) && handleClickable(option)">
          <div :class="[variantCls.circle.wrapper, getCirleCls(option)]">
            <span :class="[variantCls.circle.text, getPingAnimation(option.step)]">
              {{ option.step }}
            </span>
          </div>
          <div v-if="option.hint" :class="variantCls.label" class="cursor-pointer">
            <div v-tooltip="{ text: option.hint, position: 'bottom' }">
              {{ option.label }}
              <EcIcon icon="QuestionMark" width="12" height="12" class="text-cSuccess-600 inline-block ml-1"></EcIcon>
            </div>
          </div>
          <div v-else :class="variantCls.label">{{ option.label }}</div>
        </div>

        <!-- Dash -->
        <div v-if="idx < options.length - 1" :class="variantCls.dash.root">
          <div :class="[variantCls.dash.wrapper, getDashCls(option)]">
            <div :class="variantCls.dash.content"></div>
          </div>
        </div>
      </template>
    </div>

    <!-- Labels -->
  </div>
</template>

<script>
import { goto } from "@/modules/core/composables"

export default {
  inheritAttrs: false,
  name: "EcStepBar",
  emits: ["onClick"],
  props: {
    variant: {
      type: String,
      default: "default",
    },

    current: {
      type: Number,
      default: 0,
    },

    onStep: {
      type: Number,
      default: 0,
    },

    clickable: {
      type: Boolean,
      default: false,
    },

    currentStepPing: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    variantCls() {
      return (
        this.getComponentVariants({
          componentName: "EcStepBar",
          variant: this.variant,
        })?.el ?? {}
      )
    },
  },

  methods: {
    isClickable(step) {
      return this.current >= step || this.clickable
    },

    getPingAnimation(step) {
      return this.current === step && this.currentStepPing ? " animate-ping " : ""
    },

    /**
     *
     * @param {*} option
     */
    handleClickable(option) {
      // Handle click by emit an event
      this.$emit("onClick", false)

      // Hanle click with passed option
      if (option?.route?.length <= 0) {
        return
      }

      goto(option.route, option?.routeParams || {})
    },

    /**
     *
     * @param {*} option
     */
    getCirleCls(option) {
      if (this.isClickable(option.step) || option.step === this.onStep) {
        return option.step <= this.onStep ? this.variantCls.circle.onStep : this.variantCls.circle.finished
      }
      return this.variantCls.circle.unfinished
    },

    /**
     *
     * @param {*} option
     */
    getDashCls(option) {
      if (option.step <= this.current) {
        return option.step < this.onStep ? this.variantCls.dash.onStep : this.variantCls.dash.finished
      }
      return this.variantCls.dash.unfinished
    },
  },
}
</script>
