<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h3" variant="h3" class="text-4xl">
          {{ $t("resiliencePlanCategory.labels.addResiliencePlanCategory") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="category.name"
          :label="$t('resiliencePlanCategory.labels.name')"
          componentName="EcInputText"
          :validator="v$"
          field="category.name"
          @input="v$.category.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="category.description"
          :label="$t('resiliencePlanCategory.labels.description')"
          componentName="EcInputLongText"
          :validator="v$"
          :rows="2"
          field="category.description"
          @input="v$.category.description.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Order -->
      <RFormInput
        class="w-6/12 mt-4"
        :label="$t('resiliencePlanCategory.labels.order')"
        v-model="category.order"
        componentName="EcInputNumber"
        :options="textList"
        :isSingleSelect="true"
        :valueKey="'tag_text_color'"
        :allowSelectNothing="false"
      />

      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline-sm" @click="handleCloseAddNewModal">
          {{ $t("resiliencePlanCategory.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary-sm" @click="handleClickCreateResiliencePlanCategory">
          {{ $t("resiliencePlanCategory.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useResiliencePlanCategoryNew } from "@/modules/resilience_plan/use/category/useResiliencePlanCategoryNew"

export default {
  name: "ModalAddResiliencePlanCategory",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { category, v$, createResiliencePlanCategory } = useResiliencePlanCategoryNew()

    return {
      category,
      v$,
      createResiliencePlanCategory,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateResiliencePlanCategory() {
      this.v$.$touch()
      if (this.v$.$invalid) {
        return
      }

      this.isLoading = true

      const response = await this.createResiliencePlanCategory(this.category)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewResiliencePlanCategoryStatusOpen() {
      this.category.name = null
      this.category.description = null
      this.category.text_color = null
      this.category.bg_color = null
      this.v$.category.$reset()
    },
  },
}
</script>
