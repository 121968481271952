import * as api from "../api/apiResiliencePlanStatus"
import { ref } from "vue"

export function useResiliencePlanStatus() {
  const statuses = ref([])
  const isLoadingStatus = ref(false)

  /**
   *
   * @returns
   */
  const getStatuses = async () => {
    try {
      const { data } = await api.fetchResiliencePlanStatuses()

      return data
    } catch (error) {
      return false
    }
  }
  /**
   *
   * @returns
   */
  const getAllStatuses = async () => {
    try {
      const { data } = await api.fetchAllResiliencePlanStatuses()

      return data
    } catch (error) {
      return false
    }
  }

  return {
    statuses,
    isLoadingStatus,
    getStatuses,
    getAllStatuses,
  }
}
