<template v-if="errors && errors.length > 0">
  <EcBox v-for="(error, index) in errors" :key="index" class="text-xs text-cError-600">
    <div v-if="error?.$validator?.length > 0">
      <div v-if="error?.$validator && customRuleMessage[error?.$validator]">
        {{ customRuleMessage[error?.$validator] }}
      </div>
      <div v-else>{{ error.$message }}</div>
    </div>
    <div v-else>{{ error }}</div>
  </EcBox>
</template>

<script>
export default {
  name: "InputValidateError",
  props: {
    customRuleMessage: {
      type: Object,
      default: () => {},
    },

    errors: {
      type: Array,
      default: () => [],
    },

    fieldName: {
      type: String,
      default: "",
    },
  },
}
</script>
