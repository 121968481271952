import * as api from "@/readybc/composables/api/apiActivityPriority"
import useVuelidate from "@vuelidate/core"
import { required } from "@vuelidate/validators"
import { ref } from "vue"

export function useActivityPriorities() {
  const priority = ref({})
  const activityPriorities = ref([])

  const rules = {
    priority: {
      name: { required },
      description: {},
      order: {},
    },
  }

  const v$ = useVuelidate(rules, { priority })

  const getIconOptions = () => {
    const icons = [
      { image: "low.svg", name: "Low" },
      { image: "medium.svg", name: "Medium" },
      { image: "high.svg", name: "High" },
      { image: "minor.svg", name: "Minor" },
      { image: "major.svg", name: "Major" },
      { image: "critical.svg", name: "Critical" },
      { image: "blocker.svg", name: "Blocker" },
      { image: "trivial.svg", name: "Trivial" },
    ]

    return icons?.map((icon) => {
      return {
        value: "/public/images/priority/" + icon.image,
        name: icon.name,
        tag_color: "bg-none",
        tag_text_color: "text-cBlack",
      }
    })
  }
  /**
   *
   * @returns
   */
  const getActtivityPriorities = async () => {
    try {
      const { data } = await api.fetchActivityPriorities()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   * Get All for drop down
   * @returns
   */
  const getAllActtivityPriorities = async () => {
    try {
      const { data } = await api.fetchAllActivityPriorities()
      return data
    } catch (error) {
      return error
    }
  }

  /**
   * Get All for drop down
   * @returns
   */
  const getPriority = async (uid) => {
    try {
      const { data } = await api.fetchPriority(uid)
      return data
    } catch (error) {
      return error
    }
  }

  /**
   * Get All for drop down
   * @returns
   */
  const createPriority = async (payload) => {
    try {
      const { data } = await api.createPriority(payload)
      return data
    } catch (error) {
      return error
    }
  }

  /**
   * Get All for drop down
   * @returns
   */
  const updatePriority = async (payload, uid) => {
    try {
      const { data } = await api.updatePriority(payload, uid)
      return data
    } catch (error) {
      return error
    }
  }

  /**
   * Get All for drop down
   * @returns
   */
  const deletePriority = async (uid) => {
    try {
      const { data } = await api.deletePriority(uid)
      return data
    } catch (error) {
      return false
    }
  }

  return {
    getIconOptions,
    priority,
    activityPriorities,
    v$,
    getActtivityPriorities,
    getAllActtivityPriorities,
    getPriority,
    createPriority,
    updatePriority,
    deletePriority,
  }
}
