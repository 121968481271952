<template>
  <div></div>
</template>

<script>
import { useGlobalStore } from "@/stores/global"
import { getDatabase, query, ref as refFirebase, limitToLast, orderByChild, onValue } from "firebase/database"
import { useNotification } from "@/modules/notification/use/useNotification"
import { ref } from "vue"
export default {
  name: "RNotification",
  setup() {
    const globalStore = useGlobalStore()
    const unsubscribe = ref(null)
    const { getNotificationDetail } = useNotification()
    const audio = new Audio(require(`@/assets/sounds/notify.mp3`))
    return {
      globalStore,
      unsubscribe,
      audio,
      getNotificationDetail,
    }
  },
  mounted() {
    this.listenFirebaseDatabase()
  },
  unmounted() {
    this.unsubscribe()
  },
  data() {
    return {
      isFirstLoad: true,
    }
  },
  methods: {
    listenFirebaseDatabase() {
      const db = getDatabase()
      const notificationRef = refFirebase(
        db,
        `tenant/${this.globalStore.getTenantUid}/users/${this.globalStore.me?.uid}/notifications/`
      )
      const q = query(notificationRef, orderByChild("timestamp"), limitToLast(1))
      this.unsubscribe = onValue(q, (querySnapshot) => {
        const data = querySnapshot.val()
        this.handleNotification(data)
      })
    },

    /**
     *
     * @param {*} data
     */
    async handleNotification(data) {
      if (this.isFirstLoad) {
        this.isFirstLoad = false
        return
      }
      for (const key in data) {
        this.globalStore.increaseNotificationCnt(1)

        const notificationDetail = await this.getNotificationDetail(data[key].notification_id)
        if (notificationDetail) {
          this.globalStore.addNotifyToastMessage(notificationDetail)
          await this.audio.play()
        }
      }
    },
  },
}
</script>
