import { useGlobalStore } from "@/stores/global"

const rbac = {
  install: (app, options) => {
    const globalStore = useGlobalStore()

    /**
     *
     * @param {*} permissions
     */
    const hasPermissions = (permissions, any = false) => {
      const userPermissionKeys = []

      globalStore.me?.roles?.forEach((role) => {
        // Permission names

        role?.permissions?.forEach((permission) => {
          userPermissionKeys.push(permission.name)
        })
      })

      /**
       * If any = true, user just need at least 1 permission to
       */
      if (any) {
        permissions?.some((p) => userPermissionKeys.includes(p))
      }

      return permissions?.every((p) => userPermissionKeys.includes(p))
    }

    /**
     *
     * @param {*} str
     * @returns
     */
    const sanitizeStr = (str) => {
      return str.replace("/[^a-z0-9-]/gi", "").replace("-", ".")
    }

    /**
     *
     * @param {*} binding
     * @returns
     */
    const getPermissions = (binding) => {
      const permissions = []

      // Get from arg
      if (binding.arg?.length > 0) {
        permissions.push(sanitizeStr(binding.arg))
      }

      // Get from value

      // If array then get by for each
      if (Array.isArray(binding.value)) {
        binding.value?.forEach((item) => {
          if (item?.length > 0) {
            permissions.push(sanitizeStr(item))
          }
        })
      } else {
        if (binding.value?.length > 0) {
          permissions.push(sanitizeStr(binding.value))
        }
      }

      return permissions
    }

    /**
     *
     * Function check permissions
     */
    const checkPermissions = (permissions, any = false) => {
      if (!Array.isArray(permissions)) {
        permissions = [permissions]
      }

      return hasPermissions(permissions, any)
    }

    /**
     * Global functions
     */
    app.config.globalProperties.$permissions = checkPermissions

    /**
     * Usage
     * v-permission:<permission> to check if user have permission to perform action, dot need to be replaced with hyphen
     * v-permission=[<permission1>, <permission2>] User must have mutiple permissions to perform
     * Directives
     */
    const vPermissions = {
      async beforeMount(el, binding, vnode, prevVnode) {
        const userHasPermissions = await hasPermissions(getPermissions(binding), binding.modifiers.or)

        if (!userHasPermissions) {
          // if modifier is disable then set attribute disabled = true, else remove the element
          if (binding.modifiers.disable) {
            el.disabled = true
          } else {
            el.remove()
          }
        }
      },
    }

    app.directive("permissions", vPermissions)
  },
}

export { rbac }
