import useVuelidate from "@vuelidate/core"
import { ref } from "vue"
import { required } from "@vuelidate/validators"
import * as api from "../../api/resiliencePlanFetcher"
import { useGlobalStore } from "@/stores/global"

export function useResiliencePlanNew() {
  const globalStore = useGlobalStore()

  const resiliencePlan = ref({
    category: null,
    reports: [],
  })

  const rules = {
    resiliencePlan: {
      name: { required },
      due_date: { required },
      review_date: { required },
      category: { required },
      status: { required },
      reports: {},
    },
  }

  const vldator$ = useVuelidate(rules, { resiliencePlan })

  /**
   *
   * @param {*} payload
   * @returns
   */
  const createNewResiliencePlan = async (payload) => {
    try {
      const { data } = await api.createNewResiliencePlan(payload)
      globalStore.addSuccessToastMessage("Created new BCP, redirect to BCP list...")

      return data
    } catch (error) {
      globalStore.addErrorToastMessage(error?.message)
    }
  }

  return {
    resiliencePlan,
    vldator$,
    createNewResiliencePlan,
  }
}
