<template>
  <component :width="width" :height="height" :is="icon" :title="title" v-bind="$attrs" />
</template>

<script>
import { defineAsyncComponent } from "vue"

const lazyLoad = (iconName) => defineAsyncComponent(() => import(`./${iconName}.vue`))

export default {
  name: "Icon",
  props: {
    icon: {
      type: String,
      required: true,
      default: "Default",
    },
    width: {
      type: String,
      default: "16",
    },
    height: {
      type: String,
      default: "16",
    },
    title: {
      type: String,
      default: "16",
    },
  },
  components: {
    Action: lazyLoad("Action"),
    Delete: lazyLoad("Delete"),
    Edit: lazyLoad("Edit"),
    Export: lazyLoad("Export"),
    Sort09: lazyLoad("Sort09"),
    Sort90: lazyLoad("Sort90"),
    SortAZ: lazyLoad("SortAZ"),
    SortZA: lazyLoad("SortZA"),
    Spinner: lazyLoad("Spinner"),
    MailOpen: lazyLoad("MailOpen"),
    OfficeBuilding: lazyLoad("OfficeBuilding"),
  },
}
</script>
