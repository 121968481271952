export const nameValidator = (value) => /^[a-zA-Z][a-zA-Z -]*$/.test(value)
export const phoneValidator = (value) =>
  /(\+?\d{0,3}\s?)?(\(?\d{1,4}\)?[\s-]?)?[0-9]{1,4}[\s-]?[0-9]{1,4}[\s-]?[0-9]{1,4}(\s?(([Ee]xt[:.]?\s?)|x|X)(\s?\d+))?/.test(
    value
  )
export const subDomainValidator = (value) => /^[a-zA-Z0-9-_]+$/.test(value)
export const notContainHtmlTagValidator = (value) => {
  const regex = /<[a-z][\s\S]*>/i
  return !regex.test(value)
}

export default {
  nameValidator,
  phoneValidator,
  subDomainValidator,
  notContainHtmlTagValidator,
}
