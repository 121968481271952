<template>
  <RLayout>
    <!-- Header -->
    <EcFlex class="grid grid-cols-1 items-center">
      <EcFlex class="flex-wrap items-center justify-between w-full lg:w-auto lg:mr-4">
        <EcHeadline class="w-full mb-3 mr-4 text-cBlack lg:mb-0">
          {{ $t("report.reportDetail") }}
        </EcHeadline>
        <EcBox v-if="reportData" class="mt-4">
          <EcHeadline as="h5" class="w-full text-xl">
            {{ reportData?.name }}
            <EcBadge
              :class="[
                'px-2 py-1 rounded-full min-w-20 text-base inline',
                refineReportStatus(this.reportData?.status) === 'success'
                  ? 'text-cWhite bg-cSuccess-500'
                  : refineReportStatus(this.reportData?.status) === 'failed'
                  ? 'text-cWhite bg-cError-500'
                  : 'border-1',
              ]"
            >
              {{ refineReportStatus(this.reportData?.status) }}
            </EcBadge>
          </EcHeadline>
          <EcLabel class="text-base">
            {{ this.$t("report.labels.createdBy") }}:
            {{ this.reportData?.user ? `${this.reportData?.user?.first_name} ${this.reportData?.user?.last_name}` : "N/A" }}
          </EcLabel>
          <EcLabel class="text-base">
            {{ this.$t("report.labels.createdAt") }}: {{ formatData(this.reportData?.created_at, this.dateTimeFormat) }}
          </EcLabel>
        </EcBox>
      </EcFlex>
    </EcFlex>

    <EcFlex class="w-full items-center">
      <!-- Viewer -->
      <EcBox class="w-full h-full">
        <EcDocumentPreview v-if="reportData && reportTemplateDocument" :document="reportTemplateDocument" />
      </EcBox>
    </EcFlex>
  </RLayout>
</template>

<script>
import { useI18n } from "vue-i18n"
import { useReport } from "@/modules/report/use/useReport"
import { formatData } from "@/modules/core/composables"
import { useGlobalStore } from "@/stores/global"
import EcDocumentPreview from "@/components/EcDocumentPreview/index.vue"
import EcHeadline from "@/components/EcHeadline/index.vue"

export default {
  name: "ViewReport",
  components: { EcHeadline, EcDocumentPreview },
  data() {
    return {
      isLoading: false,
      reportData: null,
      reportTemplateDocument: null,
    }
  },
  setup() {
    const globalStore = useGlobalStore()
    const { fetchReportDetails, refineReportStatus } = useReport()
    const { t } = useI18n()

    return {
      fetchReportDetails,
      refineReportStatus,
      globalStore,
      t,
    }
  },
  beforeMount() {
    const { uid } = this.$route.params
    this.fetchReportDetailsData(uid)
  },
  computed: {
    dateTimeFormat() {
      return this.globalStore.dateTimeFormat
    },
  },
  methods: {
    formatData,
    async fetchReportDetailsData(uid) {
      this.isLoading = true

      const template = await this.fetchReportDetails(uid)

      if (template && template?.data) {
        this.reportData = template?.data
        this.reportTemplateDocument = template?.data?.document
      }

      this.isLoading = false
    },
  },
}
</script>
