<template>
  <EcModalSimple :isVisible="modalEditRoleOpen" variant="center-3xl">
    <EcBox class="mb-6">
      <EcText class="font-medium text-c0-300">Edit for</EcText>
      <EcHeadline as="h3" variant="h3" class="mt-2 text-c1-500">
        {{ this.username }}
      </EcHeadline>
    </EcBox>
    <EcFlex class="w-full mb-5">
      <RFormInput
        componentName="EcMultiSelect"
        label="Role"
        placeholder="Please select"
        :modelValue="currentRoles"
        :options="roleOptions"
        :nameKey="'label'"
        :valueKey="'uid'"
      />
    </EcFlex>
    <EcFlex v-if="!isLoading" class="mt-10">
      <EcButton v-if="isUserSelectedNewRole" class="mr-4" variant="primary" @click="handleClickBtnUpdateRole">
        {{ $t("user.button.confirm") }}
      </EcButton>
      <EcButton variant="tertiary-outline" @click="handleCloseModal">
        {{ $t("user.button.cancel") }}
      </EcButton>
    </EcFlex>
    <EcSpinner v-else />
  </EcModalSimple>
</template>

<script>
import { ref } from "vue"
import isEmpty from "lodash.isempty"
import { useUserDetail } from "../use/useUserDetail"

export default {
  props: {
    username: ref("-"),
    user: {
      type: Object,
      required: true,
    },
    modalEditRoleOpen: Boolean,
    roleOptions: ref([]),
  },

  data() {
    return {
      currentRoles: ref([]),
      isUserSelectedNewRole: false,
      isLoading: false,
    }
  },

  setup(props) {
    const { assignRole } = useUserDetail()

    return {
      assignRole,
    }
  },

  computed: {},

  methods: {
    async handleClickBtnUpdateRole() {
      if (isEmpty(this.currentRoles)) {
        return
      }

      this.isLoading = true

      const res = await this.assignRole(this.user.uid, this.currentRoles)

      if (res) {
        this.$emit("handleRoleChanged", res)
        this.handleCloseModal()
      }

      this.isLoading = false
    },

    /**
     * Close modal
     */
    handleCloseModal() {
      this.$emit("handleClickCancelEditRole")
    },
  },
  watch: {
    /**
     *
     */
    modalEditRoleOpen() {
      this.currentRoles = this.user?.roles?.map((item) => {
        return item
      })
    },

    /**
     *
     */
    currentRoles() {
      this.isUserSelectedNewRole = true
    },
  },
}
</script>
