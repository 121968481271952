<template>
  <EcModalSimple :isVisible="isModalAddNewOpen" variant="center-2xl">
    <EcBox>
      <!-- Messages -->
      <EcBox class="text-center">
        <EcHeadline as="h2" variant="h2" class="text-4xl">
          {{ $t("setting.general.buttons.addBCPStatus") }}
        </EcHeadline>
      </EcBox>

      <!-- name -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="status.name"
          :label="$t('setting.general.labels.name')"
          componentName="EcInputText"
          :validator="validator$"
          field="status.name"
          @input="validator$.status.name.$touch()"
        ></RFormInput>
      </EcBox>

      <!-- Desc -->
      <EcBox class="mt-4">
        <RFormInput
          v-model="status.description"
          :label="$t('setting.general.labels.description')"
          componentName="EcInputLongText"
          :validator="validator$"
          :rows="2"
          field="status.description"
          @input="validator$.status.description.$touch()"
        ></RFormInput>
      </EcBox>

      <RFormInput
        class="w-6/12 mt-4"
        :label="$t('setting.general.labels.bgColor')"
        v-model="status.bg"
        componentName="EcMultiSelect"
        :options="colorList"
        :isSingleSelect="true"
        :valueKey="'tag_color'"
        :allowSelectNothing="false"
      />

      <RFormInput
        class="w-6/12 mt-4"
        :label="$t('setting.general.labels.textColor')"
        v-model="status.text"
        componentName="EcMultiSelect"
        :options="textList"
        :isSingleSelect="true"
        :valueKey="'tag_text_color'"
        :allowSelectNothing="false"
      />

      <!-- Preview -->
      <EcBox class="mt-4">
        <EcLabel class="text-base font-medium"> {{ $t("setting.general.labels.preview") }}</EcLabel>
        <EcText
          variant="square-pill-wrapper"
          class="grid items-center h-8 w-32"
          :class="[status?.bg?.tag_color || 'bg-c1-800', status?.text?.tag_text_color || 'text-cWhite']"
        >
          {{ $t("setting.general.labels.lorem") }}</EcText
        >
      </EcBox>
      <!-- Actions -->
      <EcFlex v-if="!isLoading" class="justify-end mt-10">
        <EcButton variant="tertiary-outline" @click="handleCloseAddNewModal">
          {{ $t("setting.general.buttons.cancel") }}
        </EcButton>

        <EcButton class="ml-3" variant="primary" @click="handleClickCreateBCPStatus">
          {{ $t("setting.general.buttons.create") }}
        </EcButton>
      </EcFlex>
      <EcFlex v-else class="items-center justify-center mt-10 h-10">
        <EcSpinner type="dots" />
      </EcFlex>
    </EcBox>
  </EcModalSimple>
</template>
<script>
import { useBCPStatusNew } from "../use/master/bcp/useBCPStatusNew"
import { useColorMappingList } from "@/readybc/composables/use/useColorMappingList"

export default {
  name: "ModalAddBCPStatus",

  emits: ["handleCloseAddNewModal", "handleCallbackAddNew"],
  data() {
    return {
      isLoading: false,
    }
  },
  props: {
    isModalAddNewOpen: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {},
  setup() {
    const { status, validator$, createBCPStatus } = useBCPStatusNew()
    const { colorList, textList } = useColorMappingList()
    return {
      status,
      validator$,
      createBCPStatus,
      colorList,
      textList,
    }
  },
  methods: {
    /**
     * Cancel add new owner
     */
    async handleClickCreateBCPStatus() {
      this.validator$.$touch()
      if (this.validator$.$invalid) {
        return
      }

      this.isLoading = true
      this.status.bg_color = this.status?.bg?.tag_color
      this.status.text_color = this.status?.text?.tag_text_color

      const response = await this.createBCPStatus(this.status)

      if (response) {
        this.handleCloseAddNewModal()
        this.handleCallbackAddNew()
      }
      this.isLoading = false
    },

    /**
     * Close cancel modal
     */
    handleCloseAddNewModal() {
      this.$emit("handleCloseAddNewModal")
    },

    handleCallbackAddNew() {
      this.$emit("handleCallbackAddNew")
    },
  },

  watch: {
    isModalAddNewBCPStatusOpen() {
      this.status.name = null
      this.status.description = null
      this.status.text_color = null
      this.status.bg_color = null
      this.validator$.status.$reset()
    },
  },
}
</script>
