import { initializeApp } from "firebase/app"

const firebase = {
  install: (app, options) => {
    if (process.env.VUE_APP_FIREBASE_DATABASE_URL?.length > 0) {
      initializeApp({
        // your application settings
        apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
        authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: process.env.VUE_APP_FIREBASE_DATABASE_URL,
        projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
        storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: process.env.VUE_APP_FIREBASE_APP_ID,
        measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID,
      })
    } else {
      console.log("Unable to start realtime notification")
    }
  },
}
export { firebase }
